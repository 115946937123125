/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AttentionIcon from "../../../../assets/images/attention.png";
import FocusSuccessIcon from "../../../../assets/images/focussuccess.png";
import FocusWarningIcon from "../../../../assets/images/focuswarning.png";
import FocusAlertWarningIcon from "../../../../assets/images/alertwarning.png";
import { headers } from "../../../../public/auth";

function FocusServay({ focusData, storeId }) {
  const trendWithoutPercent = focusData.trend_percent.replace("%", "");
  let token = window.localStorage.getItem("access_token");
  let myHeaders = headers;
  let decrypted = myHeaders.Authorization;
  const decoded = token ? JSON.parse(window.atob(decrypted.split(".")[1])) : "";
  const Scopes_user = decoded.retailer.scopes;

  const Write_focusItem_scope = Scopes_user.includes("write:focusitem");
  const all_scope = Scopes_user.includes("all");
  return (
    <React.Fragment>
      <div className="focus-servay-warpper">
        <div className="focus-main-title">{focusData.name}</div>
        <div className="focus-servay-details">
          <div className="col-sm-2 col-auto">
            <div className="focus-icon">
              {focusData.trend === "Down" ? (
                trendWithoutPercent >= 0 && trendWithoutPercent <= 5 ? (
                  <img
                    alt="icon"
                    src={FocusWarningIcon}
                    className="img-fluid"
                  />
                ) : (
                  <img
                    alt="icon"
                    src={FocusAlertWarningIcon}
                    className="img-fluid"
                  />
                )
              ) : trendWithoutPercent > 5 ? (
                <img alt="icon" src={FocusSuccessIcon} className="img-fluid" />
              ) : (
                <img alt="icon" src={AttentionIcon} className="img-fluid" />
              )}
            </div>
          </div>
          <div className="col-sm-10 col-auto">
            <div className="focus-details">
              <div className="focus-title-num">
                <div className="focus-title-year">
                  <div className="focus-year">{focusData.currmonth_label}</div>
                  <div className="focus-servay-title">
                    {focusData.total_sales}
                  </div>
                </div>
                <div className="focus-servey-wrap">
                  <div className="focus-servay-num text-success">
                    {focusData.trend === "Up" ? (
                      <div className="focus-up-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="square"
                            d="m10.5 8.5l-3-3l-3 3"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div className="focus-down-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="square"
                            d="m4.5 6.5l3 3l3-3"
                          />
                        </svg>
                      </div>
                    )}
                    <span>{focusData.trend_percent}</span>
                  </div>
                  <div className="focus-on-date">{focusData.text_1}</div>
                </div>
              </div>
              <div className="focus-item-units">
                <div className="focus-title">
                  <span>{focusData.total_units_sold}</span>
                  <div>{focusData.units_sold_label}</div>
                </div>
                <div className="focus-servey-unit focus-title">
                  <span>{focusData.prev_unitsold}</span>
                  <div className="focus-on-unit">
                    {focusData.units_sold_label}
                  </div>
                </div>
              </div>
              <div className="focus-link-title focus-link-wrap">
                {(Write_focusItem_scope || all_scope) && (
                  <Link
                    target="_top"
                    className="focus-link edit"
                    to={`/FocusGroupItem?store_id=${storeId}&focus_id=${focusData.focus_id}`}
                  >
                    Edit
                  </Link>
                )}
                <Link
                  target="_top"
                  className="focus-link"
                  to={`/Focusitem?store_id=${storeId}&focus_id=${focusData.focus_id}&name=${focusData.name}&group_id=${focusData.group_id}`}
                >
                  View
                </Link>
                {/* <Link className="focus-promo" to={`/NewPromotion`}>Create Promotion</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FocusServay;
