import INITIAL_STATE from "../public/INITIAL_STATE";

import {
  departmentDataReducer,
  loginReducer,
  createUserReducer,
  deleteUserReducer,
  getUserListReducer,
  getSingleUserReducer,
  ruleTypeReducers,
  subTypeReducers,
  summaryDataReducer,
  storesReducers,
  ruleListReducers,
  graphDataReducer,
  ruleAnalysisReducers,
  getSingleRuleDataReducers,
  validationReducer,
  duringReducer,
  shopperReducer,
  offerReducer,
  offerDurationReducer,
  frequencyReducer,
  analysisPerformanceReducers,
  analysisGroupsByRuleReducers,
  submitRuleReducers,
  expireRuleReducers,
  bankOfferReducers,
  forgotPasswordReducer,
  changePasswordReducer,
  groupsReducer,
  zipcodeReducers,
  pendingApprovalReducers,
  approvePendingRuleReducer,
  createdOffersReducer,
  expireCouponReducer,
  getCreatedOffersReducer,
  approveRuleDataReducer,
  rejectRuleDataReducer,
  ruleAnalysisDownloadReportReducers,
  getDashboardOverviewReducers,
  getDashboardShopperGroupsReducers,
  getDashboardDepartmentsReducers,
  getDashboardFocusItemsReducers,
  getDashboardDepartmentDataAnalysisReducers,
  getDashboardFocusItemAnalysisReducers,
  getDashboardGroupAnalysisReducers,
  getCreateFocusItemReducers,
  createPromotionReducers,
  getSegementDataReducers,
  getPromotionsReducers,
  expirePromotionReducers,
  setDepartmentsReducers,
  getTrackedDepartmentsReducers,
  editFocusItemReducers,
  getRolesReducer,
  editUserReducer,
} from "./Reducers";
const reducers = {
  LOGIN_USER_START: loginReducer,
  LOGIN_USER_SUCCESS: loginReducer,
  LOGIN_USER_FAILURE: loginReducer,

  CREATE_USER_START: createUserReducer,
  CREATE_USER_SUCCESS: createUserReducer,
  CREATE_USER_FAILURE: createUserReducer,

  DELETE_USER_START: deleteUserReducer,
  DELETE_USER_SUCCESS: deleteUserReducer,
  DELETE_USER_FAILURE: deleteUserReducer,

  EDIT_USER_START: editUserReducer,
  EDIT_USER_SUCCESS: editUserReducer,
  EDIT_USER_FAILURE: editUserReducer,

  GET_USER_LIST_START: getUserListReducer,
  GET_USER_LIST_SUCCESS: getUserListReducer,
  GET_USER_LIST_FAILURE: getUserListReducer,

  GET_SINGLE_USER_START: getSingleUserReducer,
  GET_SINGLE_USER_SUCCESS: getSingleUserReducer,
  GET_SINGLE_USER_FAILURE: getSingleUserReducer,

  GET_ROLES_START: getRolesReducer,
  GET_ROLES_SUCCESS: getRolesReducer,
  GET_ROLES_FAILURE: getRolesReducer,

  FETCH_SUMMARY_DATA_START: summaryDataReducer,
  FETCH_SUMMARY_DATA_SUCCESS: summaryDataReducer,
  FETCH_SUMMARY_DATA_FAILURE: summaryDataReducer,

  DEPARTMENT_DATA_START: departmentDataReducer,
  DEPARTMENT_DATA_SUCCESS: departmentDataReducer,
  DEPARTMENT_DATA_FAILURE: departmentDataReducer,

  VALIDATION_DATA_START: validationReducer,
  VALIDATION_DATA_SUCCESS: validationReducer,
  VALIDATION_DATA_FAILURE: validationReducer,

  RULE_TYPE_DATA_START: ruleTypeReducers,
  RULE_TYPE_DATA_SUCCESS: ruleTypeReducers,
  RULE_TYPE_DATA_FAILURE: ruleTypeReducers,

  SUBMIT_RULE_DATA_START: submitRuleReducers,
  SUBMIT_RULE_DATA_SUCCESS: submitRuleReducers,
  SUBMIT_RULE_DATA_FAILURE: submitRuleReducers,

  SUB_TYPE_DATA_SUCCESS: subTypeReducers,
  SUB_TYPE_DATA_START: subTypeReducers,
  SUB_TYPE_DATA_FAILURE: subTypeReducers,

  STORES_DATA_START: storesReducers,
  STORES_DATA_SUCCESS: storesReducers,
  STORES_DATA_FAILURE: storesReducers,

  DURING_DATA_START: duringReducer,
  DURING_DATA_SUCCESS: duringReducer,
  DURING_DATA_FAILURE: duringReducer,

  //ruleList
  RULE_LIST_DATA_SUCCESS: ruleListReducers,
  RULE_LIST_DATA_START: ruleListReducers,
  RULE_LIST_DATA_FAILURE: ruleListReducers,

  //rule analysis reducers import
  RULE_ANALYSIS_DATA_START: ruleAnalysisReducers,
  RULE_ANALYSIS_DATA_SUCCESS: ruleAnalysisReducers,
  RULE_ANALYSIS_DATA_FAILURE: ruleAnalysisReducers,

  ANALYSIS_PERFORMANCE_DATA_START: analysisPerformanceReducers,
  ANALYSIS_PERFORMANCE_DATA_SUCCESS: analysisPerformanceReducers,
  ANALYSIS_PERFORMANCE_DATA_FAILURE: analysisPerformanceReducers,

  ANALYSIS_GROUPSBYRULE_DATA_START: analysisGroupsByRuleReducers,
  ANALYSIS_GROUPSBYRULE_DATA_SUCCESS: analysisGroupsByRuleReducers,
  ANALYSIS_GROUPSBYRULE_DATA_FAILURE: analysisGroupsByRuleReducers,

  //single Rule Data import
  RULE_DATA_START: getSingleRuleDataReducers,
  RULE_DATA_SUCCESS: getSingleRuleDataReducers,
  RULE_DATA_FAILURE: getSingleRuleDataReducers,

  FETCH_SUMMARY_GRAPHS_DATA_START: graphDataReducer,
  FETCH_SUMMARY_GRAPHS_DATA_SUCCESS: graphDataReducer,
  FETCH_SUMMARY_GRAPHS_DATA_FAILURE: graphDataReducer,

  SHOPPER_DATA_START: shopperReducer,
  SHOPPER_DATA_SUCCESS: shopperReducer,
  SHOPPER_DATA_FAILURE: shopperReducer,

  OFFER_DATA_START: offerReducer,
  OFFER_DATA_SUCCESS: offerReducer,
  OFFER_DATA_FAILURE: offerReducer,

  OFFER_DURATION_DATA_START: offerDurationReducer,
  OFFER_DURATION_DATA_SUCCESS: offerDurationReducer,
  OFFER_DURATION_DATA_FAILURE: offerDurationReducer,

  FREQUENCY_DATA_START: frequencyReducer,
  FREQUENCY_DATA_SUCCESS: frequencyReducer,
  FREQUENCY_DATA_FAILURE: frequencyReducer,

  EXPIRE_RULE_DATA_START: expireRuleReducers,
  EXPIRE_RULE_DATA_SUCCESS: expireRuleReducers,
  EXPIRE_RULE_DATA_FAILURE: expireRuleReducers,

  BANKOFFER_DATA_START: bankOfferReducers,
  BANKOFFER_DATA_SUCCESS: bankOfferReducers,
  BANKOFFER_DATA_FAILURE: bankOfferReducers,

  ZIPCODE_DATA_START: zipcodeReducers,
  ZIPCODE_DATA_SUCCESS: zipcodeReducers,
  ZIPCODE_DATA_FAILURE: zipcodeReducers,

  PENDING_APPROVAL_DATA_START: pendingApprovalReducers,
  PENDING_APPROVAL_DATA_SUCCESS: pendingApprovalReducers,
  PENDING_APPROVAL_DATA_FAILURE: pendingApprovalReducers,

  FORGOT_PASSWORD_DATA_START: forgotPasswordReducer,
  FORGOT_PASSWORD_DATA_SUCCESS: forgotPasswordReducer,
  FORGOT_PASSWORD_DATA_FAILURE: forgotPasswordReducer,

  //change password

  CHANGE_PASSWORD_DATA_START: changePasswordReducer,
  CHANGE_PASSWORD_DATA_SUCCESS: changePasswordReducer,
  CHANGE_PASSWORD_DATA_FAILURE: changePasswordReducer,

  GROUPS_DATA_START: groupsReducer,
  GROUPS_DATA_SUCCESS: groupsReducer,
  GROUPS_DATA_FAILURE: groupsReducer,

  PENDINGRULE_DATA_START: approvePendingRuleReducer,
  PENDINGRULE_DATA_SUCCESS: approvePendingRuleReducer,
  PENDINGRULE_DATA_FAILURE: approvePendingRuleReducer,

  CREATED_OFFERS_DATA_START: createdOffersReducer,
  CREATED_OFFERS_DATA_SUCCESS: createdOffersReducer,
  CREATED_OFFERS_DATA_FAILURE: createdOffersReducer,

  EXPIRE_COUPON_DATA_START: expireCouponReducer,
  EXPIRE_COUPON_DATA_SUCCESS: expireCouponReducer,
  EXPIRE_COUPON_DATA_FAILURE: expireCouponReducer,

  APPROVE_RULE_DATA_START: approveRuleDataReducer,
  APPROVE_RULE_DATA_SUCCESS: approveRuleDataReducer,
  APPROVE_RULE_DATA_FAILURE: approveRuleDataReducer,

  GET_CREATED_OFFERS_DATA_START: getCreatedOffersReducer,
  GET_CREATED_OFFERS_DATA_SUCCESS: getCreatedOffersReducer,
  GET_CREATED_OFFERS_DATA_FAILURE: getCreatedOffersReducer,

  REJECT_RULE_DATA_START: rejectRuleDataReducer,
  REJECT_RULE_DATA_SUCCESS: rejectRuleDataReducer,
  REJECT_RULE_DATA_FAILURE: rejectRuleDataReducer,

  // Analysis Download Report

  RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_START: ruleAnalysisDownloadReportReducers,
  RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_SUCCESS:
    ruleAnalysisDownloadReportReducers,
  RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_FAILURE:
    ruleAnalysisDownloadReportReducers,

  // Analysis Download Report

  DASHBOARD_OVERVIEW_DATA_START: getDashboardOverviewReducers,
  DASHBOARD_OVERVIEW_DATA_SUCCESS: getDashboardOverviewReducers,
  DASHBOARD_OVERVIEW_DATA_FAILURE: getDashboardOverviewReducers,

  DASHBOARD_SHOPPER_GROUPS_DATA_START: getDashboardShopperGroupsReducers,
  DASHBOARD_SHOPPER_GROUPS_DATA_SUCCESS: getDashboardShopperGroupsReducers,
  DASHBOARD_SHOPPER_GROUPS_DATA_FAILURE: getDashboardShopperGroupsReducers,

  DASHBOARD_DEPARTMENTS_DATA_START: getDashboardDepartmentsReducers,
  DASHBOARD_DEPARTMENTS_DATA_SUCCESS: getDashboardDepartmentsReducers,
  DASHBOARD_DEPARTMENTS_DATA_FAILURE: getDashboardDepartmentsReducers,

  DASHBOARD_FOCUS_ITEMS_DATA_START: getDashboardFocusItemsReducers,
  DASHBOARD_FOCUS_ITEMS_DATA_SUCCESS: getDashboardFocusItemsReducers,
  DASHBOARD_FOCUS_ITEMS_DATA_FAILURE: getDashboardFocusItemsReducers,

  DASHBOARD_DEPARTMENT_ANALYSIS_DATA_START:
    getDashboardDepartmentDataAnalysisReducers,
  DASHBOARD_DEPARTMENT_ANALYSIS_DATA_SUCCESS:
    getDashboardDepartmentDataAnalysisReducers,
  DASHBOARD_DEPARTMENT_ANALYSIS_DATA_FAILURE:
    getDashboardDepartmentDataAnalysisReducers,

  DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_START:
    getDashboardFocusItemAnalysisReducers,
  DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_SUCCESS:
    getDashboardFocusItemAnalysisReducers,
  DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_FAILURE:
    getDashboardFocusItemAnalysisReducers,

  DASHBOARD_GROUP_ANALYSIS_DATA_START: getDashboardGroupAnalysisReducers,
  DASHBOARD_GROUP_ANALYSIS_DATA_SUCCESS: getDashboardGroupAnalysisReducers,
  DASHBOARD_GROUP_ANALYSIS_DATA_FAILURE: getDashboardGroupAnalysisReducers,

  // create-focus-items

  CREATE_FOCUS_ITEM_START: getCreateFocusItemReducers,
  CREATE_FOCUS_ITEM_SUCCESS: getCreateFocusItemReducers,
  CREATE_FOCUS_ITEM_FAILURE: getCreateFocusItemReducers,

  // create-promotion

  CREATE_PROMOTION_START: createPromotionReducers,
  CREATE_PROMOTION_SUCCESS: createPromotionReducers,
  CREATE_PROMOTION_FAILURE: createPromotionReducers,

  // create-promotion

  GET_SEGMENT_DATA_START: getSegementDataReducers,
  GET_SEGMENT_DATA_SUCCESS: getSegementDataReducers,
  GET_SEGMENT_DATA_FAILURE: getSegementDataReducers,

  // create-promotion

  GET_PROMOTION_DATA_START: getPromotionsReducers,
  GET_PROMOTION_DATA_SUCCESS: getPromotionsReducers,
  GET_PROMOTION_DATA_FAILURE: getPromotionsReducers,

  // create-promotion

  EXPIRE_PROMOTION_START: expirePromotionReducers,
  EXPIRE_PROMOTION_SUCCESS: expirePromotionReducers,
  EXPIRE_PROMOTION_FAILURE: expirePromotionReducers,

  // set-departments
  SET_DEPARTMENT_START: setDepartmentsReducers,
  SET_DEPARTMENT_SUCCESS: setDepartmentsReducers,
  SET_DEPARTMENT_FAILURE: setDepartmentsReducers,

  // set-departments
  GET_TRACKED_DEPARTMENT_START: getTrackedDepartmentsReducers,
  GET_TRACKED_DEPARTMENT_SUCCESS: getTrackedDepartmentsReducers,
  GET_TRACKED_DEPARTMENT_FAILURE: getTrackedDepartmentsReducers,

  // edit-focus-items
  EDIT_FOCUS_ITEM_START: editFocusItemReducers,
  EDIT_FOCUS_ITEM_SUCCESS: editFocusItemReducers,
  EDIT_FOCUS_ITEM_FAILURE: editFocusItemReducers,
};

export const commonReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const reducer = reducers[type];
  return reducer ? reducer(state, payload) : state;
};
