import React from "react";
import { Form } from "react-bootstrap";
function PasetedEmailChips({
  infoEmailData,
  mode,
  upcsdata,
  handleDelete,
  email,
  setEmail,
  setEmails,
  emails,
  handleUpcs,
  name,
  setFormError,
  zipCode,
  existing_groups,
  info,
  blockInvalidChar,
  onWheel,
  emailValidation
}) {
  let filteredUniq = [];
  infoEmailData = [...new Set(infoEmailData)];

  const validation = (e) => {
    if (["Enter", "Tab", ","].includes(e.key) ||e.code === "Space" || e.keyCode === 32  ) {
      e.preventDefault();
      var value = email.trim();
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(e.target.value) && emails.length < 3) {
        if (value) {
          setEmails((prev) => [...prev, email.trim()]);
        }

        if (mode) {
          setEmails((prev) => [...prev, email.trim()]);
        }
      }
    }

    const arrUpc = email.split(" ");
    const filtered = arrUpc.filter((item) => item.trim().length > 0);
    const clubedArray = filtered.concat(emails);

    if (emails.length < 0 && filtered.length < 0) {
      filteredUniq = [...new Set(clubedArray)];
      filteredUniq.map((each) => setEmails((prev) => [...prev, each.trim()]));
      setFormError((prev) => {
        return {
          ...prev,
          upcs: ""
        };
      });
    }
  };

  return (
    <div>
      <div className="chips-header">
        <span className="chips-title"></span>
        <span className="chips-validation-message">
          <span className=""></span>
        </span>
      </div>
      <div className="chips">
        {infoEmailData &&
          infoEmailData.map((chip, i) => (
            <span className="ml-5 mt-5" key={i}>
              <span className="chip-value">{chip}</span>
              <button
                type="button"
                className="chip-delete-button"
                onClick={() => {
                  if (!mode) {
                    handleDelete(chip);
                  }
                }}>
                <span>x</span>
              </button>
            </span>
          ))}
        <Form.Control
          type="text"
          name="email"
          value={email}
          className="chips-input"
          placeholder="Enter Email..."
          disabled={mode}
          min={1}
          onChange={(e) => {
            setEmail(e.target.value);
            emailValidation(e);
          }}
          onWheel={onWheel}
          onKeyDown={(e) => {
            validation(e);
          }}
          // onPaste={handlePaste}
          onKeyUp={(e) => {
            if (["Enter", "Tab", ","].includes(e.key) ||e.code === "Space" || e.keyCode === 32  ) {
              setEmail("");
              setEmails([...new Set(emails)]);
              handleUpcs(emails, name);
            }
          }}></Form.Control>
      </div>
    </div>
  );
}

export default PasetedEmailChips;

// window scroll to error message position in react js
