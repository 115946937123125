import axios from "axios";
import {
  subTypeAction,
  ruleTypeAction,
  loginAction,
  summaryDataAction,
  departmentAction,
  submitRuleAction,
  storesAction,
  ruleAnalysisAction,
  getSingleRuleData,
  graphDataAction,
  ruleListAction,
  getValidationAction,
  duringAction,
  shopperAction,
  offerAction,
  offerDurationAction,
  analysisPerformanceAction,
  analysisGroupsByRuleAction,
  expiretRuleAction,
  bankOfferAction,
  forgotPasswordAction,
  changePasswordAction,
  groupsAction,
  zipcodeAction,
  pendingApprovalAction,
  pendingRuleAction,
  createdOffersAction,
  expireCouponAction,
  getCreatedOffersAction,
  approveRuleDataAction,
  rejectRuleDataAction,
  ruleAnalysisDownloadReportAction,
  getDashboardOverviewAction,
  getDashboardShopperGroupsAction,
  getDashboardDepartmentsAction,
  getDashboardFocusItemsAction,
  getDashboardDepartmentDataAnalysisAction,
  getDashboardFocusItemAnalysisAction,
  getDashboardGroupAnalysisAction,
  getCreatFocusItemAction,
  createPromotionAction,
  getSegementDataAction,
  getPromotionsAction,
  expirePromotionAction,
  frequencyAction,
  setDepartmentsAction,
  getTrackedDepartmentsAction,
  editFoucsItemAction,
  createUserAction,
  GetUserListAction,
  GetSingleUserAction,
  GetRolesAction,
  editUserAction,
  deleteUserAction,
} from "./Actions";
import * as Constants from "./Contants";
import { headers, postHeader } from "../public/auth";
import { data } from "jquery";

export const getLogin = (info) => async (dispatch) => {
  dispatch(loginAction(Constants.LOGIN_USER_START, {}, "", true));

  var data = new FormData();
  let myHeaders = new Headers({});
  myHeaders = headers;
  myHeaders.username = info.userName;
  myHeaders.password = info.password;
  myHeaders["clientId"] = 1;
  myHeaders["clientGuid"] = "0C2BCC44-5C2B-4904-8B3F-755C57B488BC";
  var config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "login/token",
    headers: myHeaders,
    data: data,
  };

  await axios(config)
    .then((response) => {
      // Never assign any value directrly to local Storage directly from API response. There is an entrire flow that needs to go.
      // Operations must be done in components.
      //It maintains code structure.
      dispatch(
        loginAction(
          Constants.LOGIN_USER_SUCCESS,
          response.data.Message,
          "Login Successfully!!",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            loginAction(
              Constants.LOGIN_USER_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            loginAction(
              Constants.LOGIN_USER_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            loginAction(
              Constants.LOGIN_USER_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

// Always make API call async as there are multiple API calls make at same time
export const fetchSummaryData = () => async (dispatch) => {
  //Before success and failure we need to make a dispatch call stating new flow is started with start type.
  //Line 8
  //Reason to do so. If we are calling the same API 2 times from different places. We if call it second time it's intial value be that of old one.
  // We need to make the values default.
  // Another secondary reason we need to start loading as true.
  dispatch(summaryDataAction(Constants.FETCH_SUMMARY_DATA_START, {}, "", true));

  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "dashboard/summary",
    headers: headers,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        summaryDataAction(
          Constants.FETCH_SUMMARY_DATA_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            summaryDataAction(
              Constants.FETCH_SUMMARY_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            summaryDataAction(
              Constants.FETCH_SUMMARY_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            summaryDataAction(
              Constants.FETCH_SUMMARY_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getDepartments = () => async (dispatch) => {
  dispatch(departmentAction(Constants.DEPARTMENT_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "masterdata/48?type=department&search",
    headers: postHeader,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        departmentAction(
          Constants.DEPARTMENT_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            departmentAction(
              Constants.DEPARTMENT_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            departmentAction(
              Constants.DEPARTMENT_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            departmentAction(
              Constants.DEPARTMENT_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getRuleType = () => async (dispatch) => {
  dispatch(ruleTypeAction(Constants.RULE_TYPE_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "masterdata/1?type=type&search=0",
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        ruleTypeAction(
          Constants.RULE_TYPE_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            ruleTypeAction(
              Constants.RULE_TYPE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            ruleTypeAction(
              Constants.RULE_TYPE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            ruleTypeAction(
              Constants.RULE_TYPE_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getSubType = (ruleId) => async (dispatch) => {
  dispatch(subTypeAction(Constants.SUB_TYPE_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL +
      "masterdata/48?type=sub-type&search=" +
      ruleId,
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        subTypeAction(
          Constants.SUB_TYPE_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getValidations = (ruleId, subId) => async (dispatch) => {
  dispatch(getValidationAction(Constants.VALIDATION_DATA_START, {}, "", true));
  var config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "rule/criteria-config",
    headers: headers,
    data: {
      rule: ruleId,
      "sub-rule": subId ? subId : 0,
    },
  };
  await axios(config)
    .then((response) => {
      dispatch(
        getValidationAction(
          Constants.VALIDATION_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            getValidationAction(
              Constants.VALIDATION_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            getValidationAction(
              Constants.VALIDATION_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            getValidationAction(
              Constants.VALIDATION_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getFrequency = (ruleId, subId) => async (dispatch) => {
  dispatch(frequencyAction(Constants.FREQUENCY_DATA_START, {}, "", true));

  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL +
      `masterdata/1?type=frequency&search=0&rule=${ruleId}&subtype=${subId}`,
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        frequencyAction(
          Constants.FREQUENCY_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            frequencyAction(
              Constants.FREQUENCY_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            frequencyAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            frequencyAction(
              Constants.FREQUENCY_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            frequencyAction(
              Constants.FREQUENCY_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getOfferType = () => async (dispatch) => {
  dispatch(offerAction(Constants.OFFER_DATA_START, {}, "", true));

  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL + "masterdata/48?type=offer-type&search=0",
    headers: postHeader,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        offerAction(
          Constants.OFFER_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            offerAction(
              Constants.OFFER_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            offerAction(
              Constants.OFFER_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            offerAction(
              Constants.OFFER_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getOfferDuration = () => async (dispatch) => {
  dispatch(
    offerDurationAction(Constants.OFFER_DURATION_DATA_START, {}, "", true)
  );

  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL + "masterdata/48?type=offer-type&search=0",
    headers: postHeader,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        offerDurationAction(
          Constants.OFFER_DURATION_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            offerDurationAction(
              Constants.OFFER_DURATION_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            offerDurationAction(
              Constants.OFFER_DURATION_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            offerDurationAction(
              Constants.OFFER_DURATION_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getStores = () => async (dispatch) => {
  dispatch(storesAction(Constants.STORES_DATA_START, {}, "", true));

  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "masterdata/48?type=stores&search=0",
    headers: postHeader,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        storesAction(
          Constants.STORES_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            storesAction(
              Constants.STORES_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            storesAction(
              Constants.STORES_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            storesAction(
              Constants.STORES_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

// get groups
export const getGroups = () => async (dispatch) => {
  dispatch(groupsAction(Constants.GROUPS_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL +
      "masterdata/48?type=shopper-groups&search=0",
    headers: postHeader,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        groupsAction(
          Constants.GROUPS_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            groupsAction(
              Constants.GROUPS_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            groupsAction(
              Constants.GROUPS_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            groupsAction(
              Constants.GROUPS_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
        }
      }
    });
};
export const getShoppers = () => async (dispatch) => {
  dispatch(shopperAction(Constants.SHOPPER_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL +
      "masterdata/48?type=top-n-shopper&search=0",
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        shopperAction(
          Constants.SHOPPER_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            shopperAction(
              Constants.SHOPPER_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            shopperAction(
              Constants.SHOPPER_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            shopperAction(
              Constants.SHOPPER_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getDuring = (ruleId, subId) => async (dispatch) => {
  dispatch(duringAction(Constants.DURING_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL +
      `masterdata/48?type=last-days&search=0&rule=${ruleId}&subtype=${subId}`,
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        duringAction(
          Constants.DURING_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            duringAction(
              Constants.DURING_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            duringAction(
              Constants.DURING_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            duringAction(
              Constants.DURING_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};
export const submitRule = (data, id, name, validation) => async (dispatch) => {
  dispatch(submitRuleAction(Constants.SUBMIT_RULE_DATA_START, {}, "", true));
  let updatedCouponArray;
  let updatedNotificationArray;

  if (name === "edit") {
    updatedCouponArray = data?.CouponArray?.map((each) => ({
      ...each,
      ApRuleOfferId: each.ApRuleOfferId ? each.ApRuleOfferId : 0,
      coupon_limit: each.coupon_limit ? parseInt(each.coupon_limit) : 1,
      frequency: each.frequency ? each.frequency.label.toString() : "Daily",
      offertype: each.offertype ? parseInt(each.offertype.value) : 0,
      offerstart: each.offerstart ? parseInt(each.offerstart) : 0,
      departments:
        each?.departments.length > 0
          ? each?.departments.map((num) => {
              return String(num.value);
            })
          : [],
      expirePrevCoupon: data?.expirePrevCoupon,
      includeinweeklyemail: each.includeinweeklyemail ? 1 : 0,
      isdiscountpercentage: each.isdiscountpercentage ? 1 : 0,
      isdow: each.isdow ? 1 : 0,
      isfeaturecoupon: each.isfeaturecoupon ? 1 : 0,
      IsMonthly: each.IsMonthly ?? false,
      CouponLimitInSingleTrans:
        each?.CouponLimitInSingleTrans?.value === "1" ? true : false,
      sendpushnotification: each.sendpushnotification ? 1 : 0,
      validityindays: each.validityindays ? parseInt(each.validityindays) : 1,
      postfb: each.postfb ? 1 : 0,
      sendsms: each.sendsms ? 1 : 0,

      pushdaysfrom:
        validation.includes("push notification") && each.pushdaysfrom
          ? parseFloat(each.pushdaysfrom)
          : 0,

      fbdaysfrom:
        validation.includes("facebook") && each.fbdaysfrom
          ? parseInt(each.fbdaysfrom)
          : 0,

      smsdaysfrom:
        validation.includes("sms") && each.smsdaysfrom
          ? parseInt(each.smsdaysfrom)
          : 0,

      emaildaysfrom:
        validation.includes("email") && each.emaildaysfrom
          ? parseInt(each.emaildaysfrom)
          : 0,

      sendemail: each.sendemail ? 1 : 0,
      minquantity: each.minquantity ? parseInt(each?.minquantity) : 0,
      reward_min_quantity: each.reward_min_quantity
        ? parseInt(each?.reward_min_quantity)
        : 0,
    }));
    updatedNotificationArray = data?.NotificationArray?.map((each) => ({
      ...each,
      ApRuleNotificationId: each.ApRuleNotificationId
        ? each.ApRuleNotificationId
        : 0,
      sendpushnotification: each.sendpushnotification ? 1 : 0,
      postfb: each.postfb ? 1 : 0,
      sendsms: each.sendsms ? 1 : 0,
      sendemail: each.sendemail ? 1 : 0,
    }));
  } else {
    updatedCouponArray = data?.CouponArray?.map((each) => ({
      ...each,
      coupon_limit: each.coupon_limit ? parseInt(each.coupon_limit) : 1,
      frequency: each.frequency ? each.frequency.label.toString() : "Daily",
      offertype: each.offertype ? parseInt(each.offertype.value) : 0,
      departments:
        each?.departments.length > 0
          ? each?.departments.map((num) => {
              return String(num.value);
            })
          : [],
      offerstart: each.offerstart ? parseInt(each.offerstart) : 0,
      expirePrevCoupon: data.expirePrevCoupon,
      includeinweeklyemail: each.includeinweeklyemail ? 1 : 0,
      isdiscountpercentage: each.isdiscountpercentage ? 1 : 0,
      isdow: each.isdow ? 1 : 0,
      isfeaturecoupon: each.isfeaturecoupon ? 1 : 0,
      IsMonthly: each.IsMonthly ?? false,
      CouponLimitInSingleTrans:
        each?.CouponLimitInSingleTrans?.value === "1" ? true : false,
      sendpushnotification: each.sendpushnotification ? 1 : 0,
      validityindays: each.validityindays ? parseInt(each.validityindays) : 0,
      postfb: each.postfb ? 1 : 0,
      sendsms: each.sendsms ? 1 : 0,
      sendemail: each.sendemail ? 1 : 0,
      step4Departments: [],
      pushdaysfrom:
        validation.includes("push notification") && each.pushdaysfrom
          ? parseFloat(each.pushdaysfrom)
          : 0,

      fbdaysfrom:
        validation.includes("facebook") && each.fbdaysfrom
          ? parseInt(each.fbdaysfrom)
          : 0,

      smsdaysfrom:
        validation.includes("sms") && each.smsdaysfrom
          ? parseInt(each.smsdaysfrom)
          : 0,

      emaildaysfrom:
        validation.includes("email") && each.emaildaysfrom
          ? parseInt(each.emaildaysfrom)
          : 0,

      minquantity: each.minquantity ? parseInt(each?.minquantity) : 0,
      reward_min_quantity: each.reward_min_quantity
        ? parseInt(each?.reward_min_quantity)
        : 0,
    }));
    updatedNotificationArray = data?.NotificationArray?.map((each) => ({
      ...each,
      sendpushnotification: each.sendpushnotification ? 1 : 0,
      postfb: each.postfb ? 1 : 0,
      sendsms: each.sendsms ? 1 : 0,
      sendemail: each.sendemail ? 1 : 0,
    }));
  }
  let body = {
    name: data.rule,
    ruletype: data.ruleType.value ? parseInt(data.ruleType.value) : 0,
    subruletype: data.subType.value ? parseInt(data.subType.value) : 0,
    createpoints: data.createPoints ? data.createPoints : 0,
    points: data.points ? parseFloat(data.points) : 0,
    stores:
      validation.includes("Choose stores") && data.storesList.length > 0
        ? data.storesList.map(function (store) {
            return String(store.value);
          })
        : [],
    limitstoresfieldselected:
      data.selectValue === "" && data.zipCodesData.length > 0
        ? "Enter Zip Codes"
        : data.selectValue === "" && data.storesList.length > 0
        ? data?.selectValue === "" && data.existing_groupsList.length > 0
          ? "Select Group"
          : "Choose stores"
        : data.selectValue,

    iselastic: data.iselastic ? 1 : 0,
    bankOffer: data.bankOffer ? 1 : 0,
    suggestedOffer: data.suggestOffer ? 1 : 0,
    suggestedOfferCount:
      validation.includes("suggested offer") && data.SuggestedOfferCount
        ? parseInt(data.SuggestedOfferCount)
        : 0,
    createOffer: data.createOffer ? 1 : 0,
    nooffer: data.ruleType.value === 7 || data.rewardOffer === false ? 1 : 0,

    criteria: {
      in_last_days:
        validation.includes("During") && data.during.label
          ? parseInt(data.during.label)
          : 0,
      existing_groups:
        validation.includes("Select Group") &&
        data.existing_groupsList.length > 0
          ? data.existing_groupsList.map((groups) => {
              return parseInt(groups.value);
            })
          : [],
      times_purchased:
        validation.includes("Number of times shopped") && data.numberOfTime
          ? parseInt(data.numberOfTime)
          : 0,
      max_times_purchased:
        validation.includes("Number of times shopped") &&
        data.max_times_purchased
          ? parseInt(data.max_times_purchased)
          : 0,
      visits_containing_upcs:
        validation.includes("Visits containing UPCs") &&
        data.visits_containing_upcs
          ? parseInt(data.visits_containing_upcs)
          : 0,

      max_visits_containing_upcs:
        validation.includes("Visits containing UPCs") &&
        data.max_visits_containing_upcs
          ? parseInt(data.max_visits_containing_upcs)
          : 0,

      amount_spend:
        validation.includes("Amount spend") && data.amountSpent
          ? parseFloat(data.amountSpent)
          : 0,
      max_amount_spend:
        validation.includes("Amount spend") && data.max_amount_spend
          ? parseFloat(data.max_amount_spend)
          : 0,
      TopNshoppers:
        validation.includes("Choose Top Shoppers") && data.chooseTop
          ? data.chooseTop
          : "0",
      zip_codes:
        validation.includes("Enter Zip Codes") && data.zipCodesData
          ? data.zipCodesData.map((zip) => {
              return zip;
            })
          : [],
      departments:
        validation.includes("Choose Department") && data.departments.length > 0
          ? data.departments.map((num) => {
              return String(num.value);
            })
          : [],
      upcs:
        (validation.includes("Enter UPCs") && data.upcsData.length) > 0
          ? data.upcsData.filter((upc) => upc.length > 0).map((info) => info)
          : [],
    },
    coupon: !data.suggestOffer && updatedCouponArray ? updatedCouponArray : [],

    schedule: {
      frequency: String(data.frequency.label),
      startdate: data.startDate,
      enddate: data.endDate,
      email: data.email.toString(),
      approvalrequired: data.offerRequired ? 1 : 0,
      notifyadminpostrun: data.notifyAdmin ? 1 : 0,
      groupstatsupdate: data.groupStatics ? 1 : 0,
      notifysuggestedoffers: 1,
    },
    notifications: updatedNotificationArray ? updatedNotificationArray : [],
  };
  let config;
  if (name === "edit") {
    config = {
      method: "put",
      url: process.env.REACT_APP_API_URL + "rule/" + id,
      headers: postHeader,
      data: body,
    };
  } else if (name === "copy") {
    config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "rule/",
      headers: postHeader,
      data: body,
    };
  } else
    config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "rule",
      headers: postHeader,
      data: body,
    };

  await axios(config)
    .then((response) => {
      dispatch(
        submitRuleAction(
          Constants.SUBMIT_RULE_DATA_SUCCESS,
          data,
          name === "edit" ? "Rule update Successful" : "Rule Create Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            submitRuleAction(
              Constants.SUBMIT_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            submitRuleAction(
              Constants.SUBMIT_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 502) {
          dispatch(
            submitRuleAction(
              Constants.SUBMIT_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            submitRuleAction(
              Constants.SUBMIT_RULE_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const fetchGraphData = () => async (dispatch) => {
  dispatch(
    graphDataAction(Constants.FETCH_SUMMARY_GRAPHS_DATA_START, {}, "", true)
  );
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "dashboard/stats",
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        graphDataAction(
          Constants.FETCH_SUMMARY_GRAPHS_DATA_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            loginAction(
              Constants.FETCH_SUMMARY_GRAPHS_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            loginAction(
              Constants.FETCH_SUMMARY_GRAPHS_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            loginAction(
              Constants.FETCH_SUMMARY_GRAPHS_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

//Rule Analysis Page API by Retailer GUID
export const getRuleAnalysisData =
  (ruleAnalysisGuid, limit, offset) => async (dispatch) => {
    dispatch(
      ruleAnalysisAction(Constants.RULE_ANALYSIS_DATA_START, {}, "", true)
    );
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + `rule/analysis/${ruleAnalysisGuid}`,
      headers: postHeader,
      data: { limit, offset },
    };
    await axios(config)
      .then((response) => {
        dispatch(
          ruleAnalysisAction(
            Constants.RULE_ANALYSIS_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              ruleAnalysisAction(
                Constants.RULE_ANALYSIS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              subTypeAction(
                Constants.SUB_TYPE_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              ruleAnalysisAction(
                Constants.RULE_ANALYSIS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              ruleAnalysisAction(
                Constants.RULE_ANALYSIS_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

export const getAnalysisPerformanceData =
  (ruleAnalysisGuid, limit, offset) => async (dispatch) => {
    dispatch(
      analysisPerformanceAction(
        Constants.ANALYSIS_PERFORMANCE_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "post",
      url:
        process.env.REACT_APP_API_URL + `rule/performance/${ruleAnalysisGuid}`,
      headers: postHeader,
      data: { limit, offset },
    };
    await axios(config)
      .then((response) => {
        dispatch(
          analysisPerformanceAction(
            Constants.ANALYSIS_PERFORMANCE_DATA_SUCCESS,
            response.data,
            "Successfull",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              analysisPerformanceAction(
                Constants.ANALYSIS_PERFORMANCE_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              subTypeAction(
                Constants.SUB_TYPE_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              analysisPerformanceAction(
                Constants.ANALYSIS_PERFORMANCE_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              analysisPerformanceAction(
                Constants.ANALYSIS_PERFORMANCE_DATA_FAILURE,
                {},
                "Internal Sever error",
                false
              )
            );
          }
        }
      });
  };

export const getAnalysisGroupsByRuleData =
  (ruleAnalysisGuid, limit, offset) => async (dispatch) => {
    dispatch(
      analysisGroupsByRuleAction(
        Constants.ANALYSIS_GROUPSBYRULE_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + `rule/groups/${ruleAnalysisGuid}`,
      headers: postHeader,
      data: { limit, offset },
    };
    await axios(config)
      .then((response) => {
        dispatch(
          analysisGroupsByRuleAction(
            Constants.ANALYSIS_GROUPSBYRULE_DATA_SUCCESS,
            response.data,
            "Successfull",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              analysisGroupsByRuleAction(
                Constants.ANALYSIS_GROUPSBYRULE_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              subTypeAction(
                Constants.SUB_TYPE_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              analysisGroupsByRuleAction(
                Constants.ANALYSIS_GROUPSBYRULE_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              analysisGroupsByRuleAction(
                Constants.ANALYSIS_GROUPSBYRULE_DATA_FAILURE,
                {},
                "Internal Server Error",
                false
              )
            );
          }
        }
      });
  };

// Rule Analysis : Get Rule data with rule GUID
export const getRuleData = (ruleGuid) => async (dispatch) => {
  dispatch(getSingleRuleData(Constants.RULE_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + `rule/${ruleGuid}`,
    headers: postHeader,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        getSingleRuleData(
          Constants.RULE_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            getSingleRuleData(
              Constants.RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            getSingleRuleData(
              Constants.RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            getSingleRuleData(
              Constants.RULE_DATA_FAILURE,
              {},
              "Internal Server Error",
              false
            )
          );
        }
      }
    });
};

export const ruleListData =
  (limit, offset, orderby, sortdesc) => async (dispatch) => {
    dispatch(ruleListAction(Constants.RULE_LIST_DATA_START, {}, "", true));
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "rule/search",
      headers: postHeader,
      data: {
        retailer: JSON.parse(atob(headers.Authorization.split(".")[1])).retailer
          .id,
        ruletype: 0,
        search: "",
        offset,
        limit,
        startdate: "",
        enddate: "",
        orderby: orderby,
        sortdesc: sortdesc,
      },
    };
    await axios(config)
      .then((response) => {
        dispatch(
          ruleListAction(
            Constants.RULE_LIST_DATA_SUCCESS,
            response.data,
            "Successfull",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              ruleListAction(
                Constants.RULE_LIST_DATA_FAILURE,
                error.response.data.Message,
                {}
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              subTypeAction(
                Constants.SUB_TYPE_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              ruleListAction(
                Constants.RULE_LIST_DATA_FAILURE,
                error.response.data.Message,
                {},
                false
              )
            );
          } else
            dispatch(
              ruleListAction(
                Constants.RULE_LIST_DATA_FAILURE,
                {},
                "Internal Server Error",
                false
              )
            );
        }
      });
  };

export const expireRule = (ruleGuid, exp_coupon) => async (dispatch) => {
  dispatch(expiretRuleAction(Constants.EXPIRE_RULE_DATA_START, {}, "", true));
  var config = {
    method: "put",
    url:
      process.env.REACT_APP_API_URL +
      `rule/expire/${ruleGuid}?exp-coupon=${exp_coupon}`,
    headers: postHeader,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        expiretRuleAction(
          Constants.EXPIRE_RULE_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            expiretRuleAction(
              Constants.EXPIRE_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            expiretRuleAction(
              Constants.EXPIRE_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            expiretRuleAction(
              Constants.EXPIRE_RULE_DATA_FAILURE,
              {},
              "Internal Server Error",
              false
            )
          );
        }
      }
    });
};

export const expireCoupon = (ruleguid, offerid) => async (dispatch) => {
  dispatch(
    expireCouponAction(Constants.EXPIRE_COUPON_DATA_START, {}, "", true)
  );
  var config = {
    method: "put",
    url:
      process.env.REACT_APP_API_URL +
      `rule/expirecoupon/${ruleguid}?offerid=${offerid}`,
    headers: postHeader,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        expireCouponAction(
          Constants.EXPIRE_COUPON_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            expireCouponAction(
              Constants.EXPIRE_COUPON_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            expireCouponAction(
              Constants.EXPIRE_COUPON_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            expireCouponAction(
              Constants.EXPIRE_COUPON_DATA_FAILURE,
              {},
              "Internal Server Error",
              false
            )
          );
        }
      }
    });
};

export const getBankOffer = () => async (dispatch) => {
  dispatch(bankOfferAction(Constants.BANKOFFER_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL + "masterdata/48?search=0&type=bank-offers",
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        bankOfferAction(
          Constants.BANKOFFER_DATA_START,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            bankOfferAction(
              Constants.BANKOFFER_DATA_SUCCESS,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            shopperAction(
              Constants.BANKOFFER_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            shopperAction(
              Constants.BANKOFFER_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getZipcodes = () => async (dispatch) => {
  dispatch(zipcodeAction(Constants.ZIPCODE_DATA_START, {}, "", true));
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "masterdata/48?search=0&type=zipcodes",
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        zipcodeAction(
          Constants.ZIPCODE_DATA_START,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            zipcodeAction(
              Constants.ZIPCODE_DATA_SUCCESS,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            zipcodeAction(
              Constants.ZIPCODE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            (Constants.ZIPCODE_DATA_FAILURE,
            {},
            "Internal server error.",
            false)
          );
      }
    });
};

export const getPendingApprovalData =
  (limitPerPage, newOffset) => async (dispatch) => {
    dispatch(
      pendingApprovalAction(Constants.PENDING_APPROVAL_DATA_START, {}, "", true)
    );
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `rule/pending-approval-offers?offset=${newOffset}&limit=${limitPerPage}`,
      headers: headers,
    };
    await axios(config)
      .then((response) => {
        dispatch(
          pendingApprovalAction(
            Constants.PENDING_APPROVAL_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              pendingApprovalAction(
                Constants.PENDING_APPROVAL_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              subTypeAction(
                Constants.SUB_TYPE_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              pendingApprovalAction(
                Constants.PENDING_APPROVAL_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else
            dispatch(
              pendingApprovalAction(
                Constants.PENDING_APPROVAL_DATA_FAILURE,
                {},
                "Internal server error.",
                false
              )
            );
        }
      });
  };

export const getForgotPassword = (info, user_guid) => async (dispatch) => {
  dispatch(
    forgotPasswordAction(Constants.FORGOT_PASSWORD_DATA_START, {}, "", true)
  );

  var config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "user/forgot-password",
    data: {
      email: info.email,
    },
  };
  await axios(config)
    .then((response) => {
      dispatch(
        forgotPasswordAction(
          Constants.FORGOT_PASSWORD_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            forgotPasswordAction(
              Constants.FORGOT_PASSWORD_DATA_SUCCESS,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            forgotPasswordAction(
              Constants.FORGOT_PASSWORD_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            forgotPasswordAction(
              Constants.FORGOT_PASSWORD_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
        }
      }
    });
};

//change password API

export const getChangePassword = (info, user_guid) => async (dispatch) => {
  dispatch(
    changePasswordAction(Constants.CHANGE_PASSWORD_DATA_START, {}, "", true)
  );

  var config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + `user/reset-password/${user_guid}`,
    headers: headers,
    data: {
      old_password: info.old_password,
      new_password: info.new_password,
    },
  };
  await axios(config)
    .then((response) => {
      dispatch(
        changePasswordAction(
          Constants.CHANGE_PASSWORD_DATA_SUCCESS,
          response.data.Message,
          "Password Updated Successfully !!!",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            changePasswordAction(
              Constants.CHANGE_PASSWORD_DATA_SUCCESS,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            changePasswordAction(
              Constants.CHANGE_PASSWORD_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            changePasswordAction(
              Constants.CHANGE_PASSWORD_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
        }
      }
    });
};

// pending Rule Approved API

export const getApprovedPendingData =
  ({ ruleId, offerid }) =>
  async (dispatch) => {
    dispatch(pendingRuleAction(Constants.PENDINGRULE_DATA_START, {}, "", true));
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `rule/approve-offers/${ruleId}?offerid=${offerid}&approve-all=0`,

      headers: headers,
      data: {
        ruleId,
        offerid,
      },
    };
    await axios(config)
      .then((response) => {
        dispatch(
          pendingRuleAction(
            Constants.PENDINGRULE_DATA_SUCCESS,
            response.data.Message,
            "Successful",
            false
          )
        );
      })

      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              pendingRuleAction(
                Constants.PENDINGRULE_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              subTypeAction(
                Constants.SUB_TYPE_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              pendingRuleAction(
                Constants.PENDINGRULE_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              pendingRuleAction(
                Constants.PENDINGRULE_DATA_FAILURE,
                {},
                "Internal Server Error",
                false
              )
            );
          }
        }
      });
  };

// Approve Rule
export const approveRuleData = (ruleId) => async (dispatch) => {
  dispatch(
    approveRuleDataAction(Constants.APPROVE_RULE_DATA_START, {}, "", true)
  );
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + `rule/approve-rule/${ruleId}`,

    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        approveRuleDataAction(
          Constants.APPROVE_RULE_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })

    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            approveRuleDataAction(
              Constants.APPROVE_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            approveRuleDataAction(
              Constants.APPROVE_RULE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            approveRuleDataAction(
              Constants.APPROVE_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            approveRuleDataAction(
              Constants.APPROVE_RULE_DATA_FAILURE,
              {},
              "Internal Server Error",
              false
            )
          );
        }
      }
    });
};
//Reject Rule

export const rejectRuleData = (ruleId) => async (dispatch) => {
  dispatch(
    rejectRuleDataAction(Constants.REJECT_RULE_DATA_START, {}, "", true)
  );
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + `rule/reject-rule/${ruleId}`,

    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        rejectRuleDataAction(
          Constants.REJECT_RULE_DATA_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })

    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            rejectRuleDataAction(
              Constants.REJECT_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            rejectRuleDataAction(
              Constants.REJECT_RULE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            rejectRuleDataAction(
              Constants.REJECT_RULE_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            rejectRuleDataAction(
              Constants.REJECT_RULE_DATA_FAILURE,
              {},
              "Internal Server Error",
              false
            )
          );
        }
      }
    });
};

export const getCreatedOffersData = (ruleGuid) => async (dispatch) => {
  dispatch(
    createdOffersAction(Constants.CREATED_OFFERS_DATA_START, {}, "", true)
  );
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + `rule/created-offers/${ruleGuid}`,
    headers: postHeader,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        createdOffersAction(
          Constants.CREATED_OFFERS_DATA_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            createdOffersAction(
              Constants.CREATED_OFFERS_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            subTypeAction(
              Constants.SUB_TYPE_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            createdOffersAction(
              Constants.CREATED_OFFERS_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            createdOffersAction(
              Constants.CREATED_OFFERS_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

export const getcreatedOffers = (ruleGuid, groupid) => async (dispatch) => {
  dispatch(
    getCreatedOffersAction(
      Constants.GET_CREATED_OFFERS_DATA_START,
      {},
      "",
      true
    )
  );
  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL +
      `rule/created-offers/${ruleGuid}?groupid=${groupid}`,
    headers: postHeader,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        getCreatedOffersAction(
          Constants.GET_CREATED_OFFERS_DATA_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            getCreatedOffersAction(
              Constants.GET_CREATED_OFFERS_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            getCreatedOffersAction(
              Constants.GET_CREATED_OFFERS_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else
          dispatch(
            getCreatedOffersAction(
              Constants.GET_CREATED_OFFERS_DATA_FAILURE,
              {},
              "Internal server error.",
              false
            )
          );
      }
    });
};

//Analysis Download Report

export const getRuleAnalysisDownloadReportData =
  (ruleAnalysisGuid) => async (dispatch) => {
    dispatch(
      ruleAnalysisDownloadReportAction(
        Constants.RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `rule/analysis-report/${ruleAnalysisGuid}`,
      headers: postHeader,
    };
    await axios(config)
      .then((response) => {
        dispatch(
          ruleAnalysisDownloadReportAction(
            Constants.RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
        window.location.replace(response.data.Message);
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              ruleAnalysisDownloadReportAction(
                Constants.RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              subTypeAction(
                Constants.SUB_TYPE_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              ruleAnalysisDownloadReportAction(
                Constants.RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              ruleAnalysisDownloadReportAction(
                Constants.RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

export const getDashboardOverviewData = (storeId) => async (dispatch) => {
  dispatch(
    getDashboardOverviewAction(
      Constants.DASHBOARD_OVERVIEW_DATA_START,
      {},
      "",
      true
    )
  );
  var config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL + `dashboard/overview?storeid=${storeId}`,
    headers: postHeader,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        getDashboardOverviewAction(
          Constants.DASHBOARD_OVERVIEW_DATA_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            getDashboardOverviewAction(
              Constants.DASHBOARD_OVERVIEW_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            getDashboardOverviewAction(
              Constants.DASHBOARD_OVERVIEW_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            getDashboardOverviewAction(
              Constants.DASHBOARD_OVERVIEW_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            getDashboardOverviewAction(
              Constants.DASHBOARD_OVERVIEW_DATA_FAILURE,
              {},
              "Internal server error",
              false
            )
          );
        }
      }
    });
};

export const getDashboardShopperGroupsData =
  (storeId, deptId, focusId, groupId) => async (dispatch) => {
    dispatch(
      getDashboardShopperGroupsAction(
        Constants.DASHBOARD_SHOPPER_GROUPS_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `dashboard/shoppergroups?storeid=${storeId}&dept_id=${deptId}&focus_id=${focusId}`,
      headers: postHeader,
    };
    await axios(config)
      .then((response) => {
        dispatch(
          getDashboardShopperGroupsAction(
            Constants.DASHBOARD_SHOPPER_GROUPS_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              getDashboardShopperGroupsAction(
                Constants.DASHBOARD_SHOPPER_GROUPS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              getDashboardShopperGroupsAction(
                Constants.DASHBOARD_SHOPPER_GROUPS_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              getDashboardShopperGroupsAction(
                Constants.DASHBOARD_SHOPPER_GROUPS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              getDashboardShopperGroupsAction(
                Constants.DASHBOARD_SHOPPER_GROUPS_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

export const getDashboardDepartmentsData =
  (storeId, focusId, groupId) => async (dispatch) => {
    dispatch(
      getDashboardDepartmentsAction(
        Constants.DASHBOARD_DEPARTMENTS_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `dashboard/departments?storeid=${storeId}&focus_id=${focusId}&group_id=${groupId}`,
      headers: postHeader,
    };
    await axios(config)
      .then((response) => {
        dispatch(
          getDashboardDepartmentsAction(
            Constants.DASHBOARD_DEPARTMENTS_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              getDashboardDepartmentsAction(
                Constants.DASHBOARD_DEPARTMENTS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              getDashboardDepartmentsAction(
                Constants.DASHBOARD_DEPARTMENTS_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              getDashboardDepartmentsAction(
                Constants.DASHBOARD_DEPARTMENTS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              getDashboardDepartmentsAction(
                Constants.DASHBOARD_DEPARTMENTS_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

export const getDashboardFocusItemssData =
  (storeId, groupId, sortDirection, departmentId) => async (dispatch) => {
    dispatch(
      getDashboardFocusItemsAction(
        Constants.DASHBOARD_FOCUS_ITEMS_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `dashboard/focus-items?storeid=${storeId}&group_id=${groupId}&sort_by=${sortDirection}&dept_id=${
          departmentId ?? 0
        }`,
      headers: postHeader,
    };
    await axios(config)
      .then((response) => {
        dispatch(
          getDashboardFocusItemsAction(
            Constants.DASHBOARD_FOCUS_ITEMS_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              getDashboardFocusItemsAction(
                Constants.DASHBOARD_FOCUS_ITEMS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              getDashboardFocusItemsAction(
                Constants.DASHBOARD_FOCUS_ITEMS_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              getDashboardFocusItemsAction(
                Constants.DASHBOARD_FOCUS_ITEMS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              getDashboardFocusItemsAction(
                Constants.DASHBOARD_FOCUS_ITEMS_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

export const getDashboardDepartmentDataAnalysisData =
  (storeId, dept_id) => async (dispatch) => {
    dispatch(
      getDashboardDepartmentDataAnalysisAction(
        Constants.DASHBOARD_DEPARTMENT_ANALYSIS_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `dashboard/department-analysis?storeid=${storeId}&dept_id=${dept_id}`,
      headers: postHeader,
    };
    await axios(config)
      .then((response) => {
        dispatch(
          getDashboardDepartmentDataAnalysisAction(
            Constants.DASHBOARD_DEPARTMENT_ANALYSIS_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              getDashboardDepartmentDataAnalysisAction(
                Constants.DASHBOARD_DEPARTMENT_ANALYSIS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              getDashboardDepartmentDataAnalysisAction(
                Constants.DASHBOARD_DEPARTMENT_ANALYSIS_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              getDashboardDepartmentDataAnalysisAction(
                Constants.DASHBOARD_DEPARTMENT_ANALYSIS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              getDashboardDepartmentDataAnalysisAction(
                Constants.DASHBOARD_DEPARTMENT_ANALYSIS_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

export const getDashboardFocusItemAnalysisData =
  (storeId, focusId) => async (dispatch) => {
    dispatch(
      getDashboardFocusItemAnalysisAction(
        Constants.DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `dashboard/focus-item-analysis?storeid=${storeId}&focus_id=${focusId}`,
      headers: postHeader,
    };
    await axios(config)
      .then((response) => {
        dispatch(
          getDashboardFocusItemAnalysisAction(
            Constants.DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              getDashboardFocusItemAnalysisAction(
                Constants.DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_FAILURE,
                {},
                error.response.data.Details,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              getDashboardFocusItemAnalysisAction(
                Constants.DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              getDashboardFocusItemAnalysisAction(
                Constants.DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_FAILURE,
                {},
                error.response.data.Details,
                false
              )
            );
          } else {
            dispatch(
              getDashboardFocusItemAnalysisAction(
                Constants.DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

export const getDashboardGroupAnalysisData =
  (storeId, groupId) => async (dispatch) => {
    dispatch(
      getDashboardGroupAnalysisAction(
        Constants.DASHBOARD_GROUP_ANALYSIS_DATA_START,
        {},
        "",
        true
      )
    );
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `dashboard/shoppergroup-analysis?storeid=${storeId}&group_id=${groupId}`,
      headers: postHeader,
    };
    await axios(config)
      .then((response) => {
        dispatch(
          getDashboardGroupAnalysisAction(
            Constants.DASHBOARD_GROUP_ANALYSIS_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              getDashboardGroupAnalysisAction(
                Constants.DASHBOARD_GROUP_ANALYSIS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              getDashboardGroupAnalysisAction(
                Constants.DASHBOARD_GROUP_ANALYSIS_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              getDashboardGroupAnalysisAction(
                Constants.DASHBOARD_GROUP_ANALYSIS_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              getDashboardGroupAnalysisAction(
                Constants.DASHBOARD_GROUP_ANALYSIS_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

// create-focus-item

export const getCreateFocusItemData = (info) => async (dispatch) => {
  dispatch(
    getCreatFocusItemAction(Constants.CREATE_FOCUS_ITEM_START, {}, "", true)
  );
  let data = JSON.stringify({
    focusid: info?.focusid,
    storeid: info?.storeid === "0" ? 0 : parseInt(info?.storeid),
    focus_grp_name: info?.focus_grp_name,
    upcs: info?.upcs?.filter((item) => item),
    departmentid: info?.departmentId === "0" ? 0 : parseInt(info?.departmentId),
  });
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + `dashboard/create-focus-items`,
    headers: headers,
    data: data,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        getCreatFocusItemAction(
          Constants.CREATE_FOCUS_ITEM_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            getCreatFocusItemAction(
              Constants.CREATE_FOCUS_ITEM_FAILURE,
              {},
              error.response.data.Details,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            getCreatFocusItemAction(
              Constants.CREATE_FOCUS_ITEM_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            getCreatFocusItemAction(
              Constants.CREATE_FOCUS_ITEM_FAILURE,
              {},
              error.response.data.Details,
              false
            )
          );
        } else {
          dispatch(
            getCreatFocusItemAction(
              Constants.CREATE_FOCUS_ITEM_FAILURE,
              {},
              error.response.data.Details,
              false
            )
          );
        }
      }
    });
};

// create-promotion

export const createPromotion = (value) => async (dispatch) => {
  dispatch(
    createPromotionAction(Constants.CREATE_PROMOTION_START, {}, "", true)
  );
  let purchaseUPCs = [];
  let rewardUpCs = [];
  if (parseInt(value.offerId) > 0) {
    purchaseUPCs = value.purchaseUPCs;
    rewardUpCs = value.rewardUPCs;
  } else {
    purchaseUPCs =
      value.purchaseUPCs.length > 0
        ? value.purchaseUPCs
            .split("\n")
            .map((e) => e.trim())
            .filter((a) => a.length !== 0)
        : [];
    rewardUpCs =
      value.rewardUPCs.length > 0
        ? value.rewardUPCs
            .split("\n")
            .map((e) => e.trim())
            .filter((a) => a.length !== 0)
        : [];
  }
  let coupons = [
    {
      OfferName: value.offerTitle,
      OfferDescription: value.offerDetails,
      OfferImageURL: value.OfferImageURL,
      AdditionalDetails: value.additionalDescription,
      Disclaimer: value.offerDesclaimer,
      RetailerOfferTypeId: parseInt(value.offerType),
      MinQty: value.MinPurchaseQty ? parseInt(value.MinPurchaseQty) : 0,
      RewardMinQty: value.rewardQty ? parseInt(value.rewardQty) : 0,
      IsDiscountPercentage: value.isDiscuountPercentage,
      DiscountAmount: value.discountAmount ? String(value.discountAmount) : "0",
      MinAmountRequired: value.MinPurcahseAmount
        ? String(value.MinPurcahseAmount)
        : "0",
      MaxDiscountAmount: value.maxDiscountAmount
        ? String(value.maxDiscountAmount)
        : "0",
      CouponStart: value.OfferStartedOn + "T00:00:00", //ISO Format if adding milliseconds add 6 digits of milliseconds e.g ('2024-03-31T00:00:00.000000')
      CouponEnd: value.OfferExpiresOn + "T00:00:00", //ISO Format if adding milliseconds add 6 digits of milliseconds e.g ('2024-03-31T00:00:00.000000')
      CouponLimit: value.couponLimit ? parseInt(value.couponLimit) : 0,
      PurchaseUPCList: purchaseUPCs,
      RewardUPCList: rewardUpCs,
      ExcludeDeptList:
        value.excludedDepartments.length > 0
          ? value.excludedDepartments.map((num) => {
              return String(num.value);
            })
          : [],
      IncludeInWeeklyEmail: true,
      IsFeatured: value.makeItFeatured,
      IsDOW: value.makeItFeatured,
      IsTargeted: false,
      IsRecurring: value.makeItRecurring,
      RecurringFrequency: value.makeItRecurring ? value.offerFrequency : "",
      IsMonthly: value.monthlyDeal,
      CouponLimitInSingleTrans:
        value.CouponLimitInSingleTrans === "1" ? true : false,
    },
  ];

  let data = {
    ExpirePrevCoupon: false,
    BQShopperGroup: value.shopperGroups
      ? [String(value.shopperGroups.value)]
      : [],
    TargettedShopperCount:
      value.ShopperFilter.length > 0
        ? parseFloat(value.ShopperFilter?.split("-")[1].replace(/,/g, ""))
        : 0,
    ShopperFilter:
      value.ShopperFilter.length > 0 ? value.ShopperFilter?.split("-")[0] : "",
    Store: value.storeId === "0" ? [] : [String(value.storeId)],
    GroupDeptList:
      value.selectedDepartments.length > 0
        ? value.selectedDepartments.map((num) => {
            return String(num.value);
          })
        : [],
    BQFocusItemsList:
      value.selectedFocusItems.length > 0
        ? value.selectedFocusItems.map((num) => {
            return String(num.value);
          })
        : [],
    Coupons: coupons,
  };
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + `rule/predefined`,
    headers: headers,
    data: data,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        createPromotionAction(
          Constants.CREATE_PROMOTION_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            createPromotionAction(
              Constants.CREATE_PROMOTION_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            createPromotionAction(
              Constants.CREATE_PROMOTION_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            createPromotionAction(
              Constants.CREATE_PROMOTION_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            createPromotionAction(
              Constants.CREATE_PROMOTION_FAILURE,
              {},
              "Internal server error",
              false
            )
          );
        }
      }
    });
};

// Fetch Segments

export const getSegementData = (value) => async (dispatch) => {
  dispatch(
    getSegementDataAction(Constants.GET_SEGMENT_DATA_START, {}, "", true)
  );
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + `dashboard/getsegments`,
    headers: headers,
    data: value,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        getSegementDataAction(
          Constants.GET_SEGMENT_DATA_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            getSegementDataAction(
              Constants.GET_SEGMENT_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            getSegementDataAction(
              Constants.GET_SEGMENT_DATA_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            getSegementDataAction(
              Constants.GET_SEGMENT_DATA_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            getSegementDataAction(
              Constants.GET_SEGMENT_DATA_FAILURE,
              {},
              "Internal server error",
              false
            )
          );
        }
      }
    });
};

// Fetch predefined Promos

export const getPredefinedPromoData =
  (groupId, limit, offset, orderby, sortdesc) => async (dispatch) => {
    dispatch(
      getPromotionsAction(Constants.GET_PROMOTION_DATA_START, {}, "", true)
    );
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL + `rule/list-predefined`,
      headers: headers,
      data: {
        group_id: groupId,
        department_id: "",
        focus_id: "",
        search: "",
        offset,
        limit,
        startdate: "",
        enddate: "",
        orderby: orderby,
        sortdesc: sortdesc,
      },
    };
    await axios(config)
      .then((response) => {
        dispatch(
          getPromotionsAction(
            Constants.GET_PROMOTION_DATA_SUCCESS,
            response.data,
            "Successful",
            false
          )
        );
      })
      .catch((error) => {
        if (error) {
          if (error.response && error.response.status === 404) {
            dispatch(
              getPromotionsAction(
                Constants.GET_PROMOTION_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else if (error.response && error.response.status === 403) {
            dispatch(
              getPromotionsAction(
                Constants.GET_PROMOTION_DATA_FAILURE,
                {},
                "Session Has Expired",
                false
              )
            );
          } else if (error.response && error.response.status === 400) {
            dispatch(
              getPromotionsAction(
                Constants.GET_PROMOTION_DATA_FAILURE,
                {},
                error.response.data.Message,
                false
              )
            );
          } else {
            dispatch(
              getPromotionsAction(
                Constants.GET_PROMOTION_DATA_FAILURE,
                {},
                "Internal server error",
                false
              )
            );
          }
        }
      });
  };

// Expire Promotion

export const expirePromotion = (guid) => async (dispatch) => {
  dispatch(
    expirePromotionAction(Constants.EXPIRE_PROMOTION_START, {}, "", true)
  );
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}rule/expire-predefined?promoguid=${guid}`,
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        expirePromotionAction(
          Constants.EXPIRE_PROMOTION_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            expirePromotionAction(
              Constants.EXPIRE_PROMOTION_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            expirePromotionAction(
              Constants.EXPIRE_PROMOTION_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            expirePromotionAction(
              Constants.EXPIRE_PROMOTION_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            expirePromotionAction(
              Constants.EXPIRE_PROMOTION_FAILURE,
              {},
              "Internal server error",
              false
            )
          );
        }
      }
    });
};

// Set Departments

export const setDepartments = (data) => async (dispatch) => {
  dispatch(setDepartmentsAction(Constants.SET_DEPARTMENT_START, {}, "", true));
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}dashboard/set-departments`,
    headers: headers,
    data: data,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        setDepartmentsAction(
          Constants.SET_DEPARTMENT_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            setDepartmentsAction(
              Constants.SET_DEPARTMENT_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            setDepartmentsAction(
              Constants.SET_DEPARTMENT_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            setDepartmentsAction(
              Constants.SET_DEPARTMENT_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            setDepartmentsAction(
              Constants.SET_DEPARTMENT_FAILURE,
              {},
              "Internal server error",
              false
            )
          );
        }
      }
    });
};

// get tracked departments

export const getTrackedDepartments = () => async (dispatch) => {
  dispatch(
    getTrackedDepartmentsAction(
      Constants.GET_TRACKED_DEPARTMENT_START,
      {},
      "",
      true
    )
  );
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}dashboard/get-tracked-departments`,
    headers: headers,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        getTrackedDepartmentsAction(
          Constants.GET_TRACKED_DEPARTMENT_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            getTrackedDepartmentsAction(
              Constants.GET_TRACKED_DEPARTMENT_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            getTrackedDepartmentsAction(
              Constants.GET_TRACKED_DEPARTMENT_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            getTrackedDepartmentsAction(
              Constants.GET_TRACKED_DEPARTMENT_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            getTrackedDepartmentsAction(
              Constants.GET_TRACKED_DEPARTMENT_FAILURE,
              {},
              "Internal server error",
              false
            )
          );
        }
      }
    });
};

// edit-focus-item

export const editFocusItemData = (info) => async (dispatch) => {
  dispatch(editFoucsItemAction(Constants.EDIT_FOCUS_ITEM_START, {}, "", true));
  let data = JSON.stringify({
    focus_id: info?.focusid,
    storeid: info?.storeid === "0" ? 0 : parseInt(info?.storeid),
    focus_grp_name: info?.focus_grp_name,
    upcs: info?.upcs,
    departmentid: info?.departmentId === "0" ? 0 : parseInt(info?.departmentId),
  });
  var config = {
    method: "put",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + `dashboard/edit-focus-items`,
    headers: headers,
    data: data,
  };
  await axios(config)
    .then((response) => {
      dispatch(
        editFoucsItemAction(
          Constants.EDIT_FOCUS_ITEM_SUCCESS,
          response.data,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            editFoucsItemAction(
              Constants.EDIT_FOCUS_ITEM_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            editFoucsItemAction(
              Constants.EDIT_FOCUS_ITEM_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            editFoucsItemAction(
              Constants.EDIT_FOCUS_ITEM_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            editFoucsItemAction(
              Constants.EDIT_FOCUS_ITEM_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        }
      }
    });
};

//Create User

export const CreateUserCall = (info) => async (dispatch) => {
  dispatch(createUserAction(Constants.CREATE_USER_START, {}, "", true));

  var data = JSON.stringify(info);

  var config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "user",
    headers: headers,
    data: data,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        createUserAction(
          Constants.CREATE_USER_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
      // if (response.status === 200 || response.status === 201) {
      setTimeout(() => {
        window.location.replace("/Userlist");
      }, 2000);
      // }
    })
    .catch((err) => {
      if (err) {
        if (err.response && err.response.status === 404) {
          dispatch(
            createUserAction(
              Constants.CREATE_USER_FAILURE,
              {},
              err.response.data.Message,
              false
            )
          );
        } else if (err.response && err.response.status === 403) {
          dispatch(
            createUserAction(
              Constants.CREATE_USER_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            createUserAction(
              Constants.CREATE_USER_FAILURE,
              {},
              err.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            createUserAction(
              Constants.CREATE_USER_FAILURE,
              {},
              "Internal Server Error.",
              false
            )
          );
        }
      }
    });
};

//Edit User Details
export const EditUserCall = (info) => async (dispatch) => {
  dispatch(editUserAction(Constants.EDIT_USER_START, {}, "", true));
  var data = JSON.stringify(info?.userBody);
  var config = {
    method: "put",
    url: process.env.REACT_APP_API_URL + `user/${info?.userGUID}`,
    headers: headers,
    data: data,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        editUserAction(
          Constants.EDIT_USER_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
      // if (response.status === 200 || response.status === 201) {
      setTimeout(() => {
        window.location.replace("/Userlist");
      }, 2000);
      // }
    })
    .catch((err) => {
      if (err) {
        if (err.response && err.response.status === 404) {
          dispatch(
            editUserAction(
              Constants.EDIT_USER_FAILURE,
              {},
              err.response.data.Message,
              false
            )
          );
        } else if (err.response && err.response.status === 403) {
          dispatch(
            editUserAction(
              Constants.EDIT_USER_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            editUserAction(
              Constants.EDIT_USER_FAILURE,
              {},
              err.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            editUserAction(
              Constants.EDIT_USER_FAILURE,
              {},
              "Internal Server Error.",
              false
            )
          );
        }
      }
    });
};

//GetUserList all
export const getUserListCall = (info) => async (dispatch) => {
  dispatch(GetUserListAction(Constants.GET_USER_LIST_START, [], "", true));
  var data = {};
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "user/userlist",
    headers: headers,
    data: data,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        GetUserListAction(
          Constants.GET_USER_LIST_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            GetUserListAction(
              Constants.GET_USER_LIST_FAILURE,
              [],
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            GetUserListAction(
              Constants.GET_USER_LIST_FAILURE,
              [],
              "Session Has Expired.",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            GetUserListAction(
              Constants.GET_USER_LIST_FAILURE,
              [],
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            GetUserListAction(
              Constants.GET_USER_LIST_FAILURE,
              [],
              "Internal Server Error.",
              false
            )
          );
        }
      }
    });
};

//Get Single User Details
export const getSingleUserCall = (info) => async (dispatch) => {
  dispatch(GetSingleUserAction(Constants.GET_SINGLE_USER_START, {}, "", true));
  var userGuid = info;
  var data = {};
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + `user/${userGuid}`,
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      dispatch(
        GetSingleUserAction(
          Constants.GET_SINGLE_USER_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            GetSingleUserAction(
              Constants.GET_SINGLE_USER_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            GetSingleUserAction(
              Constants.GET_SINGLE_USER_FAILURE,
              {},
              "Session Has Expired.",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            GetSingleUserAction(
              Constants.GET_SINGLE_USER_FAILURE,
              {},
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            GetSingleUserAction(
              Constants.GET_SINGLE_USER_FAILURE,
              {},
              "Internal Server Error.",
              false
            )
          );
        }
      }
    });
};

//Get Roles Details
export const getRolesCall = (info) => async (dispatch) => {
  dispatch(GetRolesAction(Constants.GET_ROLES_START, [], "", true));

  var data = {};
  var config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + `user/getroles`,
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      dispatch(
        GetRolesAction(
          Constants.GET_ROLES_SUCCESS,
          response.data.Message,
          "Successful",
          false
        )
      );
    })
    .catch((error) => {
      if (error) {
        if (error.response && error.response.status === 404) {
          dispatch(
            GetRolesAction(
              Constants.GET_ROLES_FAILURE,
              [],
              error.response.data.Message,
              false
            )
          );
        } else if (error.response && error.response.status === 403) {
          dispatch(
            GetRolesAction(
              Constants.GET_ROLES_FAILURE,
              [],
              "Session Has Expired.",
              false
            )
          );
        } else if (error.response && error.response.status === 400) {
          dispatch(
            GetRolesAction(
              Constants.GET_ROLES_FAILURE,
              [],
              error.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            GetRolesAction(
              Constants.GET_ROLES_FAILURE,
              [],
              "Internal Server Error.",
              false
            )
          );
        }
      }
    });
};

//Delete User call
export const deleteUserCall = (info) => async (dispatch) => {
  dispatch(deleteUserAction(Constants.DELETE_USER_START, {}, "", true));

  var data = JSON.stringify(info.reqBody);

  var config = {
    method: "delete",
    url: process.env.REACT_APP_API_URL + `user/${info.userGUID}`,
    headers: headers,
    data: data,
  };

  await axios(config)
    .then((response) => {
      dispatch(
        deleteUserAction(
          Constants.DELETE_USER_SUCCESS,
          response.data.Message,
          "User Deleted Successfully",
          false
        )
      );
      // if (response.status === 200 || response.status === 201) {
      setTimeout(() => {
        window.location.replace("/Userlist");
      }, 500);
      // }
    })
    .catch((err) => {
      if (err) {
        if (err.response && err.response.status === 404) {
          dispatch(
            deleteUserAction(
              Constants.DELETE_USER_FAILURE,
              {},
              err.response.data.Message,
              false
            )
          );
        } else if (err.response && err.response.status === 403) {
          dispatch(
            deleteUserAction(
              Constants.DELETE_USER_FAILURE,
              {},
              "Session Has Expired",
              false
            )
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            deleteUserAction(
              Constants.DELETE_USER_FAILURE,
              {},
              err.response.data.Message,
              false
            )
          );
        } else {
          dispatch(
            deleteUserAction(
              Constants.DELETE_USER_FAILURE,
              {},
              "Internal Server Error.",
              false
            )
          );
        }
      }
    });
};
