import React, { useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

// import BrandLogo from "../../assets/images/brand-logo.png";
import CalenderBlack from "../../assets/images/calender-black.svg";
import CalenderRed from "../../assets/images/calender-red.svg";
import UpArrow from "../../assets/images/up.svg";
import DownArrow from "../../assets/images/down.svg";
import dollarSign from "../../assets/images/dollar-sign.png";
import expire from "../../assets/images/expire.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getRuleAnalysisData,
  getAnalysisPerformanceData,
  getAnalysisGroupsByRuleData,
  getRuleData,
  getCreatedOffersData,
  getcreatedOffers,
  expireCoupon,
  getRuleAnalysisDownloadReportData,
} from "../../redux/API";
import { useLocation } from "react-router-dom";
import Paginate from "../Paginate";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { DateFormat } from "../../public/PublicFunctionality";

function MyVerticallyCenteredModal(props) {
  let offerid = props.offerid;

  let ruleguid = props.ruleguid;

  const dispatch = useDispatch();

  const expCoupon = () => {
    dispatch(expireCoupon(ruleguid, offerid));

    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {" "}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title> {" "}
      </Modal.Header>{" "}
      <Modal.Body style={{ fontSize: 20, textAlign: "center" }}>
        Are you sure you want to expire the Coupon?{" "}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button variant="secondary" onClick={expCoupon}>
          Expire{" "}
        </Button>
        <Button onClick={props.onHide}>Close</Button>{" "}
      </Modal.Footer>{" "}
    </Modal>
  );
}

function CenteredModal(props) {
  const data = useSelector((state) => state.getCreatedOffersData);
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Coupons targeted for Group Name
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-striped">
          {data?.Message?.length < 1 ? (
            <>
              <thead style={{ fontWeight: "600" }}>No coupon Exists</thead>
              <tbody></tbody>
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Validity</th>
                  <th>Clips</th>
                  <th>Redeems</th>
                  <th>Total Rev</th>
                </tr>
              </thead>
              <tbody>
                {data?.Message?.map((each, i) => (
                  <tr>
                    <td>{each.OfferName}</td>
                    <td>
                      {each.CouponStartDate} <p>to</p>
                      {each.CouponEndDate}
                    </td>
                    <td>{each.Clips}</td>
                    <td>{each.Redeems}</td>
                    <td>{each.TotalRevenue}</td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function RuleAnalysis() {
  const dispatch = useDispatch();
  const location = useLocation();
  const ruleguid = location?.state?.ruleguid;
  const expireCouponMessage = useSelector((state) => state.expireCouponMessage);

  const data = useSelector((state) => state);
  const ruleAnalysisData = data?.ruleAnalysisData.Message;
  const createdOffersData = useSelector((state) => state.createdOffersData);

  const ruleAnalysisPerformanceData =
    data?.ruleAnalysisPerformanceData?.Message;
  const ruleAnalysisGroupsByRuleData =
    data?.ruleAnalysisGroupsByRuleData?.Message?.entries;
  const ruleAnalysisLoading = data?.ruleAnalysisLoading;
  const ruleAnalysisPerformanceLoading = data?.ruleAnalysisPerformanceLoading;

  const ruleAnalysisGroupsByRuleLoading = data?.ruleAnalysisGroupsByRuleLoading;
  const getSingleRuleData = data?.singleRuleData;
  const ruleAnalysisDownloadLoading = data?.ruleAnalysisDownloadLoading;
  //loading Rule Analysis Data
  const [itemOffset, setItemOffset] = useState(0);
  const limitPerPage = 10; //Limit Value
  const [couponsModalShow, setCouponsModalShow] = React.useState(false);
  const [offerId, setOfferId] = useState("");
  const [groupModalShow, setGroupModalShow] = React.useState(false);
  useEffect(() => {
    dispatch(getRuleAnalysisData(ruleguid, limitPerPage, itemOffset));
    dispatch(getAnalysisPerformanceData(ruleguid, limitPerPage, itemOffset));
    dispatch(getAnalysisGroupsByRuleData(ruleguid, limitPerPage, itemOffset));
    // dispatch(getRuleData(ruleguid));
    dispatch(getCreatedOffersData(ruleguid));
  }, [dispatch, ruleguid, itemOffset]);

  useEffect(() => {
    if (expireCouponMessage === "Successful") {
      toast.success("Coupon expired successfully");

      dispatch(getCreatedOffersData(ruleguid));
    }
  }, [expireCouponMessage]);

  const recordsLength = data?.ruleAnalysisGroupsByRuleData?.Message?.totalcount;

  const pageCount = Math.ceil(recordsLength / limitPerPage);

  const handlePageClick = (event) => {
    const newOffset = event.selected * limitPerPage;
    setItemOffset(newOffset);
    dispatch(getRuleAnalysisData(ruleguid, limitPerPage, newOffset));
  };
  const handleGroupbuttonClick = (ruleguid, group_id) => {
    dispatch(getcreatedOffers(ruleguid, group_id));
    setGroupModalShow(true);
  };

  const downloadAnalasis = () => {
    dispatch(getRuleAnalysisDownloadReportData(ruleguid));
    let ruleAnalysisDownloadMessage = data?.ruleAnalysisDownloadMessage;
    if (ruleAnalysisDownloadMessage == "Internal server error") {
      toast.error(ruleAnalysisDownloadMessage);
    }
    // if(!ruleAnalysisDownloadLoading){
    //   let ruleAnalysisDownloadData=data?.ruleAnalysisDownloadData?.Message;
    //   let ruleAnalysisDownloadMessage=data?.ruleAnalysisDownloadMessage;
    //       if(ruleAnalysisDownloadMessage=="Internal server error"){
    //         toast.error(ruleAnalysisDownloadMessage);
    //       }
    //       if(ruleAnalysisDownloadData){
    //         window.location.replace(ruleAnalysisDownloadData);
    //       }
    // }
  };
  return (
    <React.Fragment>
      <div className="container-fluid mt-137 mb-50 Rule_Wrapper">
        <ToastContainer />
        <div className="row g-3">
          <div className="col-xxl-5 col-lg-6 col-12">
            <div className="Outer_Block">
              {ruleAnalysisLoading ? (
                <div
                  style={{ height: "25vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  <div className="Outer_Block_Body d-flex align-items-center">
                    {/* <div className="Custom_Logo mr-50">
                      <img src={BrandLogo} className="img-fluid" alt="Brand" />
                    </div> */}
                    <ul>
                      <li>
                        <span>Rule Name:</span>
                        <span>
                          {ruleAnalysisData &&
                            ruleAnalysisData[0].RuleDescription}
                        </span>
                      </li>
                      <li>
                        <span>Type:</span>
                        <span>
                          {ruleAnalysisData && ruleAnalysisData[0].RuleTypeName}
                        </span>
                      </li>
                      <li>
                        <span>Last Run:</span>
                        <span>
                          {ruleAnalysisData && ruleAnalysisData[0].LastRunDate}
                        </span>
                      </li>
                    </ul>
                    <div style={{ marginBottom: "65px" }}>
                      {ruleAnalysisGroupsByRuleData?.length < 1 ? (
                        <button
                        title="Report will be available after first run"
                          className="FormButton"
                          style={{
                            border: "none",
                            backgroundColor: "#3D8ECC",
                            color: "white",
                            padding: "6px",
                            borderRadius: "3px",
                            marginLeft: "130px",
                            cursor:"not-allowed"
                          }}
                          disabled={ruleAnalysisDownloadLoading}
                        >
                          Download Rule Analysis
                        </button>
                      ) : (
                        <button
                          className="FormButton"
                          style={{
                            border: "none",
                            backgroundColor: "#3D8ECC",
                            color: "white",
                            padding: "6px",
                            borderRadius: "3px",
                            marginLeft: "130px",
                          }}
                          disabled={ruleAnalysisDownloadLoading}
                          onClick={downloadAnalasis}
                        >
                          Download Rule Analysis
                        </button>
                      )}
                    </div>
                  </div>
                  <hr className="m-0"></hr>
                  <div className="Outer_Block_Footer d-flex align-items-center flex-wrap">
                    <div className="d-flex align-items-center mr-15 Start_End_Date">
                      <img
                        src={CalenderBlack}
                        className="img-fluid"
                        alt="Calender"
                      />
                      <span className="d-inline-block ml-5">
                        Start:{" "}
                        {ruleAnalysisData &&
                          DateFormat(
                            ruleAnalysisData[0].StartDate.slice(0, 10)
                          )}
                      </span>
                    </div>
                    <div className="d-flex align-items-center Start_End_Date">
                      <img
                        src={CalenderRed}
                        className="img-fluid"
                        alt="Calender"
                      />
                      <span className="d-inline-block ml-5 text-red">
                        Expires:{" "}
                        {ruleAnalysisData &&
                          DateFormat(ruleAnalysisData[0].EndDate.slice(0, 10))}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-xxl-3 col-lg-6 col-12">
            <div className="Outer_Block">
              {ruleAnalysisLoading ? (
                <div
                  className="d-flex flex-row justify-content-center align-items-center m-auto"
                  style={{ minHeight: "200px" }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  <div className="Outer_Block_Head">
                    <h5 className="fw-bold">Rule Info :</h5>
                  </div>
                  <hr className="m-0"></hr>
                  <div className="Outer_Block_Body">
                    <ul>
                      <li>
                        <span>Frequency :</span>
                        <span>
                          {ruleAnalysisData && ruleAnalysisData[0].RunFrequency}{" "}
                          Day(s)
                        </span>
                      </li>
                      <li>
                        <span>Next Run :</span>
                        <span>
                          {ruleAnalysisData &&
                            DateFormat(
                              ruleAnalysisData[0].NextRunDate.slice(0, 10)
                            )}
                        </span>
                      </li>
                      {/* <li>
                        <span>Baseline Sales :</span>
                        <span>{getSingleRuleData.BaselineSales}</span>
                      </li> */}
                      <li>
                        <span>UPCs :</span>
                        <span>{getSingleRuleData.upcs}</span>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-xxl-4 col-12">
            <div className="Outer_Block Performance_Table ">
              <div className="Performance_Table_Header">
                <h6>Approved Offers</h6>
              </div>

              {ruleAnalysisPerformanceLoading ? (
                <div
                  style={{ height: "25vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="table-responsive  CustomScroll">
                  <table className="table border-0">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Expire</th>
                      </tr>
                    </thead>
                    <tbody>
                      {createdOffersData?.Message?.map((eachGroup, i) => (
                        <tr key={i}>
                          <td>{eachGroup.OfferName}</td>
                          <td>{eachGroup.CouponStartDate.slice(0, 10)}</td>
                          <td>{eachGroup.CouponEndDate.slice(0, 10)}</td>
                          <td>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "white",
                                marginLeft: "10px",
                              }}
                              onClick={() => {
                                setCouponsModalShow(true);
                                setOfferId(eachGroup?.OfferId);
                              }}
                            >
                              <img
                                width="24"
                                height="24"
                                src={expire}
                                alt="Rule Expired"
                              />
                            </button>
                            <MyVerticallyCenteredModal
                              show={couponsModalShow}
                              onHide={() => setCouponsModalShow(false)}
                              offerid={offerId}
                              ruleguid={ruleguid}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="Outer_Block Group_Table">
              <div className="Group_Table_Header d-flex">
                <h6>Groups created by this Rule</h6>
                {ruleAnalysisGroupsByRuleData?.length < 1 && (
                  <h6 style={{ color: "grey", marginLeft: "300px" }}>
                    No groups created.
                  </h6>
                )}
              </div>
              <div className="table-responsive  CustomScroll">
                {ruleAnalysisGroupsByRuleLoading ? (
                  <div
                    className="d-flex flex-row justify-content-center align-items-center m-auto"
                    style={{ minHeight: "200px" }}
                  >
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <table className="table border-0">
                    <thead>
                      <tr>
                        <th>Group name</th>
                        <th style={{ textAlign: "center" }}>
                          GROUP DATA <br />
                          FROM - To
                        </th>
                        {/* <th>TO</th> */}
                        <th>
                          Group User Count{" "}
                          {/* <img
                            src={UpArrow}
                            alt="Sorting"
                            className="img-fluid mr-2"
                            style={{ cursor: "pointer" }}
                          />{" "}
                          <img
                            src={DownArrow}
                            alt="Sorting"
                            className="img-fluid mr-2"
                            style={{ cursor: "pointer" }}
                          /> */}
                        </th>
                        <th>Shoppers </th>
                        <th>Transactions </th>
                        <th>Revenue</th>
                        <th>Avg. Transaction </th>

                        <th>DISCOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ruleAnalysisGroupsByRuleData?.map((eachGroup, i) => (
                        <tr key={i}>
                          <td>{eachGroup.group_name}</td>
                          <td style={{ textAlign: "center" }}>
                            {eachGroup.GroupStartDate.slice(0, 10)}
                            {""}
                            <span style={{ marginLeft: "15px" }}>
                              {eachGroup.GroupEndDate.slice(0, 10)}
                            </span>
                          </td>
                          {/* <td>{eachGroup.GroupEndDate.slice(0, 10)}</td> */}
                          <td style={{ textAlign: "center" }}>
                            {eachGroup.count}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {eachGroup.shoppers}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {eachGroup.transactions}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {eachGroup.revenue}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {eachGroup.avg_transactions}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {eachGroup.discount}
                          </td>

                          <td>
                            {/* <a href="/" className="Table_Icon">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M11 7H13V17H11V7ZM15 11H17V17H15V11ZM7 13H9V17H7V13ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z"
                                  fill="current"
                                />
                              </svg>
                            </a> */}

                            {/* <button onClick={() => dispatch(getcreatedOffers(ruleguid,eachGroup?.group_id))}></button> */}
                            {/* <button
                              onClick={() =>
                                handleGroupbuttonClick(
                                  ruleguid,
                                  eachGroup?.group_id
                                )
                              }
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                marginLeft: "24px",
                              }}
                            >
                              <img
                                style={{
                                  height: "20px",
                                  width: "12px",
                                  backgroundColor: "white",
                                }}
                                src={dollarSign}
                              />
                            </button> */}
                          </td>
                          <>
                            <CenteredModal
                              show={groupModalShow}
                              onHide={() => setGroupModalShow(false)}
                            />
                          </>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* pagination componenet */}
        <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />
      </div>
    </React.Fragment>
  );
}

export default RuleAnalysis;
