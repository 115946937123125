import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import {
  CreateUserCall,
  EditUserCall,
  getDashboardDepartmentsData,
  getDashboardFocusItemssData,
  getRolesCall,
  getSingleUserCall,
} from "../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { headers } from "../../public/auth";

function AddUser() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);

  const {
    getRolesData,
    createUserMessage,
    createUserLoading,
    getSingleUserData,
    getSingleUserLoading,
    dashboardDepartmentsData,
    dashboardFocusItemsData,
  } = useSelector((state) => state);

  let token = window.localStorage.getItem("access_token");
  let myHeaders = headers;
  let decrypted = myHeaders.Authorization;
  const decoded = token ? JSON.parse(window.atob(decrypted.split(".")[1])) : "";

  const userData = location?.state?.user;
  const userGUID = location?.state?.user?.user_guid;
  const DashboarddepartmentData =
    dashboardDepartmentsData?.Details?.departments;
  const DashboardFocusItemsData = dashboardFocusItemsData?.Details?.focus_items;

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    Role: "",
    RoleId: "",
    selectedFocusItem: [],
    selectedDepartments: [],
  });
  const search = useLocation().search;
  const groupId = new URLSearchParams(search).get("group_id");
  const storeId = new URLSearchParams(search).get("store_id");
  const focusId = new URLSearchParams(search).get("focus_id") ?? "";
  const deptId = new URLSearchParams(search).get("department_id");

  useEffect(() => {
    setUserDetails({
      firstName: getSingleUserData ? getSingleUserData.first_name : "",
      lastName: getSingleUserData ? getSingleUserData.last_name : "",
      email: getSingleUserData ? getSingleUserData.email : "",
      phoneNumber: getSingleUserData ? getSingleUserData.primary_phone : "",
      Role: getSingleUserData ? getSingleUserData.role_id : "",
      RoleId: getSingleUserData ? getSingleUserData.role_id : "",
      selectedFocusItem: getSingleUserData
        ? getSingleUserData.linked_focus_items
        : [],
      selectedDepartments: getSingleUserData
        ? getSingleUserData.linked_depts
        : [],
    });
  }, [getSingleUserData]);
  const updateUserInfo = (e) => {
    const { name, value } = e.target;
    if (name === "Role") {
      setUserDetails({
        ...userDetails,
        selectedFocusItem: [],
        selectedDepartments: [],
        [name]: value,
      });
    } else setUserDetails({ ...userDetails, [name]: value });
  };

  useEffect(() => {
    dispatch(getRolesCall());
    dispatch(
      getDashboardDepartmentsData(storeId ?? 0, focusId ?? 0, groupId ?? 0)
    );
    dispatch(getDashboardFocusItemssData(storeId ?? 0, 0, "All", deptId ?? 0));
  }, [deptId, dispatch, focusId, groupId, storeId]);

  useEffect(() => {
    if (createUserMessage !== "") toast.success(createUserMessage);
  }, [createUserMessage]);

  useEffect(() => {
    userGUID && dispatch(getSingleUserCall(userGUID));
  }, [dispatch, userGUID]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (location.pathname === "/AddUser") {
        let userBody = {
          first_name: userDetails?.firstName,
          last_name: userDetails?.lastName,
          email: userDetails?.email,
          primary_phone: userDetails?.phoneNumber,
          secondary_phone: "",
          rsa_retailer_id: decoded?.retailer?.id,
          role_id: userDetails?.Role,
          departments: userDetails?.selectedDepartments,
          focus_items: userDetails?.selectedFocusItem,
        };
        await dispatch(CreateUserCall(userBody));
      } else if (location.pathname === "/EditUser") {
        let userBody = {
          first_name: userDetails?.firstName,
          last_name: userDetails?.lastName,
          primary_phone: userDetails?.phoneNumber,
          secondary_phone: "",
          rsa_retailer_id: decoded?.retailer?.id,
          role_id: userDetails?.Role,
          departments: userDetails?.selectedDepartments,
          focus_items: userDetails?.selectedFocusItem,
        };
        await dispatch(EditUserCall({ userBody, userGUID }));
      }
    }
    setValidated(true);
  };

  const updateUserInfoArray = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    const { name } = event.target;
    setUserDetails({ ...userDetails, [name]: selectedOptions });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div class="Content_Wrapper mt-50">
        <div class="container-fluid">
          <div class="container">
            <div className="back-to-profile-page">
              <Button className="profile-btn">
                <Link to="/Userlist">Back to Profile</Link>
              </Button>
              {getSingleUserLoading ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  style={{
                    marginLeft: "50%",
                  }}
                />
              ) : (
                <Form onSubmit={handleSubmit} noValidate validated={validated}>
                  <Row className="g-3">
                    <Col sm={6} xs={12}>
                      <Form.Group className="position-relative mt-10">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please Enter FirstName"
                          name="firstName"
                          onChange={updateUserInfo}
                          value={userDetails.firstName}
                          required
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        Please enter firstName.
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={6} xs={12}>
                      <Form.Group className="position-relative mt-10">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please Enter LastName"
                          name="lastName"
                          required
                          onChange={updateUserInfo}
                          value={userDetails.lastName}
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        Please Enter lastname.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="g-3">
                    <Col sm={6} xs={12}>
                      <Form.Group className="position-relative mt-10">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Please Enter Email Address"
                          name="email"
                          onChange={updateUserInfo}
                          value={userDetails.email}
                          required
                          disabled={location.pathname === "/EditUser"}
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Email.
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={6} xs={12}>
                      <Form.Group className="position-relative mt-10">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Please Enter Phone Number"
                          name="phoneNumber"
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) updateUserInfo(e);
                          }}
                          value={userDetails.phoneNumber}
                          required
                          maxLength={10}
                          minLength={10}
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        Please Enter valid Phone Number.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Form.Group className="position-relative mt-10">
                        <Form.Label>Role</Form.Label>
                        <Form.Select
                          name="Role"
                          placeholder="Please select rule Type"
                          className="user-select react-select-container select"
                          onChange={updateUserInfo}
                          value={userDetails.Role}
                          required
                        >
                          <option>Please Select Role</option>
                          {getRolesData?.map((role) => (
                            <option value={role.ap_role_id}>
                              {role.role_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        Please Select Role.
                      </Form.Control.Feedback>
                    </Col>
                    {userDetails?.Role == 3 && (
                      <Col sm={6} xs={12}>
                        <Form.Group className="position-relative mt-10">
                          <Form.Label>
                            Focus Item
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.7rem",
                                marginLeft: "2rem",
                                textDecoration: "underline",
                              }}
                            >
                              (Hold Control or Command Key to select multiple
                              values.)
                            </span>
                          </Form.Label>
                          <Form.Select
                            name="selectedFocusItem"
                            placeholder="Please select Focus Item"
                            className="user-select react-select-container select"
                            onChange={updateUserInfoArray}
                            value={userDetails.selectedFocusItem}
                            multiple
                            required
                            style={{ height: "15rem" }}
                          >
                            {DashboardFocusItemsData?.map((role) => (
                              <option
                                selected={
                                  role.focus_id ===
                                  parseInt(userDetails.selectedFocusItem)
                                }
                                value={role.focus_id}
                              >
                                {role.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                          Please Select Focus Item.
                        </Form.Control.Feedback>
                      </Col>
                    )}
                    {userDetails?.Role == 2 && (
                      <Col sm={6} xs={12}>
                        <Form.Group className="position-relative mt-10">
                          <Form.Label>
                            Department
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.7rem",
                                marginLeft: "2rem",
                                textDecoration: "underline",
                              }}
                            >
                              (Hold Control or Command Key to select multiple
                              values.)
                            </span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="selectedDepartments"
                            placeholder="Please select Department"
                            className="user-select react-select-container select"
                            onChange={updateUserInfoArray}
                            value={userDetails?.selectedDepartments}
                            multiple
                            required
                            style={{ height: "15rem" }}
                          >
                            {DashboarddepartmentData?.map((role) => (
                              <option
                                selected={
                                  role.department_id ===
                                  parseInt(userDetails?.selectedDepartments)
                                }
                                key={role.department_id}
                                value={role.department_id}
                              >
                                {role.name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Control.Feedback type="invalid">
                          Please Select Department.
                        </Form.Control.Feedback>
                      </Col>
                    )}
                  </Row>
                  <Button type="submit" className="BlueButton submit-btn">
                    {createUserLoading ? "Submitting..." : "Submit"}
                  </Button>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddUser;
