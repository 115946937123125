import SecureLS from "secure-ls";
const secureLS = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "$$3213435#^$^%^&%@&*"
});

export const headers = {
  "Content-Type": "application/json",
  Authorization: secureLS.get("access_token"),
  "x-api-key": "sbc8Iiq9FG3d9nKt639j28v0LiwAeQKd4KM3eaMg"
};

export const postHeader = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: secureLS.get("access_token"),
  "x-api-key": "sbc8Iiq9FG3d9nKt639j28v0LiwAeQKd4KM3eaMg"
};

//This setSession is used to set access_token and all required variabled in local storage.
//Using secure-ls external library make them secure. Check  application tab under local storage.
export const setSession = (data) => {
  secureLS.set("access_token", data.access_token);
  secureLS.set("expires_in", data.expires_in);
};

export const Regex = (value) => {
  let regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
  return regex.test(value);
};

export const getDate = (value) => {
  return value.split(" ")[0];
};

export const getTime = (value) => {
  let info = value.split(" ");
  let time = info[1].split(":");
  return `${time[0]}:${time[1]}`;
};
