/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import ShopperGroup from "./ShopperGroup/ShopperGroup";
import FocusGroup from "./FocusGroup/FocusGroup";
import DepartmentGroup from "./DepartmentGroup/DepartmentGroup";
import BarChart from "./BarChart/BarChart";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { headers } from "../../public/auth";
import { getStores } from "../../redux/API";
import { useDispatch, useSelector } from "react-redux";
const Home = () => {
  const dispatch = useDispatch();
  const [storeId, setStoreId] = useState(0);
  const storeData = useSelector((state) => state.storesData);
  localStorage.setItem("stores", JSON.stringify(storeData));
  const storesLoading = useSelector((state) => state.storesLoading);

  let token = window.localStorage.getItem("access_token");
  let myHeaders = headers;
  let decrypted = myHeaders.Authorization;
  const decoded = token ? JSON.parse(window.atob(decrypted.split(".")[1])) : "";
  const Scopes_user = decoded.retailer.scopes;

  const Buyer_user_scope = Scopes_user.includes("buyer:user");
  const Category_user_scope = Scopes_user.includes("category:user");
  const Write_user_scope = Scopes_user.includes("write:user");
  const Write_focusItem_scope = Scopes_user.includes("write:focusitem");
  const all_scope = Scopes_user.includes("all");

  useEffect(() => {
    dispatch(getStores());
  }, [dispatch]);

  const storeChange = (event) => {
    setStoreId(event.target.value);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
          <div className="container">
            <div className="home-page-contain">
              <div className="store-item">
                {!storesLoading && storeData?.length > 1 && (
                  <select onChange={storeChange} value={storeId}>
                    <option key="0" value="0" selected>
                      All Stores
                    </option>
                    {!storesLoading &&
                      storeData?.map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.Name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
              {(all_scope || !Buyer_user_scope) &&
                (all_scope || !Category_user_scope) && (
                  <BarChart storeId={storeId} />
                )}
              <div className="home-shoppers">
                {(all_scope || !Buyer_user_scope) &&
                  (all_scope || !Category_user_scope) && (
                    <ShopperGroup storeId={storeId} />
                  )}
              </div>
              {(all_scope || !Category_user_scope) && (
                <DepartmentGroup storeId={storeId} />
              )}
              <FocusGroup storeId={storeId} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
