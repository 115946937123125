/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ProductBarChart({ salesGraph, groupId }) {
  var groupsData = JSON.parse(localStorage.getItem("shopperGroups"));
  const groupData = groupsData?.Message?.shopper_groups?.filter(
    (a) => a.group_id == groupId
  )[0];
  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
        ticks: {
          font: {
            size: 14,
          },
        },
      },
      y: {
        stacked: false,
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
        font: { size: "29" },
        color: "333",
        position: "top",
        text: "Sales Overview",
      },
    },
  };

  const labels = salesGraph?.map((item) => item.month);

  const data = {
    labels,
    datasets: [
      {
        label: "Sales",
        data: salesGraph?.map((item) => item.sales),
        backgroundColor: "rgba(0, 104, 151, 0.5)",
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="group-department col-md-6">
        <div className="group-department-main-title">
          <div className="group-img-title">
            <div className="main-title">
              {/* <h2>{groupData?.name} Analysis</h2> */}
              <h2>sales Trend</h2>
            </div>
          </div>
          {/* <div className="group-department-category">
            <div className="group-department-tab">
              <div className="group-price">{groupData?.total_sales}</div>
              <div className="group-offer-price">
                {groupData?.trend === "Up" ?
                  <div className="income-up-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M9 5v2h6.59L4 18.59L5.41 20L17 8.41V15h2V5z" /></svg>
                  </div>
                  :
                  <div className="income-down-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><g transform="rotate(180 12 12)"><path fill="currentColor" d="M9 5v2h6.59L4 18.59L5.41 20L17 8.41V15h2V5z"></path></g></svg>
                  </div>
                }
                <span>{groupData?.trend_percent}</span>
              </div>
              <div className="group-on-date">{groupData?.trend_text}</div>
            </div>
          </div> */}
        </div>
        <Bar options={options} data={data} />
      </div>
    </React.Fragment>
  );
}

export default ProductBarChart;
