/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import FocusGroup from "../Home/FocusGroup/FocusGroup";
import DepartmentGroup from "../Home/DepartmentGroup/DepartmentGroup";
import { ToastContainer } from "react-toastify";
function FocusItemsList() {

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="Content_Wrapper mt-50">
                <div className="container-fluid">
                    <div className="container">
                        <div className="home-page-contain">
                            <div class="focus-items-list-wrap">
                            <div class="focus-items-list">
                                <div class="focus-list-title">
                                    <span>Filter:</span>
                                </div>
                                <div class="focus-list-dropdown store-list">
                                    <select>
                                        <option value="All">All</option>
                                        <option value="Up">Trending Up</option>
                                        <option value="Down">Trending Down</option>
                                    </select>
                                </div>
                            </div>
                            </div>
                            <DepartmentGroup />
                            <FocusGroup />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FocusItemsList;
