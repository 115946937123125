import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import DepartmentGroup from "../Home/DepartmentGroup/DepartmentGroup";
import FocusGroup from "../Home/FocusGroup/FocusGroup";
import { useLocation } from "react-router-dom";

function DepartmentGroupList() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const storeId = params.get("store_id");

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
          <div className="container">
            <div className="home-page-contain">
              <div class="focus-items-list-wrap">
                {/* <div class="focus-items-list">
                  <div class="focus-list-title">
                    <span>Filter:</span>
                  </div>
                  <div class="focus-list-dropdown store-list">
                    <select>
                      <option value="All">All</option>
                      <option value="Up">Trending Up</option>
                      <option value="Down">Trending Down</option>
                    </select>
                  </div>
                </div> */}
              </div>
              <DepartmentGroup storeId={storeId} />
              {/* <FocusGroup /> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DepartmentGroupList;
