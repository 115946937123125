/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FocusServay from "./FocusServay/FocusServay";
import { getDashboardFocusItemssData } from "../../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
import FocusGroupTitle from "../FocusGroupTitle/FocusGroupTitle";

function FocusGroup({ storeId, groupId, deptId }) {
  let location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboardFocusItemsData);
  const [sortedRecords, setSortedRecords] = useState(
    data?.Message?.focus_items
  );
  localStorage.setItem("focusgroups", JSON.stringify(data));
  const loading = useSelector((state) => state.dashboardFocusItemsLoading);

  useEffect(() => {
    dispatch(
      getDashboardFocusItemssData(
        storeId ?? 0,
        groupId ?? 0,
        "All",
        deptId ?? 0
      )
    );
  }, [dispatch, storeId, groupId, deptId]);

  useEffect(() => {
    if (!loading && data?.Message?.focus_items.length > 0)
      setSortedRecords(data?.Message?.focus_items);
  }, [loading, data]);

  const changeSortDirection = (value) => {
    if (value !== "All")
      setSortedRecords(
        data?.Message?.focus_items.filter((focus) => focus.trend === value)
      );
    else setSortedRecords(data?.Message?.focus_items);
  };
  return (
    <React.Fragment>
      <FocusGroupTitle
        storeId={storeId}
        changeSortDirection={changeSortDirection}
      />
      <div className="focus-item">
        <div className="grid">
          {loading && (
            <>
              <div
                style={{ height: "25vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner animation="border" variant="primary" />
              </div>
            </>
          )}
          {!loading && data?.Message != null && (
            <>
              {location.pathname === "/home"
                ? data?.Message?.focus_items
                    .slice(0, 8)
                    .map((item, index) => (
                      <FocusServay
                        key={index}
                        storeId={storeId ?? 0}
                        focusData={item}
                      />
                    ))
                : sortedRecords?.map((item, index) => (
                    <FocusServay
                      key={index}
                      storeId={storeId ?? 0}
                      focusData={item}
                    />
                  ))}
            </>
          )}
          {data?.Message?.focus_items?.length === 0 && (
            <>
              <div style={{ height: "25vh" }} className="d-flex">
                You currently do not have any focus groups set up. Please click
                on "New Focus Item Group" and add UPCs to begin tracking.
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default FocusGroup;
