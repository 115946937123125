import React from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import NextArrow from "../../assets/images/next-arrow.svg";
import PrevArrow from "../../assets/images/prev-arrow.svg";
import Switch from "react-switch";
import { useState, useEffect } from "react";
import PasetedEmailChips from "./PasetedEmailChips";
import Select from "react-select";
import {
  getRuleDate,
  getDailyandWeekly,
  getMonthlyandYearly
} from "../../public/PublicFunctionality";
import DisplayDates from "../../public/DisplayDates";
import { Link } from "react-router-dom";

export default function Step5({
  handleStep,
  handleToggle,
  frequencyLoading,
  info,
  handleInput,
  frequencyDropdown,
  mode,
  datesMode,
  editMode,
  handleUpcs,
  emailMode,
  setEmails,
  emails
}) {
  const [formError, setFormError] = useState({
    frequency: "",
    startDate: "",
    endDate: "",
    email: ""
  });

  var today = new Date();
  today.setDate(today.getDate() + 1);
  var yyyy = today.getFullYear();
  var mm = "" + (today.getMonth() + 1);
  var dd = "" + today.getDate();

  if (mm.length < 2) mm = "0" + mm;
  if (dd.length < 2) dd = "0" + dd;

  var disablePast = yyyy + "-" + mm + "-" + dd;

  const handleChange = (name) => {
    setFormError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const handleValidation = (e) => {
    if (!isValidEmail(e.target.value)) {
      setFormError((prev) => {
        return { ...prev, email: "invalid Email Id" };
      });
    } else {
      setFormError((prev) => {
        return { ...prev, email: "" };
      });
    }
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleStepNext = () => {
    if (datesMode === true) {
      handleStep("next");
    } else if (
      info.frequency.value === "" ||
      info.startDate === "" ||
      info.endDate === "" ||
      info.email.length === 0
    ) {
      if (info.frequency.value === "") {
        setFormError((prev) => {
          return { ...prev, frequency: "Please choose frequency" };
        });
      }
      if (info.startDate === "") {
        setFormError((prev) => {
          return { ...prev, startDate: "Please choose start date" };
        });
      }
      if (info.endDate === "") {
        setFormError((prev) => {
          return { ...prev, endDate: "Please choose end date" };
        });
      }
      if (info.email.length === 0) {
        setFormError((prev) => {
          return { ...prev, email: "Please enter email Id" };
        });
      }
    } else if (info?.startDate < disablePast && info?.endDate < disablePast) {
      setFormError((prev) => {
        return {
          ...prev,
          startDate: "Please choose valid start date",
          endDate: "Please choose valid end date"
        };
      });
    } else if (info?.startDate < disablePast) {
      setFormError((prev) => {
        return {
          ...prev,
          startDate: "Please Choose valid start date"
        };
      });
    } else if (info?.endDate < disablePast && info.startDate > info.endDate) {
      setFormError((prev) => {
        return {
          ...prev,
          endDate: "Please Choose valid end date"
        };
      });
    } else if (info.startDate > info.endDate) {
      setFormError((prev) => {
        return { ...prev, endDate: "Please choose valid end date" };
      });
    } else if (info?.startDate < disablePast) {
      setFormError((prev) => {
        return {
          ...prev,
          startDate: "Please Choose valid start date"
        };
      });
    } else {
      handleStep("next");
    }
  };

  const [runDate, setRunDate] = useState([]);
  let nextArrayLenght = runDate.length;

  useEffect(() => {
    if (
      info.frequency.value === 1 ||
      info.frequency.value === 2 ||
      info.frequency.value === 3 ||
      info.frequency.value === 5 ||
      info.frequency.value === 6
    ) {
      getDailyandWeekly(info.startDate, info.endDate, info, setRunDate);
    }
    // if (
    //   info.frequency.value === 3 ||
    //   info.frequency.value === 4 ||
    //   info.frequency.value === 6
    // ) {
    //   getMonthlyandYearly(info.startDate, info.endDate, info, setRunDate);
    // }
  }, [info, setRunDate]);

  const [email, setEmail] = useState("");

  // const handleDelete = (deleteChip) => {
  //   const updatedUPCSData = info.upcsData.filter(
  //     (eachChip) => eachChip !== deleteChip
  //   );
  //   setEmails(updatedUPCSData);
  //   handleUpcs(updatedUPCSData, "upcsData");
  // };
  const handleDelete = (chip) => {
    const updatedEmailData = info.email.filter(
      (eachEmail) => eachEmail !== chip
    );
    setEmails(updatedEmailData);
    handleUpcs(updatedEmailData, "email");
  };
  // email validation
  const emailValidation = (e) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(e.target.value) && e.target.value !== "") {
      setFormError((prev) => {
        return {
          ...prev,
          email: "Please Enter Valid Email Address"
        };
      });
    } else if (info.email.length === 3) {
      setFormError((prev) => {
        return {
          ...prev,
          email: "Email Id limit exceed"
        };
      });
    } else {
      setFormError((prev) => {
        return {
          ...prev,
          email: ""
        };
      });
    }
  };

  return (
    <div className="Wizard_Content_Card  ">
      <Form>
        <Row className="row g-3">
          {editMode && (
            <span style={{ color: "red", gap: "3px" }}>
              This changes will take effect for all upcoming rule runs - already
              created groups and coupon will not change.
            </span>
          )}
          {frequencyLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px"
              }}>
              <Spinner animation="border" variant="primary" sizing={"lg"} />
            </div>
          ) : (
            <>
              <Col>
                <Form.Label>
                  How Frequently would you like to run the rule?{" "}
                </Form.Label>
              </Col>
              <Row>
                {/* <Col xs={12}> */}
                {/* <Form.Group controlId="exampleForm.ControlTextarea1"> */}

                <Col lg={1}>
                  <Form.Label style={{ marginTop: "15px" }}>Every</Form.Label>
                </Col>
                <Col lg={4}>
                  <Select
                    name="frequency"
                    value={info.frequency}
                    placeholder={"Please select frequency..."}
                    options={frequencyDropdown}
                    isDisabled={mode}
                    className="react-select-container select"
                    onChange={(e) => {
                      handleInput(e, "frequency");
                      handleChange("frequency");
                    }}
                  />
                </Col>
                <Col lg={2}>
                  <Form.Label style={{ marginTop: "15px" }}>Day(s)</Form.Label>
                </Col>
                {/* </Form.Group> */}
                {/* </Col> */}
              </Row>
            </>
          )}
          {formError.frequency && (
            <p style={{ color: "red" }}>{formError.frequency}</p>
          )}
          <Col lg={6} xs={12}>
            <Form.Group>
              <Form.Label>
                Start Date{" "}
                <span style={{ fontStyle: "italic", color: "#3D8ECC" }}>
                  (Must Be a Future Date)
                </span>
              </Form.Label>
              <Form.Control
                type="date"
                value={info.startDate}
                name="startDate"
                min={disablePast}
                disabled={mode}
                onChange={(e) => {
                  handleInput(e.target.value, "startDate");
                  handleChange("startDate");
                }}
              />
            </Form.Group>
            {formError.startDate && (
              <p style={{ color: "red" }}>{formError.startDate}</p>
            )}
          </Col>

          <Col lg={6} xs={12}>
            <Form.Group>
              <Form.Label>
                End Date{" "}
                <span style={{ fontStyle: "italic", color: "#3D8ECC" }}>
                  (Must Be Greater Than Start Date)
                </span>
              </Form.Label>
              <Form.Control
                type="date"
                value={info.endDate}
                name="endDate"
                disabled={mode || info?.startDate === ""}
                //disabled ={mode}
                min={info.startDate ? info.startDate : disablePast}
                onChange={(e) => {
                  handleInput(e.target.value, "endDate");
                  handleChange("endDate");
                }}
              />
            </Form.Group>
            {formError.endDate && (
              <p style={{ color: "red" }}>{formError.endDate}</p>
            )}
          </Col>
          {info.startDate && info.endDate && (
            <Col lg={12} xs={12}>
              <DisplayDates
                info={info}
                getRuleDate={getRuleDate}
                runDate={runDate}
                nextArrayLenght={nextArrayLenght}
              />
            </Col>
          )}

          <Col xs={12}>
            <fieldset disabled={mode}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                  Send email about rule related info{" "}
                  <span style={{ fontStyle: "italic", color: "#3D8ECC" }}>
                    (Click Enter After Each Email Entry)
                  </span>
                </Form.Label>
                <PasetedEmailChips
                  infoEmailData={info?.email}
                  upcsdata
                  mode={mode}
                  handleDelete={handleDelete}
                  email={email}
                  setEmail={setEmail}
                  setEmails={setEmails}
                  emails={emails}
                  handleUpcs={handleUpcs}
                  name={"email"}
                  setFormError={setFormError}
                  formError={formError}
                  emailValidation={emailValidation}
                />
              </Form.Group>
            </fieldset>
            {formError.email && (
              <p style={{ color: "red" }}>{formError.email}</p>
            )}
          </Col>
          <Col xs={12}>
            <div className="OnOFF_Toggle d-flex align-items-center justify-content-between">
              <span className="OnOFF_Toggle_Ttl">Rule Requires Approval</span>
              <Switch
                onColor={"#3d8ecc"}
                name="offerRequired"
                disabled={mode}
                checked={info.offerRequired}
                onChange={(e) => handleToggle(e, "offerRequired")}
              />
            </div>
          </Col>
          {/* <Col xs={12}>
            <div className="OnOFF_Toggle d-flex align-items-center justify-content-between">
              <span className="OnOFF_Toggle_Ttl">Notify Admin After Run</span>
              <Switch
                onColor={"#3d8ecc"}
                name="notifyAdmin"
                disabled={mode}
                checked={info.notifyAdmin}
                onChange={(e) => handleToggle(e, "notifyAdmin")}
              />
            </div>
          </Col> */}
          {/* <Col xs={12}>
            <div className="OnOFF_Toggle d-flex align-items-center justify-content-between">
              <span className="OnOFF_Toggle_Ttl">
                Update Group Statistics
                <Link to="" title=" When checked on, group statistics will be updated before next run.">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-help-circle ml-10 mb-4">
                    <circle cx="12" cy="12" r="10"></circle>
                    <path
                      d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
                      fill="current"></path>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                </Link>
              </span>
              <Switch
                onColor={"#3d8ecc"}
                name="groupStatics"
                disabled={mode}
                checked={info.groupStatics}
                onChange={(e) => handleToggle(e, "groupStatics")}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="OnOFF_Toggle d-flex align-items-center justify-content-between">
              <span className="OnOFF_Toggle_Ttl">Create Elastic Group</span>
              <Switch
                onColor={"#3d8ecc"}
                name="iselastic"
                disabled={mode}
                checked={info.iselastic}
                onChange={(e) => handleToggle(e, "iselastic")}
              />
            </div>
          </Col> */}
        </Row>
      </Form>
      <div className="flex align-items-center justify-content-end text-end p-20 pt-0">
        <Button
          className="GrayButton mt-14 "
          type="submit"
          onClick={() => {
            handleStep("previous");
          }}>
          <img src={PrevArrow} alt="Prev Arrow" className="img-fluid mr-5" />
          Previous
        </Button>
        <Button
          className="BlueButton ml-10 mt-14"
          style={{ marginRight: "-20px" }}
          type="submit"
          onClick={() => {
            handleStepNext();
          }}>
          Next
          <img src={NextArrow} alt="Next Arrow" className="img-fluid ml-5" />
        </Button>
      </div>
    </div>
  );
}
