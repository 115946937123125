import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { ruleListData, getPendingApprovalData } from "../../redux/API";
import pendingApproval from "../../assets/images/pendingApproval.png";
import {
  getApprovedPendingData,
  getPendingApprovalData,
} from "../../redux/API";
import { ToastContainer, toast } from "react-toastify";
import Paginate from "../Paginate";
function MyVerticallyCenteredModal(ruleId, offerid) {
  // const show = props.show;
  // const onHide = props.onHide;
  const dispatch = useDispatch();
  const approvePendingRuleData = useSelector(
    (state) => state.approvePendingRuleData
  );


  const [isShow, invokeModal] = React.useState(false);
  const initModal = () => {
    return invokeModal(!false);
  };
  const closeModal = () => {
    return invokeModal(false);
  };

  const approveRuleHandler = () => {
    dispatch(getApprovedPendingData(ruleId, offerid));


    return invokeModal(false);
  };
  return (
    <>
      <button
        style={{ border: "none", backgroundColor: "transparent" }}
        onClick={initModal}
      >
        <img width="24" height="24" style={{ marginLeft: "-5px" }} alt="" src={pendingApproval} />
      </button>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isShow}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure, You want to approve this offer?</h5>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={approveRuleHandler}
            className="mr-30 btn btn-secondary"
            style={{ height: "35px", width: "100px" }}
          >
            Yes
          </Button>
          <Button
            onClick={closeModal}
            style={{ height: "35px", width: "100px" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ApproveScreen() {
  const [modalShow, setModalShow] = React.useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const limitPerPage = 10; //Limit Value
  const dispatch = useDispatch();
  const approvePendingRuleMessage = useSelector(
    (state) => state.approvePendingRuleMessage
  );
  useEffect(() => {
    // dispatch(ruleListData(limitPerPage, itemOffset));
    if (approvePendingRuleMessage === "Successful") {
      dispatch(getPendingApprovalData(limitPerPage, itemOffset));
      toast.success("Approved Succesfully");
    }
    dispatch(getPendingApprovalData(limitPerPage, itemOffset));
  }, [approvePendingRuleMessage]);

  const loading = useSelector((state) => state.pendingApprovalLoading);
  const aprrovedData = useSelector((state) => state.pendingApprovalData);
  const approvedData = aprrovedData?.Message?.entries;
  const totalRecordsLength = aprrovedData?.Message?.totalcount;
  const pageCount = Math.ceil(totalRecordsLength / limitPerPage);
  const handlePageClick = (event) => {
    const newOffset = event.selected * limitPerPage;
    setItemOffset(newOffset);
    dispatch(getPendingApprovalData(limitPerPage, newOffset));
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="mt-110">
        <div className="container-fluid">
          <div className="row g-4">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div>
                <h5 className="Coupon_Ttl">
                  Offers Created And Waiting For Approval
                </h5>
              </div>
            </div>

            <div className="col-12">
              <div className="table-responsive">
                {loading ? (
                  <div
                    className="d-flex flex-row justify-content-center align-items-center m-auto"
                    style={{ minHeight: "238px" }}
                  >
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Rule</th>
                        <th style={{ textAlign: "center" }}>Offer</th>
                        <th>Discount</th>
                        <th>Target Shoppers</th>
                        <th style={{ textAlign: "center" }}>Start</th>
                        <th style={{ textAlign: "center" }}>End</th>
                        <th style={{ textAlign: "center" }}>Status</th>
                        <th style={{ textAlign: "center" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        approvedData?.length > 0 ? (
                          approvedData?.map((each, id) => (
                            <tr key={each?.OfferId}>
                              <td >{each?.RuleDescription}</td>
                              <td>{each?.OfferName}</td>
                              <td style={{ textAlign: "center" }}>{each?.DiscountAmount}</td>
                              <td style={{ textAlign: "center" }}>{each?.ShopperGroupCount}</td>
                              <td>{each?.CouponStartDate}</td>
                              <td>{each?.CouponEndDate}</td>
                              <td>
                                {each?.OfferStatus === 1 ? (
                                  <span className="badge btn-success ml-25">
                                    Approved
                                  </span>
                                ) : (
                                  <span className="badge btn-danger ml-25">
                                    Pending Approval
                                  </span>
                                )}
                              </td>

                              <td>
                                {each?.OfferStatus === 1 ? (
                                  <button
                                    style={{
                                      border: "none",
                                      marginLeft: "60px",
                                      background: "transparent",
                                      cursor: "not-allowed",
                                    }}
                                    title={"Coupon Already Approved"}
                                  >
                                    <img
                                      width="24"
                                      height="24"
                                      src={pendingApproval}
                                      alt="pendingApproval"
                                      style={{ marginRight: "40px" }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    style={{
                                      border: "none",
                                      marginLeft: "60px",
                                      background: "transparent",
                                    }}
                                    onClick={() => setModalShow(true)}
                                  >
                                    <MyVerticallyCenteredModal
                                      offerid={each?.OfferId}
                                      ruleId={each?.RuleGuid}
                                    // show={modalShow}
                                    // onHide={() => setModalShow(false)}
                                    />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))

                        ) : (
                          <p style={{ fontWeight: "700" }}>
                            No Coupons for Approval
                          </p>
                        )
                      }
                    </tbody>
                  </table>
                )}
              </div>
              <Paginate
                handlePageClick={handlePageClick}
                pageCount={parseInt(pageCount)}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ApproveScreen;
