/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import ShopperGroup from "../Home/ShopperGroup/ShopperGroup";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import FocusBarChart from "../Home/FocusBarChart/FocusBarChart";
import ShoppersChart from "../Home/ShoppersChart/ShoppersChart";
import FocusBarTitle from "../Home/FocusBarTitle/FocusBarTitle";
import { useDispatch } from "react-redux";
import {
    getDashboardFocusItemAnalysisData
} from "../../redux/API";
import FocusItemsUPCs from "../Home/FocusItemsUPCs/FocusItemsUPCs";

function Focusitem() {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const focusId = new URLSearchParams(search).get("focus_id");
    const id = new URLSearchParams(search).get("store_id");
    const groupId = new URLSearchParams(search).get("group_id");
    const [storeId, setStoreId] = useState(id);
    const storeChange = (value) => {
        setStoreId(value);
    };
    useEffect(() => {
        dispatch(getDashboardFocusItemAnalysisData(storeId ?? 0, focusId ?? ''));
    }, [dispatch, storeId, focusId]);

    return (
        <React.Fragment>
            <div className="Content_Wrapper mt-50">
                <div className="container-fluid">
                    <div className="container">
                        <div className="home-page-contain">

                        <FocusBarTitle storeId={storeId} storeChange={storeChange} focusId={focusId} IsDepartment="0" />
                            <div className="chart-main-wrapper row">
                                <FocusBarChart storeId={storeId} storeChange={storeChange} focusId={focusId} IsDepartment="1" />
                                {/* <ShoppersChart storeId={storeId} storeChange={storeChange} focusId={focusId} IsDepartment="1" /> */}
                                <FocusItemsUPCs storeId={storeId} storeChange={storeChange} focusId={focusId} IsDepartment="1" />
                            </div>
                            <ShopperGroup storeId={storeId} focusId={focusId} groupId={groupId} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Focusitem;
