/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DepartmentServay from "./DepartmentServay/DepartmentServay";
import { getDashboardDepartmentsData } from "../../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
function DepartmentGroup({ storeId, focusId, groupId }) {
  const dispatch = useDispatch();
  let location = useLocation();
  const data = useSelector((state) => state.dashboardDepartmentsData);

  localStorage.setItem("departmentsData", JSON.stringify(data));
  const loading = useSelector((state) => state.dashboardDepartmentsLoading);

  useEffect(() => {
    dispatch(
      getDashboardDepartmentsData(storeId ?? 0, focusId ?? 0, groupId ?? 0)
    );
  }, [dispatch, storeId, focusId, groupId]);

  return (
    <React.Fragment>
      <div className="department-item">
        <div className="main-title-wrapper">
          <div className="main-title">
            <h2>Departments</h2>
          </div>
          {location.pathname !== "/DepartmentGroupList" && (
            <div className="view-link">
              <Link to={`/DepartmentGroupList?store_id=` + storeId}>
                View all
              </Link>
            </div>
          )}
        </div>
        <div className="grid">
          {loading && (
            <>
              <div
                style={{ height: "25vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner animation="border" variant="primary" />
              </div>
            </>
          )}
          {!loading && data?.Message != null && (
            <>
              {location.pathname === "/home"
                ? data?.Message?.departments
                    ?.slice(0, 8)
                    ?.map((item, index) => (
                      <DepartmentServay
                        storeId={storeId}
                        key={index}
                        departmentData={item}
                      />
                    ))
                : data?.Message?.departments?.map((item, index) => (
                    <DepartmentServay
                      storeId={storeId}
                      key={index}
                      departmentData={item}
                    />
                  ))}
            </>
          )}
          {data?.Message?.departments?.length === 0 && (
            <>
              <div
                style={{ height: "25vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                No records found
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default DepartmentGroup;
