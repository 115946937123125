import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Sorting from "../../assets/images/sorting.svg";
import { ruleListData, getPendingApprovalData } from "../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Paginate from "../Paginate";
import { Modal, Button } from "react-bootstrap";
import { expireRule,approveRuleData,rejectRuleData} from "../../redux/API";
// import copy from "../../assets/images/copy.png";
import expire from "../../assets/images/expire.png";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

///

import Logo from "../../assets/images/logo.png";
import Avtar from "../../assets/images/avatar.png";
import ProfileUser from "../../assets/images/profile-user.svg";
import { DateFormat } from "../../public/PublicFunctionality";

function PendingAprovalPopup(props) {
  const dispatch = useDispatch();
  let ruleId=props.ruleId;
  const approveRule = () =>{
    dispatch(approveRuleData(ruleId))
    props.onHide();
  }
  const rejectRule = () => {
    dispatch(rejectRuleData(ruleId))
    props.onHide();
  }
  return (
    <Modal
      className="d-flex flex-column justify-content-center "
      show={props.show}
      style={{ padding: 15 }}
    >
      <Modal.Header closeButton onClick={props.onHide}>
        <Modal.Title
          style={{ fontSize: 20, textAlign: "center" }}
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ textAlign: "center", fontWeight: "600", fontSize: "20px" }}
      >
        Are you sure you want to approve this Rule?
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={approveRule}>Approve!</Button>
        <Button variant="secondary" onClick={rejectRule}>
          Something wrong? Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalDialog({ id, SetCount, count }) {
  const dispatch = useDispatch();
  const expireRuleMessage = useSelector((state) => state.expireRuleMessage);

  const [expcoupon, setExpcoupon] = useState(true);
  const [isShow, invokeModal] = React.useState(false);

  const initModal = () => {
    return invokeModal(!false);
  };
  const closeModal = () => {
    return invokeModal(false);
  };
  const expRule = () => {
    let exp_coupon;
    if (expcoupon === true) {
      exp_coupon = 0;
    } else {
      exp_coupon = 1;
    }
    dispatch(expireRule(id, exp_coupon));
    // if (expireRuleMessage === "Successful") {
    //   toast.success("Rule expired successfully");
    // } else toast.error("Rule expired failed.");

    return invokeModal(false);
  };
  const checkedCoupon = (e) => {
    setExpcoupon(expcoupon ? false : true);
  };
  return (
    <>
      <button
        style={{ border: "none", backgroundColor: "transparent" }}
        onClick={initModal}
      >
        <img width="24" height="24" src={expire} alt="" />
      </button>
      <Modal
        className="d-flex flex-column justify-content-center "
        show={isShow}
        style={{ padding: 15 }}
      >
        <Modal.Header>
          <Modal.Title style={{ fontSize: 20, textAlign: "center" }}>
            Are you sure you want to expire the Rule?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>It will stop all future runs of this rule</p>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            style={{ width: "20px", height: "20px" }}
            type="checkbox"
            onChange={(e) => checkedCoupon(e)}
          />
          <p
            style={{
              color: "red",
              fontSize: "20px",
              fontWeight: 600,
              marginRight: "60px",
            }}
          >
            Expire all coupons for this rule {"       "}{" "}
          </p>

          <Button variant="secondary" onClick={expRule}>
            Expire
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function Coupon({ dashboardItem }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ruleListData);
  const loading = useSelector((state) => state.ruleListLoading);
  const rulesMessage = useSelector((state) => state.ruleListMessage);
  const expireRuleData = useSelector((state) => state.expireRuleData);

  const totalRecordsLength = data?.Message?.totalcount;

  const [itemOffset, setItemOffset] = useState(0);
  const limitPerPage = 10; //Limit Value

  const navigate = useNavigate();

  const [count, SetCount] = useState(false);

  const pendingData = useSelector((state) => state.pendingApprovalData);
  const [pendingAprovalPopupShow, setPendingAprovalPopupShow] = useState(false);
  const [pendingRuleId,setPendingRuleId]=useState("")
  const approveRuleData=useSelector((state) => state.approveRuleData);
  const approveRuleDataMessage=useSelector((state) => state.approveRuleDataMessage);
  const rejectRuleDataMessage=useSelector((state) => state.rejectRuleDataMessage);
  const rejectRuleData =useSelector((state) => state.rejectRuleData);
  useEffect(() => {
    dispatch(ruleListData(limitPerPage, itemOffset));
    //dispatch(getPendingApprovalData());
  }, []);

  useEffect(() => {
    if (expireRuleData === "Expired") {
      toast.success("Rule expired successfully");
      dispatch(ruleListData(limitPerPage, itemOffset));
    } else toast.error(expireRuleData);
  }, [expireRuleData]);

  useEffect(() => {
    if (approveRuleData === "Rule Approved" ) {
      toast.success("Rule Approved Successfully");
      dispatch(ruleListData(limitPerPage, itemOffset));
    } else if(approveRuleDataMessage==="Something went wrong on our side, please try again later.."){
     
      toast.error(approveRuleDataMessage);
    } 
  }, [approveRuleData]);

  useEffect(() => {
    if (rejectRuleData === "Rule Rejected") {
      toast.success("Rule Rejected Successfully");
      dispatch(ruleListData(limitPerPage, itemOffset));
    } else if(rejectRuleDataMessage== "Something went wrong on our side, please try again later.."){
      toast.error(rejectRuleDataMessage);
    } 
  }, [rejectRuleData]);


  useEffect(() => {
    if (rulesMessage === "Internal Server Error") {
      navigate("/login");
    }
  });
  const pageCount = Math.ceil(totalRecordsLength / limitPerPage);
  const [sortItem,setSortItem]=useState("");
  const [sortOder,setSortOrder]=useState(0);

  const handlePageClick = (event) => {
    debugger;
    const newOffset = event.selected * limitPerPage;
    setItemOffset(newOffset);
    if(sortdesc==1){
      dispatch(ruleListData(limitPerPage, newOffset,sortItem,0));
    }else{
      dispatch(ruleListData(limitPerPage, newOffset,sortItem,1));
    }
  };

  const [sortdesc, setSortDesc] = useState(0);
  //const [orderby, setOrderBy] = useState("StartDate");
  const startDateSort = () => {
    setSortItem("StartDate");
    if (sortdesc === 1) {
      dispatch(ruleListData(limitPerPage, itemOffset, "StartDate", 1));
      setSortDesc(0);
    }
    if (sortdesc === 0) {
      dispatch(ruleListData(limitPerPage, itemOffset, "StartDate", 0));
      setSortDesc(1);
    }
  };
 
  //const [endsortdesc, setEndSortDesc] = useState(0);
  //const [endorderby, setEndOrderBy] = useState("EndDate");
  const endDateSort = () => {
    setSortItem("EndDate");
    if (sortdesc === 1) {
      dispatch(ruleListData(limitPerPage, itemOffset, "EndDate", 1));
      setSortDesc(0);
    }
    if (sortdesc === 0) {
      dispatch(ruleListData(limitPerPage, itemOffset, "EndDate", 0));
      setSortDesc(1);
    }
  };
  //const [namesortdesc, setNameSortDesc] = useState(0);
  //const [nameorderby, setNameOrderBy] = useState("RuleDescription");
  const nameSort = () => {
    setSortItem("RuleDescription");
    if (sortdesc === 1) {
      dispatch(ruleListData(limitPerPage, itemOffset, "RuleDescription", 1));
      setSortDesc(0);
    }
    if (sortdesc === 0) {
      dispatch(ruleListData(limitPerPage, itemOffset, "RuleDescription", 0));
      setSortDesc(1);
    }
  };

  //const [statussortdesc, setstatusSortDesc] = useState(0);
  //const [statusorderby, setstatusOrderBy] = useState("RuleStatus");
  const statusSort = () => {
    setSortItem("RuleStatus");
    if (sortdesc === 1) {
      dispatch(ruleListData(limitPerPage, itemOffset, "RuleStatus", 1));
      setSortDesc(0);
    }
    if (sortdesc === 0) {
      dispatch(ruleListData(limitPerPage, itemOffset, "RuleStatus", 0));
      setSortDesc(1);
    }
  };
  //const [ruletypesortdesc, setruletypeSortDesc] = useState(0);
  //const [ruletypeorderby, setruletypeOrderBy] = useState("RuleTypeName");
  const ruleTypeSort = () => {
    setSortItem("RuleTypeName");
    if (sortdesc === 1) {
      dispatch(ruleListData(limitPerPage, itemOffset, "RuleTypeName", 1));
      setSortDesc(0);
    }
    if (sortdesc === 0) {
      dispatch(ruleListData(limitPerPage, itemOffset, "RuleTypeName", 0));
      setSortDesc(1);
    }
  };
  //const [subtypesortdesc, setsubtypeSortDesc] = useState(0);
  //const [subtypeorderby, setsubtypeOrderBy] = useState("SubTypeName");
  const subTypeSort = () => {
    setSortItem("SubTypeName");
    if (sortdesc === 1) {
      dispatch(ruleListData(limitPerPage, itemOffset, "SubTypeName", 1));
      setSortDesc(0);
    }
    if (sortdesc === 0) {
      dispatch(ruleListData(limitPerPage, itemOffset, "SubTypeName", 0));
      setSortDesc(1);
    }
  };
  //const [freqsortdesc, setFreqSortDesc] = useState(0);
  //const [freqorderby, setFreqOrderBy] = useState("RunFrequency");
  const freqSort = () => {
    setSortItem("RunFrequency");
    if (sortdesc === 1) {
      dispatch(ruleListData(limitPerPage, itemOffset, "RunFrequency", 1));
      setSortDesc(0);
    }
    if (sortdesc === 0) {
      dispatch(ruleListData(limitPerPage, itemOffset, "RunFrequency", 0));
      setSortDesc(1);
    }
  };

  //const [offerCountsortdesc, setOfferCountSortDesc] = useState(0);
  //const [offerorderby, setofferOrderBy] = useState("OfferCount");
  const offerCountSort = () => {
    setSortItem("OfferCount");
    if (sortdesc === 1) {
      dispatch(ruleListData(limitPerPage, itemOffset, "OfferCount", 1));
      setSortDesc(0);
    }
    if (sortdesc === 0) {
      dispatch(ruleListData(limitPerPage, itemOffset, "OfferCount", 0));
      setSortDesc(1);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div
        className={
          !dashboardItem ? "Content_Wrapper mt-107" : "Content_Wrapper"
        }
      >
        <div className="container-fluid">
          <div className="row g-4">
            {!dashboardItem && (
              <div className="col-12 d-flex align-items-center justify-content-between coupon-main-tab">
                <div>
                  <h5 className="Coupon_Ttl">Autopilot Loyalty Rules</h5>
                </div>
                <div className="Header_Right d-flex align-items-center coupon-drop-tab">
                <ul className="coupon-tab">
                    <li className="active"><span>insigts</span></li>
                    <li><span>action items</span></li>
                    <li><span>View Rules</span></li>
                </ul>
                {/* <div className="coupon-category"><select>
                  <option>View Rules</option>
                  <option>Rules 1</option>
                  <option>Rules 2</option>
                  <option>Rules 3</option>
              </select>
            </div> */}
                  <div className="dropdown ml-15 Profile_Dropdown coupon-drop">
                    <button
                      className="BlueButton"
                      role="button"
                      onClick={() => (window.location.href = "/wizard")}
                    >
                      Create New Rule
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="col-12">
              <div className="table-responsive">
                {loading ? (
                  <div
                    className="d-flex flex-row justify-content-center align-items-center m-auto"
                    style={{ minHeight: "238px" }}
                  >
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          Rule Name{" "}
                          <img
                            onClick={nameSort}
                            src={Sorting}
                            alt="Sorting"
                            className="img-fluid ml-5"
                          />
                        </th>
                        <th>
                          Status{" "}
                          <img
                            onClick={statusSort}
                            src={Sorting}
                            alt="Sorting"
                            className="img-fluid ml-5"
                          />
                        </th>
                        <th>
                          Rule Type{" "}
                          <img
                            onClick={ruleTypeSort}
                            src={Sorting}
                            alt="Sorting"
                            className="img-fluid ml-5"
                          />
                        </th>
                        <th>
                          Sub-Type{" "}
                          <img
                            onClick={subTypeSort}
                            src={Sorting}
                            alt="Sorting"
                            className="img-fluid ml-5"
                          />
                        </th>
                        <th>
                          Start{" "}
                          <img
                            onClick={startDateSort}
                            src={Sorting}
                            alt="Sorting"
                            className="img-fluid ml-5"
                          />
                        </th>
                        <th>
                          End{" "}
                          <img
                            onClick={endDateSort}
                            src={Sorting}
                            alt="Sorting"
                            className="img-fluid ml-5"
                          />
                        </th>
                        <th  >
                          Frequency(Days){""}
                          <img
                            onClick={freqSort}
                            src={Sorting}
                            alt="Sorting"
                            className="img-fluid ml-5"
                          />
                        </th>
                        <th>
                          Offer Count{" "}
                          <img
                            onClick={offerCountSort}
                            src={Sorting}
                            alt="Sorting"
                            className="img-fluid ml-5"
                          />
                        </th>
                        <th style={{ textAlign: "center" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.Message?.items?.length > 0 ? (
                        data?.Message?.items?.map((eachItem, i) => (
                          <tr key={i}>
                            <td>{eachItem.RuleDescription}</td>

                            <td>
                              {/* <span
                                className={
                                  eachItem.IsExpired
                                    ? "badge bg-danger"
                                    : "badge bg-success"
                                }>
                                {eachItem.IsExpired ? "Expired" : "Active"}
                              </span> */}

                              {eachItem.RuleStatus === 0 ? (
                                <span className="badge bg-secondary">
                                  Expired
                                </span>
                              ) : eachItem.RuleStatus === 1 ? (
                                <span className="badge bg-success">Active</span>
                              ) : eachItem.RuleStatus === 2 ? (
                                <span className="badge bg-danger">
                                  Rejected
                                </span>
                              ) : eachItem.RuleStatus === 3 ? (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={() =>{
                                    setPendingAprovalPopupShow(true);
                                    setPendingRuleId(eachItem.RuleGuid)
                                  }
                                    
                                  }
                                >
                                  <span className="badge bg-warning">
                                    Pending Approval
                                  </span>
                                </button>
                              ) : (
                                ""
                              )}
                            </td>
                            <PendingAprovalPopup
                              show={pendingAprovalPopupShow}
                              onHide={() => setPendingAprovalPopupShow(false)}
                              ruleId={pendingRuleId}
                            />
                            <td>{eachItem.RuleTypeName}</td>

                            <td>{eachItem.SubTypeName}</td>

                            <td>
                              {DateFormat(eachItem.StartDate.slice(0, 10))}
                            </td>

                            <td>{DateFormat(eachItem.EndDate.slice(0, 10))}</td>

                            <td style={{textAlign:"center"}}>{eachItem.RunFrequency}</td>

                            <td style={{textAlign:"center"}}>{eachItem.OfferCount}</td>

                            <td style={{whiteSpace:"nowrap"}}>
                              <Link
                                to={`/Wizard/view`}
                                state={{ copyguid: eachItem.RuleGuid }}
                                className="Table_Icon"
                                title="View Rule"
                              >
                                <svg
                                  width="25"
                                  height="24"
                                  viewBox="0 0 25 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.1069 3C17.5299 3 22.0417 6.88 22.9882 12C22.0427 17.12 17.5299 21 12.1069 21C6.68383 21 2.172 17.12 1.22559 12C2.171 6.88 6.68383 3 12.1069 3ZM12.1069 19C14.1581 18.9996 16.1484 18.3068 17.752 17.0352C19.3557 15.7635 20.4777 13.9883 20.9344 12C20.476 10.0133 19.3533 8.24 17.7498 6.97003C16.1463 5.70005 14.1569 5.00853 12.1069 5.00853C10.0568 5.00853 8.06744 5.70005 6.46395 6.97003C4.86045 8.24 3.73773 10.0133 3.27934 12C3.73605 13.9883 4.85805 15.7635 6.4617 17.0352C8.06535 18.3068 10.0557 18.9996 12.1069 19ZM12.1069 16.5C10.9065 16.5 9.75535 16.0259 8.90657 15.182C8.0578 14.3381 7.58097 13.1935 7.58097 12C7.58097 10.8065 8.0578 9.66193 8.90657 8.81802C9.75535 7.97411 10.9065 7.5 12.1069 7.5C13.3072 7.5 14.4584 7.97411 15.3072 8.81802C16.1559 9.66193 16.6328 10.8065 16.6328 12C16.6328 13.1935 16.1559 14.3381 15.3072 15.182C14.4584 16.0259 13.3072 16.5 12.1069 16.5ZM12.1069 14.5C12.7737 14.5 13.4133 14.2366 13.8848 13.7678C14.3564 13.2989 14.6213 12.663 14.6213 12C14.6213 11.337 14.3564 10.7011 13.8848 10.2322C13.4133 9.76339 12.7737 9.5 12.1069 9.5C11.44 9.5 10.8005 9.76339 10.3289 10.2322C9.85739 10.7011 9.59248 11.337 9.59248 12C9.59248 12.663 9.85739 13.2989 10.3289 13.7678C10.8005 14.2366 11.44 14.5 12.1069 14.5Z"
                                    fill="current"
                                  />
                                </svg>
                              </Link>

                              {
                                //new Date(eachItem?.StartDate) > new Date() &&
                                //eachItem?.EndDate <= new Date() ||
                                eachItem.RuleStatus === 1 ||
                                eachItem.RuleStatus === 2 ||
                                eachItem.RuleStatus === 3 ? (
                                  <Link
                                    title="Edit Rule"
                                    //to={`/Wizard/edit/${eachItem.RuleGuid}`}
                                    to={`/wizard/edit`}
                                    state={{ copyguid: eachItem.RuleGuid }}
                                    className="Table_Icon ml-24"
                                  >
                                    <svg
                                      width="19"
                                      height="18"
                                      viewBox="0 0 19 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M13.1099 6.68596L11.6878 5.27196L2.32012 14.586V16H3.74227L13.1099 6.68596ZM14.5321 5.27196L15.9542 3.85796L14.5321 2.44396L13.1099 3.85796L14.5321 5.27196ZM4.57504 18H0.308594V13.757L13.821 0.321961C14.0096 0.13449 14.2654 0.0291748 14.5321 0.0291748C14.7988 0.0291748 15.0546 0.13449 15.2432 0.321961L18.0885 3.15096C18.277 3.33849 18.3829 3.5928 18.3829 3.85796C18.3829 4.12313 18.277 4.37743 18.0885 4.56496L4.57604 18H4.57504Z"
                                        fill="current"
                                      />
                                    </svg>
                                  </Link>
                                ) : (
                                  <Link
                                    title="Rule Has Expired Cannot Edit"
                                    style={{ cursor: " not-allowed " }}
                                    to={``}
                                    className="Table_Icon_Disable ml-24"
                                  >
                                    <svg
                                      className="editsvg"
                                      width="19"
                                      height="18"
                                      viewBox="0 0 19 18"
                                      fill="red"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M13.1099 6.68596L11.6878 5.27196L2.32012 14.586V16H3.74227L13.1099 6.68596ZM14.5321 5.27196L15.9542 3.85796L14.5321 2.44396L13.1099 3.85796L14.5321 5.27196ZM4.57504 18H0.308594V13.757L13.821 0.321961C14.0096 0.13449 14.2654 0.0291748 14.5321 0.0291748C14.7988 0.0291748 15.0546 0.13449 15.2432 0.321961L18.0885 3.15096C18.277 3.33849 18.3829 3.5928 18.3829 3.85796C18.3829 4.12313 18.277 4.37743 18.0885 4.56496L4.57604 18H4.57504Z"
                                        fill="current"
                                      />
                                    </svg>
                                  </Link>
                                )
                              }

                              {eachItem.RuleStatus === 0 ||
                              eachItem.RuleStatus === 1 ? (
                                <Link
                                  to={`/ruleAnalysis`}
                                  state={{ ruleguid: eachItem.RuleGuid }}
                                  className="Table_Icon ml-24"
                                  title="Rule Analysis"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11 7H13V17H11V7ZM15 11H17V17H15V11ZM7 13H9V17H7V13ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z"
                                      fill="current"
                                    />
                                  </svg>
                                </Link>
                              ) : (
                                <Link
                                  to=""
                                  style={{ cursor: " not-allowed " }}
                                  className="Table_Icon ml-24"
                                  title="Rule Is Not Approved"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11 7H13V17H11V7ZM15 11H17V17H15V11ZM7 13H9V17H7V13ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z"
                                      fill="current"
                                    />
                                  </svg>
                                </Link>
                              )}

                              <Link
                                to={`/Wizard/copy`}
                                state={{ copyguid: eachItem.RuleGuid }}
                                className="Table_Icon ml-24"
                                title="Copy Rule"
                              >
                                <svg
                                  version="1.1"
                                  id="Layer_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 115.77 122.88"
                                >
                                  <g>
                                    <path
                                      className="st0"
                                      d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                                    />
                                  </g>
                                </svg>
                              </Link>

                              {eachItem.RuleStatus === 0 ? (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                    cursor: "not-allowed",
                                  }}
                                  title={"Rule Already Expired"}
                                  className="Table_Icon ml-30 colorchange"
                                >
                                  <img
                                    width="24"
                                    height="24"
                                    src={expire}
                                    alt="Rule Expired"
                                  />
                                </button>
                              ) : (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={() => {}}
                                  title={"Expire Rule"}
                                  className="Table_Icon ml-24"
                                >
                                  <ModalDialog
                                    id={eachItem.RuleGuid}
                                    SetCount={SetCount}
                                    count={count}
                                  />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr style={{ fontWeight: "700" }}>
                         Before you get on autopilot, lets setup few rules. To start creating rules click "Create New Rule" button.
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <Paginate
                handlePageClick={handlePageClick}
                pageCount={parseInt(pageCount)}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Coupon;
