const CONSTANT_VALUES = {
  ruleType: { label: "Please select rule-type...", value: "" },
  subType: { label: "Please select sub-type...", value: "" },
  departments: { label: "Please select department...", value: "" },
  during: { label: "Please select days...", value: "" },
  shoppers: { label: "Please select shopper...", value: "" },
  offerType: { label: "Select", value: "" },
  frequency: { label: "Select", value: "" },
  couponLimtAvailable : [ 
    {
      label: "In Multiple Transaction", value: "0"
     },
     {
      label: "In Single Transaction", value: "1"
     }
  ]
};

export default CONSTANT_VALUES;
