/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import Nextarrow from "../../assets/images/next-arrow.svg";
import Prevarrow from "../../assets/images/prev-arrow.svg";
import Bakery from "../../assets/images/Bakery.png";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function OfferDetails({
  handleStep,
  value,
  setValue,
  dropdown,
  handleInput,
  handleBankOffer,
}) {
  const bankOfferData = useSelector((state) => state.bankOfferData);
  const offerData = useSelector((state) => state.offerData);
  const { departmentData } = useSelector((state) => state);

  const [formError, setFormError] = useState({
    offerType: "",
    offerStartDate: "",
    offerEndDate: "",
    offerTitle: "",
    offerDetails: "",
    minPurschaseAmount: "",
    minPurschaseQuantity: "",
    rewardQuantity: "",
    discountAmount: "",
    couponLimit: "",
    purchaseUPCs: "",
    rewardUPCs: "",
    OfferImageURL: "",
  });

  const clearFormErrors = (e) => {
    e.preventDefault();
    setFormError({
      offerType: "",
      offerStartDate: "",
      offerEndDate: "",
      offerTitle: "",
      offerDetails: "",
      minPurschaseAmount: "",
      minPurschaseQuantity: "",
      rewardQuantity: "",
      discountAmount: "",
      couponLimit: "",
      purchaseUPCs: "",
      rewardUPCs: "",
      OfferImageURL: "",
    });
  };
  const previousClick = (e) => {
    e.preventDefault();
    handleStep("previous");
  };
  const uploadCouponImage = async (event) => {
    event.preventDefault();
    const file = event.target.files && event.target.files[0];
    const base64 = await convertBase64(file);
    handleInput(base64, "OfferImageURL");
  };

  function decimalChange(e) {
    if (e.key !== "Backspace") {
      if (e.target.value.includes(".")) {
        if (e.target.value.split(".")[1].length >= 2) {
          var num = parseFloat(e.target.value);
          var cleanNum = num.toFixed(2);
          e.target.value = cleanNum;
          e.preventDefault();
        }
      }
    }
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 101 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onloadend = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const offerDetailsClick = (e) => {
    e.preventDefault();
    var isValid = true;
    if (value.offerType === "0") {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, offerType: "Please select Offer Type" };
      });
    }
    if (value.OfferStartedOn === "") {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, offerStartDate: "Please enter Offer Start Date" };
      });
    }
    if (value.OfferExpiresOn === "") {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, offerEndDate: "Please enter Offer expiry Date" };
      });
    }
    if (value.offerTitle === "" || value.offerTitle.length > 100) {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, offerTitle: "Please enter Offer Title" };
      });
    }
    if (value.offerTitle.length > 100) {
      isValid = false;
      setFormError((prev) => {
        return {
          ...prev,
          offerTitle: "Offer Title should be less than 100 characters",
        };
      });
    }
    if (value.offerDetails === "") {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, offerDetails: "Please enter Offer Details" };
      });
    }
    if (
      (value.offerType === "3" || value.offerType === "8") &&
      parseFloat(value.MinPurcahseAmount) < 0
    ) {
      isValid = false;
      setFormError((prev) => {
        return {
          ...prev,
          minPurschaseAmount: "Please enter Min Purchase Amount",
        };
      });
    }
    if (
      (value.offerType === "5" ||
        value.offerType === "7" ||
        value.offerType === "8") &&
      parseInt(value.MinPurchaseQty) <= 0
    ) {
      isValid = false;
      setFormError((prev) => {
        return {
          ...prev,
          minPurschaseQuantity: "Please enter Min Purchase Quantity",
        };
      });
    }
    if (
      value.offerType === "7" &&
      parseFloat(value.rewardQty) <= parseFloat(0)
    ) {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, rewardQuantity: "Please enter Reward Quantity" };
      });
    }
    if (parseFloat(value.discountAmount) <= parseFloat(0)) {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, discountAmount: "Please enter Discount Amount" };
      });
    }
    if (value.offerType !== "3" && parseInt(value.couponLimit) <= 0) {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, couponLimit: "Please enter Coupon Limit" };
      });
    }
    if (
      (value.offerType === "5" ||
        value.offerType === "7" ||
        value.offerType === "8") &&
      value.purchaseUPCs === ""
    ) {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, purchaseUPCs: "Please enter Purchase UPCs" };
      });
    }
    if (value.offerType === "7" && value.rewardUPCs === "") {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, rewardUPCs: "Please enter Reward UPCs" };
      });
    }
    if (value.OfferImageURL === "") {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, OfferImageURL: "Please upload Offer Image" };
      });
    }
    if (isValid) {
      handleStep("next");
    }
  };

  const dropdownDeptData = departmentData.map((each) => {
    return { value: each.id, label: each.Name };
  });
  const selectedCouponExclDept = bankOfferData.find(
    (each) => each.OfferId === value.offerId
  )?.DepartmentList;

  return (
    <React.Fragment>
      <div className="promotion-offer-main">
        <div className="Wizard_Content">
          <div className="container-fluid">
            <div className="justify-content-center row">
              <div className="col-xxl-11 col-xl-12 col-12">
                <label class="form-label">Select From Coupon Bank</label>
                <div className="promotion-offer-btn">
                  <select
                    value={value.offerId}
                    onChange={(e) => {
                      clearFormErrors(e);
                      handleBankOffer(e.target.value);
                    }}
                  >
                    <option key="0" value="0" selected>
                      select from coupon bank
                    </option>
                    {bankOfferData?.map((offer) => (
                      <option key={offer.OfferId} value={offer.OfferId}>
                        {offer.OfferName}
                      </option>
                    ))}
                  </select>
                </div>
                <span>
                  * Selecting from coupon bank will populate or overwrite the
                  coupon details below.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Content_Wrapper_Wizard  pt-60 offer-detail new-promotion">
        <div className="Wizard mt-30">
          <div className="Wizard_Content mb-100">
            <div className="container-fluid">
              <div className="justify-content-center row">
                <div className="col-xxl-11 col-xl-12 col-12">
                  <div className="Wizard_Content_Card Rule_Type_Info">
                    <div className="g-3 row align-items-center">
                      <div className="col-xl-2 col-md-4 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">offer type</label>
                          <div>
                            <select
                              value={value.offerType}
                              onChange={(e) => {
                                handleInput(e.target.value, "offerType");
                                clearFormErrors(e);
                              }}
                            >
                              <option key="0" value="0" selected>
                                Offer Type
                              </option>
                              {offerData?.map((offer) => (
                                <option
                                  key={offer.RSAOfferTypeID}
                                  value={offer.RSAOfferTypeID}
                                >
                                  {offer.OfferTypeName}
                                </option>
                              ))}
                            </select>
                          </div>
                          {formError.offerType && (
                            <p
                              style={{ color: "red" }}
                              className="error-message"
                            >
                              {formError.offerType}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-2 col-md-4 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">offer start on</label>
                          <input
                            type="date"
                            className="form-control"
                            min="2024-01-24"
                            value={value.OfferStartedOn}
                            onChange={(e) => {
                              handleInput(e.target.value, "OfferStartedOn");
                              clearFormErrors(e);
                            }}
                          />
                        </div>
                        {formError.offerStartDate && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.offerStartDate}
                          </p>
                        )}
                      </div>
                      <div className="col-xl-2 col-md-4 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">offer expires on</label>
                          <input
                            type="date"
                            className="form-control"
                            min="2024-02-28"
                            value={value.OfferExpiresOn}
                            onChange={(e) => {
                              handleInput(e.target.value, "OfferExpiresOn");
                              clearFormErrors(e);
                            }}
                          />
                        </div>
                        {formError.offerEndDate && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.offerEndDate}
                          </p>
                        )}
                      </div>
                      <div className="col-xl-2 col-md-4 col-sm-6 col-12 setting-clc">
                        <label className="form-label">recurring Settings</label>
                        <div className="form-check setting-lable">
                          <input
                            name="chooseDeptCheckBox"
                            type="checkbox"
                            id="chkMakeItRecurring"
                            className="form-check-input"
                            checked={value.makeItRecurring}
                            disabled={true}
                            onChange={(e) =>
                              handleInput(e.target.checked, "makeItRecurring")
                            }
                          />
                          <label
                            title=""
                            for="chkMakeItRecurring"
                            className="offer-detail-lable form-check-label"
                          >
                            Make It recurring
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-2 col-md-4 col-sm-6 col-12 details-clc">
                        <div className="col-sm-12 md-detail">
                          <div className="depart-select">
                            <select
                              value={value.offerFrequency}
                              disabled={!value.makeItRecurring}
                              onChange={(e) => {
                                handleInput(e.target.value, "offerFrequency");
                                clearFormErrors(e);
                              }}
                            >
                              <option value="Daily">Daily</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-xl-2 col-md-4 col-sm-6 col-12 unit-w">
                                                <div className="until-date">
                                                    <span>Until</span>
                                                    <input type="date" className="form-control" min="2024-01-24"
                                                        value={value.recurringexpires}
                                                        onChange={(e) => {
                                                            handleInput(e.target.value, "recurringexpires");
                                                        }} />
                                                </div>
                                            </div> */}
                    </div>
                    <div className="g-3 row mt-5">
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">offer title</label>
                          <div>
                            <textarea
                              placeholder="Enter Offer Description..."
                              rows="4"
                              value={value.offerTitle}
                              onChange={(e) => {
                                handleInput(e.target.value, "offerTitle");
                                clearFormErrors(e);
                              }}
                            />
                          </div>
                          {formError.offerTitle && (
                            <p
                              style={{ color: "red" }}
                              className="error-message"
                            >
                              {formError.offerTitle}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">offer details</label>
                          <div>
                            <textarea
                              placeholder="Enter Offer Description..."
                              rows="4"
                              value={value.offerDetails}
                              onChange={(e) => {
                                handleInput(e.target.value, "offerDetails");
                              }}
                            />
                          </div>
                          {formError.offerDetails && (
                            <p
                              style={{ color: "red" }}
                              className="error-message"
                            >
                              {formError.offerDetails}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="g-3 row mt-5">
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">
                            additional Description
                          </label>
                          <div>
                            <textarea
                              placeholder="Enter Offer Description..."
                              rows="4"
                              value={value.additionalDescription}
                              onChange={(e) => {
                                handleInput(
                                  e.target.value,
                                  "additionalDescription"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">offer disclaimer</label>
                          <div>
                            <textarea
                              placeholder="Enter Offer Desclaimer..."
                              rows="4"
                              value={value.offerDesclaimer}
                              onChange={(e) => {
                                handleInput(e.target.value, "offerDesclaimer");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="g-3 row mt-5 align-items-center">
                      <div className="col-xl-2 col-md-3 col-sm-6 col-12 amount-clc">
                        <div className="position-relative">
                          <label className="form-label form-text">
                            Min purchase amount
                          </label>
                          <Form.Control
                            type="number"
                            placeholder="Min purchase amount"
                            name="MinPurchaseAmount"
                            disabled={
                              !(value.offerType === "3") &&
                              !(value.offerType === "8")
                            }
                            value={value.MinPurcahseAmount}
                            onChange={(e) => {
                              handleInput(e.target.value, "MinPurcahseAmount");
                              clearFormErrors(e);
                            }}
                          />
                        </div>
                        {formError.minPurschaseAmount && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.minPurschaseAmount}
                          </p>
                        )}
                      </div>
                      <div className="col-xl-2 col-md-3 col-sm-6 col-12 min-purchase">
                        <div className="position-relative">
                          <label className="form-label form-text">
                            Min purchase Qty
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            value={value.MinPurchaseQty}
                            disabled={
                              !(value.offerType === "5") &&
                              !(value.offerType === "7") &&
                              !(value.offerType === "8")
                            }
                            onChange={(e) => {
                              handleInput(e.target.value, "MinPurchaseQty");
                              clearFormErrors(e);
                            }}
                          />
                        </div>
                        {formError.minPurschaseQuantity && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.minPurschaseQuantity}
                          </p>
                        )}
                      </div>
                      <div className="col-xl-1 col-md-2 col-sm-6 col-12 reward-qty">
                        <div className="position-relative">
                          <label className="form-label form-text">
                            Reward Qty
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            value={value.rewardQty}
                            disabled={!(value.offerType === "7")}
                            onChange={(e) => {
                              handleInput(e.target.value, "rewardQty");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 col-12">
                        <div className="position-relative form-check">
                          <input
                            name="chooseDeptCheckBox"
                            type="checkbox"
                            id="chkIsItDiscountPercentage"
                            className="form-check-input"
                            checked={value.isDiscuountPercentage}
                            onChange={(e) =>
                              handleInput(
                                e.target.checked,
                                "isDiscuountPercentage"
                              )
                            }
                          />
                          <label
                            title=""
                            for="chkIsItDiscountPercentage"
                            className="offer-detail-lable form-check-label"
                          >
                            Is Discount Percentage?
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 col-12 disc-amount">
                        <div className="position-relative">
                          <label className="form-label form-text">
                            Discount amount
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            value={value.discountAmount}
                            onChange={(e) => {
                              handleInput(e.target.value, "discountAmount");
                              clearFormErrors(e);
                            }}
                          />
                        </div>
                        {formError.discountAmount && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.discountAmount}
                          </p>
                        )}
                      </div>
                      {/* <div className="col-md-2 col-sm-6 col-12">
                                                <div className="position-relative">
                                                    <label className="form-label form-text">Max Discount amount</label>
                                                    <input type="number" className="form-control"
                                                        value={value.maxDiscountAmount}
                                                        disabled={
                                                            !value.isDiscuountPercentage
                                                        }
                                                        onChange={(e) => {
                                                            handleInput(e.target.value, "maxDiscountAmount");
                                                        }} />
                                                </div>
                                            </div> */}
                      <div className="col-xl-1 col-md-2 col-sm-6 col-12 coupon-limit">
                        <div className="position-relative">
                          <label className="form-label form-text">
                            Coupon Limit
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            value={value.couponLimit}
                            disabled={!(value.offerType !== "3")}
                            defaultValue="1"
                            onChange={(e) => {
                              handleInput(e.target.value, "couponLimit");
                              clearFormErrors(e);
                            }}
                          />
                        </div>
                        {formError.couponLimit && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.couponLimit}
                          </p>
                        )}
                      </div>
                      <div class="col-xl-2 col-md-4 col-sm-6 col-12 limit-clc">
                        <div class="position-relative">
                          <label class="form-label">
                            Coupon limit applicable
                          </label>
                          <div>
                            <select
                              value={value.CouponLimitInSingleTrans}
                              disabled={!(value.offerType !== "3")}
                              onChange={(e) => {
                                handleInput(
                                  e.target.value,
                                  "CouponLimitInSingleTrans"
                                );
                              }}
                            >
                              <option value="0" selected>
                                In Multiple Transaction
                              </option>
                              <option value="1"> In Single Transaction</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="g-3 row mt-5">
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">
                            Purchase UPCs
                            <span>
                              {" "}
                              (enter below or paste from excel column and click
                              enter)
                            </span>
                          </label>
                          <div>
                            <textarea
                              placeholder="Enter UPC..."
                              rows="7"
                              value={value.purchaseUPCs}
                              disabled={
                                !(value.offerType === "5") &&
                                !(value.offerType === "7") &&
                                !(value.offerType === "8")
                              }
                              onChange={(e) => {
                                handleInput(e.target.value, "purchaseUPCs");
                                clearFormErrors(e);
                              }}
                            />
                          </div>
                          {formError.purchaseUPCs && (
                            <p
                              style={{ color: "red" }}
                              className="error-message"
                            >
                              {formError.purchaseUPCs}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">
                            Reward UPCs
                            <span>
                              {" "}
                              (enter below or paste from excel column and click
                              enter)
                            </span>
                          </label>
                          <div>
                            <textarea
                              placeholder="Enter UPC..."
                              rows="7"
                              value={value.rewardUPCs}
                              disabled={!(value.offerType === "7")}
                              onChange={(e) => {
                                handleInput(e.target.value, "rewardUPCs");
                                clearFormErrors(e);
                              }}
                            />
                          </div>
                          {formError.rewardUPCs && (
                            <p
                              style={{ color: "red" }}
                              className="error-message"
                            >
                              {formError.rewardUPCs}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="g-3 row mt-5 align-items-center">
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">offer image</label>
                          <div>
                            <input
                              type="file"
                              className="offer-img-sec"
                              name="img"
                              accept="image/*"
                              onChange={(e) => {
                                uploadCouponImage(e);
                              }}
                            />
                          </div>
                          {formError.OfferImageURL && (
                            <p
                              style={{ color: "red" }}
                              className="error-message"
                            >
                              {formError.OfferImageURL}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-12">
                        <div className="offer-image">
                          <img
                            src={
                              value.OfferImageURL === ""
                                ? Bakery
                                : value.OfferImageURL
                            }
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                      </div>
                      {value.offerType === "3" && (
                        <div className="col-md-4 col-sm-4 col-12">
                          <div class="position-relative">
                            <label class="form-label">Excl Department</label>
                            <Form.Group>
                              <Select
                                isMulti
                                options={dropdownDeptData?.filter(
                                  (a) => !value.selectedDepartments.includes(a)
                                )}
                                placeholder={"Multi Select Department..."}
                                value={selectedCouponExclDept}
                                onChange={(e) => {
                                  handleInput(e, "excludedDepartments");
                                }}
                                className="react-select-container select mb-15"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="g-3 row mt-5">
                      <div className="col-md-6 col-sm-6 col-12">
                        <div className="position-relative">
                          <label className="form-label">
                            Additional Settings
                          </label>
                          <div className="form-lables d-flex">
                            <div className="form-check form-mr">
                              <input
                                name="chooseDeptCheckBox"
                                type="checkbox"
                                id="chkMakeItDOW"
                                className="form-check-input"
                                checked={value.makeItDOW}
                                onChange={(e) =>
                                  handleInput(e.target.checked, "makeItDOW")
                                }
                              />
                              <label
                                title=""
                                for="chkMakeItDOW"
                                className="offer-detail-lable form-check-label"
                              >
                                Make It DOW
                              </label>
                            </div>
                            <div className="form-check form-mr">
                              <input
                                name="chooseDeptCheckBox"
                                type="checkbox"
                                id="chkMakeItFeatured"
                                className="form-check-input"
                                checked={value.makeItFeatured}
                                onChange={(e) =>
                                  handleInput(
                                    e.target.checked,
                                    "makeItFeatured"
                                  )
                                }
                              />
                              <label
                                title=""
                                for="chkMakeItFeatured"
                                className="offer-detail-lable form-check-label"
                              >
                                Make It Featured
                              </label>
                            </div>
                            <div className="form-check form-mr">
                              <input
                                name="chkMonthlyDeal"
                                type="checkbox"
                                id="chkMonthlyDeal"
                                className="form-check-input"
                                checked={value.monthlyDeal}
                                onChange={(e) =>
                                  handleInput(e.target.checked, "monthlyDeal")
                                }
                              />
                              <label
                                title=""
                                for="chkMonthlyDeal"
                                className="offer-detail-lable form-check-label"
                              >
                                Monthly Deal
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex align-items-center justify-content-end text-end p-20 pt-0 detail-btn">
                      <button
                        type="submit"
                        className="GrayButton mt-14  btn btn-primary prev-btn"
                        onClick={(e) => {
                          previousClick(e);
                        }}
                      >
                        <img
                          src={Prevarrow}
                          className="img-fluid mr-5"
                          alt="Rules"
                        />
                        Previous
                      </button>
                      <button
                        type="submit"
                        className="BlueButton ml-10 mt-14 btn btn-primary"
                        onClick={(e) => {
                          offerDetailsClick(e);
                          setValue({
                            ...value,
                            excludedDepartments: selectedCouponExclDept,
                          });
                        }}
                      >
                        Next
                        <img
                          src={Nextarrow}
                          className="img-fluid ml-5"
                          alt="Rules"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
