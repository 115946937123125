/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {  useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function FocusBarChart(props) {
    const salesGraph = useSelector((state) => state.dashboardFocusItemAnalysisData);
    const loading = useSelector((state) => state.dashboardFocusItemAnalysisLoading);
    const storeData = useSelector((state) => state.storesData);
    const focusItemsdata = useSelector((state) => state.dashboardFocusItemsData);
    const focusData = focusItemsdata?.Message?.focus_items?.filter(a => a.focus_id == props.focusId)[0];
    const [graphData, setGraphData] = useState({});
    const options = {
        responsive: true,
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                font: { size: '29' },
                color: '333',
                position: 'top',
                text: 'Sales Overview',
            },
        },
    };
    
    useEffect(() => {
        let datasets = [];
        const labels = salesGraph?.Message?.focus_sales_graph.map(item => item.month);
        const nonLoyaltyCount = salesGraph?.Message?.focus_sales_graph?.filter(a => a.non_loyalty > 0);
        const loyaltyCount = salesGraph?.Message?.focus_sales_graph?.filter(a => a.loyalty > 0);
        if (nonLoyaltyCount?.length > 0) {
            let nonloyalty = {
                label: 'Non-Loyalty',
                data: salesGraph?.Message?.focus_sales_graph.map(item => item.non_loyalty),
                backgroundColor: 'rgba(0, 104, 151, 0.5)',
            }
            datasets.push(nonloyalty);
        }
        if (loyaltyCount?.length > 0) {
            let loyalty = {
                label: 'Loyalty',
                data: salesGraph?.Message?.focus_sales_graph.map(item => item.loyalty),
                backgroundColor: 'rgba(100, 201, 254, 0.5)',
            };
            datasets.push(loyalty);
        }
        const data = {
            labels,
            datasets: datasets,
        };
        setGraphData(data);
    }, [salesGraph]);

    const hanldeStore = (event) => {
        props.storeChange(event.target.value);
    }
    return (
        <React.Fragment>
                {/* <div  className="produce-department-main-title">
                    <div className="department-img-title">
                        {props.IsDepartment === "1" ? (
                            <div className="department-image">
                                <img src={Bakery} alt="department image" className="img-fluid" />
                            </div>
                        ) : ('')}
                        <div className="main-title">
                            <h2>{focusData.name} analysis</h2>
                        </div>
                    </div>
                    <div className="produce-department-category">
                        <select value={props.storeId} onChange={hanldeStore}>
                            <option key="0" value="0" selected>All Stores</option>
                            {storeData &&
                                storeData?.map((store) => (<option key={store.id} value={store.id}>{store.Name}</option>))
                            }
                        </select>
                    </div>
                </div> */}
            <div className="produce-department col-md-6">
            <div className="chart-title">
                <h2>by sales overview</h2>
            </div>
                {
                    loading &&
                    <>
                        <div
                            style={{ height: "25vh" }}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </>
                }
                {(!loading && salesGraph?.Message != null) &&
                    <>
                        <Bar options={options} data={graphData} />
                    </>
                }
            </div>
        </React.Fragment>
    );
}

export default FocusBarChart;