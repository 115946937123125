export const getRuleDate = (value) => {
  let yyyy = value.split("-")[0];
  let mm = value.split("-")[1];
  let dd = value.split("-")[2];
  return `${mm}/${dd}/${yyyy}`;
};

export const DateFormat = (value) => {
  let yyyy = value.split("-")[0];
  let mm = value.split("-")[1];
  let dd = value.split("-")[2];
  return `${mm}-${dd}-${yyyy}`
}


const DailyandWeeklyDates = (
  newStartDate,
  endDate,
  listDate,
  dateMove,
  value,
  setRunDate
) => {
  while (newStartDate < endDate) {
   newStartDate = dateMove?.toISOString()?.slice(0, 10);
    listDate.push(newStartDate);
    dateMove?.setDate(dateMove?.getDate() + value);
    setRunDate(listDate);
  }  
};

const MonthLastDate = (y, m) => {
  let Last = new Date(y, m, 0).getDate();
  return Last;
};

export const getDailyandWeekly = (startDate, endDate, info, setRunDate) => {
  const listDate = [];
  const dateMove = new Date(startDate);
  let newStartDate = startDate;
  if (info.frequency.value === 1) {
    DailyandWeeklyDates(
      newStartDate,
      endDate,
      listDate,
      dateMove,
      1,
      setRunDate
    );
  }
  if (info.frequency.value === 2) {
    DailyandWeeklyDates(
      newStartDate,
      endDate,
      listDate,
      dateMove,
      7,
      setRunDate
    );
  }
  if (info.frequency.value === 5) {
    DailyandWeeklyDates(
      newStartDate,
      endDate,
      listDate,
      dateMove,
      14,
      setRunDate
    );
  }
  if (info.frequency.value === 3) {
    DailyandWeeklyDates(
      newStartDate,
      endDate,
      listDate,
      dateMove,
      30,
      setRunDate
    );
  }

  if (info.frequency.value === 6) {
    DailyandWeeklyDates(
      newStartDate,
      endDate,
      listDate,
      dateMove,
      90,
      setRunDate
    );
  }
  
}
export const getMonthlyandYearly = (startDate, endDate, info, setRunDate) => {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];
  var d = new Date(startDate);
  var first = d.getFullYear();
  const days = [],
    months = new Set(),
    years = new Set();
  let date = startDate;
  var s = new Date(endDate);
  var second = s.getFullYear();
  if (info.frequency.value === 3 ||info.frequency.value === 6 ) {
    for (var i = startYear; i <= endYear; i++) {
      let restartMonth = (info.frequency.value === 3 || displayMonth=== 10) ? 0 : displayMonth === 11 ? 1 : 2;
      let addMonth = info.frequency.value === 3 ? 1 : 3;
      var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : restartMonth;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + addMonth) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        date = d.toISOString().slice(0, 10);
        var y = date.split("-")[0];
        let day = date.split("-")[2];
        let newDay;
        let monthDate;
        if (day === "31" || day === "30" || day === "29") {
          if (day > MonthLastDate(y, displayMonth)) {
            newDay = MonthLastDate(y, displayMonth);
          } else {
            newDay = day;
          }
           monthDate = [i, displayMonth, newDay].join("-");
          dates.push(monthDate);
          setRunDate(dates);
        }
        if (day < "29") {
          monthDate = [i, displayMonth, day].join("-");
          dates.push(monthDate);
          setRunDate(dates);
        }
      }
    }
  }
  if (info.frequency.value === 4) {
    let nDay;
    for (i = first; i <= second; i++) {
      date = d.toISOString().slice(0, 10);
      months.add(date.slice(0, 7) + 1);
      years.add(date.slice(0, 4));
      days.push(date);
      d.setDate(d.getDate());
      let dd = date.split("-")[2];
      let m = date.split("-")[1];
      if (dd > MonthLastDate(i, m)) {
        nDay = MonthLastDate(i, m);
      } else {
        nDay = dd;
      }
      dates.push([i, m, nDay].join("-"));
      setRunDate(dates);
    }
  }
  return dates;
};

