export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const EDIT_USER_START = "EDIT_USER_START";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

export const GET_USER_LIST_START = "GET_USER_LIST_START";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

export const GET_SINGLE_USER_START = "GET_SINGLE_USER_START";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAILURE = "GET_SINGLE_USER_FAILURE";

export const GET_ROLES_START = "GET_ROLES_START";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const FETCH_SUMMARY_DATA_START = "FETCH_SUMMARY_DATA_START";
export const FETCH_SUMMARY_DATA_SUCCESS = "FETCH_SUMMARY_DATA_SUCCESS";
export const FETCH_SUMMARY_DATA_FAILURE = "FETCH_SUMMARY_DATA_FAILURE";

export const DEPARTMENT_DATA_START = "DEPARTMENT_DATA_START";
export const DEPARTMENT_DATA_SUCCESS = "DEPARTMENT_DATA_SUCCESS";
export const DEPARTMENT_DATA_FAILURE = "DEPARTMENT_DATA_FAILURE";

export const RULE_TYPE_DATA_START = "RULE_TYPE_DATA_START";
export const RULE_TYPE_DATA_SUCCESS = "RULE_TYPE_DATA_SUCCESS";
export const RULE_TYPE_DATA_FAILURE = "RULE_TYPE_DATA_FAILURE";

export const FREQUENCY_DATA_START = "FREQUENCY_DATA_START";
export const FREQUENCY_DATA_SUCCESS = "FREQUENCY_DATA_SUCCESS";
export const FREQUENCY_DATA_FAILURE = "FREQUENCY_DATA_FAILURE";

export const SUBMIT_RULE_DATA_START = "SUBMIT_RULE_DATA_START";
export const SUBMIT_RULE_DATA_SUCCESS = "SUBMIT_RULE_DATA_SUCCESS";
export const SUBMIT_RULE_DATA_FAILURE = "SUBMIT_RULE_DATA_FAILURE";

export const SUB_TYPE_DATA_START = "SUB_TYPE_DATA_START";
export const SUB_TYPE_DATA_SUCCESS = "SUB_TYPE_DATA_SUCCESS";
export const SUB_TYPE_DATA_FAILURE = "SUB_TYPE_DATA_FAILURE";

export const DURING_DATA_START = "DURING_DATA_START";
export const DURING_DATA_SUCCESS = "DURING_DATA_SUCCESS";
export const DURING_DATA_FAILURE = "DURING_DATA_FAILURE";

export const SHOPPER_DATA_START = "SHOPPER_DATA_START";
export const SHOPPER_DATA_SUCCESS = "SHOPPER_DATA_SUCCESS";
export const SHOPPER_DATA_FAILURE = "SHOPPER_DATA_FAILURE";

export const GROUPS_DATA_START = "GROUPS_DATA_START";
export const GROUPS_DATA_SUCCESS = "GROUPS_DATA_SUCCESS";
export const GROUPS_DATA_FAILURE = "GROUPS_DATA_FAILURE";

export const VALIDATION_DATA_START = "VALIDATION_DATA_START";
export const VALIDATION_DATA_SUCCESS = "VALIDATION_DATA_SUCCESS";
export const VALIDATION_DATA_FAILURE = "VALIDATION_DATA_FAILURE";

export const STORES_DATA_START = "STORES_DATA_START";
export const STORES_DATA_SUCCESS = "STORES_DATA_SUCCESS";
export const STORES_DATA_FAILURE = "STORES_DATA_FAILURE";

export const OFFER_DATA_START = "OFFER_DATA_START";
export const OFFER_DATA_SUCCESS = "OFFER_DATA_SUCCESS";
export const OFFER_DATA_FAILURE = "OFFER_DATA_FAILURE";

export const OFFER_DURATION_DATA_START = "OFFER_DURATION_DATA_START";
export const OFFER_DURATION_DATA_SUCCESS = "OFFER_DURATION_DATA_SUCCESS";
export const OFFER_DURATION_DATA_FAILURE = "OFFER_DURATION_DATA_FAILURE";

//ruleList

export const RULE_LIST_DATA_START = "RULE_LIST_DATA_START";
export const RULE_LIST_DATA_SUCCESS = "RULE_LIST_DATA_SUCCESS";
export const RULE_LIST_DATA_FAILURE = "RULE_LIST_DATA_FAILURE";
export const FETCH_SUMMARY_GRAPHS_DATA_START =
  "FETCH_SUMMARY_GRAPHS_DATA_START";
export const FETCH_SUMMARY_GRAPHS_DATA_SUCCESS =
  "FETCH_SUMMARY_GRAPHS_DATA_SUCCESS";
export const FETCH_SUMMARY_GRAPHS_DATA_FAILURE =
  "FETCH_SUMMARY_GRAPHS_DATA_FAILURE";

//Rule Analysis page constants
export const RULE_ANALYSIS_DATA_START = "RULE_ANALYSIS_DATA_START";
export const RULE_ANALYSIS_DATA_SUCCESS = "RULE_ANALYSIS_DATA_SUCCESS";
export const RULE_ANALYSIS_DATA_FAILURE = "RULE_ANALYSIS_DATA_FAILURE";

export const ANALYSIS_PERFORMANCE_DATA_START =
  "ANALYSIS_PERFORMANCE_DATA_START";
export const ANALYSIS_PERFORMANCE_DATA_SUCCESS =
  "ANALYSIS_PERFORMANCE_DATA_SUCCESS";
export const ANALYSIS_PERFORMANCE_DATA_FAILURE =
  "ANALYSIS_PERFORMANCE_DATA_FAILURE";

export const ANALYSIS_GROUPSBYRULE_DATA_START =
  "ANALYSIS_GROUPSBYRULE_DATA_START";
export const ANALYSIS_GROUPSBYRULE_DATA_SUCCESS =
  "ANALYSIS_GROUPSBYRULE_DATA_SUCCESS";
export const ANALYSIS_GROUPSBYRULE_DATA_FAILURE =
  "ANALYSIS_GROUPSBYRULE_DATA_FAILURE";

//Rule Analysis : Get Rule Data constants
export const RULE_DATA_START = "RULE_DATA_START";
export const RULE_DATA_SUCCESS = "RULE_DATA_SUCCESS";
export const RULE_DATA_FAILURE = "RULE_DATA_FAILURE";

//Expire Rule
export const EXPIRE_RULE_DATA_START = "EXPIRE_RULE_DATA_START";
export const EXPIRE_RULE_DATA_SUCCESS = "EXPIRE_RULE_DATA_SUCCESS";
export const EXPIRE_RULE_DATA_FAILURE = "EXPIRE_RULE_DATA_FAILURE";

export const BANKOFFER_DATA_START = "BANKOFFER_DATA_START";
export const BANKOFFER_DATA_SUCCESS = "BANKOFFER_DATA_SUCCESS";
export const BANKOFFER_DATA_FAILURE = "BANKOFFER_DATA_FAILURE";

export const ZIPCODE_DATA_START = "ZIPCODE_DATA_START";
export const ZIPCODE_DATA_SUCCESS = "ZIPCODE_DATA_SUCCESS";
export const ZIPCODE_DATA_FAILURE = "ZIPCODE_DATA_FAILURE";

export const PENDING_APPROVAL_DATA_START = "PENDING_APPROVAL_DATA_START";
export const PENDING_APPROVAL_DATA_SUCCESS = "PENDING_APPROVAL_DATA_SUCCESS";
export const PENDING_APPROVAL_DATA_FAILURE = "PENDING_APPROVAL_DATA_FAILURE";

// forgot password
export const FORGOT_PASSWORD_DATA_START = "FORGOT_PASSWORD_DATA_START";
export const FORGOT_PASSWORD_DATA_SUCCESS = "FORGOT_PASSWORD_DATA_SUCCESS";
export const FORGOT_PASSWORD_DATA_FAILURE = "FORGOT_PASSWORD_DATA_FAILURE";

//change password

export const CHANGE_PASSWORD_DATA_START = "CHANGE_PASSWORD_DATA_START";
export const CHANGE_PASSWORD_DATA_SUCCESS = "CHANGE_PASSWORD_DATA_SUCCESS";
export const CHANGE_PASSWORD_DATA_FAILURE = "CHANGE_PASSWORD_DATA_FAILURE";

export const APPROVE_RULE_DATA_START = "APPROVE_RULE_DATA_START";
export const APPROVE_RULE_DATA_SUCCESS = "APPROVE_RULE_DATA_SUCCESS";
export const APPROVE_RULE_DATA_FAILURE = "APPROVE_RULE_DATA_FAILURE";

export const REJECT_RULE_DATA_START = "REJECT_RULE_DATA_START";
export const REJECT_RULE_DATA_SUCCESS = "REJECT_RULE_DATA_SUCCESS";
export const REJECT_RULE_DATA_FAILURE = "REJECT_RULE_DATA_FAILURE";

// Approve Pending Rule Contants
export const PENDINGRULE_DATA_START = "PENDINGRULE_DATA_START";
export const PENDINGRULE_DATA_SUCCESS = "PENDINGRULE_DATA_SUCCESS";
export const PENDINGRULE_DATA_FAILURE = "PENDINGRULE_DATA_FAILURE";

export const CREATED_OFFERS_DATA_START = "CREATED_OFFERS_DATA_START ";
export const CREATED_OFFERS_DATA_SUCCESS = "CREATED_OFFERS_DATA_SUCCESS";
export const CREATED_OFFERS_DATA_FAILURE = "CREATED_OFFERS_DATA_FAILURE";

export const EXPIRE_COUPON_DATA_START = "EXPIRE_COUPON_DATA_START";
export const EXPIRE_COUPON_DATA_SUCCESS = "EXPIRE_COUPON_DATA_SUCCESS";
export const EXPIRE_COUPON_DATA_FAILURE = "EXPIRE_COUPON_DATA_FAILURE";

export const GET_CREATED_OFFERS_DATA_START = "GET_CREATED_OFFERS_DATA_START ";
export const GET_CREATED_OFFERS_DATA_SUCCESS =
  "GET_CREATED_OFFERS_DATA_SUCCESS";
export const GET_CREATED_OFFERS_DATA_FAILURE =
  "GET_CREATED_OFFERS_DATA_FAILURE";

// Analysis Download Report

export const RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_START =
  "RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_START";
export const RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_SUCCESS =
  "RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_SUCCESS";
export const RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_FAILURE =
  "RULE_ANALYSIS_DOWNLOAD_REPORT_DATA_FAILURE";

// Dashboard Overview
export const DASHBOARD_OVERVIEW_DATA_START = "DASHBOARD_OVERVIEW_DATA_START";
export const DASHBOARD_OVERVIEW_DATA_SUCCESS =
  "DASHBOARD_OVERVIEW_DATA_SUCCESS";
export const DASHBOARD_OVERVIEW_DATA_FAILURE =
  "DASHBOARD_OVERVIEW_DATA_FAILURE";

// Dashboard shopper groups
export const DASHBOARD_SHOPPER_GROUPS_DATA_START =
  "DASHBOARD_SHOPPER_GROUPS_DATA_START";
export const DASHBOARD_SHOPPER_GROUPS_DATA_SUCCESS =
  "DASHBOARD_SHOPPER_GROUPS_DATA_SUCCESS";
export const DASHBOARD_SHOPPER_GROUPS_DATA_FAILURE =
  "DASHBOARD_SHOPPER_GROUPS_DATA_FAILURE";

// Dashboard departments
export const DASHBOARD_DEPARTMENTS_DATA_START =
  "DASHBOARD_DEPARTMENTS_DATA_START";
export const DASHBOARD_DEPARTMENTS_DATA_SUCCESS =
  "DASHBOARD_DEPARTMENTS_DATA_SUCCESS";
export const DASHBOARD_DEPARTMENTS_DATA_FAILURE =
  "DASHBOARD_DEPARTMENTS_DATA_FAILURE";

// Dashboard FOCUS ITEMS
export const DASHBOARD_FOCUS_ITEMS_DATA_START =
  "DASHBOARD_FOCUS_ITEMS_DATA_START";
export const DASHBOARD_FOCUS_ITEMS_DATA_SUCCESS =
  "DASHBOARD_FOCUS_ITEMS_DATA_SUCCESS";
export const DASHBOARD_FOCUS_ITEMS_DATA_FAILURE =
  "DASHBOARD_FOCUS_ITEMS_DATA_FAILURE";

// Dashboard DEPARTMENT ANALYSIS
export const DASHBOARD_DEPARTMENT_ANALYSIS_DATA_START =
  "DASHBOARD_DEPARTMENT_ANALYSIS_DATA_START";
export const DASHBOARD_DEPARTMENT_ANALYSIS_DATA_SUCCESS =
  "DASHBOARD_DEPARTMENT_ANALYSIS_DATA_SUCCESS";
export const DASHBOARD_DEPARTMENT_ANALYSIS_DATA_FAILURE =
  "DASHBOARD_DEPARTMENT_ANALYSIS_DATA_FAILURE";

// Dashboard FOCUS_ITEMS ANALYSIS
export const DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_START =
  "DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_START";
export const DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_SUCCESS =
  "DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_SUCCESS";
export const DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_FAILURE =
  "DASHBOARD_FOCUS_ITEMS_ANALYSIS_DATA_FAILURE";

// Dashboard GROUP ANALYSIS
export const DASHBOARD_GROUP_ANALYSIS_DATA_START =
  "DASHBOARD_GROUP_ANALYSIS_DATA_START";
export const DASHBOARD_GROUP_ANALYSIS_DATA_SUCCESS =
  "DASHBOARD_GROUP_ANALYSIS_DATA_SUCCESS";
export const DASHBOARD_GROUP_ANALYSIS_DATA_FAILURE =
  "DASHBOARD_GROUP_ANALYSIS_DATA_FAILURE";

// crete-focus-items

export const CREATE_FOCUS_ITEM_START = "CREATE_FOCUS_ITEM_START";
export const CREATE_FOCUS_ITEM_SUCCESS = "CREATE_FOCUS_ITEM_SUCCESS";
export const CREATE_FOCUS_ITEM_FAILURE = "CREATE_FOCUS_ITEM_FAILURE";

// crete-Promotion

export const CREATE_PROMOTION_START = "CREATE_PROMOTION_START";
export const CREATE_PROMOTION_SUCCESS = "CREATE_PROMOTION_SUCCESS";
export const CREATE_PROMOTION_FAILURE = "CREATE_PROMOTION_FAILURE";

// crete-Promotion

export const GET_SEGMENT_DATA_START = "GET_SEGMENT_DATA_START";
export const GET_SEGMENT_DATA_SUCCESS = "GET_SEGMENT_DATA_SUCCESS";
export const GET_SEGMENT_DATA_FAILURE = "GET_SEGMENT_DATA_FAILURE";

// list-Promotion

export const GET_PROMOTION_DATA_START = "GET_PROMOTION_DATA_START";
export const GET_PROMOTION_DATA_SUCCESS = "GET_PROMOTION_DATA_SUCCESS";
export const GET_PROMOTION_DATA_FAILURE = "GET_PROMOTION_DATA_FAILURE";

// expire-Promotion

export const EXPIRE_PROMOTION_START = "EXPIRE_PROMOTION_START";
export const EXPIRE_PROMOTION_SUCCESS = "EXPIRE_PROMOTION_SUCCESS";
export const EXPIRE_PROMOTION_FAILURE = "EXPIRE_PROMOTION_FAILURE";

// set department

export const SET_DEPARTMENT_START = "SET_DEPARTMENT_START";
export const SET_DEPARTMENT_SUCCESS = "SET_DEPARTMENT_SUCCESS";
export const SET_DEPARTMENT_FAILURE = "SET_DEPARTMENT_FAILURE";

// get tracked department

export const GET_TRACKED_DEPARTMENT_START = "GET_TRACKED_DEPARTMENT_START";
export const GET_TRACKED_DEPARTMENT_SUCCESS = "GET_TRACKED_DEPARTMENT_SUCCESS";
export const GET_TRACKED_DEPARTMENT_FAILURE = "GET_TRACKED_DEPARTMENT_FAILURE";

// edit focus items
export const EDIT_FOCUS_ITEM_START = "EDIT_FOCUS_ITEM_START";
export const EDIT_FOCUS_ITEM_SUCCESS = "EDIT_FOCUS_ITEM_SUCCESS";
export const EDIT_FOCUS_ITEM_FAILURE = "EDIT_FOCUS_ITEM_FAILURE";
