/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect } from "react";
import Select from "react-select";
import { Button, Col, Form, FormGroup, Row, Spinner } from "react-bootstrap";
import NextArrow from "../../assets/images/next-arrow.svg";
import PrevArrow from "../../assets/images/prev-arrow.svg";
import { useState } from "react";
import PastedChips from "../PastedChips";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

function MyVerticallyCenteredModal(props) {
  let zipcodes = props.zipcodeData;
  // let setCheckedZipcode = props.setCheckedZipcode;
  // let checkedZipcode = props.checkedZipcode;

  let [checkedZipcode, setCheckedZipcode] = useState([]);

  const [filter, setFilter] = useState("");

  const handleCheck = (event) => {
    let updatedList = [...checkedZipcode];
    if (event.target.checked) {
      updatedList = [...checkedZipcode, event.target.value];
    } else {
      updatedList.splice(checkedZipcode.indexOf(event.target.value), 1);
    }
    setCheckedZipcode(updatedList);
  };

  const handleAllCheckZipCodes = (event) => {
    if (event.target.checked) {
      setCheckedZipcode(zipcodes);
    } else {
      setCheckedZipcode([]);
    }
  };

  const handleSubmit = () => {
    props.setInfo({
      ...props.info,
      zipCodesData: [...props.info.zipCodesData, ...checkedZipcode]
    });
    // const zipString = checkedZipcode.join(" ");
    // props.setUpcs(zipString);
    setCheckedZipcode([]);
    setFilter("");
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="sm"
      scrollable={true}
      aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <input
            placeholder="Search........"
            id="filter"
            name="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: "40%", height: "30px", fontSize: "13px" }}
            type="text"
          />
          <label style={{ marginLeft: "20px", fontSize: "18px" }}>
            Select All
          </label>
          <input
            style={{ marginLeft: "10px" }}
            type="checkbox"
            onChange={handleAllCheckZipCodes}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <table className="table table-striped">
          <tbody>
            {zipcodes
              .filter((f) => f.includes(filter) || filter === "")
              .map((each, i) => (
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      value={each}
                      checked={checkedZipcode.includes(each)}
                      onChange={handleCheck}
                    />
                  </td>
                  <td>{each}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button onClick={handleSubmit}>Select</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function Step3({
  handleStep,
  handleInput,
  handleMultiSelectInput,
  stores,
  storesLoading,
  shopperLoading,
  validation,
  info,
  validationData,
  setInfo,
  mode,
  shopperDropdown,
  formError,
  handleChange,
  handleUpcs,
  setFormError,
  blockInvalidChar,
  editMode,
  zipcodeData,
  setCheckedZipcode,
  checkedZipcode,
  editView,
  setValueDrop,
  valueDrop,
  handleStoresCheck,
  setStoresCheckBox,
  storesCheckBox,
  handleMultiStores,
  emailMode,
  ZipCodeUpcs,
  setZipCodeUpcs,
  groupsDropdown
}) {
  // const [ZipCodeUpcs, setZipCodeUpcs] = useState(
  //   emailMode ? info.zipCodesData : []
  // );
  const validationDatass = useSelector((state) => state.validationData);
  const [ZipCodeUpc, setZipCodeUpc] = useState("");

  const [modalShow, setModalShow] = useState(false);
  // const handleAllCheck = (e) => {
  //   if (info.storesList.length > 0) {
  //     setFormError((prev) => {
  //       return { ...prev, storesZip: "", chooseStore: "" };
  //     });
  //   }
  //   if (info.storesList.length <= 104) {
  //     setStoresCheckBox(false);
  //   }
  //   if (e.target.checked === true) {
  //     handleMultiStores(stores, "storesList");
  //     setStoresCheckBox(true);
  //   }
  //   if (e.target.checked === false) {
  //     handleMultiStores([], "storesList");
  //   }
  //   setStoresCheckBox(e.target.checked);
  // };

  const handleDeleteZipCodeUpcs = (deleteChip) => {
    const updatedUPCSData = info.zipCodesData.filter(
      (eachChip) => eachChip !== deleteChip
    );
    setZipCodeUpcs(updatedUPCSData);
    handleUpcs(updatedUPCSData, "zipCodesData");
  };

  const topshoperIndex = validationData?.findIndex(
    (each) => each.Field === "Choose Top Shoppers"
  );

  const handleStepNextLimitsAndstores = () => {
    var isValid = true;
    if (info.chooseTop === "") {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, chooseTop: "Please choose top shoppers" };
      });
    }
    const maxValue = parseInt(validationData[topshoperIndex]?.MaxValue);
    if (info.chooseTop > maxValue) {
      isValid = false;
      setFormError((prev) => {
        return {
          ...prev,
          chooseTop: `Max Value ${maxValue}`,
        };
      });
    }
    if (valueDrop === "Enter Zip Codes") {
      setInfo({
        ...info,
        storesList:[],
        existing_groupsList:[],
      })
      if (info.zipCodesData.length < 1) {
        isValid = false;
        setFormError((prev) => {
          return {
            ...prev,
            zipCode: "Please Enter Or Select Zip Codes",
          };
        });
      } else {
        setFormError((prev) => {
          return {
            ...prev,
            zipCode: "",
          };
        });
      }
    }
    if (valueDrop === "Choose stores") {
      setInfo({
        ...info,
        existing_groupsList:[],
        zipCodesData:[],
      })
      if (info.storesList.length === 0) {
        isValid = false;
        setFormError((prev) => {
          return {
            ...prev,
            chooseStore: "Please Select Stores",
          };
        });
      } else {
        setFormError((prev) => {
          return {
            ...prev,
            chooseStore: "",
          };
        });
      }
    }
    if (valueDrop === "Select Group") {
      setInfo({
        ...info,
        storesList:[],
        zipCodesData:[],
      })
      if (info?.existing_groupsList?.length === 0) {
        isValid = false;
        setFormError((prev) => {
          return {
            ...prev,
            groups: "Please Select Group",
          };
        });
      } else {
        setFormError((prev) => {
          return {
            ...prev,
            groups: "Please Select Group",
          };
        });
      }
    }

    if (isValid) {
      //setValueDrop(valueDrop);
      handleStep("next");
    }
  };

  const handleStoresNext = () => {
    const arr = info?.zipCodesData;
    let filterArray = [...new Set(arr)];
    setInfo({
      ...info,
      zipCodesData: filterArray
    });
  };

  const zipHandleChange = () => {
    if (info?.zipCodesData.length > 0) {
      setFormError((prev) => {
        return { ...prev, zipCode: "" };
      });
    }
  };
  const limitStores = validationData?.filter(
    (each) =>
      each.Section === "limits & stores" && each.Field != "Choose Top Shoppers"
  );

  const findLabel = validationData?.findIndex(
    (each) => each.Section === "limits & stores"
  );

  const maxValue = validationData[findLabel]?.Field;

  useEffect(() => {
    if (maxValue === "Enter Zip Codes" && valueDrop === undefined) {
      setValueDrop("Enter Zip Codes");
    }
    if (maxValue === "Choose stores" && valueDrop === undefined) {
      setValueDrop("Choose stores");
    }
    if (info?.ruleType?.label === "Existing Group") {
      setValueDrop("Select Group");
      info.selectValue = "Select Group";
    }
    if( maxValue === "Choose stores" && info?.selectValue === "Enter Zip Codes" && valueDrop === "Choose stores" ){
      info.selectValue = "Choose stores"; 
    }
  }, [maxValue, info,valueDrop]);

  const changeDropdown = (e) => {
    setValueDrop(e.target.value);
    setInfo((value) => ({
      ...info,
      selectValue: e.target.value
    }));
  };

  const clearArray = () => {
    // if (
    //   valueDrop === "Enter Zip Codes" &&
    //   info?.zipCodesData.length > 0 &&
    //   !mode
    // ) {
    //   info.storesList.length = 0;
    //   setStoresCheckBox(false);
    // }
    // if (valueDrop === "Choose stores" && info?.storesList.length > 0) {
    //   info.zipCodesData.length = 0;
    // }
    // if (valueDrop === "Select Group" && info?.existing_groupsList.length > 0) {
    //   info.storesList.length = 0;
    //   setStoresCheckBox(false);
    // }
  };

  useEffect(() => {
    // clearArray();
  }, [valueDrop, setValueDrop]);
 
  return (
    <div className="Wizard_Content_Card  p-0 ">
      {storesLoading || shopperLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px"
          }}>
          <Spinner animation="border" variant="primary" sizing={"lg"} />
        </div>
      ) : (
        <React.Fragment>
          <Form>
            <div className="p-17 ">
              {editMode && (
                <span style={{ color: "red", gap: "3px" }}>
                  This changes will take effect for all upcoming rule runs -
                  already created groups and coupon will not change.
                </span>
              )}

              <Row className="row g-3" style={{ marginTop: "10px" }}>
                {limitStores.length === 1 && (
                  <>
                    {validation.includes("Enter Zip Codes") && (
                      <Col lg={12} xs={12}>
                        <Form.Group>
                          <Form.Label>
                            Zip Code <span style={{fontStyle:"italic", color:"#3D8ECC"}}>(click enter after each zipcode entry )</span>
                          </Form.Label>
                          <PastedChips
                            style={{ overFlow: "scroll" }}
                            infoChipsData={info?.zipCodesData}
                            zipCode
                            mode={mode}
                            handleDelete={handleDeleteZipCodeUpcs}
                            upc={ZipCodeUpc}
                            upcs={ZipCodeUpcs}
                            setUpc={setZipCodeUpc}
                            setUpcs={setZipCodeUpcs}
                            handleUpcs={handleUpcs}
                            name={"zipCodesData"}
                            setFormError={setFormError}
                            PlaceHolderText={"enter zip or select from RSA"}
                            // zipStores={zipStores}
                            storessLiist={info.storesList}
                          />
                          <br />
                          <Button
                            className="BlueButton"
                            variant="primary"
                            onClick={() => setModalShow(true)}>
                            Get Zipcodes from RSA Portal
                          </Button>
                        </Form.Group>
                        {formError.zipCode && (
                          <p style={{ color: "red" }} className="error-message">{formError.zipCode}</p>
                        )}
                      </Col>
                    )}
                    {validation.includes("Choose stores") && (
                      <Col lg={6} xs={12}>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Choose Stores</Form.Label>
                          <Form.Check
                            type="checkbox"
                            label="Select All Stores..."
                            id="custom-switch"
                            name="storesCheckBox"
                            disabled={mode}
                            checked={storesCheckBox}
                            onChange={(e) => {
                              //handleAllCheck(e);
                              handleStoresCheck(e);
                            }}
                          />

                          <Select
                            isMulti
                            options={stores}
                            value={info.storesList}
                            isDisabled={mode}
                            className="react-select-container select"
                            onChange={(e) => {
                              handleMultiStores(e, "storesList");
                            }}
                          />
                        </Form.Group>

                        {formError.chooseStore && (
                          <p style={{ marginLeft: "15px", color: "red" }} className="error-message">
                            {formError.chooseStore}
                          </p>
                        )}
                      </Col>
                    )}

                    {validation.includes("Select Group") && (
                      <div className="col-lg-12 col-12">
                        <Form.Group className=" mt-16 position-relative">
                          <Form.Label>Choose Groups</Form.Label>
                          <Select
                            isMulti
                            isSearchable={true}
                            options={groupsDropdown}
                            value={info.existing_groupsList}
                            placeholder={"Please select groups..."}
                            isDisabled={mode}
                            className="react-select-container select"
                            onChange={(e) => {
                              // handleGroupsChange();
                              handleMultiSelectInput(e, "existing_groupsList");
                            }}
                          />
                        </Form.Group>

                        {formError.groups && (
                          <p style={{ color: "red" }} className="error-message">
                            {info.existing_groupsList.length === 0 &&
                              formError.groups}
                          </p>
                        )}
                      </div>
                    )}
                  </>
                )}

                {limitStores.length > 1 && (
                  <>
                    <Form.Select
                      aria-label="Default select example"
                      disabled={mode}
                      value={info.selectValue}
                      onChange={(e) => {
                        changeDropdown(e);
                      }}>
                      {limitStores?.map((each, i) => (
                        <option
                          // onChange={clearArray()}
                          value={each.Field}
                          id={i}>
                          {each.Field}
                        </option>
                      ))}
                    </Form.Select>
                    {valueDrop === "Choose stores" && (
                      <Col lg={6} xs={12}>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Choose Stores</Form.Label>
                          <Form.Check
                            type="checkbox"
                            label="Select All Stores..."
                            id="custom-switch"
                            name="storesCheckBox"
                            disabled={mode}
                            checked={storesCheckBox}
                            onChange={(e) => {
                              // handleAllCheck(e);
                              handleStoresCheck(e);
                            }}
                          />

                          <Select
                            isMulti
                            options={stores}
                            value={info.storesList}
                            isDisabled={mode}
                            className="react-select-container select"
                            onChange={(e) => {
                              handleMultiStores(e, "storesList");
                            }}
                          />
                        </Form.Group>
                        {formError.chooseStore && (
                          <p style={{ marginLeft: "15px", color: "red" }} className="error-message">
                            {formError.chooseStore}
                          </p>
                        )}
                      </Col>
                    )}

                    {valueDrop === "Select Group" && (
                      <div className="col-lg-12 col-12">
                        <Form.Group className=" mt-16 position-relative">
                          <Form.Label>Choose Groups</Form.Label>
                          <Select
                            isMulti
                            isSearchable={true}
                            options={groupsDropdown}
                            value={info.existing_groupsList}
                            placeholder={"Please select groups..."}
                            isDisabled={mode}
                            className="react-select-container select"
                            onChange={(e) => {
                              // handleGroupsChange();
                              handleMultiSelectInput(e, "existing_groupsList");
                            }}
                          />
                        </Form.Group>
                        {formError.groups && (
                          <p style={{ color: "red" }} className="error-message">
                            {info.existing_groupsList.length === 0 &&
                              formError.groups}
                          </p>
                        )}
                      </div>
                    )}
                    {valueDrop === "Enter Zip Codes" && (
                      <Col lg={12} xs={12}>
                        <Form.Group>
                          <Form.Label>
                            
                            Zip Code <span style={{fontStyle:"italic", color:"#3D8ECC"}}>(click enter after each zipcode entry )</span>
                          </Form.Label>
                          <PastedChips
                            style={{ overFlow: "scroll" }}
                            infoChipsData={info?.zipCodesData}
                            zipCode
                            mode={mode}
                            handleDelete={handleDeleteZipCodeUpcs}
                            upc={ZipCodeUpc}
                            upcs={ZipCodeUpcs}
                            setUpc={setZipCodeUpc}
                            setUpcs={setZipCodeUpcs}
                            handleUpcs={handleUpcs}
                            name={"zipCodesData"}
                            setFormError={setFormError}
                            PlaceHolderText={"enter zip or select from RSA"}
                            onChange={zipHandleChange}
                          />
                          <br />
                          <Button
                            className="BlueButton"
                            disabled={mode}
                            variant="primary"
                            onClick={() => setModalShow(true)}>
                            Get Zipcodes from RSA Portal
                          </Button>
                        </Form.Group>
                        {formError.zipCode && (
                          <p style={{ color: "red" }} className="error-message">{formError.zipCode}</p>
                        )}
                      </Col>
                    )}
                  </>
                )}

                {/* {validation.includes("Choose Top Shoppers") && (
                  <Col lg={6} xs={12}>
                    {validationDatass
                      .filter((x) => x.Field === "Choose Top Shoppers")
                      .map((each, i) => (
                        <>
                          <Form.Group>
                            {each?.FieldLabel === "" ? (
                              <Form.Label>Choose Top Shoppers</Form.Label>
                            ) : (
                              <Form.Label>{each?.FieldLabel}</Form.Label>
                            )}
                            <Form.Control
                              type="number"
                              name="chooseTop"
                              placeholder={each?.FieldLabel}
                              value={info.chooseTop}
                              disabled={mode}
                              min={0}
                              max={validationData[topshoperIndex]?.MaxValue}
                              className="react-select-container select"
                              onKeyDown={blockInvalidChar}
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                handleInput(e.target.value, "chooseTop");
                                handleChange("chooseTop");
                              }}
                            />
                          </Form.Group>
                        </>
                      ))}

                    {formError.chooseTop && (
                      <p style={{ color: "red" }}>{formError.chooseTop}</p>
                    )}
                  </Col>
                )} */}
              </Row>
            </div>
            <MyVerticallyCenteredModal
              show={modalShow}
              setUpcs={setZipCodeUpc}
              onHide={() => setModalShow(false)}
              zipcodeData={zipcodeData}
              ZipCodeUpcs={ZipCodeUpcs}
              setInfo={setInfo}
              info={info}
            />
          </Form>
          {/* {formError.chooseStore && (
            <p style={{ marginLeft: "15px", color: "red" }}>
              {formError.chooseStore}
            </p>
          )} */}
          {formError.storesZip && (
            <p style={{ marginLeft: "15px", color: "red" }} className="error-message">
              {formError.storesZip}
            </p>
          )}

          <div className="flex align-items-center justify-content-end text-end p-20 pt-0">
            {validation.includes("Choose stores") &&
              validation.includes("Enter Zip Codes") && (
                <p
                  style={{
                    marginRight: "70px",
                    marginTop: "100px",
                    fontSize: "10px"
                  }}>
                  Note: If you do not want to limit the criteria, then check
                  "Select All Stores". If you want to limit by stores or
                  zipcodes, then select only required stores or enter required
                  zipcodes.
                </p>
              )}
            <Button
              className="GrayButton mt-40"
              type="submit"
              onClick={() => {
                handleStep("previous");
              }}>
              <img
                src={PrevArrow}
                alt="Prev Arrow"
                className="img-fluid mr-5"
              />
              Previous
            </Button>
            <Button
              className="BlueButton ml-10 mt-40"
              type="submit"
              onClick={(e) => {
                clearArray();
                handleStepNextLimitsAndstores();
                handleStoresNext();
              }}>
              Next
              <img
                src={NextArrow}
                alt="Next Arrow"
                className="img-fluid ml-5"
              />
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

// how to empty on array when dropdown option changed in react
