export const loginReducer = (state, payload) => {
  return {
    ...state,
    loginData: payload && payload.data && payload.data,
    loginMsg: payload && payload.message && payload.message,
    loginLoading: payload && payload.loading && payload.loading,
  };
};

export const createUserReducer = (state, payload) => {
  return {
    ...state,
    createUserData: payload && payload.data && payload.data,
    createUserMessage: payload && payload.message && payload.message,
    createUserLoading: payload && payload.loading && payload.loading,
  };
};

export const deleteUserReducer = (state, payload) => {
  return {
    ...state,
    deleteUserData: payload && payload.data && payload.data,
    deleteUserMessage: payload && payload.message && payload.message,
    deleteUserLoading: payload && payload.loading && payload.loading,
  };
};

export const editUserReducer = (state, payload) => {
  return {
    ...state,
    editUserData: payload && payload.data && payload.data,
    editUserMessage: payload && payload.message && payload.message,
    editUserLoading: payload && payload.loading && payload.loading,
  };
};

export const getUserListReducer = (state, payload) => {
  return {
    ...state,
    getUserListData: payload && payload.data && payload.data,
    getUserListMessage: payload && payload.message && payload.message,
    getUserListLoading: payload && payload.loading && payload.loading,
  };
};

export const getSingleUserReducer = (state, payload) => {
  return {
    ...state,
    getSingleUserData: payload && payload.data && payload.data,
    getSingleUserMessage: payload && payload.message && payload.message,
    getSingleUserLoading: payload && payload.loading && payload.loading,
  };
};

export const getRolesReducer = (state, payload) => {
  return {
    ...state,
    getRolesData: payload && payload.data && payload.data,
    getRolesMessage: payload && payload.message && payload.message,
    getRolesLoading: payload && payload.loading && payload.loading,
  };
};

export const summaryDataReducer = (state, payload) => {
  return {
    ...state,
    summaryData: payload && payload.data && payload.data,
    summaryMessage: payload && payload.message && payload.message,
    summaryLoading: payload && payload.loading && payload.loading,
  };
};

export const departmentDataReducer = (state, payload) => {
  return {
    ...state,
    departmentData: payload && payload.data && payload.data,
    departmentMessage: payload && payload.message && payload.message,
    departmentLoading: payload && payload.loading && payload.loading,
  };
};

export const validationReducer = (state, payload) => {
  return {
    ...state,
    validationData: payload && payload.data && payload.data,
    validationMessage: payload && payload.message && payload.message,
    validationLoading: payload && payload.loading && payload.loading,
  };
};

export const ruleTypeReducers = (state, payload) => {
  return {
    ...state,
    ruleTypeData: payload && payload.data && payload.data,
    ruleTypeMessage: payload && payload.message && payload.message,
    ruleTypeLoading: payload && payload.loading && payload.loading,
  };
};

export const subTypeReducers = (state, payload) => {
  return {
    ...state,
    subTypeData: payload && payload.data && payload.data,
    subTypeMessage: payload && payload.message && payload.message,
    subTypeLoading: payload && payload.loading && payload.loading,
  };
};

export const storesReducers = (state, payload) => {
  return {
    ...state,
    storesData: payload && payload.data && payload.data,
    storesMessage: payload && payload.message && payload.message,
    storesLoading: payload && payload.loading && payload.loading,
  };
};

export const duringReducer = (state, payload) => {
  return {
    ...state,
    duringData: payload && payload.data && payload.data,
    duringMessage: payload && payload.message && payload.message,
    duringLoading: payload && payload.loading && payload.loading,
  };
};

export const shopperReducer = (state, payload) => {
  return {
    ...state,
    shopperData: payload && payload.data && payload.data,
    shopperMessage: payload && payload.message && payload.message,
    shopperLoading: payload && payload.loading && payload.loading,
  };
};

export const offerReducer = (state, payload) => {
  return {
    ...state,
    offerData: payload && payload.data && payload.data,
    offerMessage: payload && payload.message && payload.message,
    offerLoading: payload && payload.loading && payload.loading,
  };
};

export const offerDurationReducer = (state, payload) => {
  return {
    ...state,
    offerDurationData: payload && payload.data && payload.data,
    offerDurationMessage: payload && payload.message && payload.message,
    offerDurationLoading: payload && payload.loading && payload.loading,
  };
};

export const frequencyReducer = (state, payload) => {
  return {
    ...state,
    frequencyData: payload && payload.data && payload.data,
    frequencyMessage: payload && payload.message && payload.message,
    frequencyLoading: payload && payload.loading && payload.loading,
  };
};

export const graphDataReducer = (state, payload) => {
  return {
    ...state,
    graphData: payload && payload.data && payload.data,
    graphMessage: payload && payload.message && payload.message,
    graphLoading: payload && payload.loading && payload.loading,
  };
};

//Rule Analysis Reducers
export const ruleAnalysisReducers = (state, payload) => {
  return {
    ...state,
    ruleAnalysisData: payload && payload.data && payload.data,
    ruleAnalysisMessage: payload && payload.message && payload.message,
    ruleAnalysisLoading: payload && payload.loading && payload.loading,
  };
};

export const analysisPerformanceReducers = (state, payload) => {
  return {
    ...state,
    ruleAnalysisPerformanceData: payload && payload.data && payload.data,
    ruleAnalysisPerformanceMessage:
      payload && payload.message && payload.message,
    ruleAnalysisPerformanceLoading:
      payload && payload.loading && payload.loading,
  };
};

export const analysisGroupsByRuleReducers = (state, payload) => {
  return {
    ...state,
    ruleAnalysisGroupsByRuleData: payload && payload.data && payload.data,
    ruleAnalysisGroupsByRuleMessage:
      payload && payload.message && payload.message,
    ruleAnalysisGroupsByRuleLoading:
      payload && payload.loading && payload.loading,
  };
};

//Single Rule Data Reducers
export const getSingleRuleDataReducers = (state, payload) => {
  return {
    ...state,
    singleRuleData: payload && payload.data && payload.data,
    singleRuleMessage: payload && payload.message && payload.message,
    singleRuleLoading: payload && payload.loading && payload.loading,
  };
};

//ruleList

export const ruleListReducers = (state, payload) => {
  return {
    ...state,
    ruleListData: payload && payload.data && payload.data,
    ruleListMessage: payload && payload.message && payload.message,
    ruleListLoading: payload && payload.loading && payload.loading,
  };
};

export const submitRuleReducers = (state, payload) => {
  return {
    ...state,
    ruleCreateData: payload && payload.data && payload.data,
    ruleCreateMessage: payload && payload.message && payload.message,
    ruleCreateLoading: payload && payload.loading && payload.loading,
  };
};

export const expireRuleReducers = (state, payload) => {
  return {
    ...state,
    expireRuleData: payload && payload.data && payload.data,
    expireRuleMessage: payload && payload.message && payload.message,
    expireRuleLoading: payload && payload.loading && payload.loading,
  };
};

export const bankOfferReducers = (state, payload) => {
  return {
    ...state,
    bankOfferData: payload && payload.data && payload.data,
    bankOfferMessage: payload && payload.message && payload.message,
    bankOfferLoading: payload && payload.loading && payload.loading,
  };
};

export const zipcodeReducers = (state, payload) => {
  return {
    ...state,
    zipcodeData: payload && payload.data && payload.data,
    zipcodeMessage: payload && payload.message && payload.message,
    zipcodeLoading: payload && payload.loading && payload.loading,
  };
};

export const pendingApprovalReducers = (state, payload) => {
  return {
    ...state,
    pendingApprovalData: payload && payload.data && payload.data,
    pendingApprovalMessage: payload && payload.message && payload.message,
    pendingApprovalLoading: payload && payload.loading && payload.loading,
  };
};

export const forgotPasswordReducer = (state, payload) => {
  return {
    ...state,
    forgotPasswordData: payload && payload.data && payload.data,
    forgotPasswordMessage: payload && payload.message && payload.message,
    forgotPasswordLoading: payload && payload.loading && payload.loading,
  };
};

//change password

export const changePasswordReducer = (state, payload) => {
  return {
    ...state,
    changePasswordData: payload && payload.data && payload.data,
    changePasswordMessage: payload && payload.message && payload.message,
    changePasswordLoading: payload && payload.loading && payload.loading,
  };
};

export const groupsReducer = (state, payload) => {
  return {
    ...state,
    groupsData: payload && payload.data && payload.data,
    groupsMessage: payload && payload.message && payload.message,
    groupsLoading: payload && payload.loading && payload.loading,
  };
};

// Approve Panding Rule Reducer
export const approvePendingRuleReducer = (state, payload) => {
  return {
    ...state,
    approvePendingRuleData: payload && payload.data && payload.data,
    approvePendingRuleMessage: payload && payload.message && payload.message,
    approvePendingRuleLoading: payload && payload.loading && payload.loading,
  };
};

export const createdOffersReducer = (state, payload) => {
  return {
    ...state,
    createdOffersData: payload && payload.data && payload.data,
    createdOffersMessage: payload && payload.message && payload.message,
    createdOffersLoading: payload && payload.loading && payload.loading,
  };
};

export const expireCouponReducer = (state, payload) => {
  return {
    ...state,
    expireCouponData: payload && payload.data && payload.data,
    expireCouponMessage: payload && payload.message && payload.message,
    expireCouponLoading: payload && payload.loading && payload.loading,
  };
};

export const getCreatedOffersReducer = (state, payload) => {
  return {
    ...state,
    getCreatedOffersData: payload && payload.data && payload.data,
    getCreatedOffersMessage: payload && payload.message && payload.message,
    getCreatedOffersLoading: payload && payload.loading && payload.loading,
  };
};

export const approveRuleDataReducer = (state, payload) => {
  return {
    ...state,
    approveRuleData: payload && payload.data && payload.data,
    approveRuleDataMessage: payload && payload.message && payload.message,
    approveRuleDataLoading: payload && payload.loading && payload.loading,
  };
};

export const rejectRuleDataReducer = (state, payload) => {
  return {
    ...state,
    rejectRuleData: payload && payload.data && payload.data,
    rejectRuleDataMessage: payload && payload.message && payload.message,
    rejectRuleDataLoading: payload && payload.loading && payload.loading,
  };
};

//Analysis Download Report

export const ruleAnalysisDownloadReportReducers = (state, payload) => {
  return {
    ...state,
    ruleAnalysisDownloadData: payload && payload.data && payload.data,
    ruleAnalysisDownloadMessage: payload && payload.message && payload.message,
    ruleAnalysisDownloadLoading: payload && payload.loading && payload.loading,
  };
};

//Dashboard overview

export const getDashboardOverviewReducers = (state, payload) => {
  return {
    ...state,
    dashboardOverviewData: payload && payload.data && payload.data,
    dashboardOverviewMessage: payload && payload.message && payload.message,
    dashboardOverviewLoading: payload && payload.loading && payload.loading,
  };
};

//Dashboard Shopper Groups

export const getDashboardShopperGroupsReducers = (state, payload) => {
  return {
    ...state,
    dashboardShopperGroupsData: payload && payload.data && payload.data,
    dashboardShopperGroupsMessage:
      payload && payload.message && payload.message,
    dashboardShopperGroupsLoading:
      payload && payload.loading && payload.loading,
  };
};

//Dashboard Shopper Groups

export const getDashboardDepartmentsReducers = (state, payload) => {
  return {
    ...state,
    dashboardDepartmentsData: payload && payload.data && payload.data,
    dashboardDepartmentsMessage: payload && payload.message && payload.message,
    dashboardDepartmentsLoading: payload && payload.loading && payload.loading,
  };
};

//Dashboard Focus Items

export const getDashboardFocusItemsReducers = (state, payload) => {
  return {
    ...state,
    dashboardFocusItemsData: payload && payload.data && payload.data,
    dashboardFocusItemsMessage: payload && payload.message && payload.message,
    dashboardFocusItemsLoading: payload && payload.loading && payload.loading,
  };
};

//Dashboard Department Data Analysis

export const getDashboardDepartmentDataAnalysisReducers = (state, payload) => {
  return {
    ...state,
    dashboardDepartmentDataAnalysisData:
      payload && payload.data && payload.data,
    dashboardDepartmentDataAnalysisMessage:
      payload && payload.message && payload.message,
    dashboardDepartmentDataAnalysisLoading:
      payload && payload.loading && payload.loading,
  };
};

//Dashboard Department Data Analysis

export const getDashboardFocusItemAnalysisReducers = (state, payload) => {
  return {
    ...state,
    dashboardFocusItemAnalysisData: payload && payload.data && payload.data,
    dashboardFocusItemAnalysisMessage:
      payload && payload.message && payload.message,
    dashboardFocusItemAnalysisLoading:
      payload && payload.loading && payload.loading,
  };
};

//Dashboard Group Data Analysis

export const getDashboardGroupAnalysisReducers = (state, payload) => {
  return {
    ...state,
    dashboardGroupAnalysisData: payload && payload.data && payload.data,
    dashboardGroupAnalysisMessage:
      payload && payload.message && payload.message,
    dashboardGroupAnalysisLoading:
      payload && payload.loading && payload.loading,
  };
};

// create-focus-items

export const getCreateFocusItemReducers = (state, payload) => {
  return {
    ...state,
    createFocusItemData: payload && payload.data && payload.data,
    createFocusItemMessage: payload && payload.message && payload.message,
    createFocusItemLoading: payload && payload.loading && payload.loading,
  };
};

// create-promotion

export const createPromotionReducers = (state, payload) => {
  return {
    ...state,
    createPromotionData: payload && payload.data && payload.data,
    createPromotionMessage: payload && payload.message && payload.message,
    createPromotionLoading: payload && payload.loading && payload.loading,
  };
};

// create-promotion

export const getSegementDataReducers = (state, payload) => {
  return {
    ...state,
    getSegementData: payload && payload.data && payload.data,
    getSegementDataMessage: payload && payload.message && payload.message,
    getSegementDataLoading: payload && payload.loading && payload.loading,
  };
};

// get-promotion

export const getPromotionsReducers = (state, payload) => {
  return {
    ...state,
    getPromotionsData: payload && payload.data && payload.data,
    getPromotionsMessage: payload && payload.message && payload.message,
    getPromotionsLoading: payload && payload.loading && payload.loading,
  };
};

// expire-promotion

export const expirePromotionReducers = (state, payload) => {
  return {
    ...state,
    expirePromotionData: payload && payload.data && payload.data,
    expirePromotionMessage: payload && payload.message && payload.message,
    expirePromotionLoading: payload && payload.loading && payload.loading,
  };
};

// set-departments

export const setDepartmentsReducers = (state, payload) => {
  return {
    ...state,
    setDepartmentsData: payload && payload.data && payload.data,
    setDepartmentsMessage: payload && payload.message && payload.message,
    setDepartmentsLoading: payload && payload.loading && payload.loading,
  };
};

// get-tracked-departments

export const getTrackedDepartmentsReducers = (state, payload) => {
  return {
    ...state,
    getTrackedDepartmentsData: payload && payload.data && payload.data,
    getTrackedDepartmentsMessage: payload && payload.message && payload.message,
    getTrackedDepartmentsLoading: payload && payload.loading && payload.loading,
  };
};

// edit-focus-items

export const editFocusItemReducers = (state, payload) => {
  return {
    ...state,
    editFocusItemData: payload && payload.data && payload.data,
    editFocusItemMessage: payload && payload.message && payload.message,
    editFocusItemLoading: payload && payload.loading && payload.loading,
  };
};
