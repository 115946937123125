import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Users from "./Users";
import { getUserListCall } from "../../redux/API";
import { useDispatch, useSelector } from "react-redux";

function Userlist() {
  const { user_guid } = useParams();
  const dispatch = useDispatch();
  const { getUserListData, getUserListMessage, getUserListLoading } =
    useSelector((state) => state);

  const Loader = () => <div id="cover-spin"></div>;

  useEffect(() => {
    dispatch(getUserListCall());
  }, [dispatch]);

  return (
    <>
      <section className="ContentBlock pt-24 pb-24 Main_Block">
        <div className="container-fluid ">
          <div className="row justify-content-center user-list">
            <div className="col-xxl-2 col-lg-3 col-12">
              <div
                className="nav flex-column nav-pills nav-user-btn"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="profile-tab BlueButton"
                  id="v-pills-user-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-user"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-user"
                  aria-selected="false"
                >
                  Users
                </button>
              </div>
            </div>

            <div className="col-xxl-7 col-lg-9 col-12">
              <div className="tab-content mb-30" id="v-pills-tabContent">
                <div
                  className="tab-pane fade "
                  id="v-pills-user"
                  role="tabpanel"
                  aria-labelledby="v-pills-user-tab"
                >
                  <Users
                    props_user_guid={user_guid}
                    companyusers={getUserListData && getUserListData}
                    getUserListLoading={getUserListLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Userlist;
