/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { Link } from "react-router-dom";

function PerformanceServay({Title , ProductImage  , ButtonText , ImageIcon ,ImageIconDown}) {


    return (
        <React.Fragment>
                

                            <div className="Performance-wrapper">
                                <div className="Performance-img-icon">
                                        <div className="Performance-image">
                                        <img alt="icon" src={ProductImage} className="img-fluid" />
                                        </div>
                                        <div className="Performance-icon">
                                        <img width="50" height="50" alt="icon" src={ImageIcon} className="img-fluid up-icon" />
                                        </div>
                                </div>
                                        <div className="Performance-detail">
                                            <div className="main-title">{Title}</div>
                                            <div className="Performance-price-link">
                                                <Link target="_top" to="/Performancebar">{ButtonText}</Link>
                                            </div>
                                        </div>
                                    </div>
        </React.Fragment>
    );
}

export default PerformanceServay;
