import React from "react";
import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NextArrow from "../../assets/images/next-arrow.svg";
import PrevArrow from "../../assets/images/prev-arrow.svg";
import { submitRule } from "../../redux/API";
import { toast } from "react-toastify";
import usePrevious from "../../public/PrevValue";
import { useNavigate } from "react-router-dom";
import { DateFormat } from "../../public/PublicFunctionality";

export default function Step6({
  handleStep,
  modeofOps,
  validation,
  info,
  id,
  mode,
  editMode,
  couponArray,
  offerBankArray,
  storesData,
  setInfo,
  valueDrop,
  setStoresCheckBox
}) {
  const dispatch = useDispatch();

  const ruleCreateMessage = useSelector((state) => state.ruleCreateMessage);
  const ruleCreateData = useSelector((state) => state.ruleCreateData);
  const ruleCreateLoading = useSelector((state) => state.ruleCreateLoading);
  const prevCreateMsg = usePrevious(ruleCreateMessage);
  const [displayToast, setDisplayToast] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (ruleCreateMessage) {
      setDisplayToast(true);
    }
  }, [ruleCreateMessage]);

  useEffect(() => {
    if (ruleCreateMessage !== prevCreateMsg && ruleCreateMessage) {
      if (ruleCreateMessage.indexOf("Successful") > 0) {
        if (displayToast) {
          toast.success(ruleCreateMessage);
          setDisplayToast(false);
        }
        setTimeout(() => {
          window.location.href = "/rules";
        });
      } else if (
        ruleCreateMessage ===
        "Something went wrong on our side, please try again later.."
      ) {
        toast.error(ruleCreateMessage);
      } else if (ruleCreateMessage === "Internal server error.") {
        navigate("/login");
      }
    }
  }, [ruleCreateData, ruleCreateMessage, prevCreateMsg, displayToast]);
  const onPrint = () => {
    window.print()
  }
  const handleForm = () => {
    if (
      valueDrop === "Enter Zip Codes" &&
      info?.zipCodesData.length > 0 &&
      !mode
    ) {
      info.storesList.length = 0;
      info.existing_groupsList.length = 0;
      setStoresCheckBox(false);
    }
    if (valueDrop === "Choose stores" && info?.storesList.length > 0) {
      info.zipCodesData.length = 0;
      info.existing_groupsList.length = 0;
    }
    if (valueDrop === "Select Group" && info?.existing_groupsList.length > 0) {
      info.storesList.length = 0;
      setStoresCheckBox(false);
      info.zipCodesData.length = 0;
    }
    if (!validation.includes("offer_title")) {
      info.CouponArray = [];
    }
    if (modeofOps === "edit") {
      dispatch(submitRule(info, id, "edit", validation));
    } else if (modeofOps === "copy") {
      dispatch(submitRule(info, id, "copy", validation));
    } else dispatch(submitRule(info, "", "add", validation));
  };

  const validationDatass = useSelector((state) => state.validationData);


 
  return (
    <div className="Preview_Block ">
      <div className="container-fluid">
        <ul className="Preview_Info">
          {editMode && (
            <span
              style={{
                color: "red",
                marginLeft: "30px",
                marginTop: "11px",
                marginBottom: "11px",
                display: "block",
              }}
            >
              This changes will take effect for all upcoming rule runs - already
              created groups and coupon will not change.
            </span>
          )}
          <li>
            <div className="Preview_Card">
              <h5 style={{ fontWeight: "800" }}>Rule Info</h5>
              <ul className="mt-20">
                <li>
                  <h6
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color: "#7b8098",
                    }}
                  >
                    {info?.rule}
                  </h6>
                </li>

                <li>
                  <h6
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color: "#7b8098",
                    }}
                  >
                    {/* {info?.startDate} to {info?.endDate} */}
                    {DateFormat(info?.startDate)} to {DateFormat(info?.endDate)}
                  </h6>
                </li>
                <li>
                  <div className="d-flex flex-row">
                    <h6
                      style={{
                        fontWeight: "700",
                        color: "#344767",
                        fontSize: "14px",
                      }}
                    >
                      Type:
                    </h6>
                    <span
                      style={{
                        color: "#7b8098",
                        fontSize: "12px",
                        fontWeight: "600",
                        marginLeft: "3px",
                      }}
                    >
                      {info?.ruleType?.label}
                    </span>
                  </div>
                </li>

                <li>
                  <div className="d-flex flex-row">
                    <h6
                      style={{
                        fontWeight: "700",
                        color: "#344767",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Sub-Type:
                    </h6>
                    {info?.subType?.value !== "" ? (
                      <>
                        <span
                          style={{
                            color: "#7b8098",
                            fontSize: "12px",
                            fontWeight: "600",
                            marginLeft: "3px",
                          }}
                        >
                          {info?.subType?.label}
                        </span>
                      </>
                    ) : (
                      <span
                        style={{
                          color: "#7b8098",
                          fontSize: "12px",
                          fontWeight: "600",
                          marginLeft: "3px",
                        }}
                      >
                        NA
                      </span>
                    )}
                  </div>
                </li>
                <li>
                  <div className="d-flex flex-row">
                    <h6
                      style={{
                        fontWeight: "700",
                        color: "#344767",
                        fontSize: "14px",
                      }}
                    >
                      Frequency:
                    </h6>
                    <span
                      style={{
                        color: "#7b8098",
                        fontSize: "12px",
                        fontWeight: "600",
                        marginLeft: "3px",
                      }}
                    >
                      {info?.frequency?.label} Days
                    </span>
                  </div>
                </li>
                {info?.departments?.length === 0 ? (
                  ""
                ) : (
                  <li>
                    <div className="d-flex flex-column">
                      <h6
                        style={{
                          color: "#344767",
                          fontSize: "14px",
                          marginLeft: "5",
                          marginTop: "22px",
                          fontWeight: "700",
                        }}
                      >
                        Departments:
                      </h6>
                      <span>
                        {validation.includes("Choose Department") &&
                          info?.departments?.slice(0, 5).map((data) => (
                            <div
                              key={data.label}
                              style={{
                                display: "inline",
                                // width: "150px",
                                marginRight: "10px",
                                fontSize: "12px",
                                color: "#7b8098",
                                fontWeight: "600",
                              }}
                            >
                              <br />
                              {data?.label},
                            </div>
                          ))}
                        {info?.departments?.length > 3 && <>{"..."}</>}
                      </span>
                    </div>
                  </li>
                )}

                {info?.upcsData?.length === 0 ? (
                  ""
                ) : (
                  <li>
                    <div className="d-flex flex-column">
                      <h6
                        style={{
                          marginLeft: "5",
                          fontWeight: "700",
                          color: "#344767",
                          fontSize: "14px",
                        }}
                      >
                        UPC:
                      </h6>
                      <span>
                        {info?.upcsData?.slice(0, 5).map((data, i) => (
                          <div
                            key={i}
                            style={{
                              marginRight: "150px",
                              fontSize: "12px",
                              color: "#7b8098",
                              fontWeight: "600",
                            }}
                          >
                            {data},<br />
                          </div>
                        ))}
                        {info?.upcsData?.length > 3 && <>{"..."}</>}
                      </span>
                    </div>
                  </li>
                )}

                <div className="d-flex flex-row mt-120">
                  {/* <h5 style={{ fontWeight: "700", color: "black" }}>Rule</h5> */}
                  <span className="d-flex flex-row">
                    {info?.offerRequired === true ? (
                      <li className="d-flex flex-row ml-15">
                        <span
                          style={{
                            fontWeight: "700",
                            color: "#344767",
                            fontSize: "14px",
                            marginBottom: "3px",
                            marginLeft: "-15px",
                          }}
                        >
                          Rule Approval Required :
                        </span>
                        <span
                          style={{
                            color: "#7b8098",
                            fontWeight: "600",
                            fontSize: "12px",
                            marginBottom: "3px",
                            marginLeft: "5px",
                          }}
                        >
                          Yes
                        </span>
                      </li>
                    ) : (
                      <li>
                        <span
                          style={{
                            fontWeight: "700",
                            color: "#344767",
                            fontSize: "14px",
                          }}
                        >
                          Rule Approval Required :
                        </span>
                        <span
                          style={{
                            color: "#7b8098",
                            fontWeight: "600",
                            fontSize: "12px",
                            marginBottom: "3px",
                            marginLeft: "5px",
                          }}
                        >
                          No
                        </span>
                      </li>
                    )}
                  </span>
                </div>
              </ul>
            </div>
          </li>
          <li>
            {validation.includes("During") ||
            validation.includes("Amount spend") ||
            validation.includes("Number of times shopped") ||
            validation.includes("Visits containing UPCs") ||
            validation.includes("Choose Top Shoppers") ? (
              <div className="Preview_Card">
                <h5 style={{ fontWeight: "800" }}>Criteria</h5>
                <ul className="mt-20">
                  {validation.includes("During") && (
                    <li>
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "#344767",
                          fontSize: "14px",
                        }}
                      >
                        During last {info?.during?.label} days:{" "}
                      </h5>
                    </li>
                  )}
                  {validation.includes("Number of times shopped") && (
                    <li>
                      <h6
                        style={{
                          color: "#7B809A",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        No of times Purchased <br />
                        min: {info?.numberOfTime} max:{" "}
                        {info?.max_times_purchased}
                      </h6>
                    </li>
                  )}

                  {validation.includes("Amount spend") && (
                    <li className="d-flex flex-column">
                      {validation.includes("Choose Department") && (
                        <h6
                          style={{
                            color: "#7B809A",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Transactions having Dept(s)
                        </h6>
                      )}
                      {validation.includes("Enter UPCs") && (
                        <h6
                          style={{
                            color: "#7B809A",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Transactions having UPC(s)
                        </h6>
                      )}
                      <h6
                        style={{
                          color: "#7B809A",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Amount spend <br />
                        min: {info?.amountSpent} max: {info?.max_amount_spend}
                      </h6>
                    </li>
                  )}
                  {validation.includes("Visits containing UPCs") && (
                    <li>
                      <h6
                        style={{
                          color: "#7B809A",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Transactions having items from selected Depts/UPCs{" "}
                        <br />
                        min: {info?.visits_containing_upcs} max:{" "}
                        {info?.max_visits_containing_upcs}
                      </h6>
                    </li>
                  )}

                  {validation.includes("Choose Top Shoppers") && (
                    <li>
                      <>
                        {validationDatass
                          .filter((x) => x.Field === "Choose Top Shoppers")
                          .map((each, i) => (
                            <>
                              <span
                                style={{
                                  color: "#344767",
                                  fontWeight: "700",
                                  whiteSpace:"nowrap"
                                }}
                              >
                                
                                {each.FieldLabel}:{" "}
                              </span>
                            </>
                          ))}

                        <span
                          style={{
                            color: "#7b8098",
                            fontWeight: "600",
                            marginLeft: "3px",
                          }}
                        >
                          {info?.chooseTop}
                        </span>
                      </>
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <div className="Preview_Card">
                <h5 style={{ fontWeight: "800" }}>Criteria</h5>
                <span
                  style={{
                    color: "#7B809A",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  NA
                </span>
              </div>
            )}

            <li className="mt-120">
              <h6 style={{ fontWeight: "800", color: "#344767" }}>
                Limits{" "}
                <span style={{ fontStyle: "italic", color: "#3D8ECC" }}>
                  (Stores or Zip Codes)
                </span>
              </h6>
              <ul className="mt-20">
                <li>
                  {/* <span style={{ marginLeft: "5" }}>Stores:</span> */}
                  <span>
                    {valueDrop === "Select Group" && (
                      <span
                        style={{
                          color: "#7B809A",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        NA
                      </span>
                    )}
                    {validation.includes("Choose stores") &&
                      valueDrop === "Choose stores" &&
                      (info?.storesList?.length === storesData?.length ? (
                        <div
                          style={{
                            display: "inline",
                            width: "150px",
                            marginRight: "120px",
                            fontSize: "12px",
                            color: "#7b8098",
                            fontWeight: "600",
                          }}
                        >
                          {"All Stores"}
                        </div>
                      ) : (
                        info?.storesList?.slice(0, 5).map((data) => (
                          <div
                            key={data.label}
                            style={{
                              display: "inline",
                              width: "150px",
                              fontSize: "12px",
                              color: "#7b8098",
                              fontWeight: "600",
                            }}
                          >
                            {data?.label},<br />
                          </div>
                        ))
                      ))}
                  </span>
                </li>

                {/* <li>
                  {info?.chooseTop == 0 ? (
                    ""
                  ) : (
                    <>
                      <span
                        style={{
                          color: "#344767",
                          marginTop: "15px",
                          fontWeight: "700",
                        }}
                      >
                        Choosed Top Shoppers:{" "}
                      </span>
                      <span style={{ color: "#7b8098", fontWeight: "600" }}>
                        {info?.chooseTop}
                      </span>
                    </>
                  )}
                </li> */}
                <li>
                  {info?.zipCodesData?.length === 0 ? (
                    ""
                  ) : (
                    <React.Fragment>
                      {/* <span>Zip Codes:</span> */}
                      <span>
                        {validation.includes("Enter Zip Codes") &&
                          valueDrop === "Enter Zip Codes" &&
                          info?.zipCodesData?.slice(0, 5).map((data, i) => (
                            <div
                              key={i}
                              style={{
                                display: "inline",
                                width: "120px",
                                marginRight: "90px",
                                color: "#7b8098",
                                fontWeight: "600",
                                fontSize: "12px",
                              }}
                            >
                              {data},<br />
                            </div>
                          ))}

                        {info?.zipCodesData?.length > 3 && <>{"......"}</>}
                      </span>
                    </React.Fragment>
                  )}
                </li>
              </ul>
            </li>
          </li>

          {/* <li>
            <div className="Preview_Card">
              <h5 style={{ fontWeight: "800" }}>
                Limits (Stores or Zip Codes)
              </h5>
              <ul className="mt-20">
                {info?.storesList?.length > 0 && (
                  <li>
                    <span style={{ marginLeft: "5" }}>Stores:</span>
                    <span>
                      {validation.includes("Choose stores") &&
                      info?.storesList?.length === storesData?.length ? (
                        <div
                          style={{
                            display: "inline",
                            width: "150px",
                            marginRight: "120px",
                            fontSize: "12px",
                          }}
                        >
                          {"All Stores"}
                        </div>
                      ) : (
                        info?.storesList?.slice(0, 5).map((data) => (
                          <div
                            key={data.label}
                            style={{
                              display: "inline",
                              width: "150px",
                              marginLeft: "-5px",
                              fontSize: "12px",
                            }}
                          >
                            {data?.label},<br />
                          </div>
                        ))
                      )}
                      {info?.storesList?.length === storesData?.length
                        ? ""
                        : "..."}
                    </span>
                  </li>
                )}
                <li>
                  {info?.chooseTop == 0 ? (
                    ""
                  ) : (
                    <>
                      <span>Choosed Top Shoppers: </span>
                      <span>{info?.chooseTop}</span>
                    </>
                  )}
                </li>
                <li>
                  {info?.zipCodesData?.length === 0 ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <span>Zip Codes:</span>
                      <span>
                        {validation.includes("Enter Zip Codes") &&
                          info?.zipCodesData?.slice(0, 5).map((data, i) => (
                            <div
                              key={i}
                              style={{
                                display: "inline",
                                width: "120px",
                                marginRight: "90px",
                              }}
                            >
                              {data},<br />
                            </div>
                          ))}

                        {info?.zipCodesData?.length > 3 && <>{"......"}</>}
                      </span>
                    </React.Fragment>
                  )}
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li>
            <div className="Preview_Card">
              <ul className="mt-20">
                {info?.departments?.length === 0 ? (
                  ""
                ) : (
                  <li>
                    <span style={{ marginLeft: "5", marginTop: "20px" }}>
                      Departments:
                    </span>
                    <span>
                      {validation.includes("Choose Department") &&
                        info?.departments?.slice(0, 5).map((data) => (
                          <div
                            key={data.label}
                            style={{
                              display: "inline",
                              width: "150px",
                              marginLeft: "-5px",
                              fontSize: "12px",
                              marginBottom: "10px",
                            }}
                          >
                            <br />
                            {data?.label},
                          </div>
                        ))}
                      {info?.departments?.length > 3 && <>{"..."}</>}
                    </span>
                  </li>
                )}

                {info?.upcsData?.length === 0 ? (
                  ""
                ) : (
                  <li>
                    <span style={{ marginLeft: "5" }}>UPC:</span>
                    <span>
                      {info?.upcsData?.slice(0, 5).map((data, i) => (
                        <div
                          key={i}
                          style={{
                            display: "inline",
                            width: "150px",
                            fontSize: "12px",
                            marginBottom: "10px",
                            marginRight: "150px",
                          }}
                        >
                          {data},<br />
                        </div>
                      ))}
                      {info?.upcsData?.length > 3 && <>{"..."}</>}
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </li> */}
          {/* <li>
            <div className="Preview_Card">
              <h5 style={{ fontWeight: "800" }}>Rule Schedule</h5>
              <ul className="mt-20">
                {info?.offerRequired === true ? (
                  <li>
                    <span
                      style={{
                        fontWeight: "700",
                        color: "#344767",
                        fontSize: "14px",
                      }}
                    >
                      Offer Approval Required :{" "}
                      <span style={{ color: "#7B809A", fontSize: "12px" }}>
                        Yes
                      </span>
                    </span>
                  </li>
                ) : (
                  <li>
                    <span
                      style={{
                        fontWeight: "700",
                        color: "#344767",
                        fontSize: "14px",
                      }}
                    >
                      Offer Approval Required :
                      <span style={{ color: "#7B809A", fontSize: "12px" }}>
                        No
                      </span>
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </li> */}

          <li>
            {info?.CouponArray?.length < 1 ||
            !validation.includes("offer_title") ? (
              <>
                <h5
                  style={{
                    fontWeight: "800",
                    color: "#344767",
                    fontSize: "16px",
                    marginTop: "-5px",
                  }}
                >
                  Reward Offers
                </h5>
                <span
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    marginTop: "20px",
                    color: "#7b8098",
                    fontWeight: "600",
                    fontSize: "12px",
                  }}
                >
                  NA
                </span>
              </>
            ) : (
              <div className="Preview_Card">
                <h5 style={{ fontWeight: "700" }}>Reward Offers</h5>
                <ul className="mt-20">
                  {info?.CouponArray?.slice(0, 5).map((each, i) => (
                    <div className="d-flex flex-column align-items-start">
                      <span
                        style={{
                          // marginLeft: "108px",
                          fontSize: "12px",
                          color: "#7b8098",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      >
                        Every {each?.frequency?.label} Days
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#7b8098",
                          fontWeight: "600",
                        }}
                      >
                        {each.name}
                      </span>
                      <span
                        style={{
                          // marginLeft: "108px",
                          fontSize: "12px",
                          color: "#7b8098",
                          fontWeight: "600",
                        }}
                      >
                        Valid for {each?.validityindays} days
                      </span>
                    </div>
                  ))}
                  {info?.CouponArray?.length > 5 ? ". . . . . ." : ""}
                </ul>
              </div>
            )}
          </li>
        </ul>
      </div>
      <div className="flex align-items-center justify-content-end text-end">
        <Button
          className="GrayButton mt-14 mr-15"
          onClick={onPrint}
          style={{background:"#3D8ECC", color:"white", float:"left", marginLeft:"20px", width:"122.5px"}}
        >
          Print
        </Button>
        <Button
          className="GrayButton mt-14 mr-15"
          type="submit"
          onClick={() => {
            handleStep("previous");
          }}
        >
          <img src={PrevArrow} alt="Prev Arrow" className="img-fluid mr-5" />
          Previous
        </Button>
        <Button
          className="BlueButton mr-20 mt-14"
          onClick={handleForm}
          disabled={mode}
        >
          {ruleCreateLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <React.Fragment>
              Submit
              <img
                src={NextArrow}
                alt="Next Arrow"
                className="img-fluid ml-5"
              />
            </React.Fragment>
          )}
        </Button>
      </div>
    </div>
  );
}
