import React, { useEffect, useState } from "react";
import { Button, Form, Spinner, FormText } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Logo from "../../assets/images/logo.png";
import Avtar from "../../assets/images/logo-no-background.png";
import SliderImage1 from "../../assets/images/slider-image-1.png";
import ButtonArrow from "../../assets/images/button-arrow.svg";
import Eye from "../../assets/images/eye.png";
import Hidden from "../../assets/images/hidden.png";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getLogin } from "../../redux/API";
import usePrevious from "../../public/PrevValue";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Regex, setSession } from "../../public/auth";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_REACT_RECAPTCHA_SITE_KEY;
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.loginData);
  const loginMsg = useSelector((state) => state.loginMsg);
  const prevLoginMsg = usePrevious(loginMsg);
  const loginLoading = useSelector((state) => state.loginLoading);

  const ruleTypeMessage = useSelector((state) => state.ruleTypeMessage);

  const subTypeMessage = useSelector((state) => state.subTypeMessage);

  const departmentMessage = useSelector((state) => state.departmentMessage);

  const duringMessage = useSelector((state) => state.duringMessage);
  const prevDuringMessage = usePrevious(duringMessage);

  const storesMessage = useSelector((state) => state.storesMessage);

  const frequencyMessage = useSelector((state) => state.frequencyMessage);

  const shopperMessage = useSelector((state) => state.shopperMessage);
  const prevShopperMessage = usePrevious(shopperMessage);

  const validationMessage = useSelector((state) => state.validationMessage);

  const offerMessage = useSelector((state) => state.offerMessage);

  const bankOfferMessage = useSelector((state) => state.bankOfferMessage);

  const singleRuleMessage = useSelector((state) => state.singleRuleMessage);

  const ruleCreateMessage = useSelector((state) => state.ruleCreateMessage);

  const ruleListMessage = useSelector((state) => state.ruleListMessage);

  const summaryMessage = useSelector((state) => state.summaryMessage);

  const [captcha, setCaptcha] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const onChange = (value) => {
    setCaptcha(value);
    setCaptchaError("");
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000
  };

  const [value, setValue] = useState({
    userName: "",
    password: ""
  });

  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [type, setType] = useState("password");

  const handleInput = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    if (e.target.name === "userName") {
      if (e.target.value === "") setUserNameError("Please enter email.");
      else {
        if (Regex(e.target.value)) setUserNameError("");
        else setUserNameError("Please enter valid email.");
      }
    } else {
      if (e.target.value === "") setPasswordError("Please enter password.");
      else setPasswordError("");
    }
  };

  const handleForm = () => {
    if (captcha) {
      if (userNameError === "" && passwordError === "") {
        dispatch(getLogin(value));
      }
    } else {
      setCaptchaError("The captcha field is required");
    }
  };

  useEffect(() => {
    if (loginMsg !== prevLoginMsg && loginMsg) {
      if (loginMsg === "Login Successfully!!") {
        //Access token is stored in local storage here in secure way.
        setSession(loginData);
        window.location.href = "/home";
      } else toast.error(loginMsg);
    }
  }, [loginData, loginMsg, prevLoginMsg]);

  const token = window.localStorage.getItem("access_token");

  console.log(token);

  const handlePasswordIcon = () => {
    if (type === "password") setType("text");
    else setType("password");
  };
  return (
    <div className="CommonFormBg SignIn">
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pt-10 pb-10 login-image">
            <Link to="/">
              <img src={Logo} className="img-fluid" alt="Logo" />
            </Link>
            <img
              src={Avtar}
              className="img-fluid justify-content-end"
              style={{
                width: "180px",
                height: "80px",
                marginRight: "280px",
                marginTop: "30px"
              }}
              alt="Logo"
            />
          </div>
        </div>

        <div className="Wrapper mt-85">
          <div className="row g-4 justify-content-center">
            <div className="col-xl-4 col-lg-6 col-12">
              <div className="Form_Card">
                <h2 className="Form_Ttl">Welcome to Autopilot</h2>
                {/* <div className="Register_Link">
                  <span>Are You New? &nbsp;</span>
                  <Link to="/">Register here</Link>
                </div> */}
                <form className="mt-34">
                  <Form.Group>
                    <Form.Label>
                      Email <font className="Text-Red">*</font>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      name="userName"
                      onChange={(e) => handleInput(e)}
                    />
                  </Form.Group>
                  <FormText className="Text-Red">{userNameError}</FormText>
                  <Form.Group className="mt-15 position-relative">
                    <Form.Label>
                      Password <font className="Text-Red">*</font>
                    </Form.Label>
                    <Form.Control
                      type={type}
                      placeholder="Password"
                      name="password"
                      onChange={(e) => handleInput(e)}
                    />
                    <img
                      onClickCapture={handlePasswordIcon}
                      src={type === "password" ? Eye : Hidden}
                      className="img-fluid InputIcon cursor-pointer password-img"
                      alt="Password"
                    />
                  </Form.Group>
                  <FormText className="Text-Red">{passwordError}</FormText>
                  <div className="text-end">
                    <Link to="/ForgotPassword" className="ForgotLink mt-20">
                      Forgot password?
                    </Link>
                  </div>
                  <div className="mt-15">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={onChange}
                    />
                    <span style={{ color: "red" }}>{captchaError}</span>
                  </div>
                  <Button
                    type="submit"
                    onClick={handleForm}
                    className="FormButton d-block mt-37"
                    disabled={
                      loginLoading || userNameError || passwordError || !captcha
                    }>
                    {loginLoading ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <React.Fragment>
                        Continue
                        <img
                          src={ButtonArrow}
                          className="img-fluid ml-11 "
                          alt="Arrow"
                        />
                      </React.Fragment>
                    )}
                  </Button>
                </form>
              </div>
              <ul className="nav CustomLink mt-50">
                <li>
                  <a href="https://rsaamerica.com/terms/">Terms</a>
                </li>
                <li>
                  <a href="https://rsaamerica.com/privacy-policy/">Privacy</a>
                </li>
                {/* <li>
                  <a href="">Home</a>
                </li> */}
              </ul>
            </div>
            <div className="col-xl-4 col-lg-6 col-12 offset-xl-2">
              <Slider {...settings}>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Unlock Rapid Insights, Boost Engagement: Analyze Shopper Group Dynamics by Total Spend, Department, and Item Performance —  Intelligent Automation for Targeted Actions.
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Drive Customer Loyalty Automatically with AI and ML: Personalized Offers, Scheduled Rewards, Seamless Analytics.
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Effortless Loyalty, Endless Rewards: Tailor-Made Rules for everyday Grocer Needs.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// how we get  403 error in react api
