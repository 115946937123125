/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from "react";
import Nextarrow from "../../assets/images/next-arrow.svg";
import Prevarrow from "../../assets/images/prev-arrow.svg";
import { createPromotion } from "../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Spinner } from "react-bootstrap";
function PreviewPromotion({
    handleStep,
    value,
    dropdown
}) {
    const dispatch = useDispatch();
    const createPromotionLoading = useSelector((state) => state.createPromotionLoading);
    const submitPromotion = (e) => {
        e.preventDefault();
        dispatch(createPromotion(value));
    }
    const previousClick = (e) => {
        e.preventDefault();
        handleStep("previous");
    }
    return (
        <React.Fragment>
            
            <div className="Content_Wrapper_Wizard  pt-50 store-promo new-promotion">
                <div className="Wizard mt-30">
                    <div className="Wizard_Content mb-100">
                        <div className="container-fluid">
                            <div className="justify-content-center row">
                                <div className="col-xxl-10 col-xl-10 col-12">
                                    <div className="Wizard_Content_Card Rule_Type_Info preview-promo">
                                        <div className="g-3 row">
                                            <div className="col-md-4 col-sm-4 col-12">
                                                <div className="position-relative">
                                                    <h4 className="info-title">Offer Info</h4>
                                                    <div className="info-main-title">
                                                        <span>{value.offerTitle}</span>
                                                    </div>
                                                    <div className="info-desc">
                                                        <p>{value.offerDetails}</p>
                                                    </div>
                                                    <div className="valid-date">
                                                        <div className="date-title valids-title">
                                                            valid from
                                                            <div className="dates">
                                                                <span>{value.OfferStartedOn}</span> to <span>{value.OfferExpiresOn}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="qty-desc valids-title">
                                                        min Qty <span>{value.MinPurchaseQty}</span>
                                                    </div>
                                                    <div className="dicounts valids-title">
                                                        discount: <span>{value.discountAmount}</span>
                                                    </div>
                                                    <div className="limits valids-title">
                                                        coupon limit: <span>{value.couponLimit}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-12 group-items">
                                                <div className="position-relative">
                                                    <div className="group-titles mb-30">
                                                        <h6>Shopper Group</h6>
                                                        {
                                                            value.shopperGroups.label
                                                        }
                                                    </div>
                                                    <div className="items-descs mb-30">
                                                        <h6>focus items</h6>
                                                        {
                                                            value.selectedFocusItems?.map((focusItem) => (<span key={focusItem.value}>{focusItem.label}</span>))
                                                        }
                                                        {
                                                            value.selectedFocusItems?.length === 0 &&
                                                            <span>N/A</span>
                                                        }
                                                    </div>
                                                    <div className="items-depart">
                                                        <h6>Departments</h6>
                                                        {
                                                            value.selectedDepartments?.map((department) => (<span key={department.value}>{department.label}</span>))
                                                        }
                                                        {
                                                            value.selectedDepartments?.length === 0 &&
                                                            <span>N/A</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-12 limits-sec">
                                                <div className="position-relative">
                                                    <h5>Limits <span>(Stores)</span></h5>
                                                    {
                                                        value.storeId == 0 &&
                                                        <span>All Stores</span>
                                                    }
                                                    {
                                                        value.storeId > 0 &&
                                                        dropdown.stores?.filter(a => a.id == value.storeId)
                                                            .map((store) => (<><span key={store.id}>{store.Name}</span> <br></br></>))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex align-items-center justify-content-end text-end p-20 pt-0">
                                            <button type="submit" className="GrayButton mt-14  btn btn-primary prev-btn"
                                                onClick={(e) => {
                                                    previousClick(e);
                                                }}>
                                                <img src={Prevarrow} className="img-fluid mr-5" alt="Rules" />Previous
                                            </button>
                                            <button type="submit" className="BlueButton ml-10 mt-14 btn btn-primary"
                                                onClick={(e) => {
                                                    submitPromotion(e);
                                                }}
                                                disabled={createPromotionLoading}>
                                                {createPromotionLoading ?
                                                    <Spinner animation="border" variant="primary" />
                                                    :
                                                    <>
                                                        <span>Submit</span>
                                                        <img src={Nextarrow} className="img-fluid ml-5" alt="Rules" />
                                                    </>
                                                }

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PreviewPromotion;
