/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGraphData } from "../../redux/API";
import Loyality from "../../assets/images/loyality.svg";
import ShopperTilers from "../Chart/ShopperTilers";
import Summary from "./Summary";
import { useEffect } from "react";
import Coupon from "../Coupon/Coupon";

function Dashboard() {
  const dispatch = useDispatch();
  const allStores = [];
  const allSpent = [];
  const allVisit = [];
  const allTopDepatrments = [];

  const graphResponseData = useSelector(
    (state) => state.graphData.Message?.StoreData
  );
  const graphResponseLoading = useSelector((state) => state.graphLoading);

  for (var i = 0; i < graphResponseData?.length; i++) {
    allStores.push(`${graphResponseData[i].Store}`);
    allSpent.push(graphResponseData[i].spent);
    allVisit.push(graphResponseData[i].visit);
    allTopDepatrments.push(graphResponseData[i].top_departments);
  }
  useEffect(() => {
    dispatch(fetchGraphData());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
          <div className="row g-4 mt-8">
            {<Summary />}
            <div className="col-xxl-3 col-xl-4 col-md-7 col-12">
              <div className="Card Autopilot_Loyality ">
                <div className="Autopilot_Loyality_Header d-flex align-items-center">
                  <img
                    src={Loyality}
                    className="img-fluid mr-7"
                    alt="Loyality"
                  />
                  <h5>AutoPilot Loyalty</h5>
                </div>
                <div className="Autopilot_Loyality_Body d-flex flex-column">
                  <p>
                    Let AutoPilot help you run your loyalty program. You can put
                    it fully automated or semi-automated mode.
                  </p>
                  <div className="d-flex flex-column mt-auto">
                    <div className="d-flex align-items-center ">
                      <button
                        className="BlueButton flex-grow-1"
                        role="button"
                        onClick={() => (window.location.href = "/wizard")}>
                        Create New Rule
                      </button>
                      <button
                        className="GrayButton ml-6 flex-grow-1"
                        role="button">
                        Learn more
                      </button>
                    </div>
                    <span>
                      Ask your Account Manager on how to activate Autopilot?
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="Card Shopper_Tilers">
                <div className="Shopper_Tilers_Header d-flex align-items-center justify-content-between">
                  <h5>Shopper Tilers</h5>
                  <i>Last 30 Days</i>
                </div>
                <div className="Shopper_Tilers_Body">
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <div className="Chart_Info">
                        <h6>$ Spent in last 90 Days</h6>
                      </div>
                      {allSpent && (
                        <ShopperTilers
                          stores={allStores}
                          data={allSpent}
                          graphResponseLoading={graphResponseLoading}
                        />
                      )}
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="Chart_Info">
                        <h6># of Visits in last 90 Days</h6>
                      </div>
                      {allVisit && (
                        <ShopperTilers
                          stores={allStores}
                          data={allVisit}
                          graphResponseLoading={graphResponseLoading}
                        />
                      )}
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="Chart_Info">
                        <h6>Top Department (10)$ in last 90 Days</h6>
                      </div>
                      {allTopDepatrments && (
                        <ShopperTilers
                          stores={allStores}
                          data={allTopDepatrments}
                          graphResponseLoading={graphResponseLoading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Coupon dashboardItem />
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
