/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import GroupsDepartments from "./GroupsDepartments";
import OfferDetails from "./OfferDetails";
import PreviewPromotion from "./PreviewPromotion";
import { toast, ToastContainer } from "react-toastify";
import {
  getBankOffer,
  getDashboardDepartmentsData,
  getDashboardFocusItemssData,
  getDashboardShopperGroupsData,
  getDepartments,
  getFrequency,
  getOfferType,
  getSegementData,
  getStores,
} from "../../redux/API";
import usePrevious from "../../public/PrevValue";
import CONSTANT_VALUES from "../../public/constants";

export default function NewPromotion() {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const navigate = useNavigate();
  const [validation, setValidation] = useState([]);
  const storeId = new URLSearchParams(search).get("store_id");
  const [groupId, setGroupId] = useState(
    new URLSearchParams(search).get("group_id") ?? ""
  );

  const [departmentId, setDepartmentId] = useState(
    new URLSearchParams(search).get("department_id") ?? 0
  );
  const [focusId, setFocusId] = useState(
    new URLSearchParams(search).get("focus_id") ?? ""
  );
  const [dropdown, setDropdown] = useState({
    shopperGroups: [],
    departments: [],
    focusItems: [],
    segments: [],
    offerType: [],
    stores: [],
    excludedDepartments: [],
    bankOffers: [],
  });
  useEffect(() => {
    if (groupId === undefined || groupId === null) {
      navigate("/home");
    }
  }, [groupId]);

  const [step, setStep] = useState(1);
  const [flow, setFlow] = useState("");
  const [checked, setChecked] = useState([]);
  const shopperGroups = useSelector(
    (state) => state.dashboardShopperGroupsData
  );
  const departmentsList = useSelector(
    (state) => state.dashboardDepartmentsData
  );
  const focusItemsList = useSelector((state) => state.dashboardFocusItemsData);
  const createSuccess = useSelector((state) => state.createPromotionMessage);
  const storeData = useSelector((state) => state.storesData);
  const segmentsData = useSelector((state) => state.getSegementData);

  const shopperGroupDataMessage = useSelector(
    (state) => state.dashboardShopperGroupsMessage
  );
  const prevShopperGroupsMessage = usePrevious(shopperGroupDataMessage);

  const departmentsDataMessage = useSelector(
    (state) => state.dashboardDepartmentsMessage
  );
  const prevDepartmentsDataMessage = usePrevious(departmentsDataMessage);

  const focusItemsDataMessage = useSelector(
    (state) => state.dashboardFocusItemsMessage
  );
  const prevFocusItemsDataMessage = usePrevious(focusItemsDataMessage);

  const storesMessage = useSelector((state) => state.storesMessage);
  const prevStoresMessage = usePrevious(storesMessage);

  const segementMessage = useSelector((state) => state.getSegementDataMessage);
  const prevSegementMessage = usePrevious(segementMessage);

  const bankOfferData = useSelector((state) => state.bankOfferData);

  const [value, setValue] = useState({
    shopperGroups: {},
    selectedDepartments: [],
    selectedFocusItems: [],
    storeId: storeId,
    groupId: groupId,
    focusDepartmentId: 0,
    offerType: "0",
    OfferStartedOn: "",
    OfferExpiresOn: "",
    makeItRecurring: false,
    offerFrequency: "",
    recurringexpires: "",
    offerTitle: "",
    offerDetails: "",
    additionalDescription: "",
    offerDesclaimer: "",
    MinPurcahseAmount: 0,
    MinPurchaseQty: 0,
    rewardQty: 0,
    isDiscuountPercentage: false,
    discountAmount: 0,
    maxDiscountAmount: 0,
    couponLimit: 1,
    purchaseUPCs: [],
    rewardUPCs: [],
    makeItDOW: false,
    makeItFeatured: false,
    OfferImageURL: "",
    offerTypeName: "",
    segmentsData: [],
    TargettedShopperCount: 0,
    ShopperFilter: "",
    departmentId: departmentId,
    focusId: focusId,
    excludedDepartments: [],
    rewardOffer: false,
    offerId: 0,
    monthlyDeal: false,
    CouponLimitInSingleTrans: "0",
  });

  useEffect(() => {
    setValue({
      ...value,
      storeId: storeId,
      groupId: groupId,
      departmentId: departmentId,
      focusId: focusId,
    });
    dispatch(getStores());
    dispatch(getDashboardDepartmentsData(storeId, 0, 0));
    dispatch(getDashboardFocusItemssData(storeId, 0, "All"));
    dispatch(getDashboardShopperGroupsData(storeId, -1, 0));
    dispatch(getOfferType());
    dispatch(getBankOffer());
    dispatch(getDepartments());
  }, []);

  const handleStep = (value) => {
    if (value === "next") {
      setFlow(value);
      setStep(step + 1);
    } else {
      setFlow("previous");
      setStep(step - 1);
    }
  };
  const handleInput = (e, name) => {
    setValue({
      ...value,
      [name]: e,
    });
  };
  const resetMulitpleValues = (val1, val2) => {
    setValue({
      ...value,
      [val1]: [],
      [val2]: [],
    });
  };

  const handleBankOffer = (offerId) => {
    let bankOffer = bankOfferData?.filter(
      (offer) => String(offer.OfferId) === offerId
    )[0];
    setValue({
      ...value,
      ["offerType"]: String(bankOffer.RSAOfferTypeID),
      ["offerTitle"]: bankOffer?.OfferName,
      ["offerDetails"]: bankOffer?.OfferDescription,
      ["offerId"]: bankOffer?.OfferId,
      ["MinPurchaseQty"]: bankOffer?.MinQty,
      ["discountAmount"]: bankOffer?.DiscountAmount,
      ["isDiscuountPercentage"]: bankOffer?.IsDiscountPercentage,
      ["purchaseUPCs"]: bankOffer?.PurchaseUPCList,
      ["rewardUPCs"]: bankOffer?.RewardUPCList,
      ["rewardQty"]: bankOffer?.RewardMinQty,
      ["couponLimit"]: bankOffer?.CouponLimit,
      ["makeItFeatured"]: bankOffer?.IsFeatured,
      ["makeItDOW"]: bankOffer?.IsDOW,
      ["OfferImageURL"]: bankOffer?.OfferImageURL,
    });
  };

  const handleStoreChange = (storeId) => {
    setValue({
      ...value,
      ["storeId"]: storeId,
      ["shopperGroups"]: [],
      ["selectedDepartments"]: [],
      ["selectedFocusItems"]: [],
      ["ShopperFilter"]: "",
      ["groupId"]: "",
      ["departmentId"]: "",
      ["focusId"]: "",
    });
  };

  useEffect(() => {
    if (createSuccess != "") {
      if (createSuccess === "Successful") {
        toast.success("Promotion created successfully.");
        navigate("/PromotionList");
      } else {
        toast.error(createSuccess);
      }
    }
  }, [createSuccess]);

  useEffect(() => {
    if (
      prevShopperGroupsMessage !== shopperGroupDataMessage &&
      shopperGroupDataMessage
    ) {
      if (shopperGroupDataMessage === "Successful") {
        let arr =
          shopperGroups &&
          shopperGroups?.Message?.shopper_groups?.map((i) => {
            return {
              value: i.group_id,
              label: i.name,
            };
          });
        setDropdown({
          ...dropdown,
          shopperGroups: arr,
        });
        if (value.groupId) {
          setValue({
            ...value,
            shopperGroups: arr?.filter((a) => a.value === value.groupId)[0],
          });
        }
      } else if (shopperGroupDataMessage === "Internal server error.") {
        navigate("/login");
      }
    }
    if (prevStoresMessage !== storesMessage && storesMessage) {
      if (storesMessage === "Successful") {
        setDropdown({
          ...dropdown,
          stores: storeData,
        });
      } else if (storesMessage === "Internal server error.") {
        toast.error(storesMessage);
        navigate("/login");
      }
    }
    if (
      prevDepartmentsDataMessage !== departmentsDataMessage &&
      departmentsDataMessage
    ) {
      if (departmentsDataMessage === "Successful") {
        let arr =
          departmentsList &&
          departmentsList?.Message?.departments?.map((i) => {
            return {
              value: i.department_id,
              label: i.name,
            };
          });
        setDropdown({
          ...dropdown,
          departments: arr,
        });
        if (
          value.departmentId &&
          value.departmentId !== "0" &&
          value.departmentId !== ""
        ) {
          setValue({
            ...value,
            selectedDepartments: arr?.filter(
              (a) => a.value === parseInt(value.departmentId)
            ),
            focusDepartmentId: 2,
          });
        }
      } else if (departmentsDataMessage === "Internal server error.") {
        toast.error(departmentsDataMessage);
        navigate("/login");
      }
    }
    if (
      prevFocusItemsDataMessage !== focusItemsDataMessage &&
      focusItemsDataMessage
    ) {
      if (focusItemsDataMessage === "Successful") {
        let arr =
          focusItemsList &&
          focusItemsList?.Message?.focus_items?.map((i) => {
            return {
              value: i.focus_id,
              label: i.name,
            };
          });
        setDropdown({
          ...dropdown,
          focusItems: arr,
        });
        if (value.focusId) {
          setValue({
            ...value,
            selectedFocusItems: arr?.filter((a) => a.value === value.focusId),
            focusDepartmentId: 1,
          });
        }
      } else if (focusItemsDataMessage === "Internal server error.") {
        toast.error(focusItemsDataMessage);
        navigate("/login");
      }
    }
    if (prevSegementMessage !== segementMessage && segementMessage) {
      if (segementMessage === "Successful") {
        let arr =
          segmentsData &&
          segmentsData?.Message?.Segments?.filter(
            (i) => i.shopperCount !== "0"
          ).map((i) => {
            return {
              value: `${i.shopperFilter}-${i.shopperCount}`,
              label: `${i.value} (${i.shopperCount})`,
            };
          });
        setDropdown({
          ...dropdown,
          segments: arr,
        });
      } else if (segementMessage === "Internal server error.") {
        toast.error(segementMessage);
        navigate("/login");
      }
    }
  }, [
    shopperGroupDataMessage,
    prevShopperGroupsMessage,
    prevStoresMessage,
    storesMessage,
    departmentsDataMessage,
    prevDepartmentsDataMessage,
    focusItemsDataMessage,
    prevFocusItemsDataMessage,
    segementMessage,
    prevSegementMessage,
  ]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="Content_Wrapper_Wizard  pt-50 new-promotion">
        <div className="Toastify"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h3 className="Wizard_Main_Ttl">Groups & Departments</h3>
            </div>
          </div>
        </div>
        <div className="Wizard mt-30">
          <div className="Wizard_Tab pb-30">
            <div className="container-fluid">
              <div className="justify-content-center row">
                <div className="col-12">
                  <ul
                    className="nav mb-3 justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className={step > 0 ? "active" : "nav-item"}
                        href="javascript:void(0)"
                      >
                        <span className={step > 0 ? "Circle" : ""}></span>Groups
                        & Departments
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={step > 1 ? "active" : "nav-item"}
                        href="javascript:void(0)"
                      >
                        <span className={step > 1 ? "Circle" : ""}></span>Offer
                        Details
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={step > 2 ? "active" : "nav-item"}
                        href="javascript:void(0)"
                      >
                        <span className={step > 2 ? "Circle" : ""}></span>
                        Preview
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {step === 1 && (
            <GroupsDepartments
              value={value}
              handleStep={handleStep}
              handleInput={handleInput}
              resetMulitpleValues={resetMulitpleValues}
              dropdown={dropdown}
              handleStoreChange={handleStoreChange}
              //loadSegments={loadSegments}
            />
          )}
          {step === 2 && (
            <OfferDetails
              value={value}
              handleStep={handleStep}
              handleInput={handleInput}
              dropdown={dropdown}
              handleBankOffer={handleBankOffer}
              setValue={setValue}
            />
          )}
          {step === 3 && (
            <PreviewPromotion
              value={value}
              handleStep={handleStep}
              dropdown={dropdown}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
