/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Form, FormText, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../../assets/images/logo.png";
import ButtonArrow from "../../../assets/images/button-arrow.svg";
import SliderImage1 from "../../../assets/images/slider-image-1.png";
import Slider from "react-slick";
import Key from "../../../assets/images/key.png";
import { useState } from "react";
import { headers, Regex, setSession } from "../../../public/auth";
import { getForgotPassword } from "../../../redux/API";
import usePrevious from "../../../public/PrevValue";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

export default function ForgotPassword() {
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_REACT_RECAPTCHA_SITE_KEY;
  const dispatch = useDispatch();
  const forgotPasswordData = useSelector((state) => state.forgotPasswordData);
  const forgotPasswordLoading = useSelector((state) => state.forgotPasswordLoading);
  const forgotPasswordMessage = useSelector((state) => state.forgotPasswordMessage);
  const PrevforgotPasswordMessage = usePrevious(forgotPasswordMessage);

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000
  };

  const [value, setValue] = useState({
    email: ""
  });
  const [userNameError, setUserNameError] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const onChange = (value) => {
    setCaptcha(value);
    setCaptchaError("");
  };
  const handleInput = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      if (e.target.value === "") setUserNameError("Please enter email.");
      else {
        if (Regex(e.target.value)) setUserNameError("");
        else setUserNameError("Please enter valid email.");
      }
    }
  }
  const handleForm = () => {
    if (userNameError === "") {
      dispatch(getForgotPassword(value));
    }
  };

  useEffect(() => {
    if (forgotPasswordMessage !== PrevforgotPasswordMessage && forgotPasswordMessage) {
      if (forgotPasswordMessage === "Successful") {
        toast.success(forgotPasswordData);
        window.location.href = "/login";
      } else {
        toast.error(forgotPasswordMessage);
      };
    }
  }, [forgotPasswordData, forgotPasswordMessage, PrevforgotPasswordMessage]);

  return (

    <div className="CommonFormBg SignIn">
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pt-10 pb-10">
            <Link to="/">
              <img src={Logo} className="img-fluid" alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="Wrapper mt-85">
          <div className="row g-4 justify-content-center">
            <div className="col-xl-4 col-lg-6 col-12">
              <div className="Form_Card">
                <div className="Icon">
                  <img src={Key} className="img-fluid" alt="Logo" />
                </div>
                <h2 className="Form_Ttl">Reset password?</h2>
                <p className="mt-13">
                  Enter the email associated with your account and we’ll send
                  an email with instructions to reset your password.
                </p>
                <form className="mt-34">
                  <Form.Group className="mt-15 position-relative">
                    <Form.Label>
                      Enter your registered email address
                    </Form.Label>
                    <Form.Control type="email" placeholder="Email address" name="email" onChange={(e) => handleInput(e)} />
                  </Form.Group>
                  <FormText className="Text-Red">{userNameError}</FormText>
                  <div className="mt-15">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={onChange}
                    />
                    <span style={{ color: "red" }}>{captchaError}</span>
                  </div>
                  <Button
                    onClick={handleForm}
                    className="FormButton d-block mt-37"
                    disabled={forgotPasswordLoading || userNameError || !captcha}
                  >
                    {
                      forgotPasswordLoading ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <React.Fragment>
                          Send
                          <img
                            src={ButtonArrow}
                            className="img-fluid ml-11 "
                            alt="Arrow"
                          />
                        </React.Fragment>
                      )
                    }

                  </Button>

                </form>
                
              </div>
             
             
                <ul className="nav CustomLink mt-50">
                  <li>
                    <a href="https://rsaamerica.com/terms/">Terms</a>
                  </li>
                  <li>
                    <a href="https://rsaamerica.com/privacy-policy/">Privacy</a>
                  </li>
                  {/* <li>
                    <a href="#">Home</a>
                  </li> */}
                </ul>
          
            </div>
            <div className="col-xl-4 col-lg-6 col-12 offset-xl-2">
              <Slider {...settings}>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Unlock Rapid Insights, Boost Engagement: Analyze Shopper Group Dynamics by Total Spend, Department, and Item Performance —  Intelligent Automation for Targeted Actions.
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Drive Customer Loyalty Automatically with AI and ML: Personalized Offers, Scheduled Rewards, Seamless Analytics.
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Effortless Loyalty, Endless Rewards: Tailor-Made Rules for everyday Grocer Needs.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

