import React from "react";
import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import NextArrow from "../../assets/images/next-arrow.svg";
import PrevArrow from "../../assets/images/prev-arrow.svg";
import Select from "react-select";
import { Link } from "react-router-dom";
import PastedChips from "../PastedChips";
import { useSelector } from "react-redux";

export default function Step2({
  handleStep,
  handleInput,
  validation,
  info,
  mode,
  duringDropdown,
  duringLoading,
  blockInvalidChar,
  editMode,
  handleUpcs,
  handleMultiSelectInput,
  groupsDropdown,
  validationData
}) {
  const [formError, setFormError] = useState({
    numberOfTime: "",
    amountSpent: "",
    during: "",
    existingGroupsIds: "",
    groups: "",
  });

  //console.log("VALUE", info.during.label);

  const validationDatass = useSelector((state) => state.validationData);

  const [groupsCheckBox, setGroupsCheckBox] = useState(false);

  const [existingGroupdIDs, setExisitngGroupIDs] = useState(
    editMode ? info.existing_groupsList : []
  );
  const [existingGroupID, setExistingGroupID] = useState(" ");

  const handleAllCheck = (e) => {
    if (e.target.checked === true) {
      handleMultiSelectInput(groupsDropdown, "existing_groupsList");
    } else {
      handleMultiSelectInput([], "existing_groupsList");
    }
    setGroupsCheckBox(e.target.checked);
  };

  const handleChange = (name) => {
    setFormError((prev) => {
      return { ...prev, [name]: "" };
    });
  };



  const handleStepNext = () => {
    if (validation.includes("During") && info.during.value === "") {
      setFormError((prev) => {
        return { ...prev, during: "Please select days" };
      });
    } else if (
      validation.includes("Select Group") &&
      !validation.includes("Choose stores") &&
      !validation.includes("Enter Zip Codes") &&
      info.existing_groupsList.length === 0
    ) {
      setFormError((prev) => {
        return {
          ...prev,
          existingGroupsIds: "Please Enter Group ID.",
          groups: "Please select groups.",
        };
      });
    } else {
      handleStep("next");
    }
  };
  const handleDeleteExistingGroupID = (deleteChip) => {
    const updatedUPCSData = info.existing_groupsList.filter(
      (eachChip) => eachChip !== deleteChip
    );
    setExisitngGroupIDs(updatedUPCSData);
    handleUpcs(updatedUPCSData, "existing_groupsList");
  };

  const handleGroupsChange = () => {
    if (validation.includes("Select Group")) {
      // info.storesList.length = 0;
    }
  };

  const topshoperIndex = validationData?.findIndex(
    (each) => each.Field === "Choose Top Shoppers"
  );

  const limitStores = validationData?.filter(
    (each) =>
      each.Section === "limits & stores" && each.Field != "Choose Top Shoppers"
  );
  return (
    <div className="Wizard_Content_Card Criteria p-0">
      <Form>
        {duringLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <Spinner animation="border" variant="primary" sizing={"lg"} />
          </div>
        ) : (
          <div className="p-17">
            {editMode && (
              <span style={{ color: "red", gap: "3px" }}>
                This changes will take effect for all upcoming rule runs -
                already created groups and coupon will not change.
              </span>
            )}
            {validation.includes("During") && (
              <Row>
                <Col xs={12}>
                  <Form.Group className="mt-16">
                    {info.during.label === "Please select days..." ? (
                      <Form.Label style={{ letterSpacing: "0.2px" }}>
                        Looks in the database for all customers who visited in
                        the past days
                      </Form.Label>
                    ) : (
                      <Form.Label style={{ letterSpacing: "0.2px" }}>
                        Looks in the database for all customers who visited in
                        the past {info.during.label} days
                      </Form.Label>
                    )}

                    <Link
                      to=""
                      title="number of days before each rule run date (baseline timeframe for group)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-help-circle mb-4"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <path
                          d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
                          fill="current"
                        ></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                      </svg>
                    </Link>
                    <Form.Label
                      style={{ fontStyle: "italic", color: "#3D8ECC" }}
                    >
                      {" "}
                      (timeframe becomes group baseline)
                    </Form.Label>
                    <Select
                      name="during"
                      value={info.during}
                      placeholder={"Please select days..."}
                      options={duringDropdown}
                      isDisabled={mode}
                      className="react-select-container select"
                      onChange={(e) => {
                        handleInput(e, "during");
                        handleChange("during");
                      }}
                    />
                  </Form.Group>
                  {formError.during && (
                    <p style={{ color: "red" }} className="error-message">{formError.during}</p>
                  )}
                </Col>
              </Row>
            )}

            {validation.includes("Choose Top Shoppers") && (
              <Col lg={12} xs={12} className="mt-20">
                {validationDatass
                  .filter((x) => x.Field === "Choose Top Shoppers")
                  .map((each, i) => (
                    <>
                      <Form.Group>
                        {each?.FieldLabel === "" ? (
                          <Form.Label>Choose Top Shoppers</Form.Label>
                        ) : (
                          <Form.Label>{each?.FieldLabel}</Form.Label>
                        )}
                        <Form.Control
                          type="number"
                          name="chooseTop"
                          placeholder={each?.FieldLabel}
                          value={info.chooseTop}
                          disabled={mode}
                          min={0}
                          max={validationData[topshoperIndex]?.MaxValue}
                          className="react-select-container select"
                          onKeyDown={blockInvalidChar}
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => {
                            handleInput(e.target.value, "chooseTop");
                            handleChange("chooseTop");
                          }}
                        />
                      </Form.Group>
                    </>
                  ))}

                {formError.chooseTop && (
                  <p style={{ color: "red" }} className="error-message">{formError.chooseTop}</p>
                )}
              </Col>
            )}
            <div className="row gx-2 ">
              {/* {validation.includes("Amount spend") && (
                <div className="col-lg-6 col-12">
                  <Form.Group className=" mt-10 position-relative">
                    <Form.Label>Minimum Amount Spent</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Minimum Amount Spend"
                      name="amountSpent"
                      disabled={mode}
                      min={0}
                      value={info.amountSpent}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      onChange={(e) => {
                        handleInput(e.target.value, "amountSpent");
                        handleChange("amountSpent");
                      }}
                    />
                  </Form.Group>
                  {formError.visitContainingupcs && (
                    <p style={{ color: "red" }}>
                      {formError.visitContainingupcs}
                    </p>
                  )}
                </div>
              )} */}
              {/* {validation.includes("Amount spend") && (
                <div className="col-lg-6 col-12">
                  <Form.Group className=" mt-16 position-relative">
                    <Form.Label>Maximum Amount Spent</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Maximum Amount Spend"
                      name="max_amount_spend"
                      disabled={mode}
                      min={0}
                      value={info.max_amount_spend}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      onChange={(e) => {
                        handleInput(e.target.value, "max_amount_spend");
                        handleChange("max_amount_spend");
                      }}
                    />
                  </Form.Group>
                  {formError.visitContainingupcs && (
                    <p style={{ color: "red" }}>
                      {formError.visitContainingupcs}
                    </p>
                  )}
                </div>
              )} */}

              {validation.includes("Select Group") &&
                !validation.includes("Choose stores") && (
                  <div className="col-lg-12 col-12">
                    <Form.Group className=" mt-16 position-relative">
                      <Form.Label>Choose Groups</Form.Label>
                      <Select
                        isMulti
                        isSearchable={true}
                        options={groupsDropdown}
                        value={info.existing_groupsList}
                        placeholder={"Please select groups..."}
                        isDisabled={mode}
                        className="react-select-container select"
                        onChange={(e) => {
                          handleGroupsChange();
                          handleMultiSelectInput(e, "existing_groupsList");
                        }}
                      />
                    </Form.Group>
                    {formError.groups && (
                      <p style={{ color: "red" }} className="error-message">
                        {info.existing_groupsList.length === 0 &&
                          formError.groups}
                      </p>
                    )}
                  </div>
                )}
            </div>
            {validation.includes("Amount spend") && (
              <div className="col-lg-12 col-12 mt-10">
                <Form.Label>Amount Spent</Form.Label>
              </div>
            )}
            {validation.includes("Amount spend") && (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col lg={2}>
                        <Form.Label
                          style={{ marginRight: "5px", marginTop: "15px" }}
                        >
                          Between{" "}
                        </Form.Label>
                      </Col>
                      <Col lg={10}>
                        {/* <Form.Label>Minimum Number of Store visits </Form.Label> */}
                        <Form.Group className=" mt-5 position-relative">
                          <Form.Control
                            type="number"
                            placeholder="Minimum Amount Spend"
                            name="amountSpent"
                            disabled={mode}
                            value={info.amountSpent}
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={blockInvalidChar}
                            onChange={(e) => {
                              handleInput(e.target.value, "amountSpent");
                              handleChange("amountSpent");
                            }}
                          />
                        </Form.Group>
                        {formError.visitContainingupcs && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.visitContainingupcs}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-6" xs={6}>
                    <Row>
                      <Col lg={1}>
                        <Form.Label
                          style={{ marginTop: "15px", marginLeft: "-10px" }}
                        >
                          and
                        </Form.Label>
                      </Col>
                      <Col lg={11}>
                        <Form.Group className=" mt-5 position-relative">
                          {/* <Form.Label>Maximum Number of Store visits</Form.Label> */}
                          <Form.Control
                            type="number"
                            placeholder="Maximum Amount Spend"
                            name="max_amount_spend"
                            disabled={mode}
                            value={info.max_amount_spend}
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={blockInvalidChar}
                            onChange={(e) => {
                              handleInput(e.target.value, "max_amount_spend");
                              handleChange("max_amount_spend");
                            }}
                          />
                        </Form.Group>
                        {formError.visitContainingupcs && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.visitContainingupcs}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}

            {validation.includes("Number of times shopped") && (
              <div className="col-lg-12 col-12 mt-10">
                <Form.Label>Number of Store Visits</Form.Label>
              </div>
            )}
            {validation.includes("Number of times shopped") && (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col lg={2}>
                        <Form.Label
                          style={{ marginRight: "5px", marginTop: "15px" }}
                        >
                          Between{" "}
                        </Form.Label>
                      </Col>
                      <Col lg={10}>
                        {/* <Form.Label>Minimum Number of Store visits </Form.Label> */}
                        <Form.Group className=" mt-5 position-relative">
                          <Form.Control
                            type="number"
                            placeholder="Number of Store visits..."
                            name="numberOfTime"
                            disabled={mode}
                            value={info.numberOfTime}
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={blockInvalidChar}
                            onChange={(e) => {
                              handleInput(e.target.value, "numberOfTime");
                              handleChange("numberOfTime");
                            }}
                          />
                        </Form.Group>
                        {/* {formError.numberOfTime && (
                          <p style={{ color: "red" }}>{formError.numberOfTime}</p>
                        )} */}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-6" xs={6}>
                    <Row>
                      <Col lg={1}>
                        <Form.Label
                          style={{ marginTop: "15px", marginLeft: "-10px" }}
                        >
                          and
                        </Form.Label>
                      </Col>
                      <Col lg={11}>
                        <Form.Group className=" mt-5 position-relative">
                          {/* <Form.Label>Maximum Number of Store visits</Form.Label> */}
                          <Form.Control
                            type="number"
                            placeholder="Number of Store visits..."
                            name="max_times_purchased"
                            disabled={mode}
                            value={info.max_times_purchased}
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={blockInvalidChar}
                            onChange={(e) => {
                              handleInput(
                                e.target.value,
                                "max_times_purchased"
                              );
                              handleChange("max_times_purchased");
                            }}
                          />
                        </Form.Group>
                        {/* {formError.numberOfTime && (
                          <p style={{ color: "red" }}>{formError.numberOfTime}</p>
                        )} */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}

            {/* <div className="d-flex">
           {validation.includes("Amount spend") && (
              <div className="col-lg-6 col-12 mr-5">
                <Form.Group className=" mt-15 position-relative">
                  <Form.Label>Minimum Amount Spent</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Minimum Amount Spend"
                    name="amountSpent"
                    disabled={mode}
                    min={0}
                    value={info.amountSpent}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={blockInvalidChar}
                    onChange={(e) => {
                      handleInput(e.target.value, "amountSpent");
                      handleChange("amountSpent");
                    }}
                  />
                </Form.Group>
                {formError.visitContainingupcs && (
                  <p style={{ color: "red" }}>
                    {formError.visitContainingupcs}
                  </p>
                )}
              </div>
            )}
            {validation.includes("Amount spend") && (
              <div className="col-lg-6 col-12">
                <Form.Group className=" mt-16 position-relative">
                  <Form.Label>Maximum Amount Spent</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Maximum Amount Spend"
                    name="max_amount_spend"
                    disabled={mode}
                    min={0}
                    value={info.max_amount_spend}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={blockInvalidChar}
                    onChange={(e) => {
                      handleInput(e.target.value, "max_amount_spend");
                      handleChange("max_amount_spend");
                    }}
                  />
                </Form.Group>
                {formError.visitContainingupcs && (
                  <p style={{ color: "red" }}>
                    {formError.visitContainingupcs}
                  </p>
                )}
              </div>
            )}
           </div> */}
            {validation.includes("Visits containing UPCs") && (
              <div className="col-lg-12 col-12 mt-15">
                <Form.Label>
                  Visits having items from selected Depts/UPCs
                </Form.Label>
              </div>
            )}
            {validation.includes("Visits containing UPCs") && (
              <>
                <div className="Container">
                  <Row>
                    <Col>
                      <Row>
                        <Col lg={2}>
                          <Form.Label
                            style={{ marginRight: "5px", marginTop: "15px" }}
                          >
                            Between{" "}
                          </Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Group className=" mt-5 position-relative">
                            <Form.Control
                              type="number"
                              placeholder="Visits Containing UPCs..."
                              name="visits_containing_upcs"
                              disabled={mode}
                              min={0}
                              value={info.visits_containing_upcs}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              onChange={(e) => {
                                handleInput(
                                  e.target.value,
                                  "visits_containing_upcs"
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-6" xs={6}>
                      <Row>
                        <Col lg={1}>
                          <Form.Label style={{ marginTop: "15px" }}>
                            and{" "}
                          </Form.Label>
                        </Col>
                        <Col lg={11}>
                          <Form.Group className=" mt-5 position-relative">
                            {/* <Form.Label>
                   
                      Maximun Visits Containing UPCs
                    </Form.Label> */}
                            <Form.Control
                              type="number"
                              placeholder="Visits Containing UPCs..."
                              name="max_visits_containing_upcs"
                              disabled={mode}
                              min={0}
                              value={info.max_visits_containing_upcs}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              onChange={(e) => {
                                handleInput(
                                  e.target.value,
                                  "max_visits_containing_upcs"
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        )}
      </Form>
      <div className="flex align-items-center justify-content-end text-end p-17">
        <Button
          className="GrayButton mt-80"
          type="submit"
          onClick={() => {
            handleStep("previous");
          }}
        >
          <img src={PrevArrow} alt="Prev Arrow" className="img-fluid mr-5" />
          Previous
        </Button>
        <Button
          className="BlueButton ml-10 mt-80"
          type="submit"
          onClick={() => {
            handleStepNext();
          }}
        >
          Next
          <img src={NextArrow} alt="Next Arrow" className="img-fluid ml-5" />
        </Button>
      </div>
    </div>
  );
}
