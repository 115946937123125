/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  Row,
  Spinner,
  Modal,
  Container
} from "react-bootstrap";
import { getRuleDate } from "../../public/PublicFunctionality";
import NextArrow from "../../assets/images/next-arrow.svg";
import PrevArrow from "../../assets/images/prev-arrow.svg";
import BankModel from "./BankModel";
// import SuggestModel from "./SuggestModel";
import PurchaseUpcsMultiple from "../PurchaseUpcs";
import RewardUpcsMutliple from "./RewardUpcs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Image } from "react-bootstrap";
import DisplayDates from "../../public/DisplayDates";
import CONSTANT_VALUES from "../../public/constants";

function MyVerticallyCenteredModal(props) {
  let setSuggestedCouponArray = props.setSuggestedCouponArray;
  let setChecked = props.setChecked;
  const showToastMessage = () => {
    toast.success("Coupon Deleted Successfully", {
      position: toast.POSITION.TOP_RIGHT
    });
  };
  const deleteCoupon = () => {
    let elementToRemove = props.id;
    let setCouponArray = props.setCouponArray;
    setCouponArray((prev) => prev.filter((_, i) => i !== elementToRemove));
    setSuggestedCouponArray((prev) =>
      prev.filter((_, i) => i !== elementToRemove)
    );
    setChecked((prev) => prev.filter((_, i) => i !== elementToRemove));
    var updatedSingleCouponData = props.SingleCouponData?.splice(
      props.couponId,
      1
    );

    showToastMessage();
    props.onHide();
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    let couponArray = props.couponArray;
  };

  return (
    <Modal
      {...props}
      centered
      className="d-flex flex-column justify-content-center "
      style={{ padding: 15 }}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ fontSize: 20, textAlign: "center" }}>
        Are you sure you want to delete the Offer?
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button variant="secondary" onClick={deleteCoupon}>
          Delete
        </Button>
        <Button onClick={props.onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

function BankOfferPopup(props) {
  let bankofferdata = props.bankofferdata;
  //let bankofferdata = [];
  let checked = props.checked;
  let setChecked = props.setChecked;
  let setSuggestedCouponArray = props.setSuggestedCouponArray;
  let suggestedCouponArray = props.suggestedCouponArray;
  // var removedArray = bankofferdata.filter(
  //   (el) => !suggestedCouponArray.includes(el)
  // );
  //var removedArray=bankofferdata;
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleSubmit = () => {
    let suggestedData = bankofferdata;

    suggestedData.map((each) => {
      checked.map((eachChecked) => {
        if (each.OfferId == eachChecked) {
          setSuggestedCouponArray((prev) => {
            return [...prev, each];
          });
        }
      });
    });
    setChecked([]);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      scrollable={true}
      aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Offers
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <table className="table table-striped">
          {bankofferdata?.length < 1 ? (
            <>
              <thead style={{ fontWeight: "600" }}>
                No offers found in offer bank
              </thead>
              <tbody></tbody>
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th>Coupon</th>
                  <th>Offer Name</th>
                  <th>Offer Type</th>
                  <th>Selected</th>
                </tr>
              </thead>
              <tbody>
                {bankofferdata?.map((each, i) => (
                  <tr>
                    <td>
                      {" "}
                      <img
                        src={each.OfferImageURL}
                        className="img-fluid"
                      />{" "}
                    </td>
                    <td>{each.OfferName}</td>
                    <td>{each.OfferTypeName.label}</td>
                    <td>
                      <input
                        type="checkbox"
                        value={each.OfferId}
                        onChange={handleCheck}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </table>
      </Modal.Body>
      {bankofferdata?.length < 1 ? (
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button onClick={handleSubmit}>Select</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default function Step4({
  handleStep,
  couponInitialObject,
  couponArray,
  setCouponArray,
  handleMultiSelectInput,
  departmentLoading,
  offerLoading,
  info,
  deptData,
  disableField,
  formError,
  handleChange,
  basketDisable,
  upcDisable,
  mode,
  modeDetails,
  offerDropdown,
  couponLimitDisable,
  dowDisabled,
  featuredDisabled,
  weeklyDisabled,
  setFormError,
  frequencyDropdown,
  setValue,
  SingleCouponData,
  radioChange,
  suggestRadio,
  bankRadio,
  handleInput,
  blockInvalidChar,
  bankOfferData,
  departmentDropdown,
  handleAllCheck,
  setOfferBankArray,
  offerBankArray,
  setChecked,
  checked,
  editMode,
  validation,
  rewardOfferChange,
  newOfferChange,
  rewardOfferChangeNew,
  offerCheckBox,
  blockInvalidCharMin,
  setOfferCheckBox,
  handleExpirePrevCoupon,
  setSelectedCheckbox,
  selectedCheckbox,
  singleRuleData
}) {
  const [couponErrorArray, setCouponErrorArray] = useState([formError]);
  const [couponId, setCouponId] = useState();
  const [PurchaseUpcs, setPurchaseUpcs] = useState([]);
  const departmentData = useSelector((state) => state.departmentData);

  const [chooseDeptCheckBox, setChooseDeptCheckBox] = useState(false);
  const [bankOfferShow, setBankofferShow] = useState(false);
  let [suggestedCouponArray, setSuggestedCouponArray] = useState([]);

  const [chooseDeptChecked, setChooseDeptChecked] = useState(false);
  const [chooseDeptCheckedOfferBank, setChooseDeptCheckedOfferBank] =
    useState(false);
  const [baseImage, setBaseImage] = useState("");

  const handleCheckboxChange = (event) => {
    if (selectedCheckbox === event.target.id) {
      setSelectedCheckbox(null);
    } else {
      setSelectedCheckbox(event.target.id);
    }
  };

  const handleGivePointsChange = (event) => {
    setValue((value) => ({
      ...value,
      rewardOffer: false,
      createPoints: 1
    }));
    couponArray.length = 0;
    offerBankArray.length = 0;
    suggestedCouponArray.length = 0;
    checked.length = 0;
    info.points = "";
    setFormError((prev) => {
      return { ...prev, offer: "" };
    });
  };
  function decimalChange(e) {
    if (e.key !== "Backspace") {
      if (e.target.value.includes(".")) {
        if (e.target.value.split(".")[1].length >= 2) {
          var num = parseFloat(e.target.value);
          var cleanNum = num.toFixed(2);
          e.target.value = cleanNum;
          e.preventDefault();
        }
      }
    }
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 101 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  }

  const givePointsDecimal = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 101 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const handleButtonClickOfferBank = () => {
    // setValue((value) => ({
    //   ...value,
    //   suggestOffer: false,
    //   createOffer: false,
    //   bankOffer: true,
    // }));
    couponArray.length = 0;
    setFormError((prev) => {
      return { ...prev, offer: "" };
    });
    setBankofferShow(true);
  };

  const handleButtonClickBuildOffer = () => {
    // setValue((value) => ({
    //   ...value,
    //   suggestOffer: false,
    //   createOffer: true,
    //   bankOffer: false,
    // }));
    // offerBankArray.length = 0;
    setOfferBankArray([couponInitialObject, ...offerBankArray]);
    setFormError((prev) => {
      return { ...prev, offer: "" };
    });

    //setCouponArray([couponInitialObject]);
  };

  const handleSetValue = () => {
    if (couponArray.length > 0) {
      if (couponArray[0].name != "") {
        let temp = offerBankArray.concat(couponArray);
        setValue((prev) => {
          return { ...prev, CouponArray: temp };
        });
      } else {
        setValue((prev) => {
          return { ...prev, CouponArray: offerBankArray };
        });
      }
    } else {
      setValue((prev) => {
        return { ...prev, CouponArray: offerBankArray };
      });
    }
  };

  const handleChecked = (e, i) => {
    if (e.target.checked === false) {
      handleStep4dept([], "departments", i);
    } else {
      handleStep4dept(deptData, "departments", i);
    }
    setChooseDeptChecked(e.target.checked);
  };

  const handleCheckedofferbank = (e, i) => {
    if (e.target.checked === false) {
      handleStep4deptOfferbank([], "departments", i);
    } else {
      handleStep4deptOfferbank(deptData, "departments", i);
    }
    setChooseDeptCheckedOfferBank(e.target.checked);
  };

  // useEffect(() => {
  //   if (info.bankOffer === true) {
  //     setBankofferShow(true);
  //   }
  // }, [info.bankOffer]);

  useEffect(() => {
    if (deptData)
      handleMultiSelectInput(info.departments, "chooseDepartmentsData");
  }, [info.departments]);

  useEffect(() => {
    if (info.chooseDepartmentsData.length === 0)
      handleMultiSelectInput(info.departments, "chooseDepartmentsData");
  }, [info.chooseDepartmentsData.length, info.departments, PurchaseUpcs]);

  useEffect(() => {
    if (couponArray.length === 0 && info.createOffer === true) {
      setValue((value) => ({
        ...value,
        createOffer: false
      }));
    }
  }, [couponArray.length]);

  useEffect(() => {
    if (suggestedCouponArray?.length > 0) {
      let suggestedCouponTempArray = suggestedCouponArray.map((each) => {
        return {
          ApRuleOfferId: each.OfferId,
          name: each.OfferName,
          // frequency: each?.Frequency,
          frequency: { label: "Please select frequency...", value: "" },
          offertype: each.OfferTypeName,
          offerstart: 0,
          description: each.OfferDescription,
          image: each.OfferImageURL,
          minamount: each.MinAmountRequired.toString(),
          minquantity: each.MinQty,
          reward_min_quantity: each.RewardMinQty,
          rewardamount: each.DiscountAmount.toString(),
          isdiscountpercentage: each.IsDiscountPercentage === true ? 1 : 0,
          purchaseupcs: each.PurchaseUPCList,
          rewardupcs: each.RewardUPCList,
          stores: [],
          departments: each.DepartmentList,
          expirePrevCoupon: 0,
          isdow: each.IsDOW === true ? 1 : 0,
          coupon_limit: each.CouponLimit,
          isfeaturecoupon: each.IsFeatured === true ? 1 : 0,
          includeinweeklyemail: each.IsTargeted === true ? 1 : 0,
          validityindays: each.ValidityInDays,
          coupon_start_time: "00:00",
          coupon_end_time: "23:59",
          sendpushnotification: 0,
          message: "",
          pushdaysfrom: 0,
          time: "08:00",
          postfb: 0,
          fbmessage: "",
          fbdaysfrom: 0,
          fbtime: "09:00",
          sendsms: 0,
          smsmessage: "",
          smsdaysfrom: 0,
          smstime: "10:00",
          sendemail: 0,
          emailmessage: "",
          emaildaysfrom: 0,
          emailtime: "11:00",
          CouponLimitInSingleTrans: "0",
          IsMonthly: false
        };
      });

      //setOfferBankArray(suggestedCouponTempArray);
      //we need to remove data from suggestedCouponTempArray, which already stored in offerbank array.
      //append that filter array to offerbankarray.
      const arr = [...suggestedCouponTempArray, ...offerBankArray];
      // const uniqueOfferBankArray = arr
      //   .map((e) => e["ApRuleOfferId"])
      //   .map((e, i, final) => final.indexOf(e) === i && i)
      //   .filter((e) => arr[e])
      //   .map((e) => arr[e]);
      // setOfferBankArray(uniqueOfferBankArray);
      setOfferBankArray(arr);
      setSuggestedCouponArray([]);
      setChecked([]);
    }
  }, [suggestedCouponArray]);

  const addCouponObject = () => {
    if (info.bankOffer == true) {
      //setCouponArray([...couponArray, couponInitialObject]);
      setOfferBankArray([...offerBankArray, couponInitialObject]);
    } else {
      handleStepNextCouponOffer("addCoupon");
    }
  };
  const rewardOfferChangeTwo = (e) => {
    let temp = !e.target.checked;
    if (temp) {
      couponArray.length = 0;
      offerBankArray.length = 0;
      suggestedCouponArray.length = 0;
      checked.length = 0;
    }
    setFormError((prev) => {
      return { ...prev, offer: "" };
    });
  };

  const handleCouponInput = (value, name, index) => {
    var couponInput = [...couponArray];
    var couponObject = { ...couponArray[index], [name]: value };
    couponInput.splice(index, 1, couponObject);
    setCouponArray(couponInput);
  };

  const handleCouponOfferBank = (value, name, index) => {
    var offerInput = [...offerBankArray];
    var couponObject = { ...offerBankArray[index], [name]: value };
    offerInput.splice(index, 1, couponObject);

    setOfferBankArray(offerInput);
    handleCouponDateChange();
  };
  const [couponstartsDay, setCouponstartsDay] = useState("");
  const [couponStart, setCouponStart] = useState("");

  useEffect(() => {
    handleCouponStartDay(info.startDate);
  }, [modeDetails]);

  const handleCouponStartDay = (value) => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    let today = new Date(value);
    //let day = today.toLocaleString('en-us', {weekday: 'long'});
    let day = weekday[today.getUTCDay()];
    setCouponstartsDay(day);
  };

  const displayDate = (value, index) => {
    let temp = info.startDate;
    let dt = new Date(temp);
    dt.setDate(dt.getDate() + parseInt(value));
    var today = new Date(dt);
    today.setDate(today.getDate() + 1);
    var yyyy = today.getFullYear();
    var mm = "" + (today.getMonth() + 1);
    var dd = "" + today.getDate();

    if (mm.length < 2) mm = "0" + mm;
    if (dd.length < 2) dd = "0" + dd;

    // var disablePast = yyyy + "-" + mm + "-" + dd;
    var currentDate = mm + "/" + dd + "/" + yyyy;
    var offerInput = [...offerBankArray];
    let newDate = new Date(currentDate);

    let day = newDate.toLocaleString("en-us", { weekday: "long" });
    var couponObject = { ...offerBankArray[index], ["couponStartDate"]: value };
    offerInput[index].couponStartDate = currentDate;
    offerInput[index].couponStartDay = day;

    offerInput.splice(index, 1, couponObject);
  };

  const handleCouponDateChange = (value, name, index) => {
    let temp = info.startDate;
    let dt = new Date(temp);
    dt.setDate(dt.getDate() + parseInt(value));
    var today = new Date(dt);
    today.setDate(today.getDate() + 1);
    var yyyy = today.getFullYear();
    var mm = "" + (today.getMonth() + 1);
    var dd = "" + today.getDate();

    if (mm.length < 2) mm = "0" + mm;
    if (dd.length < 2) dd = "0" + dd;

    // var disablePast = yyyy + "-" + mm + "-" + dd;
    var currentDate = mm + "/" + dd + "/" + yyyy;

    // display day name
    //let day = today.toLocaleString('en-us', {weekday: 'long'});
    let dateValue = value === "" ? getRuleDate(info?.startDate) : currentDate;

    let newDate = new Date(dateValue);

    let day = newDate.toLocaleString("en-us", { weekday: "long" });

    var offerInput = [...offerBankArray];
    // var date = new Date();
    // let temp=info.startDate
    // let tempDate=date.setDate(temp.getDate() + 1);
    // console.log("startdate", tempDate);
    var couponObject = { ...offerBankArray[index], [name]: value };
    offerInput.splice(index, 1, couponObject);
    offerInput[index].couponStartDate =
      value === "" ? getRuleDate(info.startDate) : currentDate;
    offerInput[index].couponStartDay = day;
    setCouponStart(offerInput[index].couponStartDate);
    setCouponstartsDay(offerInput[index].couponStartDay);
    // console.log("offerstart",offerInput)
    setOfferBankArray(offerInput);
  };

  const handleCouponCheckBox = (e, index) => {
    var couponInput = [...couponArray];

    var couponObject = {
      ...couponArray[index],
      [e.target.name]: e.target.checked
    };

    couponInput.splice(index, 1, couponObject);
    setCouponArray(couponInput);
  };

  const handleCouponCheckBoxOfferBank = (e, index) => {
    var offerInput = [...offerBankArray];
    var couponObject = {
      ...offerBankArray[index],
      [e.target.name]: e.target.checked
    };

    offerInput.splice(index, 1, couponObject);
    setOfferBankArray(offerInput);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onloadend = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const couponUploadImage = async (event, index) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    couponArray[index].image = base64;
    setBaseImage(base64);
  };

  const offerUploadImage = async (event, index, name) => {
    const file = event.target.files && event.target.files[0];
    const base64 = await convertBase64(file);
    var offerInput = [...offerBankArray];
    var couponObject = { ...offerBankArray[index], [name]: base64 };
    offerInput.splice(index, 1, couponObject);
    setOfferBankArray(offerInput);
    setBaseImage(base64);
  };

  const handleStep4dept = (e, name, index) => {
    var couponInput = [...couponArray];
    var couponObject = { ...couponArray[index], [name]: e };
    couponInput.splice(index, 1, couponObject);
    setCouponArray(couponInput);
  };
  const handleStep4deptOfferbank = (e, name, index) => {
    var offerInput = [...offerBankArray];
    var couponObject = { ...offerBankArray[index], [name]: e };
    offerInput.splice(index, 1, couponObject);
    setOfferBankArray(offerInput);
  };

  const handleStepNextCouponOffer = (addCoupon) => {
    if (info.createOffer === true) {
      //array
      offerBankArray.length = 0;
      if (
        couponArray.some((each) => each.name === "") ||
        couponArray.some((each) => each.frequency.value === "") ||
        couponArray.some((each) => each.offertype.value === "") ||
        offerBankArray.some((each) => each.image === "") ||
        couponArray.some(
          (each) =>
            each.offertype?.label === "UPC" && each.purchaseupcs.length === 0
        ) ||
        couponArray.some((each) => each.rewardamount === "0") ||
        couponArray.some((each) => each.rewardamount === "")
      ) {
        if (couponArray.some((each) => each.name === "")) {
          couponArray.map((each, i) => {
            if (each.name === "" || couponArray[i].name) {
              setFormError((prev) => {
                return { ...prev, coupon: "Please Enter Coupon Name" };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, coupon: " " };
              });
            }
          });
        }
        if (couponArray.some((each) => each.frequency.value === "")) {
          couponArray.map((each, i) => {
            if (
              each.frequency?.value === "" ||
              couponArray[i].frequency.value
            ) {
              setFormError((prev) => {
                return {
                  ...prev,
                  frequency: "Please Enter Frequency of Coupon "
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, frequency: " " };
              });
            }
          });
        }
        if (couponArray?.some((each) => each.offertype.value === "")) {
          couponArray.map((each, i) => {
            if (each.offertype.value === "" || couponArray[i].offertype.value) {
              setFormError((prev) => {
                return {
                  ...prev,
                  offerType: "Please Enter OfferType of Coupon "
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, offerType: " " };
              });
            }
          });
        }

        if (
          couponArray.some(
            (each) =>
              each.offertype.label === "UPC" && each.purchaseupcs.length === 0
          )
        ) {
          couponArray.map((each, i) => {
            if (
              (each.offertype.label === "UPC" &&
                each.purchaseupcs.length === 0) ||
              couponArray[i].offerType.label
            ) {
              setFormError((prev) => {
                return { ...prev, purchaseUpcs: "Please Enter Purchase UPCS" };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, purchaseUpcs: " " };
              });
            }
          });
        }

        if (
          couponArray.some(
            (each) =>
              each.offertype.label === "Cross Sell" &&
              each.rewardupcs.length === 0
          )
        ) {
          couponArray.map((each, i) => {
            if (
              (each.offertype.label === "Cross Sell" &&
                each.rewardupcs.length === 0) ||
              couponArray[i].offerType.label
            ) {
              setFormError((prev) => {
                return { ...prev, rewardupcs: "Please Enter Reward UPCS" };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, rewardupcs: "" };
              });
            }
          });
        }
        if (
          couponArray?.some((each) => each.rewardamount === "0") ||
          couponArray?.some((each) => each.rewardamount === "")
        ) {
          couponArray.map((each, i) => {
            if (each.rewardamount === "0" || each.rewardamount === "") {
              setFormError((prev) => {
                return { ...prev, rewardamount: "Please Enter Reward Amount." };
              });
            }
          });
        } else {
          setFormError((prev) => {
            return { ...prev, rewardamount: "" };
          });
        }
      } else {
        if (addCoupon) {
          setCouponArray([...couponArray, couponInitialObject]);
          //setCouponErrorArray([...couponErrorArray, formError]);
          setFormError([...formError, formError]);
        } else handleStep("next");
      }
    } else {
      if (
        info.rewardOffer === true &&
        couponArray.length === 0 &&
        offerCheckBox === false
      ) {
        if (info.rewardOffer === true && offerBankArray.length === 0) {
          setFormError((prev) => {
            return { ...prev, offer: "You must add atleast one reward offer" };
          });
        }
      } else {
        handleStep("next");
      }
    }
  };

  const [showMesg, setShowMesg] = useState(false);
  const handleOfferNext = () => {
    if (info.rewardOffer === true) {
      //array
      couponArray.length = 0;
      if (
        offerBankArray.some((each) => each.name === "") ||
        offerBankArray.some((each) => each.frequency.value === "") ||
        offerBankArray.some((each) => each.frequency === undefined) ||
        offerBankArray.some((each) => each.offertype.value === "") ||
        offerBankArray.some((each) => each.image === "") ||
        // offerBankArray.some(
        //   (each) =>
        //     each.offertype?.label === "UPC" && each.purchaseupcs.length === 0
        // ) ||
        offerBankArray.some(
          (each) =>
            (each.offertype.label === "UPC" &&
              each.purchaseupcs.length === 0) ||
            (each.offertype.label === "Cross Sell" &&
              each.purchaseupcs.length === 0)
        ) ||
        offerBankArray.some(
          (each) =>
            each.offertype?.label === "Cross Sell" &&
            each.rewardupcs.length === 0
        ) ||
        offerBankArray.some((each) => each.rewardamount === "0") ||
        offerBankArray.some((each) => each.rewardamount === "") ||
        offerBankArray.some(
          (each) => each?.sendpushnotification === true && each?.message === ""
        ) ||
        offerBankArray.some(
          (each) => each?.sendemail === true && each?.emailmessage === ""
        )
      ) {
        if (offerBankArray.some((each) => each.name === "")) {
          offerBankArray.map((each, i) => {
            if (each.name === "" || offerBankArray[i].name) {
              setFormError((prev) => {
                return { ...prev, coupon: "Please Enter Coupon Name" };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, coupon: " " };
              });
            }
          });
        }
        if (offerBankArray.some((each) => each.frequency.value === "")) {
          offerBankArray.map((each, i) => {
            if (
              each.frequency?.value === "" ||
              offerBankArray[i].frequency.value
            ) {
              setFormError((prev) => {
                return {
                  ...prev,
                  frequency: "Please Enter Frequency of Coupon "
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, frequency: " " };
              });
            }
          });
        }
        if (
          offerBankArray.some(
            (each) => each?.sendemail === true && each?.emailmessage === ""
          )
        ) {
          offerBankArray.map((each, i) => {
            if (each.emailmessage === "" || offerBankArray[i].emailmessage) {
              setFormError((prev) => {
                return {
                  ...prev,

                  emailmessage: "Please Enter Email Message"
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, emailmessage: " " };
              });
            }
          });
        }

        if (
          offerBankArray.some(
            (each) =>
              each?.sendpushnotification === true && each?.message === ""
          )
        ) {
          offerBankArray.map((each, i) => {
            if (each?.message === "" || offerBankArray[i]?.message) {
              setFormError((prev) => {
                return {
                  ...prev,

                  pushNotification: "Please Enter Push Notification Message"
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, emailmessage: " " };
              });
            }
          });
        }
        if (offerBankArray.some((each) => each.frequency === undefined)) {
          offerBankArray.map((each, i) => {
            if (each.frequency === undefined || offerBankArray[i].frequency) {
              setFormError((prev) => {
                return {
                  ...prev,
                  frequency: "Please Enter Frequency of Coupon "
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, frequency: " " };
              });
            }
          });
        }
        if (offerBankArray?.some((each) => each.offertype.value === "")) {
          offerBankArray.map((each, i) => {
            if (each.offertype.value === "" || offerBankArray[i].offertype) {
              setFormError((prev) => {
                return {
                  ...prev,
                  offerType: "Please Enter OfferType of Coupon "
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, offerType: " " };
              });
            }
          });
        }
        if (offerBankArray.some((each) => each.image === "")) {
          offerBankArray.map((each, i) => {
            if (each.image === "" || offerBankArray[i].image) {
              setFormError((prev) => {
                return { ...prev, image: "Please Upload Image" };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, image: " " };
              });
            }
          });
        }
        if (
          offerBankArray?.some(
            (each) => each.sendpushnotification === true && each.message === ""
          )
        ) {
          offerBankArray.map((each, i) => {
            if (each.message === "") {
              setFormError((prev) => {
                return {
                  ...prev,
                  pushNotification: "Please Enter Push Notification Message "
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, pushNotification: " " };
              });
            }
          });
        }
        if (
          offerBankArray?.some(
            (each) => each.sendemail === true && each.emailmessage === ""
          )
        ) {
          offerBankArray.map((each, i) => {
            if (each.emailmessage === "") {
              setFormError((prev) => {
                return {
                  ...prev,
                  emailmessage: "Please Enter E-Mail Message "
                };
              });
            } else {
              setFormError((prev) => {
                return { ...prev, emailmessage: " " };
              });
            }
          });
        }
        // if (
        //   offerBankArray.some(
        //     (each) =>
        //       (each.offertype.label === "UPC") && each.purchaseupcs.length === 0
        //   )
        // ) {
        //   offerBankArray?.map((each, i) => {
        //     if (
        //       ((each.offertype.label === "UPC") &&
        //         each.purchaseupcs.length === 0) ||
        //         offerBankArray[i].offertype.label === "UPC"
        //     ) {
        //       setFormError((prev) => {
        //         return { ...prev, purchaseUpcs: "Please Enter Purchase UPCS" };
        //       });
        //     } else {
        //       setFormError((prev) => {
        //         return { ...prev, purchaseUpcs: " " };
        //       });
        //     }
        //   });
        // }
        if (
          offerBankArray.some(
            (each) =>
              (each.offertype.label === "UPC" &&
                each.purchaseupcs.length === 0) ||
              (each.offertype.label === "Cross Sell" &&
                each.purchaseupcs.length === 0)
          )
        ) {
          setFormError((prev) => {
            return { ...prev, purchaseUpcs: "Please Enter Purchase UPCS" };
          });
        } else {
          setFormError((prev) => {
            return { ...prev, purchaseUpcs: " " };
          });
        }
        if (
          offerBankArray.some(
            (each) =>
              each.offertype.label === "Cross Sell" &&
              each.rewardupcs.length === 0
          )
        ) {
          setFormError((prev) => {
            return { ...prev, rewardupcs: "Please Enter Reward UPCS" };
          });
        } else {
          setFormError((prev) => {
            return { ...prev, rewardupcs: " " };
          });
        }
        // if (
        //   offerBankArray.some(
        //     (each) =>
        //       each.offertype.label === "Cross Sell" &&
        //       each.rewardupcs.length === 0
        //   )
        // ) {
        //   offerBankArray?.map((each, i) => {
        //     if (
        //       ((each.offertype.label === "Cross Sell") &&
        //         each.rewardupcs.length === 0) ||
        //         offerBankArray[i].offertype.label === "Cross Sell"
        //     ) {
        //       setFormError((prev) => {
        //         return { ...prev, rewardupcs: "Please Enter Reward UPCS" };
        //       });
        //     } else {
        //       setFormError((prev) => {
        //         return { ...prev, rewardupcs: "" };
        //       });
        //     }
        //   });
        // }
        if (
          offerBankArray?.some((each) => each.rewardamount === "0") ||
          offerBankArray?.some((each) => each.rewardamount === "")
        ) {
          offerBankArray.map((each, i) => {
            if (each.rewardamount === "0" || each.rewardamount === "") {
              setFormError((prev) => {
                return { ...prev, rewardamount: "Please Enter Reward Amount." };
              });
            }
          });
        } else {
          setFormError((prev) => {
            return { ...prev, rewardamount: "" };
          });
        }
      } else {
        if (offerBankArray.length === 0 && info.rewardOffer === true) {
          setFormError((prev) => {
            return { ...prev, offer: "You must add atleast one reward offer" };
          });
        } else {
          handleStep("next");
        }
      }
    } else {
      if (info.rewardOffer === true) {
        if (offerBankArray.length === 0 && info.rewardOffer === true) {
          setFormError((prev) => {
            return { ...prev, offer: "You must add atleast one reward offer" };
          });
        }
      } else if (info.createPoints == 1 && info.points === "") {
        setFormError((prev) => {
          return { ...prev, offer: "You must enter Points" };
        });
      } else {
        handleStep("next");
      }
    }
  };

  useEffect(() => {
    if (modeDetails !== undefined) {
      if (info.CouponArray) {
        const updatedSingelCouponData = info.CouponArray?.map((each, i) => {
          return {
            ApRuleOfferId: each?.ApRuleOfferId ? each.ApRuleOfferId : 0,
            name: each?.name,
            frequency: each?.frequency,
            offertype: each?.offertype,
            offerstart: each?.offerstart,
            offerdescription: each?.description,
            offerimage: each?.image,
            description: each?.description,
            image: each?.image,
            minamount: each?.minamount,
            minquantity: each?.minquantity,
            reward_min_quantity: each?.reward_min_quantity,
            rewardamount: each?.rewardamount,
            isdiscountpercentage: each?.isdiscountpercentage,
            purchaseupcs: each?.purchaseupcs,
            rewardupcs: each?.rewardupcs,
            stores: each?.stores,
            departments: each?.departments,
            chooseDepartmentsData: each?.chooseDepartmentsData,
            deptData: each?.deptData,
            expirePrevCoupon: each?.ExpirePrevCoupon,
            isdow: each?.isdow,
            coupon_limit: each?.coupon_limit,
            isfeaturecoupon: each?.isfeaturecoupon,
            includeinweeklyemail: each?.includeinweeklyemail,
            validityindays: each?.validityindays,
            coupon_start_time: each?.coupon_start_time,
            coupon_end_time: each?.coupon_end_time,
            sendpushnotification: each?.sendpushnotification,
            message: each?.message,
            pushdaysfrom: each?.pushdaysfrom,
            time: each?.time?.slice(0, 10),
            postfb: each?.postfb,
            fbmessage: each?.fbmessage,
            fbdaysfrom: each?.fbdaysfrom,
            fbtime: each?.fbtime?.slice(0, 10),
            sendsms: each?.sendsms,
            smsmessage: each?.smsmessage,
            smsdaysfrom: each?.smsdaysfrom,
            smstime: each?.smstime?.slice(0, 10),
            sendemail: each?.Sendemail,
            emailmessage: each?.emailmessage,
            emaildaysfrom: each?.emaildaysfrom,
            emailtime: each?.emailtime?.slice(0, 10)
          };
        });
        setCouponArray(updatedSingelCouponData);
      } else {
        if (SingleCouponData.length) {
          const updatedSingelCouponData = SingleCouponData?.map((each, i) => {
            return {
              ApRuleOfferId: each?.ApRuleOfferId,
              name: each?.OfferName,
              frequency: each?.Frequency,
              offertype: each?.OfferTypeName,
              offerstart: each?.OfferStart,
              description: each?.OfferDescription,
              image: each.OfferImageURL,
              minamount: each?.MinAmountRequired,
              minquantity: each?.MinQty,
              reward_min_quantity: each?.RewardMinQty,
              rewardamount: each?.DiscountAmount,
              isdiscountpercentage: each?.IsDiscountPercentage,
              purchaseupcs: each?.PurchaseUPCList,
              rewardupcs: each?.RewardUPCList,
              stores: each?.StoreList,
              departments: each?.DepartmentList ? each?.DepartmentList : [],
              expirePrevCoupon: each?.ExpirePrevCoupon,
              isdow: each?.IsDOW,
              coupon_limit: each?.CouponLimit,
              isfeaturecoupon: each?.IsFeatured,
              includeinweeklyemail: each?.IncludeInWeeklyEmail,
              validityindays: each?.ValidityInDays,
              coupon_start_time: each?.CouponStartTime,
              coupon_end_time: each?.CouponEndTime,
              sendpushnotification: each?.SendPushNotification,
              message: each?.PushNotificationMessageText,
              pushdaysfrom: each?.PushDaysFromOffer,
              time: each?.PushNotificationTime?.slice(0, 10),
              postfb: each?.PostFB,
              fbmessage: each?.FBMsg,
              fbdaysfrom: each?.FBDaysFromOffer,
              fbtime: each?.FBTime?.slice(0, 10),
              sendsms: each?.SendSMS,
              smsmessage: each?.SMSMsg,
              smsdaysfrom: each?.SMSDaysFromOffer,
              smstime: each?.SMSTime?.slice(0, 10),
              sendemail: each?.SendEmail,
              emailmessage: each?.EmailMsg,
              emaildaysfrom: each?.EmailDaysFromOffer,
              emailtime: each?.EmailTime?.slice(0, 10),
              CouponLimitInSingleTrans: each?.CouponLimitInSingleTrans ? {
                label: "In Single Transaction", value: "1"
               } : {
                label: "In Multiple Transaction", value: "0"
               },
               IsMonthly: each?.IsMonthly  
            };
          });
          setOfferBankArray(updatedSingelCouponData);
        }
      }
    }
  }, [modeDetails]);

  const handleSelectInput = (e, name, index) => {
    var couponInput = [...couponArray];
    var couponObject = { ...couponArray[index], [name]: e };
    couponInput.splice(index, 1, couponObject);
    setCouponArray(couponInput);
  };

  var offerInput = [...offerBankArray];
  const handleSelectOfferInput = (e, name, index) => {
    // var offerInput = [...offerBankArray];
    var couponObject = { ...offerBankArray[index], [name]: e };
    offerInput.splice(index, 1, couponObject);
    setOfferBankArray(offerInput);
    offerBankArray[index].departments.length = 0;
  };

  var offerDurationCheck = [...offerBankArray];
  const handleOfferDuration = (e, name, index) => {
    console.log("function", index);
    console.log(e.label, offerDurationCheck[index].validityindays);
    if (parseInt(e.label) >= offerDurationCheck[index].validityindays) {
      console.log("true");
    } else {
      console.log("false");
      offerDurationCheck[index].validityindays = 1;
      offerInput[index].validityindays = 1;
    }
  };

  //set single coupon data to dispaly here --
  const handleCouponChange = (name, i) => {
    const newErrorArray = couponErrorArray.map(
      (eachErrorObject, errorIndex) => {
        if (i === errorIndex) {
          return { ...eachErrorObject, [name]: "" };
        } else {
          return { ...eachErrorObject };
        }
      }
    );

    setCouponErrorArray(newErrorArray);
  };

  const handleCouponUPCDelete = (i, chip) => {
    let FilteredResult = offerBankArray[i].purchaseupcs.filter(
      (each) => each !== chip
    );
    let IndexedCouponObject = offerBankArray[i];
    let filteredCouponObject = {
      ...IndexedCouponObject,
      purchaseupcs: FilteredResult
    };
    let updatedCouponArray = [...offerBankArray];
    updatedCouponArray.splice(i, 1, filteredCouponObject);
    setOfferBankArray(updatedCouponArray);
  };
  const RewardHandleCouponUPCDelete = (i, chip) => {
    let FilteredResult = offerBankArray[i].rewardupcs.filter(
      (each) => each !== chip
    );
    let IndexedCouponObject = offerBankArray[i];
    let filteredCouponObject = {
      ...IndexedCouponObject,
      rewardupcs: FilteredResult
    };
    let updatedCouponArray = [...offerBankArray];
    updatedCouponArray.splice(i, 1, filteredCouponObject);
    setOfferBankArray(updatedCouponArray);
  };

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="Wizard_Content_Card  p-0">
      {offerLoading || departmentLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px"
          }}>
          <Spinner animation="border" variant="primary" sizing={"lg"} />
        </div>
      ) : (
        <React.Fragment>
          <Row className="row g-3">
            <Form.Group>
              <Form.Check
                style={{ paddingLeft: "50px" }}
                inline
                type="checkbox"
                id="offer"
                disabled={mode}
                name="offer"
                label=""
                className="ml-30"
                value={info.rewardOffer}
                checked={
                  (selectedCheckbox === "offer" && info.rewardOffer === true) ||
                  (selectedCheckbox === null && info.rewardOffer)
                }
                // onChange={bankRadio}

                onChange={(e) => {
                  rewardOfferChange(e);
                  rewardOfferChangeTwo(e);
                  handleCheckboxChange(e);
                }}
              />
              <Form.Label style={{}}>
                Would you like to create an Offer based on the rule criteria,
                created in the previous steps?
              </Form.Label>

              {/* <Form.Check
                style={{ paddingLeft: "50px" }}
                inline
                type="checkbox"
                id="givePoints"
                disabled={mode}
                name="givePoints"
                label="Give points"
                className="mr-110 ml-30"
                value={info.createPoints}
                checked={
                  info.createPoints == 1 && selectedCheckbox == "givePoints"
                }
                onChange={(e) => {
                  handleCheckboxChange(e);
                  handleGivePointsChange(e);
                }}
              /> */}
            </Form.Group>
            <Form.Group className="ml-60 mt-30">
              {info.rewardOffer && offerCheckBox && (
                <Button
                  className="BlueButton mr-20"
                  variant="primary"
                  disabled={mode}
                  onClick={handleButtonClickOfferBank}>
                  Copy From Offer Bank
                </Button>
              )}
              {info.rewardOffer && offerCheckBox && (
                <>
                  <Button
                    className="BlueButton mr-20"
                    variant="primary"
                    disabled={mode}
                    onClick={handleButtonClickBuildOffer}>
                    Build Offer
                  </Button>
                  <h5
                    className="d-flex flex-row justify-content-end mr-80 mb-10"
                    style={{ marginTop: "-35px" }}>
                    *Rule runs every {`${info.frequency.label}`} Day(s)
                  </h5>
                </>
              )}

              {/* <Form.Check
                style={{ paddingLeft: "130px" }}
                inline
                type="radio"
                disabled={mode}
                name="offer"
                label="Copy From Offer Bank"
                className="mr-110"
                value={info.bankOffer}
                checked={info.bankOffer === true}
                // onChange={bankRadio}
                onChange={(e) => {
                  bankRadio(e);
                }}
              />

              {validation.includes("suggested offer") && (
                <Form.Check
                  inline
                  type="radio"
                  name="offer"
                  disabled={mode}
                  label="Suggested Offer"
                  className="mr-110"
                  value={info.suggestOffer}
                  checked={info.suggestOffer === true}
                  onChange={suggestRadio}
                />
              )}

              <Form.Check
                inline
                type="radio"
                name="offer"
                label="Build Offer"
                className="mr-110"
                disabled={mode}
                value={info.createOffer}
                checked={info.createOffer === true}
                // onChange={radioChange}
                onChange={(e) => {
                  radioChange(e);
                }}
              /> */}
              {selectedCheckbox == "givePoints" && (
                <Form.Control
                  type="number"
                  min={0}
                  step={0.1}
                  disabled={mode}
                  placeholder="Enter points here"
                  style={{ width: "200px", marginLeft: "340px" }}
                  onKeyDown={(e) => {
                    givePointsDecimal(e);
                  }}
                  value={info.points}
                  onChange={(e) => {
                    handleInput(e.target.value, "points");
                    setFormError((prev) => {
                      return { ...prev, offer: "" };
                    });
                  }}
                // onWheel={(e) => e.target.blur()}
                />
              )}
              {offerBankArray?.slice(0, 1).map((each, i) => (
                <div className=" mt-35">
                  <Form.Check
                    type="checkbox"
                    label="Expire Previous One On Run. "
                    //id={`cbExpirePrev+${i}`}
                    name="expirePrevCoupon"
                    disabled={mode || disableField || info.createGroups}
                    checked={info.expirePrevCoupon}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleExpirePrevCoupon(e)}
                  />

                  <p>
                    <span style={{ color: "red" }}>Note:</span> Previously
                    issued active coupons for this rule will be expired, before
                    issuing a new coupons.
                  </p>
                </div>
              ))}
            </Form.Group>

            {formError.offer && (
              <p
                style={{
                  color: "red",
                  paddingLeft: "155px",
                  fontWeight: "bold"
                }}
                className="error-message">
                {formError.offer}
              </p>
            )}

            {info.rewardOffer && (
              <>
                <BankOfferPopup
                  show={bankOfferShow}
                  onHide={() => setBankofferShow(false)}
                  bankofferdata={bankOfferData}
                  couponarray={couponArray}
                  setcouponarray={setCouponArray}
                  suggestedCouponArray={suggestedCouponArray}
                  setSuggestedCouponArray={setSuggestedCouponArray}
                  setChecked={setChecked}
                  checked={checked}
                />
                {offerBankArray.map((each, i) => (
                  <Form
                    key={i}
                    style={{
                      borderBottom: "3px solid #3d8ecc",
                      borderTop: "3px solid #3d8ecc",
                      borderRadius: "12px",
                      marginBottom: "10px",
                      paddingTop: "20px",
                      paddingBottom: "20px"
                    }}>
                    <div className="p-17">
                      <Row className="row g-3">
                        {editMode && (
                          <span
                            style={{ color: "red", gap: "3px", margin: "0px" }}>
                            This changes will take effect for all upcoming rule
                            runs - already created groups and coupon will not
                            change.
                          </span>
                        )}
                        <Col xs={3}>
                          <Form.Group>
                            <Form.Label>Offer Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Offer Title"
                              name="name"
                              disabled={
                                mode ||
                                disableField ||
                                info.createGroups ||
                                each.ApRuleOfferId > 0
                              }
                              value={each?.name}
                              onChange={(e) => {
                                handleCouponOfferBank(
                                  e.target.value,
                                  "name",
                                  i
                                );
                              }}
                            />
                          </Form.Group>
                          {formError?.coupon && !each?.name && (
                            <p
                              style={{ color: "red" }}
                              className="error-message">
                              {formError?.coupon}
                            </p>
                          )}
                        </Col>
                        <Col xs={3}>
                          <Form.Group>
                            <Form.Label>Offer Type</Form.Label>
                            <Select
                              name="offertype"
                              value={each?.offertype}
                              placeholder={"Please select offer type..."}
                              options={offerDropdown}
                              isDisabled={
                                mode ||
                                disableField ||
                                info.createGroups ||
                                each.ApRuleOfferId > 0
                              }
                              className="react-select-container select"
                              onChange={(e) => {
                                handleSelectOfferInput(e, "offertype", i);
                              }}
                            />
                          </Form.Group>
                          {formError?.offerType && !each?.offertype.value && (
                            <p
                              style={{ color: "red" }}
                              className="error-message">
                              {formError?.offerType}
                            </p>
                          )}
                        </Col>
                        <Col xs={3}>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Offer Frequency</Form.Label>

                            {/* <Link
                              to=""
                              title="Typically, this should match rule frequency. But at times offer frequency can be different from rule frequency. 
                            For example, the rule runs every month, but you want to give 4 same coupons during the month (one offer every week). In that scenario rule frequency is set to Monthly, and offer frequency is set to: Weekly. "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-info-circle-fill mr-10 ml-2"
                                viewBox="0 0 16 16"
                                title="Next 5 Rule Run Dates"
                              >
                                {" "}
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />{" "}
                              </svg>
                            </Link> */}
                            <Row>
                              <Col lg={2}>
                                <p
                                  style={{
                                    marginTop: "12px",
                                    fontWeight: "500"
                                  }}>
                                  Every
                                </p>
                              </Col>
                              <Col lg={7}>
                                <Select
                                  name="frequency"
                                  value={each?.frequency}
                                  //placeholder={"Please select frequency..."}
                                  options={frequencyDropdown}
                                  isDisabled={mode}
                                  className="react-select-container select"
                                  onChange={(e) => {
                                    handleSelectOfferInput(e, "frequency", i);
                                    handleOfferDuration(e, "frequency", i);
                                  }}
                                />
                              </Col>
                              <Col lg={3}>
                                <p
                                  style={{
                                    marginLeft: "-17px",
                                    marginTop: "12px",
                                    fontWeight: "500"
                                  }}>
                                  Day(s)
                                </p>
                              </Col>
                            </Row>
                          </Form.Group>

                          {formError?.frequency && !each?.frequency.value && (
                            <p
                              style={{ color: "red" }}
                              className="error-message">
                              {formError?.frequency}
                            </p>
                          )}
                        </Col>
                        <Col xs={3}>
                          <Form.Group>
                            <Form.Label>Offer Starts In Days</Form.Label>
                            <Form.Control
                              type="number"
                              name="offerstart"
                              disabled={
                                mode || disableField || info.createGroups
                              }
                              value={each?.offerstart}
                              min="0"
                              max="10"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                handleCouponOfferBank &&
                                  handleCouponDateChange(
                                    e.target.value,
                                    "offerstart",
                                    i
                                  )(e.target.value, "couponStartDate", i);
                              }}
                              onKeyDown={blockInvalidChar}
                            />
                          </Form.Group>
                          {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-info-circle-fill mr-5 ml-2"
                              viewBox="0 0 16 16"
                              title="Next 5 Rule Run Dates">
                              {" "}
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />{" "}
                            </svg> */}
                          <span
                            style={{ fontSize: "10px", fontStyle: "italic" }}>
                            <span style={{ color: "red" }}>
                              First coupon startdate:
                            </span>
                            {each?.couponStartDate
                              ? each?.couponStartDate
                              : each?.couponStartDate === undefined
                                ? displayDate(each?.offerstart, i)
                                : getRuleDate(info.startDate)}

                            {", "}
                            {each.couponStartDate == ""
                              ? couponstartsDay
                              : each?.couponStartDay}
                          </span>
                        </Col>
                        <Col lg={6} xs={12}>
                          <Form.Group>
                            <Form.Label>Offer Descriptions</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              disabled={mode || each.ApRuleOfferId > 0}
                              placeholder="Enter Offer Descriptions... "
                              value={each?.description}
                              name="description"
                              onChange={(e) => {
                                handleCouponOfferBank(
                                  e.target.value,
                                  "description",
                                  i
                                );
                              }}
                              z
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6} xs={12}>
                          <Form.Group>
                            <Form.Label>Offer Image</Form.Label>
                            <Form.Control
                              type="file"
                              id="file"
                              className="image-select"
                              name="image"
                              disabled={mode}
                              style={{ height: "61px" }}
                              onChange={(e) => offerUploadImage(e, i, "image")}
                            />
                          </Form.Group>
                          {formError?.image && !each?.image && (
                            <p
                              style={{ color: "red" }}
                              className="error-message">
                              {formError?.image}
                            </p>
                          )}
                        </Col>

                        {each?.offertype?.label !== "Basket" ? (
                          <Col xl={2} lg={3} xs={12}>
                            <Form.Group>
                              <Form.Label>Coupon Limit</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Coupon Limit"
                                value={each?.coupon_limit}
                                disabled={
                                  mode ||
                                  couponLimitDisable ||
                                  info.createGroups ||
                                  basketDisable ||
                                  each?.offertype?.label === "Basket" ||
                                  each.ApRuleOfferId > 0
                                }
                                name="coupon_limit"
                                min={1}
                                max={10}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) =>
                                  handleCouponOfferBank(
                                    e.target.value,
                                    "coupon_limit",
                                    i
                                  )
                                }
                                onKeyDown={blockInvalidChar}
                              />
                            </Form.Group>
                          </Col>

                        ) : (
                          ""
                        )}
                        {each?.offertype?.label !== "Basket" ? (
                          <div className="col-xl-4 col-md-4 col-sm-12 col-12">
                            <div className="position-relative">
                              <label className="form-label">Coupon Limit Applicable</label>
                              <div>
                                <Select
                                  name="CouponLimitInSingleTrans"
                                  value={each?.CouponLimitInSingleTrans}
                                  options={CONSTANT_VALUES.couponLimtAvailable}
                                  defaultValue={{
                                    label: "In Multiple Transaction", value: "0"
                                  }}
                                  disabled={
                                    mode ||
                                    couponLimitDisable ||
                                    info.createGroups ||
                                    basketDisable ||
                                    each?.offertype?.label === "Basket" ||
                                    each.ApRuleOfferId > 0
                                  }
                                  className="react-select-container select"
                                  onChange={(e) =>
                                    handleSelectOfferInput(e, "CouponLimitInSingleTrans", i)
                                  }
                                >
                                </Select>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <Col lg={6} xs={12}>
                          <Form.Group>
                            <Form.Label style={{ textAlign: "center" }}>
                              Image Preview
                            </Form.Label>
                            {/* <Image src={  each?.image}  className="offerImage"/> */}
                            <Image
                              src={
                                each?.image ||
                                (baseImage !== "" && each?.image !== ""
                                  ? baseImage
                                  : "")
                              }
                              disabled={each?.image !== ""}
                              style={{
                                height: "142px",
                                border: "1px solid black"
                              }}
                              className="offerImage"
                            />
                          </Form.Group>
                        </Col>
                        {console.log("freqency", each?.frequency)}
                        {each?.offertype?.label === "Basket" ? (
                          <Col lg={6} xs={12}>
                            <Form.Group>
                              <Form.Label>Offer Duration</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter duration..."
                                name="validityindays"
                                disabled={
                                  mode ||
                                    disableField ||
                                    info.createGroups ||
                                    each?.frequency.value === ""
                                    ? true
                                    : false
                                }
                                value={each?.validityindays}
                                min={1}
                                max={each?.frequency.label}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={blockInvalidChar}
                                onChange={(e) =>
                                  handleCouponOfferBank(
                                    e.target.value,
                                    "validityindays",
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                            <p style={{ color: "red", fontStyle: "italic" }}>
                              Offer duration can be less than or equal to offer
                              frequency.
                            </p>
                          </Col>
                        ) : (
                          <Col xl={4} lg={6} xs={12}>
                            {console.log("fre", each?.frequency)}
                            <Form.Group>
                              <Form.Label>Offer Duration (Days)</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter duration..."
                                name="validityindays"
                                disabled={
                                  mode ||
                                    disableField ||
                                    info.createGroups ||
                                    each?.frequency.value === ""
                                    ? true
                                    : false
                                }
                                value={each?.validityindays}
                                min={1}
                                max={each?.frequency.label}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={blockInvalidChar}
                                onChange={(e) =>
                                  handleCouponOfferBank(
                                    e.target.value,
                                    "validityindays",
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                            <p style={{ color: "red", fontStyle: "italic", fontSize: "12px" }}>
                              Offer duration Should be less than or equal to offer
                              frequency.
                            </p>
                          </Col>
                        )}


                        {each?.validityindays === "1" && (
                          <>
                            <Col lg={6} xs={12}>
                              <Form.Group>
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control
                                  type="time"
                                  value={
                                    each?.coupon_start_time
                                      ? each?.coupon_start_time.slice(0, 5)
                                      : "00:00"
                                  }
                                  disabled={mode || each.ApRuleOfferId > 0}
                                  name="coupon_start_time"
                                  onChange={(e) =>
                                    handleCouponOfferBank(
                                      e.target.value,
                                      "coupon_start_time",
                                      i
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} xs={12}>
                              <Form.Group>
                                <Form.Label>End Time</Form.Label>
                                <Form.Control
                                  type="time"
                                  value={
                                    each?.coupon_end_time
                                      ? each?.coupon_end_time
                                        .slice(0, 5)
                                        .toString()
                                      : "23:59"
                                  }
                                  disabled={mode || each.ApRuleOfferId > 0}
                                  name="coupon_end_time"
                                  onChange={(e) =>
                                    handleCouponOfferBank(
                                      e.target.value,
                                      "coupon_end_time",
                                      i
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </>
                        )}
                        {each?.offertype?.label === "Cross Sell" ? (
                          <Col lg={6} xs={12} style={{ marginTop: "-81px" }}>
                            <Form.Group>
                              <Form.Label>Reward Quantity</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Reward Quantity"
                                value={each?.reward_min_quantity}
                                disabled={
                                  mode ||
                                  basketDisable ||
                                  each?.offertype?.label === "Basket" ||
                                  each.ApRuleOfferId > 0
                                }
                                name="reward_min_quantity"
                                min={1}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={blockInvalidChar}
                                onChange={(e) =>
                                  handleCouponOfferBank(
                                    e.target.value,
                                    "reward_min_quantity",
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        ) : each?.offertype?.label !== "UPC" ? (
                          <Col xl={4} lg={6} xs={12}>
                            <Form.Group>
                              <Form.Label>Minimum Purchase Amount</Form.Label>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="grey"
                                className="bi bi-currency-dollar icon"
                                viewBox="0 0 16 16"
                                style={{ marginLeft: "0" }}>
                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                              </svg>

                              <Form.Control
                                type="number"
                                step={0.1}
                                placeholder="Minimum Amount in $"
                                value={each?.minamount}
                                // style={{textAlign:"center"}}
                                disabled={
                                  mode ||
                                  upcDisable ||
                                  each?.offertype?.label === "UPC" ||
                                  each?.offertype?.label === "Cross Sell" ||
                                  each.ApRuleOfferId > 0
                                }
                                name="minamount"
                                min={0}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => {
                                  decimalChange(e);
                                  //blockInvalidCharMin(e);
                                }}
                                onChange={(e) =>
                                  handleCouponOfferBank(
                                    e.target.value,
                                    "minamount",
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        ) : (
                          ""
                        )}

                        {each?.offertype?.label === "Basket" ? (
                          <Col xl={4} lg={6} xs={12}>
                            <Form.Group>
                              <Form.Label>Customer Savings</Form.Label>
                              {each?.isdiscountpercentage === true ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="grey"
                                  className="bi bi-percent icon"
                                  viewBox="0 0 16 16"
                                  style={{ marginLeft: "240px" }}>
                                  <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="grey"
                                  className="bi bi-currency-dollar icon"
                                  viewBox="0 0 16 16"
                                  style={{ marginLeft: "240px" }}>
                                  <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                </svg>
                              )}

                              <Form.Control
                                type="number"
                                placeholder="Reward Amount"
                                value={each?.rewardamount}
                                name="rewardamount"
                                step={0.01}
                                min={0}
                                disabled={
                                  mode ||
                                  disableField ||
                                  info.createGroups ||
                                  each.ApRuleOfferId > 0
                                }
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => {
                                  decimalChange(e);
                                  //blockInvalidCharMin(e);
                                }}
                                onChange={(e) =>
                                  handleCouponOfferBank(
                                    e.target.value,
                                    "rewardamount",
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                            {/* {
                            info.createOffer === true &&
                            formError?.rewardamount && !each.rewardamount && (
                              <p style={{color:"red"}}>{formError?.rewardamount}</p>
                            )
                          } */}
                            {/* { info.rewardOffer === true && info.createOffer === false &&
                            formError?.rewardamount &&  each.rewardamount && (
                              <p style={{color:"red"}}>{formError?.rewardamount}</p>
                            )
                          } */}
                            {each?.rewardamount == 0
                              ? formError?.rewardamount &&
                              each.rewardamount && (
                                <p
                                  style={{ color: "red" }}
                                  className="error-message">
                                  {formError?.rewardamount}
                                </p>
                              )
                              : formError?.rewardamount &&
                              !each.rewardamount && (
                                <p
                                  style={{ color: "red" }}
                                  className="error-message">
                                  {formError?.rewardamount}
                                </p>
                              )}
                          </Col>
                        ) : (
                          ""
                        )}

                        {/* <Row className="mt-20"> */}
                        {each?.offertype?.label !== "Basket" ? (
                          <Col xl={4} lg={6} xs={12}>
                            <Form.Group>
                              <Form.Label>
                                Minimum Purchase Quantity
                              </Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Minimum Quantity"
                                value={each?.minquantity}
                                disabled={
                                  mode ||
                                  basketDisable ||
                                  each?.offertype?.label === "Basket" ||
                                  each.ApRuleOfferId > 0
                                }
                                name="minquantity"
                                min={1}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={blockInvalidChar}
                                onChange={(e) =>
                                  handleCouponOfferBank(
                                    e.target.value,
                                    "minquantity",
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        ) : (
                          ""
                        )}

                        {each?.offertype?.label === "UPC" ||
                          each?.offertype?.label === "Cross Sell" ? (
                          <Col lg={6} xs={12}>
                            <Form.Group>
                              <Form.Label>Customer Savings</Form.Label>
                              {each?.isdiscountpercentage === true ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="grey"
                                  className="bi bi-percent icon"
                                  viewBox="0 0 16 16"
                                  style={{ marginLeft: "220px" }}>
                                  <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="grey"
                                  className="bi bi-currency-dollar icon"
                                  viewBox="0 0 16 16"
                                  style={{ marginLeft: "220px" }}>
                                  <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                </svg>
                              )}

                              <Form.Control
                                type="number"
                                placeholder="Reward Amount"
                                value={each?.rewardamount}
                                name="rewardamount"
                                step={0.01}
                                min={0}
                                disabled={
                                  mode ||
                                  disableField ||
                                  info.createGroups ||
                                  each.ApRuleOfferId > 0
                                }
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => {
                                  decimalChange(e);
                                  //blockInvalidCharMin(e);
                                }}
                                onChange={(e) =>
                                  handleCouponOfferBank(
                                    e.target.value,
                                    "rewardamount",
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                            {/* {
                            info.createOffer === true &&
                            formError?.rewardamount && !each.rewardamount && (
                              <p style={{color:"red"}}>{formError?.rewardamount}</p>
                            )
                          } */}
                            {/* { info.rewardOffer === true && info.createOffer === false &&
                            formError?.rewardamount &&  each.rewardamount && (
                              <p style={{color:"red"}}>{formError?.rewardamount}</p>
                            )
                          } */}
                            {each?.rewardamount == 0
                              ? formError?.rewardamount &&
                              each.rewardamount && (
                                <p
                                  style={{ color: "red" }}
                                  className="error-message">
                                  {formError?.rewardamount}
                                </p>
                              )
                              : formError?.rewardamount &&
                              !each.rewardamount && (
                                <p
                                  style={{ color: "red" }}
                                  className="error-message">
                                  {formError?.rewardamount}
                                </p>
                              )}
                          </Col>
                        ) : (
                          ""
                        )}
                        {/* </Row> */}

                        {/* <Col lg={4} xs={12}>
                          <Form.Group>
                            <Form.Label>Minimum Purchase Quantity</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Minimum Quantity"
                              value={each?.minquantity}
                              disabled={
                                mode ||
                                basketDisable ||
                                each?.offertype?.label === "Basket" ||
                                each.ApRuleOfferId > 0
                              }
                              name="minquantity"
                              min={1}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              onChange={(e) =>
                                handleCouponOfferBank(
                                  e.target.value,
                                  "minquantity",
                                  i
                                )
                              }
                            />
                          </Form.Group>
                        </Col> */}

                        <Col xs={12}>
                          <Form.Check
                            type="checkbox"
                            className="Discount_Percentage"
                            label="Is Discount Percentage?"
                            id="custom-switch"
                            name="isdiscountpercentage"
                            disabled={
                              mode ||
                              disableField ||
                              info.createGroups ||
                              each.ApRuleOfferId > 0
                            }
                            checked={each?.isdiscountpercentage}
                            onChange={(e) =>
                              handleCouponCheckBoxOfferBank(e, i)
                            }
                          />
                        </Col>

                        {each?.offertype?.label === "Basket" &&
                          (info?.departments.length > 0 ||
                            info?.departments) && (
                            <Col xs={12}>
                              <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Exclude Departments</Form.Label>
                                <Select
                                  isMulti
                                  options={deptData}
                                  value={offerBankArray[i].departments}
                                  isDisabled={
                                    mode ||
                                    disableField ||
                                    info.createGroups ||
                                    upcDisable ||
                                    each.ApRuleOfferId > 0
                                    // each?.offertype?.label === "UPC" ||
                                    // each?.offertype?.label === "Cross Sell"
                                  }
                                  className="react-select-container select"
                                  onChange={(e) => {
                                    handleStep4deptOfferbank(
                                      e,
                                      "departments",
                                      i
                                    );
                                    handleChange("departments");
                                  }}
                                />

                                <div className="p-17 mt-10">
                                  <div className="row g-3 mb-20">
                                    <div className="col-12"></div>
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                          )}

                        {/* {} */}
                        {each?.offertype?.label === "Basket" ? (
                          ""
                        ) : (
                          <Col lg={6} xs={12}>
                            <fieldset
                              disabled={
                                mode ||
                                basketDisable ||
                                each?.offertype?.label === "Basket" ||
                                each.ApRuleOfferId > 0
                              }>
                              <Form.Group>
                                <Form.Label className="mt-30">
                                  Purchase UPCs{" "}
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      color: "#3D8ECC"
                                    }}>
                                    (enter below or paste from excel column and
                                    click enter)
                                  </span>
                                </Form.Label>

                                <PurchaseUpcsMultiple
                                  mode={mode || each.ApRuleOfferId > 0}
                                  eachCouponPurchaseChips={each?.purchaseupcs}
                                  // couponArray={couponArray}
                                  // setCouponArray={setCouponArray}
                                  couponArray={offerBankArray}
                                  setCouponArray={setOfferBankArray}
                                  index={i}
                                  setCouponErrorArray={setFormError}
                                  couponErrorArray={formError}
                                  handleCouponUPCDelete={handleCouponUPCDelete}
                                  SingleCouponData={SingleCouponData}
                                />
                              </Form.Group>
                              {formError?.purchaseUpcs &&
                                !each?.purchaseupcs.length &&
                                (each?.offertype?.label === "UPC" ||
                                  each?.offertype?.label === "Cross Sell") && (
                                  <p
                                    style={{ color: "red" }}
                                    className="error-message">
                                    {formError?.purchaseUpcs}
                                  </p>
                                )}
                            </fieldset>
                          </Col>
                        )}

                        {each?.offertype?.label === "Basket" ||
                          each?.offertype?.label === "UPC" ? (
                          ""
                        ) : (
                          <Col lg={6} xs={12}>
                            <fieldset
                              disabled={
                                mode ||
                                basketDisable ||
                                upcDisable ||
                                each?.offertype?.label === "Basket" ||
                                each?.offertype?.label === "UPC" ||
                                each.ApRuleOfferId > 0
                              }>
                              <Form.Group>
                                <Form.Label className="mt-30">
                                  Reward UPCs{" "}
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      color: "#3D8ECC"
                                    }}>
                                    (enter below or paste from excel column and
                                    click enter)
                                  </span>
                                </Form.Label>

                                <RewardUpcsMutliple
                                  mode={mode || each.ApRuleOfferId > 0}
                                  eachCouponRewardChips={each?.rewardupcs}
                                  couponArray={offerBankArray}
                                  setCouponArray={setOfferBankArray}
                                  index={i}
                                  setCouponErrorArray={setFormError}
                                  couponErrorArray={formError}
                                  RewardHandleCouponUPCDelete={
                                    RewardHandleCouponUPCDelete
                                  }
                                  SingleCouponData={SingleCouponData}
                                />
                              </Form.Group>

                              {formError?.rewardupcs &&
                                !each?.rewardupcs.length &&
                                each?.offertype?.label === "Cross Sell" && (
                                  <p
                                    style={{ color: "red" }}
                                    className="error-message">
                                    {formError?.rewardupcs}
                                  </p>
                                )}
                            </fieldset>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <hr className="m-0"></hr>
                    <div className="p-17">
                      <div className="row g-3 mb-20">
                        <div className="col-12">
                          {/* <Form.Check
                            type="checkbox"
                            label="Expire Previous One On Run. "
                            id={`cbExpirePrev+${i}`}
                            name="expirePrevCoupon"
                            disabled={mode || disableField || info.createGroups}
                            checked={each?.expirePrevCoupon}
                            onChange={(e) =>
                              handleCouponCheckBoxOfferBank(e, i)
                            }
                          />

                          <p>
                            <span style={{ color: "red" }}>Note:</span>{" "}
                            Previously issued active coupons for this rule will
                            be expired, before issuing a new coupons.
                          </p> */}
                        </div>
                      </div>
                      <h5 style={{ fontSize: "16px" }}>Additional Settings</h5>
                      <div className="Additional_Setting mt-11">
                        <div className="p-20">
                          <Form.Check
                            inline
                            disabled={mode || dowDisabled || info.createGroups}
                            type="checkbox"
                            label="Make It DOW"
                            id={`cbDOW+${i}`}
                            className="mr-29"
                            name="isdow"
                            checked={each?.isdow}
                            onChange={(e) =>
                              handleCouponCheckBoxOfferBank(e, i)
                            }
                          />
                          <Form.Check
                            inline
                            disabled={
                              mode || featuredDisabled || info.createGroups
                            }
                            type="checkbox"
                            label="Make It Featured"
                            id={`cbFeatured+${i}`}
                            className="mr-29"
                            name="isfeaturecoupon"
                            checked={each?.isfeaturecoupon}
                            onChange={(e) =>
                              handleCouponCheckBoxOfferBank(e, i)
                            }
                          />
                          <Form.Check
                            inline
                            disabled={
                              mode || featuredDisabled || info.createGroups
                            }
                            type="checkbox"
                            label="Monthly Deal"
                            id={`cbFeatured+${i}`}
                            className="mr-29"
                            name="IsMonthly"
                            checked={each?.IsMonthly}
                            onChange={(e) =>
                              handleCouponCheckBoxOfferBank(e, i)
                            }
                          />
                          {/* <Form.Check
                            inline
                            disabled={
                              mode || weeklyDisabled || info.createGroups
                            }
                            type="checkbox"
                            label="Include In Weekly Email"
                            id={`weeklyEmail+${i}`}
                            className="mr-29"
                            name="includeinweeklyemail"
                            checked={each?.includeinweeklyemail}
                            onChange={(e) =>
                              handleCouponCheckBoxOfferBank(e, i)
                            }
                          /> */}
                        </div>
                        <hr className="m-0"></hr>
                      </div>
                      {(validation.includes("push notification") ||
                        validation.includes("facebook") ||
                        validation.includes("sms") ||
                        validation.includes("email")) && (
                          <div className="Additional_Setting mt-11">
                            <div className="p-20">
                              {validation.includes("push notification") && (
                                <Form.Check
                                  inline
                                  disabled={mode}
                                  type="checkbox"
                                  label="Send Push Notification"
                                  id={`cbPushNotification+${i}`}
                                  className="mr-29"
                                  name="sendpushnotification"
                                  checked={each?.sendpushnotification}
                                  onChange={(e) =>
                                    handleCouponCheckBoxOfferBank(e, i)
                                  }
                                />
                              )}
                              {validation.includes("facebook") && (
                                <Form.Check
                                  inline
                                  disabled={mode}
                                  type="checkbox"
                                  label="Post to Facebook"
                                  id={`cbPostToFacebook+${i}`}
                                  className="mr-29"
                                  name="postfb"
                                  checked={each?.postfb}
                                  onChange={(e) =>
                                    handleCouponCheckBoxOfferBank(e, i)
                                  }
                                />
                              )}
                              {validation.includes("sms") && (
                                <Form.Check
                                  inline
                                  disabled={mode}
                                  type="checkbox"
                                  label="Send SMS"
                                  id={`cbSendSMS+${i}`}
                                  className="mr-29"
                                  name="sendsms"
                                  checked={each?.sendsms}
                                  onChange={(e) =>
                                    handleCouponCheckBoxOfferBank(e, i)
                                  }
                                />
                              )}
                              {validation.includes("email") && (
                                <Form.Check
                                  inline
                                  disabled={mode}
                                  type="checkbox"
                                  label="Send Email"
                                  id={`cbSendEmail+${i}`}
                                  className="mr-29"
                                  name="sendemail"
                                  checked={each?.sendemail}
                                  onChange={(e) =>
                                    handleCouponCheckBoxOfferBank(e, i)
                                  }
                                />
                              )}
                            </div>

                            <hr className="m-0"></hr>
                            {each?.sendpushnotification ? (
                              <div className="p-20">
                                <Row className="row g-3">
                                  <Col xs={12} lg={6}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <Form.Label>
                                        Enter Push Notification Message Below
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={2}
                                        disabled={mode}
                                        placeholder="Enter Push Notification Message"
                                        value={each?.message}
                                        name="message"
                                        onChange={(e) => {
                                          handleCouponOfferBank(
                                            e.target.value,
                                            "message",
                                            i
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                    {formError?.pushNotification &&
                                      !each?.message && (
                                        <p
                                          style={{ color: "red" }}
                                          className="error-message">
                                          {formError?.pushNotification}
                                        </p>
                                      )}
                                  </Col>
                                  {validation.includes("push notification") && (
                                    <Col xs={12} lg={3}>
                                      <Form.Group>
                                        <Form.Label>
                                          Days from Offer Start
                                        </Form.Label>
                                        <Link
                                          to=""
                                          title="Value entered here:1 Means push notification will be sent 1 day after offer is created.
                                              0 Means-push notification will be sent on the same day offer is created.">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="black"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-help-circle ml-10 mb-4">
                                            <circle
                                              cx="12"
                                              cy="12"
                                              r="10"></circle>
                                            <path
                                              d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
                                              fill="current"></path>
                                            <line
                                              x1="12"
                                              y1="17"
                                              x2="12.01"
                                              y2="17"></line>
                                          </svg>
                                        </Link>
                                        <Form.Control
                                          type="number"
                                          placeholder="Enter Days from Offer Start"
                                          name="pushdaysfrom"
                                          disabled={mode}
                                          value={each?.pushdaysfrom}
                                          min={0}
                                          onWheel={(e) => e.target.blur()}
                                          onKeyDown={blockInvalidChar}
                                          onChange={(e) =>
                                            handleCouponOfferBank(
                                              e.target.value,
                                              "pushdaysfrom",
                                              i
                                            )
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                  )}

                                  <Col lg={3} xs={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <Form.Label>
                                        Select Notification Time
                                      </Form.Label>
                                      <Form.Control
                                        type="time"
                                        id="selectTime"
                                        value={each?.time}
                                        name="time"
                                        disabled={mode}
                                        onChange={(e) => {
                                          handleCouponOfferBank(
                                            e.target.value,
                                            "time",
                                            i
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              ""
                            )}

                            {each?.postfb ? (
                              <div className="p-20">
                                <Row className="row g-3">
                                  <Col lg={6} xs={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <Form.Label>
                                        Enter FaceBook Message Below
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={2}
                                        disabled={mode}
                                        placeholder="Enter FaceBook Message"
                                        value={each?.fbmessage}
                                        name="fbmessage"
                                        onChange={(e) => {
                                          handleCouponOfferBank(
                                            e.target.value,
                                            "fbmessage",
                                            i
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  {validation.includes("facebook") && (
                                    <Col xs={12} lg={3}>
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>
                                          Days from Offer Start
                                        </Form.Label>
                                        <Link
                                          to=""
                                          title="Value entered here:1 Means push FaceBook Message will be sent 1 day after offer is created.
                                              0 Means-FaceBook Message will be sent on the same day offer is created.">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="black"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-help-circle ml-10 mb-4">
                                            <circle
                                              cx="12"
                                              cy="12"
                                              r="10"></circle>
                                            <path
                                              d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
                                              fill="current"></path>
                                            <line
                                              x1="12"
                                              y1="17"
                                              x2="12.01"
                                              y2="17"></line>
                                          </svg>
                                        </Link>
                                        <Form.Control
                                          type="number"
                                          disabled={mode}
                                          placeholder="Enter Days..."
                                          value={each?.fbdaysfrom}
                                          name="fbdaysfrom"
                                          min={0}
                                          onWheel={(e) => e.target.blur()}
                                          onKeyDown={blockInvalidChar}
                                          onChange={(e) => {
                                            handleCouponOfferBank(
                                              e.target.value,
                                              "fbdaysfrom",
                                              i
                                            );
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                  )}

                                  <Col lg={3} xs={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <Form.Label>
                                        Select FaceBook Time
                                      </Form.Label>
                                      <Form.Control
                                        type="time"
                                        value={each?.fbtime}
                                        name="fbtime"
                                        disabled={mode}
                                        onChange={(e) => {
                                          handleCouponOfferBank(
                                            e.target.value,
                                            "fbtime",
                                            i
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                            {each.sendsms ? (
                              <div className="p-20">
                                <Row className="row g-3">
                                  <Col xs={12} lg={6}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <Form.Label>
                                        Enter SMS Message Below
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={2}
                                        disabled={mode}
                                        placeholder="Enter SMS Message"
                                        value={each?.smsmessage}
                                        name="smsmessage"
                                        onChange={(e) => {
                                          handleCouponOfferBank(
                                            e.target.value,
                                            "smsmessage",
                                            i
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  {validation.includes("sms") && (
                                    <Col xs={12} lg={3}>
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>
                                          Days from Offer Start
                                        </Form.Label>
                                        <Link
                                          to=""
                                          title="Value entered here:1 Means SMS Message will be sent 1 day after offer is created.
                                              0 Means-SMS Message will be sent on the same day offer is created.">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="black"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-help-circle ml-10 mb-4">
                                            <circle
                                              cx="12"
                                              cy="12"
                                              r="10"></circle>
                                            <path
                                              d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
                                              fill="current"></path>
                                            <line
                                              x1="12"
                                              y1="17"
                                              x2="12.01"
                                              y2="17"></line>
                                          </svg>
                                        </Link>
                                        <Form.Control
                                          type="number"
                                          disabled={mode}
                                          placeholder="Enter Days..."
                                          value={each?.smsdaysfrom}
                                          name="smsdaysfrom"
                                          onWheel={(e) => e.target.blur()}
                                          min={0}
                                          onKeyDown={blockInvalidChar}
                                          onChange={(e) => {
                                            handleCouponOfferBank(
                                              e.target.value,
                                              "smsdaysfrom",
                                              i
                                            );
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                  )}

                                  <Col lg={3} xs={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <Form.Label>Select SMS Time</Form.Label>
                                      <Form.Control
                                        type="time"
                                        value={each?.smstime}
                                        name="smstime"
                                        disabled={mode}
                                        onChange={(e) => {
                                          handleCouponOfferBank(
                                            e.target.value,
                                            "smstime",
                                            i
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                            {each?.sendemail ? (
                              <div className="p-20">
                                <Row className="row g-3">
                                  <Col xs={12} lg={6}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <Form.Label>
                                        Enter Email Message Below
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={2}
                                        disabled={mode}
                                        placeholder="Enter Email Message"
                                        value={each?.emailmessage}
                                        name={"emailmessage"}
                                        onChange={(e) =>
                                          handleCouponOfferBank(
                                            e.target.value,
                                            "emailmessage",
                                            i
                                          )
                                        }
                                      />
                                    </Form.Group>
                                    {formError?.emailmessage &&
                                      !each?.emailmessage && (
                                        <p
                                          style={{ color: "red" }}
                                          className="error-message">
                                          {formError?.emailmessage}
                                        </p>
                                      )}
                                  </Col>
                                  {validation.includes("email") && (
                                    <Col xs={12} lg={3}>
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>
                                          Days from Offer Start
                                        </Form.Label>
                                        <Link
                                          to=""
                                          title="Value entered here:1 Means Email will be sent 1 day after offer is created.
                                              0 Means-Email will be sent on the same day offer is created.">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="black"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-help-circle ml-10 mb-4">
                                            <circle
                                              cx="12"
                                              cy="12"
                                              r="10"></circle>
                                            <path
                                              d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
                                              fill="current"></path>
                                            <line
                                              x1="12"
                                              y1="17"
                                              x2="12.01"
                                              y2="17"></line>
                                          </svg>
                                        </Link>
                                        <Form.Control
                                          type="number"
                                          disabled={mode}
                                          placeholder="Enter Days..."
                                          value={each?.emaildaysfrom}
                                          name="emaildaysfrom"
                                          min={0}
                                          onWheel={(e) => e.target.blur()}
                                          onKeyDown={blockInvalidChar}
                                          onChange={(e) => {
                                            handleCouponOfferBank(
                                              e.target.value,
                                              "emaildaysfrom",
                                              i
                                            );
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                  )}

                                  <Col lg={3} xs={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <Form.Label>Select Email Time</Form.Label>
                                      <Form.Control
                                        type="time"
                                        value={each?.emailtime}
                                        name="emailtime"
                                        disabled={mode}
                                        onChange={(e) => {
                                          handleCouponOfferBank(
                                            e.target.value,
                                            "emailtime",
                                            i
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                    </div>
                    {!mode && (
                      <div>
                        <Button
                          className="BlueButton"
                          variant="primary"
                          style={{
                            marginLeft: "22px",
                            height: "40px",
                            borderRadius: "5px",
                            width: "140px",
                            fontSize: "13px",
                            fontWeight: "600",
                            border: "none"
                          }}
                          disabled={mode}
                          onClick={() => {
                            setModalShow(true);
                            setCouponId(i);
                          }}>
                          Delete Offer
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          id={couponId}
                          couponArray={offerBankArray}
                          setCouponArray={setOfferBankArray}
                          SingleCouponData={SingleCouponData}
                          setSuggestedCouponArray={setSuggestedCouponArray}
                          setChecked={setChecked}
                        />
                      </div>
                    )}
                  </Form>
                ))}
              </>
            )}

            {info.suggestOffer ? (
              <>
                {editMode && (
                  <span style={{ color: "red", marginLeft: "50px" }}>
                    This changes will take effect for all upcoming rule runs -
                    already created groups and coupon will not change.
                  </span>
                )}
                <Form.Label style={{ marginLeft: "50px" }}>
                  Suggest offers will be available in 1 hour after rule is
                  processed
                </Form.Label>

                {validation.includes("suggested offer") && (
                  <Col xs={4}>
                    <Form.Group className="position-relative pl-50">
                      <Form.Label>How Many Offers To Generate</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="How Many Offers To Generate"
                        name="SuggestedOfferCount"
                        value={info.SuggestedOfferCount}
                        disabled={mode}
                        min={1}
                        onChange={(e) => {
                          handleInput(e.target.value, "SuggestedOfferCount");
                          handleChange("SuggestedOfferCount");
                        }}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                      />
                    </Form.Group>
                    {formError.offerGenerate && (
                      <p style={{ color: "red" }} className="error-message">
                        {formError.offerGenerate}
                      </p>
                    )}
                  </Col>
                )}
              </>
            ) : (
              ""
            )}
          </Row>

          <div className="flex align-items-center  text-end p-20 pt-0">
            <>
              {offerBankArray
                .filter(
                  (each, i) =>
                    (formError?.frequency && !each?.frequency.value) ||
                    formError.offer ||
                    (formError?.coupon && !each?.name) ||
                    (formError?.offerType && !each?.offertype.value) ||
                    (formError?.image && !each?.image) ||
                    (each?.rewardamount == 0
                      ? formError?.rewardamount && each.rewardamount
                      : formError?.rewardamount && !each.rewardamount) ||
                    (formError?.purchaseUpcs &&
                      !each?.purchaseupcs.length &&
                      (each?.offertype?.label === "UPC" ||
                        each?.offertype?.label === "Cross Sell")) ||
                    (formError?.rewardupcs &&
                      !each?.rewardupcs.length &&
                      each?.offertype?.label === "Cross Sell") ||
                    (formError?.pushNotification &&
                      !each?.message &&
                      each?.sendpushnotification === true) ||
                    offerBankArray[i] ||
                    formError.offerGenerate
                )
                .map((each, i) => {
                  return (
                    <>
                      {showMesg === true && (
                        <p
                          style={{
                            color: "red",
                            paddingLeft: "155px"
                          }}
                          className="error-message"
                          key={each.i}>
                          {offerBankArray[i].frequency.value === "" ||
                            offerBankArray[i].name === "" ||
                            offerBankArray[i].offertype.value === "" ||
                            offerBankArray[i].image === "" ||
                            offerBankArray[i].rewardamount === "0" ||
                            (offerBankArray[i].offertype.label === "UPC" &&
                              offerBankArray[i].purchaseupcs.length === 0) ||
                            (offerBankArray[i].offertype.label === "Cross Sell" &&
                              (offerBankArray[i].rewardupcs.length === 0 ||
                                offerBankArray[i].purchaseupcs.length === 0)) ||
                            (offerBankArray[i].sendpushnotification === true &&
                              offerBankArray[i].message === "")
                            ? `Please fill all mandatory fields for Coupon ${i + 1
                            }`
                            : ""}
                        </p>
                      )}
                    </>
                  );
                })}

              {info.rewardOffer && offerCheckBox === true ? (
                ""
              ) : (
                <p
                  style={{
                    textAlign: "left",
                    marginLeft: "40px",
                    marginTop: "20px",
                    fontStyle: "italic"
                  }}>
                  **If this check box is left empty, a group with the name of
                  the ruleis created which groups all customers according to the
                  criteria defined in the previous steps. This group can be used
                  in future to schedule an offer.
                </p>
              )}
              <Button
                className="GrayButton mt-14"
                type="submit"
                onClick={() => {
                  handleStep("previous");
                  setValue((prev) => {
                    return { ...prev, CouponArray: couponArray };
                  });
                }}>
                <img
                  src={PrevArrow}
                  alt="Prev Arrow"
                  className="img-fluid mr-5"
                />
                Previous
              </Button>
              <Button
                className="BlueButton ml-10 mt-14"
                type="submit"
                onClick={() => {
                  handleOfferNext();
                  handleSetValue();
                  setShowMesg(true);
                }}>
                Next
                <img
                  src={NextArrow}
                  alt="Next Arrow"
                  className="img-fluid ml-5"
                />
              </Button>
            </>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
