import React from "react";
import Chart from "react-apexcharts";
import { Spinner } from "react-bootstrap";

function ShopperTilers({ stores, data, graphResponseLoading }) {
  const keys = [];
  const values = [];
  const seriesData = [];

  for (var i = 0; i < data.length; i++) {
    keys.push(Object.keys(data[i]));
  }

  for (i = 0; i < data.length; i++) {
    values.push(Object.values(data[i]));
  }

  for (i = 0; i < stores.length; i++) {
    seriesData.push({
      name: stores[i],
      data: values[i]
    });
  }

  const chartData = {
    series: seriesData,
    options: {
      chart: {
        type: "bar",
        height: 270,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "22%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      grid: {
        borderColor: "#DDE3EE;",
        strokeDashArray: 10
      },
      xaxis: {
        categories: keys[0],
        labels: {
          style: {
            fontSize: "11px",
            cssClass: ".apexcharts-margin"
          },
          hideOverlappingLabels: false,
          show: true,
          rotate: -45,
          rotateAlways: true,
          minHeight: 80,
          maxHeight: 2000
        }
      },
      legend: {
        position: "right",
        horizontalAlign: "center"
      },
      yaxis: {
        title: {
          show: false
        },
        labels: {
          formatter: function (value) {
            return value / 1000 + "k";
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val / 1000 + " thousands";
          }
        }
      }
    }
  };

  return (
    <div>
      {graphResponseLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px"
          }}>
          <Spinner animation="border" variant="primary" sizing={"lg"} />
        </div>
      ) : (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height="200"
        />
      )}
    </div>
  );
}

export default ShopperTilers;
