/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { Link } from "react-router-dom";

function DepartmentServay({ departmentData, storeId }) {
    return (
        <React.Fragment>
            <div className="department-card-main">
            <div className="department-card-wrapper">
                {/* <div className="department-image">
                    <img width="24" height="24" alt="icon" src={departmentData.image_url} className="img-fluid" />
                </div> */}
                <div className="department-card-detail">
                    <div className="main-title">{departmentData.name}</div>
                    <div className="department-month">{departmentData.currmonth_label}</div>
                    <div className="department-price">{departmentData.total_sales}</div>
                    <div className="department-price-wrap">
                        <div className="department-off-price">
                            {departmentData.trend === "Down" ?
                                <div className="down-arrow-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><path fill="currentColor" d="m208.49 152.49l-72 72a12 12 0 0 1-17 0l-72-72a12 12 0 0 1 17-17L116 187V40a12 12 0 0 1 24 0v147l51.51-51.52a12 12 0 0 1 17 17Z" /></svg>
                                </div>
                                :
                                <div className="up-arrow-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><path fill="currentColor" d="M208.49 120.49a12 12 0 0 1-17 0L140 69v147a12 12 0 0 1-24 0V69l-51.51 51.49a12 12 0 0 1-17-17l72-72a12 12 0 0 1 17 0l72 72a12 12 0 0 1 0 17"></path></svg>
                                </div>
                            }
                            <span>{departmentData.trend_percent}</span>
                            </div>
                        <div className="department-on-date">{departmentData.text_1}</div>
                        {/* <div className="dep-year">nov 2023</div> */}
                    </div>
                    <div className="department-links">
                    <div className="department-price-link">
                        <Link target="_top" to={`/Department?store_id=${storeId}&department_id=${departmentData.department_id}`}>View</Link>
                    </div>
                    {/* <div className="department-promo">
                        <Link to={`/NewPromotion`}>Create Promotion</Link>
                    </div> */}
                    </div>
                </div>
            </div>
            </div>
        </React.Fragment>
    );
}

export default DepartmentServay;
