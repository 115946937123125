/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";

import {  useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

function FocusItemsUPCs(props) {
    const loading = useSelector((state) => state.dashboardFocusItemAnalysisLoading);
    const focusItemsDataUPC = useSelector((state) => state.dashboardFocusItemAnalysisData);
    return (
        <React.Fragment>
             
            <div className="produce-department col-md-6 focus-tag-wrap">
            <div className="chart-title">
                <h2>Focus Upc's</h2>
            </div>
            <div className="focus-tags-wrap">
                {
                    loading &&
                    <>
                        <div
                            style={{ height: "25vh" }}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </>
                }
                
                {(!loading && focusItemsDataUPC?.Message != null) &&
                    focusItemsDataUPC?.Message?.focus_upcs.map((item, index) =>
                       <div className="focus-tag">{item}</div>
                    )
                }
            </div>
            </div>
        </React.Fragment>
    );
}

export default FocusItemsUPCs;