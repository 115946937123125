/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";


function Footer(){
  const getCurrentDay = new Date();
  const getCurrentYear = getCurrentDay.getFullYear();
  return (
    <footer className="Footer footer-height">
    <div className="container-fluid">
      <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-center Footer_Content">
            <span className="Line mr-24"></span>
            <span className="d-block">© {getCurrentYear} RSA America LLC. </span>
            <ul className="ml-24 nav">
              {/* <li>
                <a href="#">Home</a>
              </li> */}
              <li>
                <a href="https://rsaamerica.com/terms/">Terms</a>
              </li>
              <li>
                <a href="https://rsaamerica.com/privacy-policy/">Privacy</a>
              </li>
            </ul>
            <span className="Line ml-24"></span>
          </div>
        </div>
      </div>
      </div>
    </div>
  </footer>
  );
}

export default Footer;
