/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../../assets/images/logo.png";
import Avtar from "../../assets/images/logo-black.png";
import ProfileUser from "../../assets/images/profile-user.svg";
import { headers } from "../../public/auth";
import { toast } from "react-toastify";
import usePrevious from "../../public/PrevValue";

function Header() {
  const data = useSelector((state) => state.ruleListData);
  const PendingApproval = data?.Message?.items[0]?.PendingApproval;
  let myHeaders = headers;
  let decrypted = myHeaders.Authorization;
  let token = window.localStorage.getItem("access_token");
  const decoded = token ? JSON.parse(window.atob(decrypted.split(".")[1])) : "";
  const [isToken, setIsToken] = useState(token);
  const handleLogOut = () => {
    window.localStorage.clear();
    setIsToken(null);
    window.location.href = "/login";
  };

  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const scroll = window.scrollY;

    if (scroll < 250) {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const PendingApprovalChange = () => {
  //   window.location.href = "/ApproveScreen";
  // };

  const ruleTypeMessage = useSelector((state) => state.ruleTypeMessage);

  const subTypeMessage = useSelector((state) => state.subTypeMessage);

  const departmentMessage = useSelector((state) => state.departmentMessage);

  const duringMessage = useSelector((state) => state.duringMessage);
  const prevDuringMessage = usePrevious(duringMessage);

  const storesMessage = useSelector((state) => state.storesMessage);

  const frequencyMessage = useSelector((state) => state.frequencyMessage);

  const shopperMessage = useSelector((state) => state.shopperMessage);
  const prevShopperMessage = usePrevious(shopperMessage);

  const validationMessage = useSelector((state) => state.validationMessage);

  const offerMessage = useSelector((state) => state.offerMessage);

  const bankOfferMessage = useSelector((state) => state.bankOfferMessage);

  const singleRuleMessage = useSelector((state) => state.singleRuleMessage);

  const ruleCreateMessage = useSelector((state) => state.ruleCreateMessage);

  const ruleListMessage = useSelector((state) => state.ruleListMessage);

  const summaryMessage = useSelector((state) => state.summaryMessage);
  const ruleAnalysisMessage = useSelector((state) => state.ruleAnalysisMessage);

  useEffect(() => {
    if (
      (token && ruleTypeMessage === "Session Has Expired") ||
      subTypeMessage === "Session Has Expired" ||
      departmentMessage === "Session Has Expired" ||
      prevDuringMessage === "Session Has Expired" ||
      prevShopperMessage === "Session Has Expired" ||
      offerMessage === "Session Has Expired" ||
      frequencyMessage === "Session Has Expired" ||
      storesMessage === "Session Has Expired" ||
      bankOfferMessage === "Session Has Expired" ||
      singleRuleMessage === "Session Has Expired." ||
      validationMessage === "Session Has Expired" ||
      ruleCreateMessage === "Session Has Expired" ||
      ruleListMessage === "Session Has Expired" ||
      summaryMessage === "Session Has Expired" ||
      ruleAnalysisMessage === "Session Has Expired"
    ) {
      toast.error("Session Has Expired");
      window.location.href = "/login";
    }
  }, [
    subTypeMessage,
    ruleTypeMessage,
    departmentMessage,
    prevDuringMessage,
    prevShopperMessage,
    offerMessage,
    frequencyMessage,
    storesMessage,
    bankOfferMessage,
    singleRuleMessage,
    validationMessage,
    ruleCreateMessage,
    ruleListMessage,
    summaryMessage,
    ruleAnalysisMessage,
    token,
  ]);

  return (
    <header className={`Header ${isSticky ? "sticky-menu" : ""}`}>
      <div className="container-fluid">
        <div className="row header-row">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <a href="/home" className="Logo mr-15">
              <img
                src={Avtar}
                className="img-fluid"
                style={{ height: "45px", width: "130px" }}
                alt="Logo"
              />
            </a>

            <div className="Header_Right d-flex align-items-center">
              {PendingApproval === 1 && (
                <div className="peding-approval">
                  <p
                    style={{
                      color: "red",
                      marginRight: "5px",
                    }}
                  >
                    You have offers pending approval. Click Pending Approval
                    button to view them.
                  </p>
                  <Link to="/ApproveScreen" className="badge btn-danger mr-40">
                    Pending Approval
                  </Link>
                </div>
              )}
              <img
                className="brand-img"
                style={{
                  width: "150px",
                  height: "55px",
                }}
                src={isToken && decoded.retailer.logo}
                alt=""
              />
              <div className="User ml-15">
                <div className="User_Image">
                  <img
                    src={Logo}
                    style={{
                      width: "150px",
                      height: "45px",
                    }}
                    alt="Logo"
                  />
                </div>
                {/* <h5 className="ml-9">Welcome to RSA’s AutoPilot</h5> */}
              </div>
              <div className="BrandLogo ml-10">
                {/* <Link to="/dashboard">
                  <img src={BrandLogo} className="img-fluid" alt="Brand" />
                </Link> */}
              </div>
              <div className="dropdown ml-15 Profile_Dropdown">
                <a
                  className=" dropdown-toggle d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={ProfileUser}
                    className="img-fluid mr-7"
                    alt="ProfileUser"
                  />
                  <span className="user-name">
                    {isToken && decoded.first_name}
                    {/* {isToken && decoded.last_name} */}
                  </span>
                  <svg
                    className="user-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      d="m18 9l-6 6l-6-6"
                    />
                  </svg>
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className="dropdown-item" href="/home">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="currentColor"
                          d="M16.612 2.214a1.01 1.01 0 0 0-1.242 0L1 13.419l1.243 1.572L4 13.621V26a2.004 2.004 0 0 0 2 2h20a2.004 2.004 0 0 0 2-2V13.63L29.757 15L31 13.428ZM18 26h-4v-8h4Zm2 0v-8a2.002 2.002 0 0 0-2-2h-4a2.002 2.002 0 0 0-2 2v8H6V12.062l10-7.79l10 7.8V26Z"
                        />
                      </svg>
                      Home
                    </a>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/FocusGroupList?store_id=0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 48 48"
                      >
                        <g fill="none">
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="4"
                            d="M16 6H8a2 2 0 0 0-2 2v8m10 26H8a2 2 0 0 1-2-2v-8m26 10h8a2 2 0 0 0 2-2v-8M32 6h8a2 2 0 0 1 2 2v8"
                          />
                          <rect
                            width="20"
                            height="20"
                            x="14"
                            y="14"
                            stroke="currentColor"
                            stroke-width="4"
                            rx="10"
                          />
                          <circle
                            r="3"
                            fill="currentColor"
                            transform="matrix(-1 0 0 1 24 24)"
                          />
                        </g>
                      </svg>
                      Focus Items
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/ShoppersGroupList?store_id=0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M8 8.5c-.08 0-.15-.02-.22-.05L1.28 5.2a.5.5 0 0 1 0-.9l6.5-3.25c.14-.07.31-.07.45 0l6.5 3.25a.5.5 0 0 1 0 .9l-6.5 3.25c-.07.04-.15.05-.22.05ZM2.62 4.75L8 7.44l5.38-2.69L8 2.06z"
                        />
                        <path
                          fill="currentColor"
                          d="M8 11.75c-.08 0-.15-.02-.22-.05l-6.5-3.25a.5.5 0 0 1 0-.9l3.25-1.62c.25-.12.55-.02.67.22c.12.25.02.55-.22.67L2.62 8L8 10.69L13.38 8l-2.36-1.18a.488.488 0 0 1-.22-.67c.12-.25.42-.35.67-.22l3.25 1.62a.5.5 0 0 1 0 .9l-6.5 3.25c-.07.04-.15.05-.22.05"
                        />
                        <path
                          fill="currentColor"
                          d="M8 15c-.08 0-.15-.02-.22-.05l-6.5-3.25a.5.5 0 0 1 0-.9l3.25-1.62c.25-.12.55-.02.67.22c.12.25.02.55-.22.67l-2.36 1.18L8 13.94l5.38-2.69l-2.36-1.18a.488.488 0 0 1-.22-.67c.12-.25.42-.35.67-.22l3.25 1.62a.5.5 0 0 1 0 .9l-6.5 3.25c-.07.04-.15.05-.22.05"
                        />
                      </svg>
                      Shopper Groups
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/PromotionList">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none" stroke="currentColor" stroke-width="1.5">
                          <path d="M16.755 2h-9.51c-1.159 0-1.738 0-2.206.163a3.046 3.046 0 0 0-1.881 1.936C3 4.581 3 5.177 3 6.37v14.004c0 .858.985 1.314 1.608.744a.946.946 0 0 1 1.284 0l.483.442a1.657 1.657 0 0 0 2.25 0a1.657 1.657 0 0 1 2.25 0a1.657 1.657 0 0 0 2.25 0a1.657 1.657 0 0 1 2.25 0a1.657 1.657 0 0 0 2.25 0l.483-.442a.946.946 0 0 1 1.284 0c.623.57 1.608.114 1.608-.744V6.37c0-1.193 0-1.79-.158-2.27a3.045 3.045 0 0 0-1.881-1.937C18.493 2 17.914 2 16.755 2Z" />
                          <path
                            stroke-linecap="round"
                            d="M10.5 11H17M7 11h.5M7 7.5h.5m-.5 7h.5m3-7H17m-6.5 7H17"
                          />
                        </g>
                      </svg>
                      Promotion List
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://s3.amazonaws.com/aogmedia/Autopilot/AP-Insights.pdf"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M224 200h-8V40a8 8 0 0 0-8-8h-56a8 8 0 0 0-8 8v40H96a8 8 0 0 0-8 8v40H48a8 8 0 0 0-8 8v64h-8a8 8 0 0 0 0 16h192a8 8 0 0 0 0-16M160 48h40v152h-40Zm-56 48h40v104h-40Zm-48 48h32v56H56Z"
                        />
                      </svg>
                      Insights
                    </a>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/wizard">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="currentColor"
                          d="m29.707 19.293l-3-3a1 1 0 0 0-1.414 0L16 25.586V30h4.414l9.293-9.293a1 1 0 0 0 0-1.414M19.586 28H18v-1.586l5-5L24.586 23zM26 21.586L24.414 20L26 18.414L27.586 20zM8 16h10v2H8zm0-6h12v2H8z"
                        />
                        <path
                          fill="currentColor"
                          d="M26 4a2.002 2.002 0 0 0-2-2H4a2.002 2.002 0 0 0-2 2v13a10.981 10.981 0 0 0 5.824 9.707L13 29.467V27.2l-4.234-2.258A8.986 8.986 0 0 1 4 17V4h20v9h2Z"
                        />
                      </svg>
                      Create New Rule
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/rules">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M4 1L3 2v12l1 1h8l1-1V2l-1-1zm0 2V2h8v12H4v-1h2v-1H4v-2h4V9H4V7h2V6H4V4h4V3z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      View Rules
                    </Link>
                  </li>

                  {/* <li>
                    <a className="dropdown-item" href="https://s3.amazonaws.com/aogmedia/Autopilot/AP-Action">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M7 18V6h3v12zm7 0V6h3v12zM2 4V2h20v2zm0 18v-2h20v2z" /></svg>
                    Action Items
                    </a>
                  </li> */}

                  <li>
                    <Link className="dropdown-item" to="/CreatePassword">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="currentColor"
                          d="M21 2a8.998 8.998 0 0 0-8.612 11.612L2 24v6h6l10.388-10.388A9 9 0 1 0 21 2m0 16a7.013 7.013 0 0 1-2.032-.302l-1.147-.348l-.847.847l-3.181 3.181L12.414 20L11 21.414l1.379 1.379l-1.586 1.586L9.414 23L8 24.414l1.379 1.379L7.172 28H4v-3.172l9.802-9.802l.848-.847l-.348-1.147A7 7 0 1 1 21 18"
                        />
                        <circle cx="22" cy="10" r="2" fill="currentColor" />
                      </svg>
                      Change Password
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/Userlist">
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.8337 5.83337H19.0003V7.50004H14.8337V5.83337ZM13.167 10H19.0003V11.6667H13.167V10ZM15.667 14.1667H19.0003V15.8334H15.667V14.1667ZM0.666992 18.3334C0.666992 16.5653 1.36937 14.8696 2.61961 13.6193C3.86986 12.3691 5.56555 11.6667 7.33366 11.6667C9.10177 11.6667 10.7975 12.3691 12.0477 13.6193C13.2979 14.8696 14.0003 16.5653 14.0003 18.3334H12.3337C12.3337 17.0073 11.8069 15.7355 10.8692 14.7978C9.93151 13.8602 8.65974 13.3334 7.33366 13.3334C6.00758 13.3334 4.73581 13.8602 3.79813 14.7978C2.86044 15.7355 2.33366 17.0073 2.33366 18.3334H0.666992ZM7.33366 10.8334C4.57116 10.8334 2.33366 8.59587 2.33366 5.83337C2.33366 3.07087 4.57116 0.833374 7.33366 0.833374C10.0962 0.833374 12.3337 3.07087 12.3337 5.83337C12.3337 8.59587 10.0962 10.8334 7.33366 10.8334ZM7.33366 9.16671C9.17533 9.16671 10.667 7.67504 10.667 5.83337C10.667 3.99171 9.17533 2.50004 7.33366 2.50004C5.49199 2.50004 4.00033 3.99171 4.00033 5.83337C4.00033 7.67504 5.49199 9.16671 7.33366 9.16671Z"
                          fill="current"
                        ></path>
                      </svg>
                      Account
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/Setting">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="32"
                          d="M262.29 192.31a64 64 0 1 0 57.4 57.4a64.13 64.13 0 0 0-57.4-57.4M416.39 256a154.34 154.34 0 0 1-1.53 20.79l45.21 35.46a10.81 10.81 0 0 1 2.45 13.75l-42.77 74a10.81 10.81 0 0 1-13.14 4.59l-44.9-18.08a16.11 16.11 0 0 0-15.17 1.75A164.48 164.48 0 0 1 325 400.8a15.94 15.94 0 0 0-8.82 12.14l-6.73 47.89a11.08 11.08 0 0 1-10.68 9.17h-85.54a11.11 11.11 0 0 1-10.69-8.87l-6.72-47.82a16.07 16.07 0 0 0-9-12.22a155.3 155.3 0 0 1-21.46-12.57a16 16 0 0 0-15.11-1.71l-44.89 18.07a10.81 10.81 0 0 1-13.14-4.58l-42.77-74a10.8 10.8 0 0 1 2.45-13.75l38.21-30a16.05 16.05 0 0 0 6-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 0 0-6.07-13.94l-38.19-30A10.81 10.81 0 0 1 49.48 186l42.77-74a10.81 10.81 0 0 1 13.14-4.59l44.9 18.08a16.11 16.11 0 0 0 15.17-1.75A164.48 164.48 0 0 1 187 111.2a15.94 15.94 0 0 0 8.82-12.14l6.73-47.89A11.08 11.08 0 0 1 213.23 42h85.54a11.11 11.11 0 0 1 10.69 8.87l6.72 47.82a16.07 16.07 0 0 0 9 12.22a155.3 155.3 0 0 1 21.46 12.57a16 16 0 0 0 15.11 1.71l44.89-18.07a10.81 10.81 0 0 1 13.14 4.58l42.77 74a10.8 10.8 0 0 1-2.45 13.75l-38.21 30a16.05 16.05 0 0 0-6.05 14.08c.33 4.14.55 8.3.55 12.47"
                        />
                      </svg>
                      Settings
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogOut}
                    >
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.699416 8.25055C0.646173 4.13324 3.94055 0.752551 8.05785 0.699308C12.1752 0.646065 15.5559 3.94044 15.6091 8.05775C15.6249 9.21522 15.3706 10.3604 14.8664 11.4024C14.3621 12.4444 13.6218 13.3544 12.7043 14.0602L12.6782 12.0399C13.4261 11.1692 13.9069 10.1012 14.0629 8.96406C14.2189 7.82688 14.0435 6.66884 13.5577 5.6289C13.0718 4.58896 12.2962 3.7113 11.324 3.10123C10.3517 2.49116 9.22403 2.1746 8.0763 2.18954C6.92857 2.20448 5.80953 2.55028 4.85345 3.18544C3.89738 3.82061 3.1449 4.71816 2.68629 5.77039C2.22769 6.82262 2.08244 7.98483 2.26799 9.11757C2.45354 10.2503 2.962 11.3054 3.73236 12.1563L3.75848 14.1766C2.8229 13.4947 2.05926 12.604 1.52822 11.5753C0.997185 10.5466 0.713401 9.40817 0.699416 8.25055V8.25055ZM5.2398 13.4111L7.47625 13.3822L7.39913 7.4183L8.8901 7.39902L8.96722 13.3629L11.2037 13.334L8.26994 17.1L5.2398 13.4111Z"
                          fill="current"
                        ></path>
                      </svg>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
