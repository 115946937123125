/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";

function MonthlySales({ monthlySales }) {
    return (
        <React.Fragment>
            <div className="sales-report-main">
                {monthlySales.map((row, i) => (
                    <div className="sales-report-detail">
                        <div className="sales-wrapper">
                            <div className="sales-month">
                                <span>{row.month}</span>
                            </div>
                            <div className="sales-data">
                                <div className="sales-main-data">{row.total_sales}</div>
                                <div className="sales-sub-title">total sales</div>
                                {row.trend !== "No-show" ?
                                    <>
                                        <div className="sales-off-price">
                                            {row.trend === "Down" && (
                                                <div className="down-arrow-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><path fill="currentColor" d="m208.49 152.49l-72 72a12 12 0 0 1-17 0l-72-72a12 12 0 0 1 17-17L116 187V40a12 12 0 0 1 24 0v147l51.51-51.52a12 12 0 0 1 17 17Z"></path></svg>
                                                </div>
                                            )
                                            }
                                            {row.trend === "Up" &&
                                                (
                                                    <div className="up-arrow-price">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><path fill="currentColor" d="M208.49 120.49a12 12 0 0 1-17 0L140 69v147a12 12 0 0 1-24 0V69l-51.51 51.49a12 12 0 0 1-17-17l72-72a12 12 0 0 1 17 0l72 72a12 12 0 0 1 0 17"></path></svg>
                                                    </div>
                                                )
                                            }
                                            <span>{row.trend_percent}</span>
                                        </div>
                                        <div className="sales-on-date">{row.text_1}</div>
                                    </>
                                    :
                                    (<></>)
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default MonthlySales;