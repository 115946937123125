const INITIAL_STATE = {
  loginData: {},
  loginMsg: "",
  loginLoading: false,

  createUserData: {},
  createUserMessage: "",
  createUserLoading: false,

  deleteUserData: {},
  deleteUserMessage: "",
  deleteUserLoading: false,

  editUserData: {},
  editUserMessage: "",
  editUserLoading: false,

  getUserListData: [],
  getUserListMessage: "",
  getUserListLoading: false,

  getSingleUserData: {},
  getSingleUserMessage: "",
  getSingleUserLoading: false,

  getRolesData: [],
  getRolesMessage: "",
  getRolesLoading: false,

  departmentData: [],
  departmentMessage: "",
  departmentLoading: false,

  validationData: [],
  validationMessage: "",
  validationLoading: false,

  frequencyData: [],
  frequencyMessage: "",
  frequencyLoading: false,

  ruleTypeData: [],
  ruleTypeMessage: "",
  ruleTypeLoading: false,

  subTypeData: [],
  subTypeMessage: "",
  subTypeLoading: false,

  summaryData: {},
  summaryMessage: "",
  summaryLoading: false,

  storesData: [],
  storesMessage: "",
  storesLoading: false,

  offerData: {},
  offerMessage: "",
  offerLoading: false,

  offerDurationData: {},
  offerDurationMessage: "",
  offerDurationLoading: false,

  ruleListData: [],
  ruleListMessage: "",
  ruleListLoading: false,

  graphData: {},
  graphMessage: "",
  graphLoading: false,

  ruleAnalysisData: {},
  ruleAnalysisMessage: "",
  ruleAnalysisLoading: false,

  singleRuleData: {},
  singleRuleMessage: "",
  singleRuleLoading: false,

  duringData: {},
  duringMessage: "",
  duringLoading: false,

  shopperData: {},
  shopperMessage: "",
  shopperLoading: false,

  ruleCreateData: {},
  ruleCreateMessage: "",
  ruleCreateLoading: false,

  bankOfferData: {},
  bankOfferMessage: "",
  bankOfferLoading: false,

  zipcodeData: {},
  zipcodeMessage: "",
  zipcodeLoading: false,

  pendingApprovalData: [],
  pendingApprovalMessage: "",
  pendingApprovalLoading: false,

  forgotPasswordData: {},
  forgotPasswordMessage: "",
  forgotPasswordLoading: false,

  groupsData: {},
  groupsMessage: "",
  groupsLoading: false,

  // Approval Pending Intila State
  approvePendingRuleData: {},
  approvePendingRuleMessage: "",
  approvePendingRuleLoading: false,

  createdOffersData: {},
  createdOffersMessage: "",
  createdOffersLoading: false,

  expireCouponData: {},
  expireCouponMessage: "",
  expireCouponLoading: false,

  approveRuleData: {},
  approveRuleDataMessage: "",
  approveRuleDataLoading: false,

  rejectRuleData: {},
  rejectRuleDataMessage: "",
  rejectRuleDataLoading: false,

  ruleAnalysisDownloadData: {},
  ruleAnalysisDownloadMessage: "",
  ruleAnalysisDownloadLoading: false,

  dashboardOverviewData: {},
  dashboardOverviewMessage: "",
  dashboardOverviewLoading: false,

  dashboardShopperGroupsData: {},
  dashboardShopperGroupsMessage: "",
  dashboardShopperGroupsLoading: false,

  dashboardDepartmentsData: {},
  dashboardDepartmentsMessage: "",
  dashboardDepartmentsLoading: false,

  dashboardFocusItemsData: {},
  dashboardFocusItemsMessage: "",
  dashboardFocusItemsLoading: false,

  dashboardDepartmentDataAnalysisData: {},
  dashboardDepartmentDataAnalysisMessage: "",
  dashboardDepartmentDataAnalysisLoading: false,

  dashboardFocusItemAnalysisData: {},
  dashboardFocusItemAnalysisMessage: "",
  dashboardFocusItemAnalysisLoading: false,

  // create-focus-items

  createFocusItemData: {},
  createFocusItemMessage: "",
  createFocusItemLoading: "",
};

export default INITIAL_STATE;
