/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import FocusGroup from "../Home/FocusGroup/FocusGroup";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
function FocusGroupList() {
    const search = useLocation().search;
    const createSuccess = useSelector((state) => state.createFocusItemMessage);
    const editFocusItemMessage = useSelector((state) => state.editFocusItemMessage);
    const storeId = new URLSearchParams(search).get("store_id");
    useEffect(() => {
        if (createSuccess === "Successful") {
            toast.success(`Focus Item created successfully. The newly added focus groups will require up to 24 hours for setup. 
            After this period, the data and analysis will begin to appear. 
            If its more than 24 hours, please contact the support. `);
        }
    }, [createSuccess]);

    useEffect(() => {
        if (editFocusItemMessage !== "") {
            if (editFocusItemMessage === "Successful") {
                toast.success(`Focus Item saved successfully.`);
            }
        }
    }, [editFocusItemMessage]);
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="Content_Wrapper mt-50">
                <div className="container-fluid">
                    <div className="container">
                        <div className="home-page-contain">
                            <FocusGroup storeId={storeId} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FocusGroupList;
