/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect , useState } from "react";
import DepartmentGroup from "../Home/DepartmentGroup/DepartmentGroup";
import PerformanceBarChart from "./PerformanceBarChart/PerformanceBarChart";
import ProductBarChart from "./ProductBarChart/ProductBarChart";
import GroupPromotionsList from "../Home/GroupPromotionsList/GroupPromotionsList";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardGroupAnalysisData } from "../../redux/API";
import { Spinner } from "react-bootstrap";
import FocusGroup from "../Home/FocusGroup/FocusGroup";
import PromotionList from "../Home/PromotionList";
function GroupAnalysis() {
  const search = useLocation().search;
  const groupId = new URLSearchParams(search).get("group_id");
  const storeId = new URLSearchParams(search).get("store_id");
  const dispatch = useDispatch();
  const groupAnalysisData = useSelector(
    (state) => state.dashboardGroupAnalysisData
  );
  const loading = useSelector((state) => state.dashboardGroupAnalysisLoading);
  const dashboardDepartmentsData = useSelector(
    (state) => state.dashboardDepartmentsData
  );
  const dashboardFocusItemsData = useSelector(
    (state) => state.dashboardFocusItemsData
  );

  var groupsData = JSON.parse(localStorage.getItem("shopperGroups"));
  const groupData = groupsData?.Message?.shopper_groups?.filter(
    (a) => a.group_id == groupId
  )[0];

  useEffect(() => {
    dispatch(getDashboardGroupAnalysisData(storeId, groupId));
  }, [dispatch, storeId, groupId]);

  //stickey add
  
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const scroll = window.scrollY;

    if (scroll < 250) {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
          <div className="container">
            <div className="home-page-contain">
              {loading && (
                <>
                  <div
                    style={{ height: "25vh" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Spinner animation="border" variant="primary" />
                  </div>
                </>
              )}
              {!loading && groupAnalysisData?.Message != null && (
                <>
                  <div className="group-chart-main-wrap row">
                    <div className="navbar-fixed">
                      <div className={`group-department-main-title ${isSticky ? "sticky-menu" : ""}`} >
                        <div className="group-img-title">
                          <div className="main-title">
                            <h2>{groupData?.name} Analysis</h2>
                          </div>
                        </div>
                        <div className="group-department-category">
                          <div className="group-department-tab">
                            <div className="group-price">
                              {groupData?.total_sales}
                            </div>
                            <div className="group-offer-price">
                              {groupData?.trend === "Up" ? (
                                <div className="income-up-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M9 5v2h6.59L4 18.59L5.41 20L17 8.41V15h2V5z"
                                    />
                                  </svg>
                                </div>
                              ) : (
                                <div className="income-down-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                  >
                                    <g transform="rotate(180 12 12)">
                                      <path
                                        fill="currentColor"
                                        d="M9 5v2h6.59L4 18.59L5.41 20L17 8.41V15h2V5z"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              )}
                              <span>{groupData?.trend_percent}</span>
                            </div>
                            <div className="group-on-date">
                              {groupData?.trend_text}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ProductBarChart
                      salesGraph={groupAnalysisData?.Message?.sales_graph}
                      groupId={groupId}
                    />
                    <PerformanceBarChart
                      salesGraph={
                        groupAnalysisData?.Message?.sales_graph_by_department
                      }
                    />
                  </div>
                </>
              )}
              {dashboardDepartmentsData?.Message?.departments?.length !== 0 && (
                <DepartmentGroup storeId={storeId} groupId={groupId} />
              )}
              {dashboardFocusItemsData?.Message?.focus_items?.length !== 0 && (
                <FocusGroup storeId={storeId} groupId={groupId} />
              )}
            </div>
          </div>
              <PromotionList />
        </div>
      </div>
    </React.Fragment>
  );
}

export default GroupAnalysis;
