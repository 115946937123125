import React from "react";

const DisplayDates = ({ info, getRuleDate, nextArrayLenght, runDate }) => {
  return (
    <>
      {(nextArrayLenght === 0 && info.frequency.value !== 1) ||
      (nextArrayLenght === 0 && info.frequency.value !== 2) ||
      (nextArrayLenght === 0 && info.frequency.value !== 3) ||
      (nextArrayLenght === 0 && info.frequency.value !== 4) ||
      (nextArrayLenght === 0 && info.frequency.value !== 5) ||
      (nextArrayLenght === 0 && info.frequency.value !== 6) 
      ? (
        <></>
      ) : (
        <p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-info-circle-fill mr-10 ml-2"
            viewBox="0 0 16 16"
            title="Next 5 Rule Run Dates">
            {" "}
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />{" "}
          </svg>
          Next{" "}
          {(info.endDate >= runDate[4] && 5) ||
            (info.endDate >= runDate[3] && 4) ||
            (info.endDate >= runDate[2] && 3) ||
            (info.endDate >= runDate[1] && 2) ||
            (info.endDate >= runDate[0] && 1)}{" "}
          {info.endDate < runDate[1] ? " rule run date is" : " "}
          {info.endDate >= runDate[1] && " rule run dates are "}{" "}
          {nextArrayLenght === 1 && " rule run date is "}
          {info.startDate === info.endDate ? (
            <>{getRuleDate(runDate[0])}</>
          ) : (
            <>
              {info.endDate >= runDate[0] && getRuleDate(runDate[0])}
              {nextArrayLenght === 2 && info.endDate >= runDate[1] && " and "}
              {nextArrayLenght === 3 && info.endDate >= runDate[2] && ", "}{" "}
              {nextArrayLenght === 3 && info.endDate < runDate[2] && " and "}
              {nextArrayLenght >= 4 && ", "}
              {info.endDate >= runDate[1] && getRuleDate(runDate[1])}
              {nextArrayLenght === 3 && info.endDate >= runDate[2] && " and "}
              {nextArrayLenght === 4 && info.endDate >= runDate[3] && ", "}{" "}
              {nextArrayLenght === 4 && info.endDate < runDate[3] && " and "}{" "}
              {nextArrayLenght > 4 && ", "}
              {info.endDate >= runDate[2] && getRuleDate(runDate[2])}
              {nextArrayLenght === 4 && info.endDate >= runDate[3] && " and "}
              {nextArrayLenght === 5 && info.endDate >= runDate[4] && ", "}
              {nextArrayLenght === 5 && info.endDate < runDate[4] && " and "}
              {nextArrayLenght > 5 && ", "}
              {info.endDate >= runDate[3] && getRuleDate(runDate[3])}
              {info.endDate >= runDate[4] && " and "}
              {info.endDate >= runDate[4] && getRuleDate(runDate[4])}
            </>
          )}
        </p>
      )}
    </>
  );
};

export default DisplayDates;
