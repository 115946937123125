/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { headers } from "../../../public/auth";

function FocusGroupTitle(props) {
  let location = useLocation();
  const hanldeSortDirection = (e) => {
    let filterValue = e.target.value;
    props.changeSortDirection(filterValue);
  };

  let token = window.localStorage.getItem("access_token");
  let myHeaders = headers;
  let decrypted = myHeaders.Authorization;
  const decoded = token ? JSON.parse(window.atob(decrypted.split(".")[1])) : "";
  const Scopes_user = decoded.retailer.scopes;

  const Write_focusItem_scope = Scopes_user.includes("write:focusitem");
  const all_scope = Scopes_user.includes("all");

  return (
    <React.Fragment>
      <div className="focus-item focus-item-title">
        <div className="main-title-wrapper">
          <div className="main-title">
            <h2>focus items</h2>
          </div>
          <div className="store-link-warapper">
            {location.pathname === "/FocusGroupList" && (
              <>
                <div className="focus-list-wrap">
                  <div className="focus-list-title">
                    <span>Filter:</span>
                  </div>
                  <div
                    className="focus-list-dropdown store-list"
                    onChange={hanldeSortDirection}
                  >
                    <select>
                      <option value="All">All</option>
                      <option value="Up">Trending Up</option>
                      <option value="Down">Trending Down</option>
                    </select>
                  </div>
                </div>
              </>
            )}
            {(Write_focusItem_scope || all_scope) && (
              <div className="focus-link">
                <Link to="/FocusGroupItem">+ new focus item group</Link>
              </div>
            )}

            {location.pathname !== "/FocusGroupList" && (
              <div className="view-link">
                <Link to={`/FocusGroupList?store_id=` + props.storeId}>
                  View all
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FocusGroupTitle;
