/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import NextArrow from "../../assets/images/next-arrow.svg";
import {
  getSubType,
  getValidations,
  getFrequency,
  getDuring,
} from "../../redux/API";
import * as Constants from "../../redux/Contants";
import { useDispatch } from "react-redux";
import { getValidationAction, subTypeAction } from "../../redux/Actions";
import { toast } from "react-toastify";
import { useState } from "react";
import Select from "react-select";
import CONSTANT_VALUES from "../../public/constants";
import PastedChips from "../PastedChips";
import scissors from "../../assets/images/scissors.png";

export default function Step1({
  handleStep,
  info,
  handleInput,
  handleSubTypeUpcs,
  handleUpcs,
  handleManualInput,
  handleStepper,
  handleValidation,
  departmentLoading,
  ruleTypeLoading,
  subTypeData,
  subTypeLoading,
  subTypeMessage,
  prevSubTypeMessage,
  validationData,
  validationLoading,
  validationMessage,
  prevValidationMessage,
  singleRuleLoading,
  validation,
  mode,
  checkMode,
  ruleTypeDropdown,
  subTypeDropdown,
  departmentDropdown,
  handleMultiSelectInput,
  editMode,
  setUpcs,
  upcs,
}) {
  const dispatch = useDispatch();
  //useState for form error
  const [formError, setFormError] = useState({
    rule: "",
    ruleType: "",
    subType: "",
    departments: "",
    upcs: "",
  });

  // const [upcs, setUpcs] = useState([]);
  const [upc, setUpc] = useState("");

  const [chooseDeptCheckBox, setChooseDeptCheckBox] = useState(false);

  const handleAllCheck = (e) => {
    if (e.target.checked === true) {
      handleMultiSelectInput(departmentDropdown, "departments");
    } else {
      handleMultiSelectInput([], "departments");
    }
    setChooseDeptCheckBox(e.target.checked);
  };

  // {-----------------------------------------------------------}
  const handleStepDispatch = () => {
    if (info.subType.value === "") {
      dispatch(getFrequency(info.ruleType.value, 0));
      dispatch(getDuring(info.ruleType.value, 0));
    } else {
      dispatch(getFrequency(info.ruleType.value, info.subType.value));
      dispatch(getDuring(info.ruleType.value, info.subType.value));
    }
  };
  const handleTextValidation = (e) => {
    if (!onInputChange(e.target.value)) {
      setFormError((prev) => {
        return { ...prev, rule: "Please Enter Valid Rule Name" };
      });
    } else {
      setFormError((prev) => {
        return { ...prev, rule: "" };
      });
    }
  };
  function onInputChange(textValidation) {
    return /^[ A-Za-z0-9_-]*$/.test(textValidation);
  }

  const handleChange = (name) => {
    setFormError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const handleStepNext = () => {
    if (
      info.rule === "" ||
      info.ruleType.value === "" ||
      info.subType.value === ""
    ) {
      if (info.rule === "") {
        setFormError((prev) => {
          return { ...prev, rule: "Please Enter rule name" };
        });
      } else if (info.ruleType.value === "") {
        setFormError((prev) => {
          return { ...prev, ruleType: "Please select ruletype" };
        });
      } else if (subTypeData?.length < 1) {
        handleStep("next");
      } else if (info.subType.value === "") {
        setFormError((prev) => {
          return { ...prev, subType: "Please select subtype" };
        });
      } else if (
        validation.includes("Enter UPCs") &&
        info.upcsData.length === 0
      ) {
        setFormError((prev) => {
          return { ...prev, upcs: "Please Enter UPCs" };
        });
      } else if (
        validation.includes("Choose Department") &&
        info.departments === ""
      ) {
        setFormError((prev) => {
          return { ...prev, departments: "Please choose departments" };
        });
      }
    } else if (
      validation.includes("Enter UPCs") &&
      info.upcsData.length === 0
    ) {
      setFormError((prev) => {
        return { ...prev, upcs: "Please Enter UPCs" };
      });
    } else if (
      validation.includes("Choose Department") &&
      info.departments.length < 1
    ) {
      setFormError((prev) => {
        return { ...prev, departments: "Please choose departments" };
      });
    } else {
      handleStep("next");
    }
  };

  useEffect(() => {
    if (prevValidationMessage !== validationMessage && validationMessage) {
      if (validationMessage === "Successful") {
        if (checkMode === undefined) handleManualInput("departments", []);
        let valid = [],
          arr = [];
        for (let i = 0; i < validationData.length; i++) {
          valid.push(validationData[i].Field);
          arr.push(validationData[i].Section);
        }
        handleValidation(valid);
        handleStepper(arr);
      } else toast.error(validationMessage);
    }

    if (
      prevSubTypeMessage !== subTypeMessage &&
      subTypeMessage &&
      subTypeMessage === "Successful"
    ) {
      handleManualInput("subType", CONSTANT_VALUES.subType);
    }
  }, [validationMessage, prevValidationMessage]);

  const handleDelete = (deleteChip) => {
    const updatedUPCSData = info.upcsData.filter(
      (eachChip) => eachChip !== deleteChip
    );
    setUpcs(updatedUPCSData);
    handleUpcs(updatedUPCSData, "upcsData");
  };


  return (
    <Row className="g-3">
      {checkMode || info.ruleType.value === "" ? (
        <Col lg={12}>
          <div className="Wizard_Content_Card Rule_Type_Info">
            {departmentLoading || singleRuleLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Spinner animation="border" variant="primary" sizing={"lg"} />
              </div>
            ) : (
              <React.Fragment>
                <Form>
                  <Row className="g-3">
                    {editMode && (
                      <span style={{ color: "red", gap: "3px" }}>
                        This changes will take effect for all upcoming rule runs
                        - already created groups and coupon will not change.
                      </span>
                    )}
                    <Col xs={12}>
                      <Form.Group className="position-relative">
                        <Form.Label>Rule Name:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="What do you want call this rule?"
                          name="rule"
                          value={info.rule}
                          disabled={mode}
                          maxLength="100"
                          onChange={(e) => {
                            handleInput(e.target.value, "rule");
                            handleChange("rule");
                            handleTextValidation(e);
                          }}
                        />
                      </Form.Group>
                      {formError.rule && (
                        <p style={{ color: "red" }} className="error-message">
                          {formError.rule}
                        </p>
                      )}
                    </Col>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label>Rule Type:</Form.Label>
                        <Select
                          name="ruleType"
                          value={info.ruleType}
                          placeholder={"Please select rule Type"}
                          options={ruleTypeDropdown}
                          isDisabled={mode}
                          className="react-select-container select"
                          onChange={(e) => {
                            handleInput(e, "ruleType");
                            handleChange("ruleType");
                            dispatch(
                              getValidationAction(
                                Constants.VALIDATION_DATA_START,
                                [],
                                "",
                                false
                              )
                            );
                            dispatch(
                              subTypeAction(
                                Constants.SUB_TYPE_DATA_START,
                                [],
                                "",
                                false
                              )
                            );
                            dispatch(getSubType(e.value));
                            dispatch(getValidations(e.value, 0));
                          }}
                        />
                      </Form.Group>
                      {formError.ruleType && (
                        <p style={{ color: "red" }} className="error-message">
                          {formError.ruleType}
                        </p>
                      )}
                    </Col>
                    {subTypeLoading &&
                      info.ruleType.value !== 2 &&
                      info.ruleType.value !== 10 &&
                      info.ruleType.value !== 12 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "40px",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="primary"
                            sizing={"lg"}
                          />
                        </div>
                      )}
                    {subTypeData.length <= 0
                      ? ""
                      : info.ruleType.value !== "" &&
                      info.ruleType.value !== 2 &&
                      info.ruleType.value !== 10 &&
                      !subTypeLoading &&
                      (subTypeData.length > 0 || info.subType !== "") && (
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>Rule Sub-type:</Form.Label>
                            <Select
                              name="subType"
                              value={info.subType}
                              options={subTypeDropdown}
                              placeholder={"Please select sub-type"}
                              isDisabled={mode}
                              className="react-select-container select"
                              onChange={(e) => {
                                handleSubTypeUpcs();
                                handleInput(e, "subType");
                                handleChange("subType");
                                dispatch(
                                  getValidations(info.ruleType.value, e.value)
                                );
                              }}
                            />
                          </Form.Group>
                          {formError.subType && (
                            <p
                              style={{ color: "red" }}
                              className="error-message"
                            >
                              {formError.subType}
                            </p>
                          )}
                        </Col>
                      )}
                    {validationLoading && subTypeData.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        <Spinner
                          animation="border"
                          variant="primary"
                          sizing={"lg"}
                        />
                      </div>
                    )}
                    {(info.subType.value !== "" || subTypeData.length > 0) &&
                      !subTypeLoading &&
                      !validationLoading &&
                      validationData &&
                      validationData
                        .filter(
                          (i) =>
                            i.Section === "rule_type_info" &&
                            i.Field === "Enter UPCs"
                        )
                        .map((i) => {
                          return (
                            <Col xs={12} key={i}>
                              <fieldset disabled={mode}>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Enter UPCs</Form.Label>
                                  <PastedChips
                                    infoChipsData={info?.upcsData}
                                    upcsdata
                                    mode={mode}
                                    handleDelete={handleDelete}
                                    upc={upc}
                                    setUpc={setUpc}
                                    setUpcs={setUpcs}
                                    upcs={upcs}
                                    handleUpcs={handleUpcs}
                                    name={"upcsData"}
                                    setFormError={setFormError}
                                    PlaceHolderText={"enter UPCs"}
                                  />
                                </Form.Group>
                              </fieldset>
                              {formError.upcs && (
                                <p
                                  style={{ color: "red" }}
                                  className="error-message"
                                >
                                  {formError.upcs}
                                </p>
                              )}
                            </Col>
                          );
                        })}
                    {(info.subType.value !== "" || subTypeData.length > 0) &&
                      !subTypeLoading &&
                      !validationLoading &&
                      validationData &&
                      validationData
                        .filter(
                          (i) =>
                            i.Section === "rule_type_info" &&
                            i.Field === "Choose Department"
                        )
                        .map((i) => {
                          return (
                            <Col xs={12} key={i}>
                              <Form.Group>
                                <Form.Label>
                                  Choose Departments
                                  <Form.Check
                                    type="checkbox"
                                    label="Select all departments..."
                                    id="custom-switch"
                                    name="chooseDeptCheckBox"
                                    disabled={mode}
                                    checked={chooseDeptCheckBox}
                                    onChange={(e) => handleAllCheck(e)}
                                  />
                                </Form.Label>
                                <Select
                                  isMulti
                                  options={departmentDropdown}
                                  placeholder={"Please select departments..."}
                                  value={info.departments}
                                  isDisabled={mode}
                                  className="react-select-container select"
                                  onChange={(e) => {
                                    handleMultiSelectInput(e, "departments");
                                    handleChange("departments");
                                  }}
                                />

                                <div className="p-17 mt-20">
                                  <div className="row g-3 mb-20">
                                    <div className="col-12"></div>
                                  </div>
                                </div>
                              </Form.Group>
                              {formError.departments && (
                                <p
                                  style={{ color: "red" }}
                                  className="error-message"
                                >
                                  {formError.departments}
                                </p>
                              )}
                            </Col>
                          );
                        })}
                  </Row>
                </Form>
                <div className="flex align-items-center justify-content-end text-end">
                  <Button
                    className="BlueButton ml-10 mt-40"
                    disabled={validationData.length === 0 ? true : false}
                    onClick={() => {
                      handleStepNext();
                      handleStepDispatch();
                    }}
                  >
                    Next
                    <img
                      src={NextArrow}
                      alt="Next Arrow"
                      className="img-fluid ml-5"
                    />
                  </Button>
                </div>
              </React.Fragment>
            )}
          </div>
        </Col>
      ) : (
        <Col lg={9}>
          <div className="Wizard_Content_Card Rule_Type_Info">
            {departmentLoading || singleRuleLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Spinner animation="border" variant="primary" sizing={"lg"} />
              </div>
            ) : (
              <React.Fragment>
                <Form>
                  <Row className="g-3">
                    {editMode && (
                      <span style={{ color: "red", gap: "3px" }}>
                        This changes will take effect for all upcoming rule runs
                        - already created groups and coupon will not change.
                      </span>
                    )}
                    <Col xs={12}>
                      <Form.Group className="position-relative">
                        <Form.Label>Rule Name:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="What do you want call this rule?"
                          name="rule"
                          value={info.rule}
                          disabled={mode}
                          maxLength="100"
                          onChange={(e) => {
                            handleInput(e.target.value, "rule");
                            handleChange("rule");
                            handleTextValidation(e);
                          }}
                        />
                      </Form.Group>
                      {formError.rule && (
                        <p style={{ color: "red" }} className="error-message">
                          {formError.rule}
                        </p>
                      )}
                    </Col>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label>Rule Type:</Form.Label>
                        <Select
                          name="ruleType"
                          value={info.ruleType}
                          placeholder={"Please select rule Type"}
                          options={ruleTypeDropdown}
                          isDisabled={mode}
                          className="react-select-container select"
                          onChange={(e) => {
                            handleInput(e, "ruleType");
                            handleChange("ruleType");
                            dispatch(
                              getValidationAction(
                                Constants.VALIDATION_DATA_START,
                                [],
                                "",
                                false
                              )
                            );
                            dispatch(
                              subTypeAction(
                                Constants.SUB_TYPE_DATA_START,
                                [],
                                "",
                                false
                              )
                            );
                            dispatch(getSubType(e.value));
                            dispatch(getValidations(e.value, 0));
                          }}
                        />
                      </Form.Group>
                      {formError.ruleType && (
                        <p style={{ color: "red" }} className="error-message">
                          {formError.ruleType}
                        </p>
                      )}
                    </Col>
                    {subTypeLoading &&
                      info.ruleType.value !== 2 &&
                      info.ruleType.value !== 10 &&
                      info.ruleType.value !== 12 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "40px",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="primary"
                            sizing={"lg"}
                          />
                        </div>
                      )}
                    {subTypeData.length <= 0
                      ? ""
                      : info.ruleType.value !== "" &&
                      info.ruleType.value !== 2 &&
                      info.ruleType.value !== 10 &&
                      !subTypeLoading &&
                      (subTypeData.length > 0 || info.subType !== "") && (
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>Rule Sub-type:</Form.Label>
                            <Select
                              name="subType"
                              value={info.subType}
                              options={subTypeDropdown}
                              placeholder={"Please select sub-type"}
                              isDisabled={mode}
                              className="react-select-container select"
                              onChange={(e) => {
                                handleSubTypeUpcs();
                                handleInput(e, "subType");
                                handleChange("subType");
                                dispatch(
                                  getValidations(info.ruleType.value, e.value)
                                );
                              }}
                            />
                          </Form.Group>
                          {formError.subType && (
                            <p
                              style={{ color: "red" }}
                              className="error-message"
                            >
                              {formError.subType}
                            </p>
                          )}
                        </Col>
                      )}
                    {validationLoading && subTypeData.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        <Spinner
                          animation="border"
                          variant="primary"
                          sizing={"lg"}
                        />
                      </div>
                    )}
                    {(info.subType.value !== "" || subTypeData.length > 0) &&
                      !subTypeLoading &&
                      !validationLoading &&
                      validationData &&
                      validationData
                        .filter(
                          (i) =>
                            i.Section === "rule_type_info" &&
                            i.Field === "Enter UPCs"
                        )
                        .map((i) => {
                          return (
                            <Col xs={12} key={i}>
                              <fieldset disabled={mode}>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Enter UPCs</Form.Label>
                                  <PastedChips
                                    infoChipsData={info?.upcsData}
                                    upcsdata
                                    mode={mode}
                                    handleDelete={handleDelete}
                                    upc={upc}
                                    setUpc={setUpc}
                                    setUpcs={setUpcs}
                                    upcs={upcs}
                                    handleUpcs={handleUpcs}
                                    name={"upcsData"}
                                    setFormError={setFormError}
                                    PlaceHolderText={"enter UPCs"}
                                  />
                                </Form.Group>
                              </fieldset>
                              {formError.upcs && (
                                <p
                                  style={{ color: "red" }}
                                  className="error-message"
                                >
                                  {formError.upcs}
                                </p>
                              )}
                            </Col>
                          );
                        })}
                    {(info.subType.value !== "" || subTypeData.length > 0) &&
                      !subTypeLoading &&
                      !validationLoading &&
                      validationData &&
                      validationData
                        .filter(
                          (i) =>
                            i.Section === "rule_type_info" &&
                            i.Field === "Choose Department"
                        )
                        .map((i) => {
                          return (
                            <Col xs={12} key={i}>
                              <Form.Group>
                                <Form.Label>
                                  Choose Departments
                                  <Form.Check
                                    type="checkbox"
                                    label="Select all departments..."
                                    id="custom-switch"
                                    name="chooseDeptCheckBox"
                                    disabled={mode}
                                    checked={chooseDeptCheckBox}
                                    onChange={(e) => handleAllCheck(e)}
                                  />
                                </Form.Label>
                                <Select
                                  isMulti
                                  options={departmentDropdown}
                                  placeholder={"Please select departments..."}
                                  value={info.departments}
                                  isDisabled={mode}
                                  className="react-select-container select"
                                  onChange={(e) => {
                                    handleMultiSelectInput(e, "departments");
                                    handleChange("departments");
                                  }}
                                />

                                <div className="p-17 mt-20">
                                  <div className="row g-3 mb-20">
                                    <div className="col-12"></div>
                                  </div>
                                </div>
                              </Form.Group>
                              {formError.departments && (
                                <p
                                  style={{ color: "red" }}
                                  className="error-message"
                                >
                                  {formError.departments}
                                </p>
                              )}
                            </Col>
                          );
                        })}
                  </Row>
                </Form>
                <div className="flex align-items-center justify-content-end text-end">
                  <Button
                    className="BlueButton ml-10 mt-40"
                    disabled={validationData.length === 0 ? true : false}
                    onClick={() => {
                      handleStepNext();
                      handleStepDispatch();
                    }}
                  >
                    Next
                    <img
                      src={NextArrow}
                      alt="Next Arrow"
                      className="img-fluid ml-5"
                    />
                  </Button>
                </div>
              </React.Fragment>
            )}
          </div>
        </Col>
      )}

      {checkMode || info.ruleType.value === "" ? (
        ""
      ) : (
        <Col lg={3}>
          <div className="Wizard_Content_Card Rule_Type_Info preview-container">
            <div
              style={{ backgroundColor: "#3D8ECC", backgroundSize: "cover" }}
            >
              <h5 style={{ textAlign: "center", color: "white" }}>
                Rule Definition
              </h5>
            </div>
            <div className="Common_Block_Body pt-12 pr-12 pb-12 pl-12">
              <div className="Coupons_Card text-center">
                <img
                  src={scissors}
                  className="img-fluid scissors"
                  alt="Products"
                />
                {/* {
                info.ruleType.label === "Please select rule-type..."?
                (
                  ""
                ):
                (
                  <h6 style={{marginTop:"-60px"}}>{info.ruleType.label}</h6>
                )
              }
              {
                info.subType.label === "Please select sub-type..." ?
                (
                  ""
                ):
                (
                  <h6 style={{fontSize:"12px", marginTop:"5px"}}>{info.subType.label}</h6>
                )
              } */}



                {ruleTypeDropdown &&
                  ruleTypeDropdown
                    .filter((each) => each.value === info.ruleType?.value)
                    .map((eachDec) => {
                      return (
                        <p style={{ textAlign: "left", marginTop: "-60px" }}>
                          {eachDec.dec}
                        </p>
                      );
                    })}
              </div>
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
}
