export const loginAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const createUserAction = (type, data, message, loading) => {
  return { type: type, payload: { data, message, loading } };
};

export const deleteUserAction = (type, data, message, loading) => {
  return { type: type, payload: { data, message, loading } };
};

export const editUserAction = (type, data, message, loading) => {
  return { type: type, payload: { data, message, loading } };
};

export const GetUserListAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: { data, message, loading },
  };
};

export const GetSingleUserAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: { data, message, loading },
  };
};

export const GetRolesAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: { data, message, loading },
  };
};

export const summaryDataAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const ruleTypeAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const departmentAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const subTypeAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const duringAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const shopperAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const getValidationAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const storesAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const offerAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const offerDurationAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const submitRuleAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const graphDataAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Rule Analysis Action Item
export const ruleAnalysisAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const analysisGroupsByRuleAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const analysisPerformanceAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

//Rule Analysis : GetRule Data Action Item
export const getSingleRuleData = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const ruleListAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const expiretRuleAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const bankOfferAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const zipcodeAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};
export const pendingApprovalAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const forgotPasswordAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

//change password

export const changePasswordAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const groupsAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Approve Pedning Rule CommonReducers

export const pendingRuleAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const approveRuleDataAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const rejectRuleDataAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const createdOffersAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const expireCouponAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const getCreatedOffersAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

//Analysis Download Report

export const ruleAnalysisDownloadReportAction = (
  type,
  data,
  message,
  loading
) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Dashboard Overview
export const getDashboardOverviewAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Dashboard Shopper Groups
export const getDashboardShopperGroupsAction = (
  type,
  data,
  message,
  loading
) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Dashboard Departments
export const getDashboardDepartmentsAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Dashboard Focus Items
export const getDashboardFocusItemsAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Dashboard Department Data Analysis
export const getDashboardDepartmentDataAnalysisAction = (
  type,
  data,
  message,
  loading
) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Dashboard Focus Item Analysis
export const getDashboardFocusItemAnalysisAction = (
  type,
  data,
  message,
  loading
) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// Dashboard Group Item Analysis
export const getDashboardGroupAnalysisAction = (
  type,
  data,
  message,
  loading
) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// create-focus-items

export const getCreatFocusItemAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// create-focus-items

export const createPromotionAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// create-focus-items

export const getSegementDataAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// get promotions

export const getPromotionsAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// expire promotions

export const expirePromotionAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const frequencyAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const setDepartmentsAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

export const getTrackedDepartmentsAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};

// edit-focus-items

export const editFoucsItemAction = (type, data, message, loading) => {
  return {
    type: type,
    payload: {
      data,
      message,
      loading,
    },
  };
};
