/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import ButtonArrow from "../../assets/images/button-arrow.svg";
import SliderImage1 from "../../assets/images/slider-image-1.png";
import Slider from "react-slick";
import Mail from "../../assets/images/message.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CheckYourMail extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 7000,
    };
    return (
      <div className="CommonFormBg SignIn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pt-10 pb-10">
              <Link to="/">
                <img src={Logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="Wrapper mt-85">
            <div className="row g-4 justify-content-center">
              <div className="col-xl-4 col-lg-6 col-12">
                <div className="Form_Card">
                  <div className="Icon">
                    <img src={Mail} className="img-fluid" alt="Logo" />
                  </div>
                  <h2 className="Form_Ttl">Check Your Mail</h2>
                  <p className="mt-13">
                    We have sent a password recovery instructions to your email.
                  </p>
                  <form className="mt-34">
                    <Link
                      to="/CreatePassword"
                      className="FormButton d-block mt-37"
                    >
                      Open email app
                      <img
                        src={ButtonArrow}
                        className="img-fluid ml-11 "
                        alt="Arrow"
                      />
                    </Link>
                    <a className="Link mt-30 d-block text-center" href="/">
                      Skip, I’ll confirm later
                    </a>
                    <span className="RegisterLink mt-20 text-center d-block">
                      Did not receive the email? Check your spam filter, or{" "}
                      <Link to="/ResetPassword" className="d-block">
                        {" "}
                        try another email address
                      </Link>
                    </span>
                  </form>
                </div>
                <ul className="nav CustomLink mt-120">
                  <li>
                    <a href="#">Terms</a>
                  </li>
                  <li>
                    <a href="#">Privacy</a>
                  </li>
                  <li>
                    <a href="#">Home</a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-4 col-lg-6 col-12 offset-xl-2">
                <Slider {...settings}>
                  <div className="text-center">
                    <img
                      src={SliderImage1}
                      className="img-fluid mx-auto"
                      alt="Logo"
                    />
                    <h3>Autopilot</h3>
                    <p>
                      Unlock Rapid Insights, Boost Engagement: Analyze Shopper
                      Group Dynamics by Total Spend, Department, and Item
                      Performance — Intelligent Automation for Targeted Actions.
                    </p>
                  </div>
                  <div className="text-center">
                    <img
                      src={SliderImage1}
                      className="img-fluid mx-auto"
                      alt="Logo"
                    />
                    <h3>Autopilot</h3>
                    <p>
                      Drive Customer Loyalty Automatically with AI and ML:
                      Personalized Offers, Scheduled Rewards, Seamless
                      Analytics.
                    </p>
                  </div>
                  <div className="text-center">
                    <img
                      src={SliderImage1}
                      className="img-fluid mx-auto"
                      alt="Logo"
                    />
                    <h3>Autopilot</h3>
                    <p>
                      Effortless Loyalty, Endless Rewards: Tailor-Made Rules for
                      everyday Grocer Needs.
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckYourMail;
