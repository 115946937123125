/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";

function Insights() {
  return (
    <React.Fragment>
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
          <div className="container">
            <div className="home-page-contain">
              <div class="insight-item-title">
                <select>
                  <option>All Stores</option>
                  <option>Farmbri Stores</option>
                  <option>Flatbush Stores</option>
                  <option>Wyckoff Stores</option>
                  <option>Junction Stores</option>
                </select>
              </div>

              <div class="insight-item">
                <div class="main-title">
                  <h2>insights</h2>
                </div>
                <div class="insight-item-wrapper">
                  <div class="insight-report-main">
                    <div class="insight-report-detail">
                      <div class="insight-wrapper">
                      <h2>Revenue Trends</h2>
                      </div>
                    </div>
                  </div>
                  <div class="insight-report-main">
                    <div class="insight-report-detail">
                      <div class="insight-wrapper">
                      <h2>Shopper Group Analysis</h2>
                      </div>
                    </div>
                  </div>
                  <div class="insight-report-main">
                    <div class="insight-report-detail">
                      <div class="insight-wrapper">
                      <h2>Month-over-Month Growth (%)</h2>
                      </div>
                    </div>
                  </div>
                  <div class="insight-report-main">
                    <div class="insight-report-detail">
                      <div class="insight-wrapper">
                      <h2>Key Takeaways</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Insights;
