/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { Link } from "react-router-dom";
import users from "../../../../assets/images/users.svg";
function IncomeServay({ surveyData, storeId, deptId, focusId }) {
  return (
    <React.Fragment>
      <div className="income-servay-warpper" title={surveyData.hover_text}>
        <div className="income-title-num">
          <div className="income-title">
            <div className="income-servay-title">{surveyData.name}</div>
          </div>

          {surveyData.trend && (
            <div className="income-servay-num text-success">
              {surveyData.trend === "Up" ? (
                <div className="income-up-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M9 5v2h6.59L4 18.59L5.41 20L17 8.41V15h2V5z"
                    />
                  </svg>
                </div>
              ) : (
                <div className="income-down-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                  >
                    <g transform="rotate(180 12 12)">
                      <path
                        fill="currentColor"
                        d="M9 5v2h6.59L4 18.59L5.41 20L17 8.41V15h2V5z"
                      ></path>
                    </g>
                  </svg>
                </div>
              )}
              {surveyData?.trend_percent && (
                <span>{surveyData.trend_percent}</span>
              )}
              <div className="income-year">
                <span>{surveyData.trend_text}</span>
                <span className="icome-text">{surveyData.trend_text}</span>
              </div>
            </div>
          )}
        </div>
        <div className="income-date-year">
          <div className="income-date">
            <span>{surveyData.prev_month}</span>
          </div>
        </div>
        <div className="income-title-wrapper">
          <div className="income-main-title">{surveyData.total_sales}</div>
          <div className="income-title-tooltip">
            <span>Loyalty sales.</span>
          </div>
        </div>
        <div className="income-shoppers">
          <span>{surveyData.total_shoppers}</span>
        </div>
        <div className="income-link-icon">
          <div className="link-sec">
            <div className="income-link">
              <Link
                to={`/GroupAnalysis?store_id=${storeId}&group_id=${surveyData.group_id}`}
              >
                View
              </Link>
            </div>
            <div className="promotion-link">
              <Link
                target="_top"
                to={`/NewPromotion?store_id=${storeId}&group_id=${
                  surveyData.group_id
                }&department_id=${deptId ?? 0}&focus_id=${focusId ?? ""}`}
              >
                Create Promotion
              </Link>
            </div>
          </div>
          <div className="income-icon">
            <img
              width="24"
              height="24"
              alt="icon"
              src={users}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default IncomeServay;
