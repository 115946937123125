/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



function PerformanceBarChart({salesGraph}) {
  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        font: { size: '29' },
        color: '333',
        position: 'top',
        text: 'Sales Overview',
      },
    },
  };

  const labels = salesGraph?.map(item => item.department_name);
  const data = {
    labels,
    datasets: [
      {
        label: salesGraph?.map(item => item.second_month_name)[0],
        data: salesGraph?.map(item => item.second),
        backgroundColor: 'rgba(0, 104, 151, 0.5)',
      },
      {
        label: salesGraph?.map(item => item.first_month_name)[0],
        data: salesGraph?.map(item => item.first),
        backgroundColor: 'rgba(100, 201, 254, 0.5)',
      },
    ],
  };
  
  return (
    <React.Fragment>
      <div className="performancebar col-md-6">
        <div className="performancebar-main-title">
          <div className="department-img-title">
          <div className="main-title">
            <h2>department trend</h2>
          </div>
          </div>
        </div>

        <Bar options={options} data={data} />
      </div>
    </React.Fragment>
  );
}

export default PerformanceBarChart;