/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import salesenablement from "../../../assets/images/salesenablement.png";
import statistics from "../../../assets/images/statistics.png";
import increase from "../../../assets/images/increase.png";
import analyzeupicon from "../../../assets/images/analyzeupicon.svg";
import analyzedownicon from "../../../assets/images/analyzedownicon.svg";
import PerformanceServay from "./PerformanceServay/PerformanceServay";

function PerformanceGroup() {


    return (
        <React.Fragment>
            <div className="department-item">
                <div className="main-title-wrapper">
                    <div className="main-title">
                        <h2>Items Performance</h2>
                    </div>
                    <div className="view-link">
                        <a href="/Focusitem">View all</a>
                    </div>
                </div>
                <div className="grid">
                    <PerformanceServay Title="top 20%" ButtonText="analyze" ProductImage={salesenablement} ImageIcon={analyzeupicon} />
                    <PerformanceServay Title="middle 40%" ButtonText="analyze" ProductImage={statistics} ImageIcon={analyzeupicon} />
                    <PerformanceServay Title="bottom 20%" ButtonText="analyze" ProductImage={increase} ImageIcon={analyzeupicon} />
                    <PerformanceServay Title="lost" ButtonText="analyze" ProductImage={salesenablement} ImageIcon={analyzedownicon} />

                </div>
            </div>
        </React.Fragment>
    );
}

export default PerformanceGroup;
