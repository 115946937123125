/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { getGroups, getSubType } from "../../redux/API";
import { useNavigate } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartments,
  //getDuring,
  // getFrequency,
  getOfferType,
  getRuleData,
  getRuleType,
  getShoppers,
  getStores,
  getValidations,
  getBankOffer,
  getZipcodes
} from "../../redux/API";
import usePrevious from "../../public/PrevValue";
import CONSTANT_VALUES from "../../public/constants";
import { getDate } from "../../public/auth";
import Notifications from "./Notifications";

const couponInitialObject = {
  name: "",
  frequency: CONSTANT_VALUES.frequency,
  offertype: CONSTANT_VALUES.offerType,
  offerstart: 0,
  description: "",
  image: "",
  minamount: "0",
  minquantity: 1,
  reward_min_quantity: 1,
  rewardamount: "0",
  isdiscountpercentage: 0,
  purchaseupcs: [],
  rewardupcs: [],
  stores: [],
  departments: [],
  step4Departments: [],
  expirePrevCoupon: 0,
  isdow: 0,
  coupon_limit: 1,
  isfeaturecoupon: 0,
  includeinweeklyemail: 0,
  validityindays: 1,
  coupon_start_time: "00:00",
  coupon_end_time: "23:59",
  sendpushnotification: 0,
  message: "",
  pushdaysfrom: 0,
  time: "08:00",
  postfb: 0,
  fbmessage: "",
  fbdaysfrom: 0,
  fbtime: "09:00",
  sendsms: 0,
  smsmessage: "",
  smsdaysfrom: 0,
  smstime: "10:00",
  sendemail: 0,
  emailmessage: "",
  emaildaysfrom: 0,
  emailtime: "11:00",
  couponStartDate: "",
  couponStartDay: ""
};

const notificationInitialObject = {
  sendpushnotification: 0,
  message: "",
  time: "08:00",
  postfb: 0,
  fbmessage: "",
  fbtime: "09:00",
  sendsms: 0,
  smsmessage: "",
  smstime: "10:00",
  sendemail: 0,
  emailmessage: "",
  emailtime: "11:00"
};
export default function Wizard(props) {
  const dispatch = useDispatch();
  const [stores, setStores] = useState([]);
  const [couponArray, setCouponArray] = useState([couponInitialObject]);
  const [offerBankArray, setOfferBankArray] = useState([]);
  const [notificationArray, setNotificationArray] = useState([
    notificationInitialObject
  ]);
  const [id, setID] = useState("");
  const [offerCheckBox, setOfferCheckBox] = useState(true);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [value, setValue] = useState({
    rule: "",
    ruleType: CONSTANT_VALUES.ruleType,
    subType: CONSTANT_VALUES.subType,
    departments: [],
    upcsData: [],
    upcs: [],
    rewardOffer: false,
    during: CONSTANT_VALUES.during,
    numberOfTime: 0,
    max_times_purchased: 0,
    visits_containing_upcs: 1,
    max_visits_containing_upcs: 1,
    amountSpent: 0.0,
    max_amount_spend: 0,
    storesList: [],
    stores: [],
    limitstoresfieldselected: "",
    shoppers: CONSTANT_VALUES.shoppers,
    chooseTop: 0,
    zipCodes: [],
    zipCodesData: [],
    existing_groups: [],
    existing_groupsList: [],
    createGroups: false,
    bankOffer: false,
    suggestOffer: false,
    SuggestedOfferCount: 1,
    createOffer: false,
    coupon: "",
    selectValue: "",
    OfferBankArray: [],
    CouponArray: [],
    notifications: "",
    NotificationArray: [],
    chooseDepartmentsData: [],
    chooseDepartments: [],
    purchaseUpcsData: [],
    rewardUpcsData: [],
    expirePrevCoupon: 0,
    frequency: CONSTANT_VALUES.frequency,
    startDate: "",
    endDate: "",
    email: [],
    offerRequired: true,
    notifyAdmin: true,
    groupStatics: true,
    // notifySuggestedOffers: true,
    iselastic: true,
    createPoints: 0,
    points: "",
    CouponLimitInSingleTrans: "0",
    IsMonthly: false
  });

  const [dropdown, setDropdown] = useState({
    ruleType: [],
    subType: [],
    during: [],
    shoppers: [],
    offerType: [],
    frequency: [],
    departments: [],
    groups: []
  });

  let { mode, guid } = useParams();
  const [validation, setValidation] = useState([]);
  const [stepper, setStepper] = useState([]);
  const [step, setStep] = useState(1);

  const [flow, setFlow] = useState("");

  const location = useLocation();

  const locationId = location?.state?.copyguid;
  const [checked, setChecked] = useState([]);
  //const [checkedZipcode,setCheckedZipcode] = useState([]);

  const [disableField, setDisableFiels] = useState(false);

  const ruleTypeData = useSelector((state) => state.ruleTypeData);
  const ruleTypeMessage = useSelector((state) => state.ruleTypeMessage);
  const prevruleTypeMessage = usePrevious(ruleTypeMessage);
  const ruleTypeLoading = useSelector((state) => state.ruleTypeLoading);

  const subTypeData = useSelector((state) => state.subTypeData);
  const subTypeMessage = useSelector((state) => state.subTypeMessage);
  const prevSubTypeMessage = usePrevious(subTypeMessage);
  const subTypeLoading = useSelector((state) => state.subTypeLoading);

  const departmentData = useSelector((state) => state.departmentData);
  const departmentMessage = useSelector((state) => state.departmentMessage);
  const prevDepartmentMessage = usePrevious(departmentMessage);
  const departmentLoading = useSelector((state) => state.departmentLoading);

  const duringData = useSelector((state) => state.duringData);
  const duringMessage = useSelector((state) => state.duringMessage);
  const prevDuringMessage = usePrevious(duringMessage);
  const duringLoading = useSelector((state) => state.duringLoading);

  const storesData = useSelector((state) => state.storesData);
  const storesMessage = useSelector((state) => state.storesMessage);
  const prevstoresMessage = usePrevious(storesMessage);
  const storesLoading = useSelector((state) => state.storesLoading);

  const frequencyData = useSelector((state) => state.frequencyData);
  const frequencyMessage = useSelector((state) => state.frequencyMessage);
  const prevFrequencyMessage = usePrevious(frequencyMessage);
  const frequencyLoading = useSelector((state) => state.frequencyLoading);

  const shopperData = useSelector((state) => state.shopperData);
  const shopperMessage = useSelector((state) => state.shopperMessage);
  const prevShopperMessage = usePrevious(shopperMessage);
  const shopperLoading = useSelector((state) => state.shopperLoading);

  const groupsData = useSelector((state) => state.groupsData);
  const groupsMessage = useSelector((state) => state.groupsMessage);
  const prevGroupsMessage = usePrevious(groupsMessage);
  const groupsLoading = useSelector((state) => state.groupsLoading);

  const validationData = useSelector((state) => state.validationData);
  const validationMessage = useSelector((state) => state.validationMessage);
  const prevValidationMessage = usePrevious(validationMessage);
  const validationLoading = useSelector((state) => state.validationLoading);

  const offerData = useSelector((state) => state.offerData);
  const offerMessage = useSelector((state) => state.offerMessage);
  const prevOfferMessage = usePrevious(offerMessage);
  const offerLoading = useSelector((state) => state.offerLoading);

  const bankOfferData = useSelector((state) => state.bankOfferData);
  const bankOfferMessage = useSelector((state) => state.bankOfferMessage);
  const prevBankOfferMessage = usePrevious(bankOfferMessage);
  const bankOfferLoading = useSelector((state) => state.bankOfferLoading);

  const zipcodeData = useSelector((state) => state.zipcodeData);
  const zipcodeMessage = useSelector((state) => state.zipcodeMessage);
  const zipcodeLoading = useSelector((state) => state.zipcodeLoading);

  const singleRuleData = useSelector((state) => state.singleRuleData);
  const singleRuleMessage = useSelector((state) => state.singleRuleMessage);
  const prevSingleRuleMessage = usePrevious(singleRuleMessage);
  const singleRuleLoading = useSelector((state) => state.singleRuleLoading);
  
  const [formError, setFormError] = useState({
    coupon: "",
    offerType: "",
    frequency: "",
    shoppers: "",
    zipCode: "",
    purchaseUpcs: "",
    rewardupcs: "",
    offer: "",
    chooseTop: "",
    chooseStore: "",
    offerGenerate: "",
    existingGroupsIds: "",
    rewardamount: "",
    pushNotification: "",
    emailmessage: "",
    storesZip: "",
    zipCode: "",
    image: ""
  });
  const handleChange = (name) => {
    setFormError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const rewardOfferChange = (event) => {
    if (event.target.checked) {
      setValue((value) => ({
        ...value,
        rewardOffer: true,
        createPoints: 0
      }));
    } else {
      setValue((value) => ({
        ...value,
        rewardOffer: false
      }));
      setFormError((prev) => {
        return { ...prev, offer: "" };
      });
    }
    setOfferCheckBox(event.target.checked);
  };

  const handleExpirePrevCoupon = (event) => {
    if (event.target.checked) {
      setValue((value) => ({
        ...value,
        expirePrevCoupon: 1
      }));
    } else {
      setValue((value) => ({
        ...value,
        expirePrevCoupon: 0
      }));
    }
  };
  const radioChange = (e, name) => {
    setValue((value) => ({
      ...value,
      createOffer: true,
      suggestOffer: false,
      bankOffer: false,
      [name]: e.target.checked
    }));
    setFormError((prev) => {
      return { ...prev, offer: "" };
    });
  };
  const suggestRadio = (e, name) => {
    setValue((value) => ({
      ...value,
      suggestOffer: true,
      createOffer: false,
      bankOffer: false,
      [name]: e.target.checked
    }));
    setFormError((prev) => {
      return { ...prev, offer: "" };
    });
  };
  const bankRadio = (e, name) => {
    setValue((value) => ({
      ...value,
      bankOffer: true,
      createOffer: false,
      suggestOffer: false,
      [name]: e.target.checked
    }));

    setFormError((prev) => {
      return { ...prev, offer: "" };
    });
  };

  const handleStepNextRewardOffer = () => {
    if (value.createGroups === false) {
      if (value.coupon === "") {
        setFormError((prev) => {
          return { ...prev, coupon: "Please enter coupon name" };
        });
      } else if (value.offerType.value === "") {
        setFormError((prev) => {
          return { ...prev, offerType: "Please choose offertype" };
        });
      } else if (
        value.offerType.label === "UPC" &&
        value.purchaseUpcsData.length === 0
      ) {
        setFormError((prev) => {
          return { ...prev, purchaseUpcs: "Please enter purchase upcs" };
        });
      } else {
        handleStep("next");
      }
    } else {
      handleStep("next");
    }
  };

  const handleCouponTimesInput = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleStepNextLimitsAndstores = () => {
    var isValid = true;
    if (value.chooseTop === "") {
      isValid = false;
      setFormError((prev) => {
        return { ...prev, chooseTop: "Please choose top shoppers" };
      });
    }
    if (isValid) {
      handleStep("next");
    }
  };

  const handleManualInput = (name, val) => {
    setValue({
      ...value,
      [name]: val
    });
  };
  const handleInput = (e, name) => {
    setValue({
      ...value,
      [name]: e
    });
  };

  const handleToggle = (e, name) => {
    setValue({
      ...value,
      handleToggle,
      [name]: e
    });
  };

  const handleUpcs = (data, name) => {
    setFormError((prev) => {
      return { ...prev, purchaseUpcs: "" };
    });
    setValue({
      ...value,
      [name]: data
    });
  };

  const handleZipCodesValidations = (data) => {
    let c = 0;
    data.map((info) => {
      if (!isValidZipcode(info.email)) {
        c = c + 1;
        setFormError((prev) => {
          return { ...prev, zipCode: "invalid zipcode" };
        });
      }
      if (c === 0) {
        setFormError((prev) => {
          return { ...prev, zipCode: "" };
        });
      }
    });
  };
  function isValidZipcode(zipcode) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode);
  }

  const handleZipCodes = (data, name, parentName) => {
    // let arr = data.map((info) => info.email);
    setValue({
      ...value,
      [name]: data,
      [parentName]: data
    });
  };

  const handleValidation = (val) => {
    setValidation(val);
  };

  const handleStepper = (val) => {
    setStepper(val);
  };

  const handleMultiSelectInput = (e, name) => {
    setValue({
      ...value,
      [name]: e
    });
  };

  const handleMultiStores = (e, name) => {
    if (value.storesList.length >= 0) {
      setFormError((prev) => {
        return { ...prev, chooseStore: "", storesZip: "" };
      });
    }
    if (value.storesList.length > storesData?.length) {
      setStoresCheckBox(true);
    } else {
      setStoresCheckBox(false);
    }
    setValue({
      ...value,
      [name]: e
    });
  };

  const handleCheckBox = (e) => {
    setValue({
      ...value,
      createOffer: e.target.checked
    });
  };

  const handleStep = (value) => {
    if (value === "next") {
      setFlow(value);
      setStep(step + 1);
    } else {
      setFlow("previous");
      setStep(step - 1);
    }
  };

  const handleRuleType = (e) => {
    //console.log("CURRENT TARGET",value.ruleType.value)
    setStep(1);
    e.preventDefault();
  };

  const handleRuleSchedule = (e) => {
    //console.log("CURRENT TARGET",value.ruleType.value)
    setStep(2);
    e.preventDefault();
  };

  const handleCriteria = (e) => {
    //console.log("CURRENT TARGET",value.ruleType.value)
    setStep(3);
    e.preventDefault();
  };

  const handleLimits = (e) => {
    //console.log("CURRENT TARGET",value.ruleType.value)
    setStep(4);
    e.preventDefault();
  };

  const handleRewardOffers = (e) => {
    //console.log("CURRENT TARGET",value.ruleType.value)
    setStep(5);
    e.preventDefault();
  };

  const handlenotifications = (e) => {
    //console.log("CURRENT TARGET",value.ruleType.value)
    setStep(6);
    e.preventDefault();
  };

  const handlePreview = (e) => {
    //console.log("CURRENT TARGET",value.ruleType.value)
    setStep(7);
    e.preventDefault();
  };

  const handleNotificationInput = (e) => {
    setValue({
      ...value,
      notification: {
        ...value.notification,
        [e.target.name]: e.target.value
      }
    });
  };
  const handlePostNotificationInput = (e) => {
    setValue({
      ...value,
      postnotification: {
        ...value.postnotification,
        [e.target.name]: e.target.value
      }
    });
  };
  const handleSmsNotificationInput = (e) => {
    setValue({
      ...value,
      smsnotification: {
        ...value.smsnotification,
        [e.target.name]: e.target.value
      }
    });
  };
  const handleEmailNotificationInput = (e) => {
    setValue({
      ...value,

      emailnotification: {
        ...value.emailnotification,
        [e.target.name]: e.target.value
      }
    });
  };

  const blockInvalidChar = (e) => {
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  };
  const blockInvalidCharMin = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const [basketDisable, setBasketDisable] = useState(false);
  const [upcDisable, setUpcDisable] = useState(false);
  const [couponLimitDisable, setCouponLimitDisable] = useState(false);
  const [dowDisabled, setDowDisabled] = useState(false);
  const [featuredDisabled, setFeaturedDisabled] = useState(false);
  const [weeklyDisabled, setWeeklyDisabled] = useState(false);
  const [heading, setHeading] = useState("New AutoPilot Rule");

  const handleOfferType = (e, name) => {
    if (e.label === "Basket") {
      setBasketDisable(true);
      setUpcDisable(false);
      setValue({
        ...value,
        [name]: e,
        minQuantity: 0
      });
    }

    if (e.label === "UPCS" || e.label === "Basket") {
      setUpcDisable(true);
      setBasketDisable(true);
    }
  };

  //
  const navigate = useNavigate();

  const createGroupsCheckBox = (e) => {
    if (value.createGroups === false) {
      setDisableFiels(true);
      setBasketDisable(true);
      setUpcDisable(true);
      setCouponLimitDisable(true);
      setDowDisabled(true);
      setFeaturedDisabled(true);
      setWeeklyDisabled(true);
      setValue({
        ...value,
        [e.target.name]: e.target.checked,
        coupon: "",
        offerType: CONSTANT_VALUES.offerType,
        offerDuration: "",
        minAmount: "",
        minQuantity: "",
        rewardAmount: "",
        disPercentage: false,
        expireOldCoupon: false,
        chooseDepartments: [""],
        chooseDepartmentsData: [],
        purchaseUpcsData: [],
        rewardUpcsData: [],
        couponLimit: 1,
        dow: false,
        featured: false,
        weeklyEmail: false,
        CouponLimitInSingleTrans: "0",
        IsMonthly: false
      });
      //validation
      setFormError((prev) => {
        return { ...prev, coupon: "" };
      });
      setFormError((prev) => {
        return { ...prev, offerType: "" };
      });
    }
    if (value.createGroups === true) {
      setBasketDisable(false);
      setUpcDisable(false);
      setDisableFiels(false);
      setCouponLimitDisable(false);
      setDowDisabled(false);
      setFeaturedDisabled(false);
      setWeeklyDisabled(false);

      setValue({
        ...value,
        offerDuration: 7,
        couponLimit: 1,
        [e.target.name]: e.target.checked
      });
    }
    setFormError((prev) => {
      return { ...prev, purchaseUpcs: "" };
    });
  };

  useEffect(() => {
    dispatch(getDepartments());
    dispatch(getRuleType());
    dispatch(getStores());
    //dispatch(getDuring());
    dispatch(getShoppers());
    dispatch(getOfferType());
    // dispatch(getFrequency());
    dispatch(getBankOffer());
    dispatch(getGroups());
    dispatch(getZipcodes());
    if (guid) {
      dispatch(getRuleData(guid));
    }
    if (locationId) {
      dispatch(getRuleData(locationId));
    }
  }, [dispatch, guid, locationId]);

  // ---useEffect for  RuleType/Subtype name displaying in the tittle of the wizard----------//
  useEffect(() => {
    //--- condition for subType dropDown values changes when we change Rule Type
    if (subTypeLoading && !mode) {
      value.subType = CONSTANT_VALUES.subType;
    }

    let name = value.ruleType.label;
    let sname = value.subType.label;
    if (mode) {
      let capitalized = mode.charAt(0).toUpperCase() + mode.slice(1);

      if (name === "All Shoppers") {
        setHeading(`${capitalized}(${name})Rule`);
      } else if (
        name !== "Please select rule-type..." &&
        sname !== "Please select sub-type..."
      ) {
        setHeading(`${capitalized}(${name}/${sname})Rule`);
      } else if (
        name !== "Please select rule-type..." &&
        sname === "Please select sub-type..."
      ) {
        setHeading(`${capitalized}(${name})Rule`);
      }
    } else {
      let capitalized = "New";
      if (name === "All Shoppers") {
        setHeading(`${capitalized}(${name})Rule`);
      } else if (
        name !== "Please select rule-type..." &&
        sname !== "Please select sub-type..."
      ) {
        setHeading(`${capitalized}(${name}/${sname})Rule`);
      } else if (
        name !== "Please select rule-type..." &&
        sname === "Please select sub-type..."
      ) {
        setHeading(`${capitalized}(${name})Rule`);
      }
    }
  }, [value.ruleType, value.subType]);

  const [valueDrop, setValueDrop] = useState(value.selectValue);
  const [upcs, setUpcs] = useState(mode ? value.upcsData : []);
  const handleSubTypeUpcs = () => {
    if (subTypeMessage === "Successful") {
      setUpcs([]);
      value.upcsData = [];
    }
  };

  useEffect(() => {
    if (singleRuleData.givepoints == 1) {
      setSelectedCheckbox("givePoints");
    } else {
      setSelectedCheckbox("offer");
    }
    let CreateGroupOnlyNoOfferRequired =
      !singleRuleData.CreateGroupOnlyNoOfferRequired;
    if (Object.keys(singleRuleData).length) {
      setID(singleRuleData.RuleGuid);
      setDropdown({
        ruleType: dispatch(getRuleType())
      });
      setValue({
        rewardOffer: CreateGroupOnlyNoOfferRequired,
        createPoints: singleRuleData.givepoints,
        points: singleRuleData.pointvalue,
        expirePrevCoupon: singleRuleData.coupon[0]
          ? singleRuleData.coupon[0].ExpirePrevCoupon
            ? 1
            : 0
          : 0,

        selectValue: singleRuleData.LimitStoresFieldSelected,
        rule: singleRuleData.RuleDescription,
        ruleType: singleRuleData.RuleTypeName,
        subType: singleRuleData.SubTypeName,
        departments: singleRuleData.criteria.departments,
        upcsData: singleRuleData.criteria?.upcs.map((info, index) => info),
        during: singleRuleData.criteria.InLastDays,
        numberOfTime: singleRuleData.criteria.NTimesPurchased,
        max_times_purchased: singleRuleData.criteria.MaxTimesPurchased,
        amountSpent: singleRuleData.criteria.AmountPurchased,
        max_amount_spend: singleRuleData.criteria.MaxAmountPurchased,
        visits_containing_upcs: singleRuleData.criteria.VisitsContainingUPCs,
        max_visits_containing_upcs:
          singleRuleData.criteria.MaxVisitsContainingUPCs,
        storesList:
          singleRuleData.stores.length === 0 &&
          value?.selectValue === "Choose stores"
            ? value.storesList
            : singleRuleData.stores,
        chooseTop: singleRuleData.criteria?.TopNCustomers,
        // existing_groupsList:singleRuleData.criteria?.ExistingGroupList.map((info,index) =>info),
        existing_groupsList: singleRuleData.criteria?.ExistingGroupList,
        zipCodesData: singleRuleData.criteria?.ZipCodeList.map(
          (info, index) => info
        ),

        bankOffer: false,
        // isElastic: singleRuleData?.IsElastic,
        suggestOffer: singleRuleData?.SuggestedOffer ? true : false,
        SuggestedOfferCount: singleRuleData?.SuggestedOfferCount,
        createOffer: singleRuleData?.coupon.length > 0 ? true : false,
        coupon: singleRuleData?.coupon,
        notifications: singleRuleData?.notifications,
        chooseDepartmentsData: singleRuleData.criteria?.departments,
        frequency: singleRuleData.RunFrequency,
        startDate: getDate(singleRuleData.StartDate),
        endDate: getDate(singleRuleData.EndDate),
        email: singleRuleData.RuleNotificationEmail.split(","),
        offerRequired: singleRuleData.ApprovalRequired,
        notifyAdmin: singleRuleData.NotifyAdminPostRun,
        groupStatics: singleRuleData.UpdateGroupStats,
        // notifySuggestedOffers: singleRuleData?.notifysuggestedoffer
        //   ? true
        //   : false,
        iselastic: singleRuleData?.IsElastic ? true : false
      });
      // setValueDrop(
      //   singleRuleData.LimitStoresFieldSelected === "Zip code" ? "2" : "1"
      // );
      setEmails(singleRuleData.RuleNotificationEmail.split(","));
      setUpcs(singleRuleData.criteria?.upcs.map((info, index) => info));
      setStoresCheckBox(singleRuleData.stores.length === 0 ? true : false);
      setDropdown({
        subType: singleRuleData.RuleTypeName.value
          ? dispatch(getSubType(singleRuleData.RuleTypeName.value))
          : "",
        during: dropdown.during,
        offerType: dropdown.offerType,
        frequency: dropdown.frequency,
        departments: dropdown.departments,
        groups: dropdown.groups
      });

      dispatch(
        getValidations(
          singleRuleData.RuleTypeName?.value,
          singleRuleData.SubTypeName?.value
        )
      );
    }
  }, [dispatch, singleRuleData]);
  //const [count, setCount] = useState(0);
  useEffect(() => {
    //--------------------RuleType Section Starts-----------------------------------
    if (prevruleTypeMessage !== ruleTypeMessage && ruleTypeMessage) {
      if (ruleTypeMessage === "Successful") {
        let arr =
          ruleTypeData &&
          ruleTypeData.map((i) => {
            return {
              value: i.ApRuleTypeId,
              label: i.RuleTypeName,
              dec: i.RuleTypeDesc
            };
          });
        setDropdown({
          ...dropdown,
          ruleType: arr
        });
      } else if (ruleTypeMessage === "Internal server error.") {
        // toast.error("Session has Ended");
        navigate("/login");
      }
    }
    //--------------------RuleType Section Ends-----------------------------------

    //--------------------SubType Section Starts-----------------------------------
    if (prevSubTypeMessage !== subTypeMessage && subTypeMessage) {
      if (subTypeMessage === "Successful") {
        let arr =
          subTypeData &&
          subTypeData.map((i) => {
            return { value: i.ApRuleSubTypeId, label: i.SubTypeName };
          });
        setDropdown({
          ...dropdown,
          subType: arr
        });
      }
      if (subTypeMessage === "Internal server error.") {
        toast.error("Session has ended,Please Login Again!!!");
        navigate("/login");
      }
    }
    //--------------------SubType Section Ends-----------------------------------

    //--------------------Department Section Starts-----------------------------------
    if (prevDepartmentMessage !== departmentMessage && departmentMessage) {
      if (departmentMessage === "Successful") {
        let arr =
          departmentData &&
          departmentData.map((i) => {
            return { value: i.id, label: i.Name };
          });
        setDropdown({
          ...dropdown,
          departments: arr
        });
      } else if (departmentMessage === "Internal server error.") {
        toast.error(departmentMessage);
        navigate("/login");
      }
    }
    //--------------------Department Section Ends-----------------------------------

    //--------------------During Section Ends-----------------------------------
    if (duringMessage !== prevDuringMessage && duringMessage) {
      if (duringMessage === "Successful") {
        let arr =
          duringData &&
          duringData.map((i) => {
            return { value: i.ApRuleLastDaysId, label: i.RuleLastDaysName };
          });
        setDropdown({
          ...dropdown,
          during: arr
        });
      } else if (prevDuringMessage === "Internal server error.") {
        toast.error(prevDuringMessage);
        navigate("/login");
      }
    }
    //--------------------During Section Ends-----------------------------------

    //--------------------Shopper Section Starts-----------------------------------
    if (shopperMessage !== prevShopperMessage && shopperMessage) {
      if (shopperMessage === "Successful") {
        let arr =
          shopperData &&
          shopperData.map((i) => {
            return { value: i.ApRuleTopNShoppersId, label: i.RuleTopNShoppers };
          });
        setDropdown({
          ...dropdown,
          shoppers: arr
        });
      } else if (prevShopperMessage === "Internal server error.") {
        toast.error(prevShopperMessage);
        navigate("/login");
      }
    }
    //--------------------Shopper Section Starts-----------------------------------

    //--------------------Groups Section Starts-----------------------------------
    if (groupsMessage !== prevGroupsMessage && groupsMessage) {
      if (groupsMessage === "Successful") {
        let arr =
          groupsData &&
          groupsData.map((i) => {
            return { value: i.id, label: i.Name };
          });
        setDropdown({
          ...dropdown,
          groups: arr
        });
      } else if (prevGroupsMessage === "Internal server error.") {
        toast.error(prevGroupsMessage);
        navigate("/login");
      }
    }
    //--------------------Groups Section Starts-----------------------------------

    //--------------------Frequency Section Starts-----------------------------------
    if (prevFrequencyMessage !== frequencyMessage && frequencyMessage) {
      if (frequencyMessage === "Successful") {
        let arr =
          frequencyData &&
          frequencyData.map((i) => {
            return { value: i.ApRuleFrequencyId, label: i.RuleFrequency };
          });
        setDropdown({
          ...dropdown,
          frequency: arr
        });
      } else if (frequencyMessage === "Internal server error.") {
        toast.error(frequencyMessage);
        navigate("/login");
      }
    }
    //--------------------Frequency Section Ends-----------------------------------

    //--------------------Offer Section Starts-----------------------------------
    if (prevOfferMessage !== offerMessage && offerMessage) {
      if (offerMessage === "Successful") {
        let arr =
          offerData &&
          offerData.map((i) => {
            return { value: i.ApOfferTypeId, label: i.OfferTypeName };
          });
        setDropdown({
          ...dropdown,
          offerType: arr
        });
      } else if (offerMessage === "Internal server error.") {
        toast.error(offerMessage);
        navigate("/login");
      }
    }
    //--------------------Offer Section Ends-----------------------------------

    //--------------------Single Rule Starts-----------------------------------
    if (
      prevSingleRuleMessage !== singleRuleMessage &&
      singleRuleMessage &&
      singleRuleMessage !== "Successful"
    ) {
      toast.error(singleRuleMessage);
    }
    //--------------------Single Rule Section Starts-----------------------------------

    if (prevstoresMessage !== storesMessage && storesMessage) {
      if (storesMessage === "Successful") {
        let arr =
          storesData &&
          storesData.map((i) => {
            return { value: i.id, label: i.Name };
          });
        setStores(arr);
      } else if (storesMessage === "Internal server error.") {
        toast.error(storesMessage);
        navigate("/login");
      }
    }
    if (prevValidationMessage !== validationMessage && validationMessage) {
      if (validationMessage === "Successful") {
        let arr =
          validationData &&
          validationData.filter((each) => {
            return (
              each.Section === "limits & stores" &&
              each.Field != "Choose Top Shoppers"
            );
          });

        if (singleRuleData.LimitStoresFieldSelected) {
          // if (count === 0) {
          //   setValueDrop(singleRuleData.LimitStoresFieldSelected);
          //   setValue({
          //     ...value,
          //     selectValue: singleRuleData.LimitStoresFieldSelected
          //   });
          //   setCount(count + 1);
          // } else {
          //   setValueDrop(arr[0]?.Field);
          //   setValue({
          //     ...value,
          //     selectValue: arr[0]?.Field
          //   });
          // }
          setValueDrop(singleRuleData.LimitStoresFieldSelected);
          setValue({
            ...value,
            selectValue: singleRuleData.LimitStoresFieldSelected
          });
          if (
            value.ruleType != singleRuleData.RuleTypeName ||
            value.subType != singleRuleData.SubTypeName
          ) {
            setValueDrop(arr[0]?.Field);
            setValue({
              ...value,
              selectValue: arr[0]?.Field
            });
          }
        } else {
          setValueDrop(arr[0]?.Field);
          setValue({
            ...value,
            selectValue: arr[0]?.Field
          });
        }
      }
    }
  }, [
    departmentMessage,
    ruleTypeMessage,
    subTypeMessage,
    storesMessage,
    duringMessage,
    offerMessage,
    singleRuleMessage,
    frequencyMessage,
    shopperMessage,
    prevDuringMessage,
    prevstoresMessage,
    prevSubTypeMessage,
    prevruleTypeMessage,
    prevDepartmentMessage,
    prevShopperMessage,
    prevOfferMessage,
    prevFrequencyMessage,
    prevSingleRuleMessage,
    departmentData,
    dropdown,
    duringData,
    frequencyData,
    offerData,
    ruleTypeData,
    shopperData,
    storesData,
    subTypeData,
    groupsData,
    groupsMessage,
    prevGroupsMessage,
    validationMessage
  ]);

  useEffect(() => {
    if (!stepper.includes("criteria") && step === 3) {
      flow === "next" ? setStep(step + 1) : setStep(step - 1);
    }
    if (!stepper.includes("limits & stores") && step === 4) {
      flow === "next" ? setStep(step + 1) : setStep(step - 1);
    }
    if (!stepper.includes("reward offer") && step === 5) {
      flow === "next" ? setStep(step + 1) : setStep(step - 1);
    }
    if (stepper.length === 0 && step === 5) {
      if (flow === "previous") {
        setStep(step - 2);
      }
    }
    if (value.ruleType.value !== 6 && step === 6) {
      flow === "next" ? setStep(step + 1) : setStep(step - 1);
    }
    // if (
    //   (value.subType.value === 21 || value.subType.value === 22) &&
    //   step === 4
    // ) {
    //   if (flow === "next") {
    //     setStep(step);
    //   }
    // }
    // if (
    //   (value.subType.value === 21 || value.subType.value === 22) &&
    //   step === 5
    // ) {
    //   if (flow === "previous") {
    //     setStep(step - 1);
    //   }
    // }
    // if (
    //   (value.subType.value === 10 ||
    //     value.subType.value === 11 ||
    //     value.subType.value === 12) &&
    //   step === 5
    // ) {
    //   if (flow === "previous") {
    //     setStep(step - 3);
    //   }
    // }
  }, [stepper, step, flow, value]);

  // check box useState for stores

  const [storesCheckBox, setStoresCheckBox] = useState(true);

  const handleStoresCheck = (e) => {
    if (e.target.checked === true) {
      handleMultiStores(stores, "storesList");
      setStoresCheckBox(true);
    }
    if (e.target.checked === false) {
      handleMultiStores([], "storesList");
    }
    setStoresCheckBox(e.target.checked);
  };

  useEffect(() => {
    if (storesCheckBox === true) {
      value.storesList = stores;
    }
  }, [value, storesCheckBox, stores, subTypeData, ruleTypeData]);

  const [emails, setEmails] = useState(!mode ? value?.email : []);

  // zipcodes
  const [ZipCodeUpcs, setZipCodeUpcs] = useState(
    mode ? value.zipCodesData : []
  );

  /// useEffect for [Create Rule] stores,zipcode,select group if we change rule/sub type we are clearing zipcode & selectgroup array -----//
  useEffect(() => {
    if (validationMessage === "Successful" && !mode) {
      setValue(() => ({
        ...value,
        storesList: stores,
        existing_groupsList: [],
        zipCodesData: []
      }));
      setStoresCheckBox(true);
      setZipCodeUpcs([]);
    }
  }, [validationMessage]);

  // ----useEffect for step3DropDown [when we change sub/rule type dropdown value will change accordingly]----//
  useEffect(() => {
    if (validationMessage === "Successful" && mode) {
      value.storesList = stores;
    }
    if (validationMessage === "Successful") {
      value.selectValue = "";
    }
  }, [validationMessage]);

  //-- useEffect [edit& copy] for subType dropDown when we change ruletype----//
  useEffect(() => {
    if (mode && value.ruleType !== singleRuleData.RuleTypeName) {
      setValue({
        ...value,
        subType: CONSTANT_VALUES.subType
      });
    }
  }, [value.ruleType]);

  // useEffect(()=>{
  //   if(value.CouponArray === undefined && mode){
  //     setValue({
  //       ...value,
  //       CouponArray:[]
  //     })
  //   }
  // },[value.CouponArray])

  // console.log('modeDetails',mode)

  return (
    <>
      <div className="Content_Wrapper_Wizard  pt-50">
        <ToastContainer />
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h3 className="Wizard_Main_Ttl">{heading}</h3>
            </Col>
          </Row>
        </Container>
        <div className="Wizard mt-30">
          <div className="Wizard_Tab pb-100">
            <Container fluid>
              <Row className="justify-content-center">
                <Col xs={12}>
                  {mode === "view" ? (
                    <ul
                      className="nav  mb-3 justify-content-center"
                      id="pills-tab"
                      role="tablist">
                      <li className="nav-item" role="presentation">
                        <Link
                          to=""
                          className={`${step === 1 ? "active" : "completed"}`}
                          id="pills-schedule-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-schedule"
                          role="tab"
                          aria-controls="pills-schedule"
                          aria-selected="false"
                          onClick={handleRuleType}>
                          <span className="Circle"></span>
                          Rule Type Info
                        </Link>
                      </li>
                      <li className="nav-item" role="presentation">
                        <Link
                          to=""
                          className={`${
                            step === 2 ? "active" : step > 2 ? "completed" : ""
                          }`}
                          id="pills-schedule-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-schedule"
                          role="tab"
                          aria-controls="pills-schedule"
                          aria-selected="false"
                          onClick={handleRuleSchedule}>
                          <span className="Circle"></span>
                          Rule schedule
                        </Link>
                      </li>
                      {stepper.includes("criteria") && (
                        <li className="nav-item" role="presentation">
                          <Link
                            to=""
                            className={`${
                              step === 3
                                ? "active"
                                : step > 3
                                ? "completed"
                                : ""
                            }`}
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                            onClick={handleCriteria}>
                            <span className="Circle"></span>
                            Criteria
                          </Link>
                        </li>
                      )}
                      {stepper.includes("limits & stores") && (
                        <li className="nav-item" role="presentation">
                          <Link
                            to=""
                            className={`${
                              step === 4
                                ? "active"
                                : step > 4
                                ? "completed"
                                : ""
                            }`}
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-contact"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                            onClick={handleLimits}>
                            <span className="Circle"></span>
                            Limits & stores
                          </Link>
                        </li>
                      )}
                      {stepper.includes("reward offer") && (
                        <li className="nav-item" role="presentation">
                          <Link
                            to=""
                            className={`${
                              step === 5
                                ? "active"
                                : step > 5
                                ? "completed"
                                : ""
                            }`}
                            id="pills-reward-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-reward"
                            role="tab"
                            aria-controls="pills-reward"
                            aria-selected="false"
                            onClick={handleRewardOffers}>
                            <span className="Circle"></span>
                            Reward Offer{" "}
                            {offerBankArray?.length > 1 && (
                              <span style={{ color: "#3D8ECC" }}>
                                ({offerBankArray?.length})
                              </span>
                            )}
                          </Link>
                        </li>
                      )}
                      {value.ruleType.value === 6 && (
                        <li className="nav-item" role="presentation">
                          <Link
                            to=""
                            className={`${
                              step === 6
                                ? "active"
                                : step > 6
                                ? "completed"
                                : ""
                            }`}
                            id="pills-reward-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-reward"
                            role="tab"
                            aria-controls="pills-reward"
                            aria-selected="false"
                            onClick={handlenotifications}>
                            <span className="Circle"></span>
                            Notifications
                          </Link>
                        </li>
                      )}

                      <li className="nav-item" role="presentation">
                        <Link
                          to=""
                          className={`${step === 7 ? "active" : ""}`}
                          id="pills-Preview-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-Preview"
                          role="tab"
                          aria-controls="pills-Preview"
                          aria-selected="false"
                          onClick={handlePreview}>
                          <span className="Circle"></span>
                          Preview
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul
                      className="nav  mb-3 justify-content-center"
                      id="pills-tab"
                      role="tablist">
                      <li className="nav-item" role="presentation">
                        <Link
                          to=""
                          className={`${step === 1 ? "active" : "completed"}`}
                          style={{ cursor: "default" }}
                          // id="pills-schedule-tab"
                          // data-bs-toggle="pill"
                          // data-bs-target="#pills-schedule"
                          // role="tab"
                          // aria-controls="pills-schedule"
                          // aria-selected="false"
                        >
                          <span className="Circle"></span>
                          Rule Type Info
                        </Link>
                      </li>
                      <li className="nav-item" role="presentation">
                        <Link
                          to=""
                          className={`${
                            step === 2 ? "active" : step > 2 ? "completed" : ""
                          }`}
                          style={{ cursor: "default" }}
                          // id="pills-schedule-tab"
                          // data-bs-toggle="pill"
                          // data-bs-target="#pills-schedule"
                          // role="tab"
                          // aria-controls="pills-schedule"
                          // aria-selected="false"
                        >
                          <span className="Circle"></span>
                          Rule schedule
                        </Link>
                      </li>
                      {stepper.includes("criteria") && (
                        <li className="nav-item" role="presentation">
                          <Link
                            to=""
                            className={`${
                              step === 3
                                ? "active"
                                : step > 3
                                ? "completed"
                                : ""
                            }`}
                            style={{ cursor: "default" }}
                            // id="pills-profile-tab"
                            // data-bs-toggle="pill"
                            // data-bs-target="#pills-profile"
                            // role="tab"
                            // aria-controls="pills-profile"
                            // aria-selected="false"
                          >
                            <span className="Circle"></span>
                            Criteria
                          </Link>
                        </li>
                      )}
                      {stepper.includes("limits & stores") && (
                        <li className="nav-item" role="presentation">
                          <Link
                            to=""
                            className={`${
                              step === 4
                                ? "active"
                                : step > 4
                                ? "completed"
                                : ""
                            }`}
                            style={{ cursor: "default" }}
                            // id="pills-contact-tab"
                            // data-bs-toggle="pill"
                            // data-bs-target="#pills-contact"
                            // role="tab"
                            // aria-controls="pills-contact"
                            // aria-selected="false"
                          >
                            <span className="Circle"></span>
                            Limits & stores
                          </Link>
                        </li>
                      )}
                      {stepper.includes("reward offer") && (
                        <li className="nav-item" role="presentation">
                          <Link
                            to=""
                            className={`${
                              step === 5
                                ? "active"
                                : step > 5
                                ? "completed"
                                : ""
                            }`}
                            style={{ cursor: "default" }}
                            // id="pills-reward-tab"
                            // data-bs-toggle="pill"
                            // data-bs-target="#pills-reward"
                            // role="tab"
                            // aria-controls="pills-reward"
                            // aria-selected="false"
                          >
                            <span className="Circle"></span>
                            Reward Offer{" "}
                            {offerBankArray?.length > 1 && (
                              <span style={{ color: "#3D8ECC" }}>
                                ({offerBankArray?.length})
                              </span>
                            )}
                          </Link>
                        </li>
                      )}
                      {value.ruleType.value === 6 && (
                        <li className="nav-item" role="presentation">
                          <Link
                            to=""
                            className={`${
                              step === 6
                                ? "active"
                                : step > 6
                                ? "completed"
                                : ""
                            }`}
                            style={{ cursor: "default" }}
                            id="pills-reward-tab"
                            // data-bs-toggle="pill"
                            // data-bs-target="#pills-reward"
                            // role="tab"
                            // aria-controls="pills-reward"
                            // aria-selected="false"
                          >
                            <span className="Circle"></span>
                            Notifications
                          </Link>
                        </li>
                      )}

                      <li className="nav-item" role="presentation">
                        <Link
                          to=""
                          className={`${step === 7 ? "active" : ""}`}
                          style={{ cursor: "default" }}
                          // id="pills-Preview-tab"
                          // data-bs-toggle="pill"
                          // data-bs-target="#pills-Preview"
                          // role="tab"
                          // aria-controls="pills-Preview"
                          // aria-selected="false"
                        >
                          <span className="Circle"></span>
                          Preview
                        </Link>
                      </li>
                    </ul>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="Wizard_Content mb-100">
            <Container fluid>
              <Row className="justify-content-center">
                <Col xxl={8} xl={10} xs={12}>
                  <div>
                    {step === 1 && (
                      <Step1
                        handleStep={handleStep}
                        handleInput={handleInput}
                        handleSubTypeUpcs={handleSubTypeUpcs}
                        handleUpcs={handleUpcs}
                        handleManualInput={handleManualInput}
                        handleStepper={handleStepper}
                        handleValidation={handleValidation}
                        departmentData={departmentData}
                        departmentLoading={departmentLoading}
                        ruleTypeData={ruleTypeData}
                        ruleTypeLoading={ruleTypeLoading}
                        subTypeData={subTypeData}
                        subTypeLoading={subTypeLoading}
                        subTypeMessage={subTypeMessage}
                        prevSubTypeMessage={prevSubTypeMessage}
                        validationData={validationData}
                        validationLoading={validationLoading}
                        validationMessage={validationMessage}
                        prevValidationMessage={prevValidationMessage}
                        validation={validation}
                        stepper={stepper}
                        info={value}
                        mode={mode === "view" ? true : false}
                        editMode={mode === "edit"}
                        checkMode={mode}
                        ruleTypeDropdown={dropdown.ruleType}
                        subTypeDropdown={dropdown.subType}
                        departmentDropdown={dropdown.departments}
                        singleRuleLoading={singleRuleLoading}
                        handleMultiSelectInput={handleMultiSelectInput}
                        blockInvalidChar={blockInvalidChar}
                        setUpcs={setUpcs}
                        upcs={upcs}
                      />
                    )}
                    {step === 2 && (
                      <Step5
                        handleInput={handleInput}
                        handleToggle={handleToggle}
                        handleCheckBox={handleCheckBox}
                        handleStep={handleStep}
                        handleNotificationInput={handleNotificationInput}
                        frequencyData={frequencyData}
                        frequencyLoading={frequencyLoading}
                        info={value}
                        mode={mode === "view" ? true : false}
                        datesMode={
                          mode === "view"
                            ? true
                            : false || mode === "edit"
                            ? true
                            : false
                        }
                        editMode={mode === "edit"}
                        emailMode={mode}
                        setEmails={setEmails}
                        emails={emails}
                        frequencyDropdown={dropdown.frequency}
                        handleSmsNotificationInput={handleSmsNotificationInput}
                        handlePostNotificationInput={
                          handlePostNotificationInput
                        }
                        handleEmailNotificationInput={
                          handleEmailNotificationInput
                        }
                        blockInvalidChar={blockInvalidChar}
                        handleUpcs={handleUpcs}
                      />
                    )}
                    {step === 3 && (
                      <Step2
                        handleStep={handleStep}
                        handleInput={handleInput}
                        validationData={validationData}
                        validationLoading={validationLoading}
                        validationMessage={validationMessage}
                        prevValidationMessage={prevValidationMessage}
                        validation={validation}
                        stepper={stepper}
                        duringLoading={duringLoading}
                        info={value}
                        mode={mode === "view" ? true : false}
                        editMode={mode === "edit"}
                        duringDropdown={dropdown.during}
                        blockInvalidChar={blockInvalidChar}
                        handleMultiSelectInput={handleMultiSelectInput}
                        handleUpcs={handleUpcs}
                        groupsDropdown={dropdown.groups}
                      />
                    )}

                    {step === 4 && (
                      <Step3
                        handleStep={handleStep}
                        groupsDropdown={dropdown.groups}
                        handleMultiSelectInput={handleMultiSelectInput}
                        handleMultiStores={handleMultiStores}
                        handleInput={handleInput}
                        stores={stores}
                        ZipCodeUpcs={ZipCodeUpcs}
                        setZipCodeUpcs={setZipCodeUpcs}
                        handleUpcs={handleUpcs}
                        validation={validation}
                        validationData={validationData}
                        storesLoading={storesLoading}
                        shopperLoading={shopperLoading}
                        shopperData={shopperData}
                        info={value}
                        singleRuleData={singleRuleData}
                        setInfo={setValue}
                        valueDrop={valueDrop}
                        setValueDrop={setValueDrop}
                        mode={mode === "view" ? true : false}
                        editMode={mode === "edit"}
                        editView={mode}
                        emailMode={mode}
                        shopperDropdown={dropdown.shoppers}
                        handleZipCodes={handleZipCodes}
                        handleStepNextLimitsAndstores={
                          handleStepNextLimitsAndstores
                        }
                        formError={formError}
                        handleChange={handleChange}
                        handleZipCodesValidations={handleZipCodesValidations}
                        setFormError={setFormError}
                        blockInvalidChar={blockInvalidChar}
                        zipcodeData={zipcodeData}
                        handleStoresCheck={handleStoresCheck}
                        storesCheckBox={storesCheckBox}
                        setStoresCheckBox={setStoresCheckBox}
                        blockInvalidCharMin={blockInvalidCharMin}
                      />
                    )}

                    {step === 5 && (
                      <Step4
                        selectedCheckbox={selectedCheckbox}
                        setSelectedCheckbox={setSelectedCheckbox}
                        validation={validation}
                        offerCheckBox={offerCheckBox}
                        setOfferCheckBox={setOfferCheckBox}
                        couponInitialObject={couponInitialObject}
                        couponArray={couponArray}
                        setCouponArray={setCouponArray}
                        departmentData={departmentData}
                        departmentLoading={departmentLoading}
                        offerData={offerData}
                        offerLoading={offerLoading}
                        handleInput={handleInput}
                        handleStep={handleStep}
                        handleMultiSelectInput={handleMultiSelectInput}
                        handleUpcs={handleUpcs}
                        handleCheckBox={handleCheckBox}
                        info={value}
                        mode={mode === "view" ? true : false}
                        editMode={mode === "edit"}
                        deptData={dropdown.departments}
                        disableField={disableField}
                        validationData={validationData}
                        createGroupsCheckBox={createGroupsCheckBox}
                        formError={formError}
                        setFormError={setFormError}
                        handleChange={handleChange}
                        handleStepNextRewardOffer={handleStepNextRewardOffer}
                        basketDisable={basketDisable}
                        upcDisable={upcDisable}
                        handleOfferType={handleOfferType}
                        offerDropdown={dropdown.offerType}
                        couponLimitDisable={couponLimitDisable}
                        dowDisabled={dowDisabled}
                        featuredDisabled={featuredDisabled}
                        modeDetails={mode}
                        weeklyDisabled={weeklyDisabled}
                        setUpcDisable={setUpcDisable}
                        setBasketDisable={setBasketDisable}
                        handleCouponTimesInput={handleCouponTimesInput}
                        frequencyDropdown={dropdown.frequency}
                        setValue={setValue}
                        value={value}
                        SingleCouponData={singleRuleData?.coupon}
                        handleEmailNotificationInput={
                          handleEmailNotificationInput
                        }
                        handlePostNotificationInput={
                          handlePostNotificationInput
                        }
                        handleNotificationInput={handleNotificationInput}
                        handleSmsNotificationInput={handleSmsNotificationInput}
                        radioChange={radioChange}
                        suggestRadio={suggestRadio}
                        bankRadio={bankRadio}
                        blockInvalidChar={blockInvalidChar}
                        bankOfferData={bankOfferData}
                        offerBankArray={offerBankArray}
                        setOfferBankArray={setOfferBankArray}
                        setChecked={setChecked}
                        checked={checked}
                        rewardOfferChange={rewardOfferChange}
                        handleExpirePrevCoupon={handleExpirePrevCoupon}
                      />
                    )}
                    {step === 6 && value.ruleType.value === 6 && (
                      <Notifications
                        notificationArray={notificationArray}
                        setNotificationArray={setNotificationArray}
                        handleStep={handleStep}
                        info={value}
                        mode={mode === "view" ? true : false}
                        editMode={mode === "edit"}
                        modeDetails={mode}
                        setValue={setValue}
                        SingleNotificationData={singleRuleData?.notifications}
                      />
                    )}

                    {step === 7 && (
                      <Step6
                        id={id}
                        valueDrop={valueDrop}
                        setStoresCheckBox={setStoresCheckBox}
                        modeofOps={mode}
                        mode={mode === "view" ? true : false}
                        editMode={mode === "edit"}
                        handleStep={handleStep}
                        info={value}
                        setInfo={setValue}
                        validation={validation}
                        couponArray={couponArray}
                        storesData={storesData}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
