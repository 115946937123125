/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import Nextarrow from "../../assets/images/next-arrow.svg";
import { getDashboardDepartmentsData, getDashboardFocusItemssData, getDashboardShopperGroupsData, getSegementData } from "../../redux/API";
import { useDispatch, useSelector } from "react-redux";
function GroupsDepartments({
    handleStep,
    value,
    handleInput,
    resetMulitpleValues,
    dropdown,
    handleStoreChange
}) {
    const dispatch = useDispatch();
    const segementDataLoading = useSelector((state) => state.getSegementDataLoading);
    const shopperGroupsLoading = useSelector((state) => state.dashboardShopperGroupsLoading);
    const departmentsLoading = useSelector((state) => state.dashboardDepartmentsLoading);
    const focusItemsLoading = useSelector((state) => state.dashboardFocusItemsLoading);
    const [formError, setFormError] = useState({
        departments: "",
        focusItems: "",
        storeId: "",
        focusDepartment: "",
        segments: ""
    });
    const grpDepartmentsClick = (e) => {
        e.preventDefault();
        if (value.ShopperFilter.length === 0) {
            setFormError((prev) => {
                return { ...prev, segments: "Please select Shopper Group Segment" };
            });
        }
        else {
            handleStep("next");
        }
    }
    const handleChange = (name) => {
        setFormError((prev) => {
            return { ...prev, [name]: "" };
        });
    };
    useEffect(() => {
        let data = {
            "storeid": value.storeId === "0" ? 0 : parseInt(value.storeId),
            "group_ids": Object.keys(value.shopperGroups).length > 0 ? [String(value.shopperGroups?.value)] : [String(value.groupId)],
            "departments_ids": value.selectedDepartments.length > 0 ? value.selectedDepartments.map((num) => {
                return parseInt(num.value);
            }) : [],
            "focus_ids": value.selectedFocusItems.length > 0 ? value.selectedFocusItems.map((num) => {
                return String(num.value);
            }) : []
        }
        dispatch(getSegementData(data));
    }, [value.shopperGroups, value.selectedDepartments, value.selectedFocusItems])

    useEffect(() => {
        handleChange("focusDepartment");
        switch (parseInt(value.focusDepartmentId)) {
            case 1:
                handleInput([], "selectedDepartments");
                break;
            case 2:
                handleInput([], "selectedFocusItems");
                break;
            case 3:
                resetMulitpleValues("selectedFocusItems", "selectedDepartments");
                break;
            default:
                resetMulitpleValues("selectedFocusItems", "selectedDepartments");
                break;
        }
    }, [value.focusDepartmentId]);

    return (
        <React.Fragment>
            <div className="Content_Wrapper_Wizard  pt-50 new-promotion">
                <div className="Wizard mt-30">
                    <div className="Wizard_Content mb-100">
                        <div className="container-fluid">
                            <div className="justify-content-center row">
                                <div className="col-xxl-11 col-xl-11 col-12">
                                    <div className="Wizard_Content_Card Rule_Type_Info promotion-card">
                                        <div className="g-3 row">
                                            <div className="col-xl-4 col-md-4 col-sm-4 col-12">
                                                <div className="position-relative mb-15">
                                                    <label className="form-label">Select Store</label>
                                                    <div>
                                                        <select
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                handleStoreChange(e.target.value);
                                                                dispatch(getDashboardDepartmentsData(e.target.value, 0, 0));
                                                                dispatch(getDashboardFocusItemssData(e.target.value, 0, 'All'));
                                                                dispatch(getDashboardShopperGroupsData(e.target.value, -1, 0));
                                                            }}
                                                            value={value.storeId}>
                                                            <option key="0" value="0" selected>All Stores</option>
                                                            {
                                                                dropdown.stores?.map((store) => (<option key={store.id} value={store.id}>{store.Name}</option>))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-4 col-sm-4 col-12">
                                                <label className="form-label">Select Shopper Groups:</label>
                                                {!shopperGroupsLoading ?
                                                    (
                                                        < Form.Group >
                                                            <Select
                                                                options={dropdown.shopperGroups}
                                                                placeholder={"Select Shopper Groups..."}
                                                                value={value.shopperGroups}
                                                                onChange={(e) => {
                                                                    handleInput(e, "shopperGroups");
                                                                }}
                                                                className="react-select-container select mb-15"
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Spinner animation="border" variant="primary" />
                                                    )}
                                            </div>
                                            <div className="col-xl-4 col-md-4 col-sm-4 col-12">
                                            </div>
                                            <div className="col-xl-4 col-md-4 col-sm-6 col-12 promotion-items">
                                                <div className="position-relative">
                                                    <label className="form-label">Focus Items or Departments</label>
                                                    <div>
                                                        <select id="selFocusDept" value={value.focusDepartmentId}
                                                            onChange={(e) => {
                                                                handleInput(e.target.value, "focusDepartmentId");
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            <option value="0" selected>All Focus Items and Departments</option>
                                                            <option value="2">Departments</option>
                                                            <option value="1" >Focus Items</option>
                                                        </select>
                                                    </div>
                                                    {formError.focusDepartment && (
                                                        <p style={{ color: "red" }} className="error-message">
                                                            {formError.focusDepartment}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-4 col-sm-6 col-12 promotion-items">

                                                <div className="position-relative mb-10">
                                                    <label className="form-label">Select Departments:</label>
                                                    {!departmentsLoading ?
                                                        (
                                                            <Form.Group>
                                                                <Select
                                                                    isMulti
                                                                    options={dropdown.departments}
                                                                    placeholder={"Select departments..."}
                                                                    isDisabled={!(value.focusDepartmentId === "2")}
                                                                    value={value.selectedDepartments}
                                                                    onChange={(e) => {
                                                                        handleInput(e, "selectedDepartments");
                                                                    }}
                                                                    className="react-select-container select"
                                                                />
                                                            </Form.Group>
                                                        ) :
                                                        (
                                                            <Spinner animation="border" variant="primary" />
                                                        )}
                                                </div>
                                                <div className="position-relative">
                                                    <div className="focus-items mb-8">
                                                        <label className="form-label mb-0">Select Focus items</label>
                                                    </div>
                                                    {!focusItemsLoading ? (
                                                        <Form.Group>
                                                            <Select
                                                                isMulti
                                                                options={dropdown.focusItems}
                                                                placeholder={"Select Focus Items..."}
                                                                isDisabled={!(value.focusDepartmentId === "1")}
                                                                value={value.selectedFocusItems}
                                                                className="react-select-container select"
                                                                onChange={(e) => {
                                                                    handleInput(e, "selectedFocusItems");
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    ) :
                                                        (
                                                            <Spinner animation="border" variant="primary" />
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-4 col-sm-6 col-12 promotion-items">

                                                <div className="position-relative mb-15">
                                                    <label className="form-label">Select Shopper Group Segments</label>
                                                    <div>
                                                        {!segementDataLoading ? (
                                                            <select
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    handleChange("segments");
                                                                    handleInput(e.target.value, "ShopperFilter");
                                                                }}
                                                                value={value.ShopperFilter}>
                                                                <option key="0" value="0" selected>Select Segment</option>
                                                                {
                                                                    dropdown?.segments?.map((segment) => (<option key={segment.value} value={segment.value}>{segment.label}</option>))
                                                                }
                                                            </select>
                                                        ) : (
                                                            <Spinner animation="border" variant="primary" />
                                                        )
                                                        }

                                                    </div>
                                                    {formError.segments && (
                                                        <p style={{ color: "red" }} className="error-message">
                                                            {formError.segments}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="flex align-items-center justify-content-end text-end">
                                            <button type="button"
                                                onClick={(e) => {
                                                    grpDepartmentsClick(e);
                                                }}
                                                className="BlueButton ml-10 mt-40 btn btn-primary">
                                                Next <img src={Nextarrow} className="img-fluid ml-5" alt="Rules" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}
export default GroupsDepartments;
