import React, { useEffect } from "react";

import Rules from "../../assets/images/rules.svg";
import Offers from "../../assets/images/offer.svg";
import Notification from "../../assets/images/notification.svg";
import DigitalViews from "../../assets/images/digital-views.svg";
import ShoppersReturned from "../../assets/images/shopper-returned.svg";
import RevenueGenerated from "../../assets/images/revenue-generated.svg";
import PreferredDepartment from "../../assets/images/preferred-department.svg";
import { fetchSummaryData } from "../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function DashboardSummary() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.summaryData);
  const summaryDataLoading = useSelector((state) => state.summaryLoading);
  const summaryMessage = useSelector((state) => state.summaryMessage);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if(summaryMessage === "Internal server error."){
  //     navigate("/login")
  //   }
  // })

  useEffect(() => {
    dispatch(fetchSummaryData());
  }, [dispatch]);

  return (
    <div className="col-xxl-9 col-xl-8 col-12">
      <div className="Card OverView">
        <h5 className="OverView_Ttl">Overview</h5>

        {summaryDataLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Spinner animation="border" variant="primary" sizing={"lg"} />
          </div>
        ) : (
          <div className="row g-3 mt-20">
            <div className="col-xxl-3 col-xl-4 col-md-6 col-12">
              <div className="Overview_Card">
                <div className="Icon">
                  <img src={Rules} className="img-fluid" alt="Rules" />
                </div>
                <div className="Overview_Card_Content text-end">
                  <span>Rules</span>
                  <h5>{data?.Message?.rules}</h5>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-12">
              <div className="Overview_Card">
                <div className="Icon">
                  <img src={Offers} className="img-fluid" alt="Offers" />
                </div>
                <div className="Overview_Card_Content text-end">
                  <span>Offers</span>
                  <h5>{data?.Message?.offers}</h5>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-12">
              <div className="Overview_Card">
                <div className="Icon">
                  <img
                    src={Notification}
                    className="img-fluid"
                    alt="Notifications"
                  />
                </div>
                <div className="Overview_Card_Content text-end">
                  <span>Notifications</span>
                  <h5>{data?.Message?.notifications}</h5>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-12">
              <div className="Overview_Card">
                <div className="Icon">
                  <img
                    src={DigitalViews}
                    className="img-fluid"
                    alt="Digital Views"
                  />
                </div>
                <div className="Overview_Card_Content text-end">
                  <span>Digital Views</span>
                  <h5>{data?.Message?.digitalviews}</h5>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-12">
              <div className="Overview_Card">
                <div className="Icon">
                  <img
                    src={ShoppersReturned}
                    className="img-fluid"
                    alt="Shoppers Returned"
                  />
                </div>
                <div className="Overview_Card_Content text-end">
                  <span>Shoppers Returned</span>
                  <h5>{data?.Message?.shoppersreturned}</h5>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-12">
              <div className="Overview_Card">
                <div className="Icon">
                  <img
                    src={RevenueGenerated}
                    className="img-fluid"
                    alt="Revenue Generated"
                  />
                </div>
                <div className="Overview_Card_Content text-end">
                  <span>Revenue Generated</span>
                  <h5>{data?.Message?.revenuegenerated}</h5>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-12">
              <div className="Overview_Card">
                <div className="Icon">
                  <img
                    src={PreferredDepartment}
                    className="img-fluid"
                    alt="preferred Departments"
                  />
                </div>
                <div className="Overview_Card_Content text-end">
                  <span>(preferred Departments)</span>
                  <h5>{data?.Message?.revenuegeneratedfromdepartments}</h5>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-12">
              <div className="Overview_Card">
                <div className="Icon">
                  <img
                    src={RevenueGenerated}
                    className="img-fluid"
                    alt="preferred Departments"
                  />
                </div>
                <div className="Overview_Card_Content text-end">
                  <span>Shoppers Targeted</span>
                  <h5>{data?.Message?.shopperstargeted}</h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardSummary;
