/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartments,
  getTrackedDepartments,
  setDepartments,
} from "../../redux/API";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
function Setting() {
  const dispatch = useDispatch();
  const departmentsList = useSelector((state) => state.departmentData);
  const loading = useSelector((state) => state.departmentLoading);

  const setDepartmentsMessage = useSelector(
    (state) => state.setDepartmentsMessage
  );
  const setDepartmentsLoading = useSelector(
    (state) => state.setDepartmentsLoading
  );
  const trackedDepartments = useSelector(
    (state) => state.getTrackedDepartmentsData
  );
  const trackedDepartmentsMessage = useSelector(
    (state) => state.getTrackedDepartmentsMessage
  );
  const trackedDepartmentsLoading = useSelector(
    (state) => state.getTrackedDepartmentsLoading
  );
  const [departments, updateDepartments] = useState([]);
  useEffect(() => {
    dispatch(getTrackedDepartments());
    dispatch(getDepartments());
  }, [dispatch]);

  useEffect(() => {
    var depts =
      trackedDepartments?.Message?.tracked_depts?.length > 0
        ? trackedDepartments?.Message?.tracked_depts.map((dept) => {
            return parseInt(dept.departmentid);
          })
        : [];
    updateDepartments(depts);
  }, [trackedDepartments]);

  useEffect(() => {
    if (setDepartmentsMessage !== "") {
      if (setDepartmentsMessage === "Successful") {
        toast.success("Departments saved successfully.");
      } else {
        toast.error(setDepartmentsMessage);
      }
    }
  }, [setDepartmentsMessage]);

  const handleDepartment = (e) => {
    if (!e.target.checked) {
      e.target.checked = false;
      updateDepartments(
        departments.filter((item) => item !== parseInt(e.target.id))
      );
    } else {
      //   if (departments.length === 10) {
      //     e.target.checked = false;
      //     toast.warning("You can't select more than 10 departments");
      //     updateDepartments(
      //       departments.filter((item) => item !== parseInt(e.target.id))
      //     );
      //   }
      //   else {
      updateDepartments((departments) => [
        ...departments,
        parseInt(e.target.id),
      ]);
      //   }
    }
  };

  const saveDepartments = (e) => {
    e.preventDefault();
    let data = {
      storeid: 0,
      departmentids: departments,
    };
    dispatch(setDepartments(data));
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
          <div className="container">
            <div className="home-page-contain">
              <div className="department-track">
                <div className="department-track-main-title">
                  <div className="main-title">
                    <h2>Choose Departments to Track</h2>
                  </div>
                  {/* <span>
                    *You can chose up to 10 departments. Need more, contact your
                    account manager.
                  </span> */}
                </div>
                {loading && (
                  <>
                    <div
                      style={{ height: "25vh" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Spinner animation="border" variant="primary" />
                    </div>
                  </>
                )}
                {!loading &&
                trackedDepartmentsMessage === "Successful" &&
                departmentsList?.length > 0 ? (
                  <>
                    <div className="col-md-12 col-sm-12 col-12 department-checkbox">
                      <div className="department-track-main">
                        {departmentsList?.map((item, index) => (
                          <div className="department-track-items">
                            <div className="department-track-lable" id={index}>
                              <input
                                type="checkbox"
                                name={item.Name}
                                checked={
                                  departments.filter(
                                    (i) => i === parseInt(item.id)
                                  ) > 0
                                }
                                id={item.id}
                                onChange={(e) => handleDepartment(e)}
                              />
                              <label for={item.Name}>{item.Name}</label>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="department-item-btn">
                        <div className="department-delete-btn"></div>
                        <div className="department-submit-btn">
                          <button
                            className="cancel-btn"
                            disabled={setDepartmentsLoading}
                            onClick={(e) => {
                              updateDepartments([]);
                            }}
                          >
                            <span>Reset</span>
                          </button>
                          <button
                            className="save-btn"
                            // disabled={departments.length < 1 || departments.length > 10}
                            onClick={(e) => {
                              saveDepartments(e);
                            }}
                          >
                            {setDepartmentsLoading ? (
                              <Spinner animation="border" variant="primary" />
                            ) : (
                              <span>Save</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {departmentsList?.length === 0 && (
                      <div>No Departments found</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Setting;
