/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import MonthlySales from "../MonthlySales/MonthlySales";
import {
  getDashboardOverviewData
} from "../../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ storeId }) {
  const dispatch = useDispatch();
  const salesGraph = useSelector((state) => state.dashboardOverviewData);
  const loading = useSelector((state) => state.dashboardOverviewLoading);
  const [graphData, setGraphData] = useState({});
  useEffect(() => {
    dispatch(getDashboardOverviewData(storeId));
  }, [dispatch, storeId]);

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        font: { size: '29' },
        color: '333',
        position: 'top',
        text: 'Sales Overview',
      },
    },
  };

  useEffect(() => {
    let datasets = [];
    const labels = salesGraph?.Message?.sales_graph.map(item => item.month);
    const nonLoyaltyCount = salesGraph?.Message?.sales_graph?.filter(a => a.non_loyalty > 0);
    const loyaltyCount = salesGraph?.Message?.sales_graph?.filter(a => a.loyalty > 0);
    if (nonLoyaltyCount?.length > 0) {
      let nonloyalty = {
        label: 'Non-Loyalty',
        data: salesGraph?.Message?.sales_graph.map(item => item.non_loyalty),
        backgroundColor: 'rgba(0, 104, 151, 0.5)',
      }
      datasets.push(nonloyalty);
    }
    if (loyaltyCount?.length > 0) {
      let loyalty = {
        label: 'Loyalty',
        data: salesGraph?.Message?.sales_graph.map(item => item.loyalty),
        backgroundColor: 'rgba(100, 201, 254, 0.5)',
      };
      datasets.push(loyalty);
    }
    const data = {
      labels,
      datasets: datasets,
    };
    setGraphData(data);
  }, [salesGraph]);

  return (
    <React.Fragment>
      <div className="sales-item">
        <div className="main-title">
          <h2>sales overview</h2>
        </div>
        <div className="sales-item-wrapper">
          {
            loading &&
            <>
              <div
                style={{ height: "25vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner animation="border" variant="primary" />
              </div>
            </>
          }
          {(!loading && salesGraph?.Message != null) &&
            <>
              <MonthlySales monthlySales={salesGraph?.Message?.month_comparison} />
              <Bar options={options} data={graphData} />
            </>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default BarChart;