/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DepartmentBarChart(props) {
  const salesGraph = useSelector(
    (state) => state.dashboardDepartmentDataAnalysisData
  );
  const loading = useSelector(
    (state) => state.dashboardDepartmentDataAnalysisLoading
  );
  const storeData = JSON.parse(localStorage.getItem("stores"));
  const departmentsData = JSON.parse(localStorage.getItem("departmentsData"));
  const departmentData = departmentsData?.Message?.departments?.filter(
    (a) => a.department_id === parseInt(props.deptId)
  )[0];
  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        font: { size: "29" },
        color: "333",
        position: "top",
        text: "Sales Overview",
      },
    },
  };
  const hanldeStore = (event) => {
    props.storeChange(event.target.value);
  };

  // console.log("departmentData2", props.salesGraph?.Message?.department_details);

  return (
    <React.Fragment>
      <div className="produce-department">
        <div className="produce-department-main-title">
          <div className="department-img-title">
            {/* {props.IsDepartment === "1" ? (
              <div className="department-image">
                {departmentData?.image_url &&
                  <img src={departmentData?.image_url} alt="department image" className="img-fluid" />
                }
              </div>
            ) : ('')} */}
            {props.IsDepartment === "1" && departmentData?.image_url && (
              <div className="department-image">
                <img
                  src={departmentData.image_url}
                  alt="department image"
                  className="img-fluid"
                />
              </div>
            )}

            <div className="main-title">
              <h2>{departmentData?.name} analysis</h2>
            </div>
          </div>

          <div className="title-unit-main-wrapper">
            <div className="department-item-title-num">
              <div className="department-unit-wrap"></div>
              <div className="department-rev-data">
                <div className="department-rev-main-wrap">
                  <div className="department-unit-title">
                    <span>Revenue</span>
                  </div>
                  <div className="department-title-wrap">
                    <div className="department-item-title-year">
                      <div className="department-item-year">
                        {" "}
                        {props.salesGraph?.Message?.department_details?.text_1?.replace(
                          "vs",
                          ""
                        )}
                      </div>
                      <div className="department-item-servay-title">
                        {
                          props.salesGraph?.Message?.department_details
                            ?.prev_sales
                        }
                      </div>
                    </div>
                    <div className="department-item-title-year">
                      <div className="department-item-year">
                        {
                          props.salesGraph?.Message?.department_details
                            ?.currmonth_label
                        }
                      </div>
                      <div className="department-item-servay-title">
                        {
                          props.salesGraph?.Message?.department_details
                            ?.total_sales
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="department-item-servey-wrap">
                  <div className="department-item-servay-num text-success">
                    {props.salesGraph?.Message?.department_details?.trend ===
                    "Up" ? (
                      <div className="department-item-up-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="square"
                            d="m10.5 8.5l-3-3l-3 3"
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      <div className="department-item-down-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="square"
                            d="m4.5 6.5l3 3l3-3"
                          />
                        </svg>
                      </div>
                    )}
                    <span>
                      {
                        props.salesGraph?.Message?.department_details
                          ?.trend_percent
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="department-units-title-num">
              <div className="department-unit-data">
                <div className="department-unit-main-wrapper">
                  <div className="department-unit-wrape">
                    <div className="department-unit-main-wrapper">
                      <div className="department-unit-main">
                        <div className="department-unit-title">
                          <span>units sold</span>
                        </div>
                      </div>
                      <div className="department-units-title-wrap">
                        <div className="department-units-title-year">
                          <div className="department-units-servay-title">
                            {props.salesGraph?.Message?.department_details?.text_1?.replace(
                              "vs",
                              ""
                            )}
                          </div>
                          <div className="department-units">
                            {props.salesGraph?.Message?.department_details
                              ?.prev_unitsold ?? 0}
                          </div>
                        </div>
                        <div className="department-units-title-year">
                          <div className="department-units-servay-title">
                            {
                              props.salesGraph?.Message?.department_details
                                ?.currmonth_label
                            }
                          </div>
                          <div className="department-units">
                            {props.salesGraph?.Message?.department_details
                              ?.total_units_sold ?? 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="department-units-servey-wrap">
                      <div className="department-units-servay-num text-success">
                        {props.salesGraph?.Message?.department_details
                          ?.unit_trend === "Up" ? (
                          <div className="department-item-up-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="square"
                                d="m10.5 8.5l-3-3l-3 3"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <div className="department-units-down-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="square"
                                d="m4.5 6.5l3 3l3-3"
                              ></path>
                            </svg>
                          </div>
                        )}
                        <span>
                          {
                            salesGraph?.Message?.department_details
                              ?.unit_percent
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="department-buyers-title-num">
              <div className="department-unit-data">
                <div className="department-unit-main-wrapper">
                  <div className="department-unit-wrape">
                    <div className="department-unit-main-wrapper">
                      <div className="department-unit-main">
                        <div className="department-unit-title">
                          <span>Unique Buyers</span>
                        </div>
                      </div>
                      <div className="department-units-title-wrap">
                        <div className="department-units-title-year">
                          <div className="department-units-servay-title">
                            {" "}
                            {props.salesGraph?.Message?.department_details?.text_1?.replace(
                              "vs",
                              ""
                            )}
                          </div>
                          <div className="department-units">
                            {
                              props.salesGraph?.Message?.department_details
                                ?.prev_members
                            }
                          </div>
                        </div>
                        <div className="department-units-title-year">
                          <div className="department-units-servay-title">
                            {
                              props.salesGraph?.Message?.department_details
                                ?.currmonth_label
                            }
                          </div>
                          <div className="department-units">
                            {
                              props.salesGraph?.Message?.department_details
                                ?.curr_members
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="department-units-servey-wrap">
                      <div className="department-units-servay-num text-success">
                        {props.salesGraph?.Message?.department_details
                          ?.member_status === "Up" ? (
                          <div className="department-units-up-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="square"
                                d="m10.5 8.5l-3-3l-3 3"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <div className="department-units-down-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="square"
                                d="m4.5 6.5l3 3l3-3"
                              />
                            </svg>
                          </div>
                        )}
                        <span>
                          {
                            props.salesGraph?.Message?.department_details
                              ?.member_percentage
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="produce-department-category">
            <select value={props.storeId} onChange={hanldeStore}>
              <option key="0" value="0" selected>
                All Stores
              </option>
              {storeData &&
                storeData?.map((store) => (
                  <option key={store.id} value={store.id}>
                    {store.Name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {loading && (
          <>
            <div
              style={{ height: "25vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Spinner animation="border" variant="primary" />
            </div>
          </>
        )}
        {!loading && salesGraph?.Message != null && (
          <>
            <Bar options={options} data={props.graphData} />
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default DepartmentBarChart;
