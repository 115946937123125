/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Nextarrow from "../../assets/images/next-arrow.svg";
import Prevarrow from "../../assets/images/prev-arrow.svg";
function StoresPromotion() {
    const storeDropdown = [
        {
            "value": 25,
            "label": "Store 1"
        },
        {
            "value": 18,
            "label": "Store 2"
        },
        {
            "value": 15,
            "label": "Store 3"
        }
    ];
    const storeDropdownValue = [
        {
            "value": 18,
            "label": "Store 2"
        }
    ];

    return (
        <React.Fragment>
            <div className="Content_Wrapper_Wizard  pt-50 store-promo new-promotion">
                <div className="Wizard mt-30">
                    <div className="Wizard_Content mb-100">
                        <div className="container-fluid">
                            <div className="justify-content-center row">
                                <div className="col-xxl-10 col-xl-10 col-12">
                                    <div className="Wizard_Content_Card Rule_Type_Info">
                                        <div className="g-3 row">
                                            <div className="col-md-4 col-sm-4 col-12">
                                                <div className="position-relative">
                                                    <label className="form-label">Choose Stores</label>
                                                    {/* <div className="position-relative form-check">
                                                        <input name="chooseDeptCheckBox" type="checkbox" id="custom-switch" className="form-check-input" />
                                                        <label title="" for="custom-switch" className="offer-detail-lable form-check-label">Select All Stores...</label>
                                                    </div>
                                                    <div>
                                                        <select>
                                                            <option disabled selected>Select</option>
                                                            <option value="Choose Store 1">Choose Store 1</option>
                                                            <option value="Choose Store 2">Choose Store 2</option>
                                                            <option value="Choose Store 3">Choose Store 3</option>
                                                        </select>
                                                    </div> */}
                                                    <Form.Group>
                                                            <Form.Label>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Select all stores..."
                                                                    id="custom-switch"
                                                                    name="chooseDeptCheckBox"
                                                                    checked={false}
                                                                />
                                                            </Form.Label>
                                                            <Select
                                                                isMulti
                                                                options={storeDropdown}
                                                                placeholder={"Please select departments..."}
                                                                value={storeDropdownValue}
                                                                className="react-select-container select"
                                                            />
                                                            <div className="p-17 mt-20">
                                                                <div className="row g-3 mb-20">
                                                                    <div className="col-12"></div>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex align-items-center justify-content-end text-end p-20 pt-0">
                                            <button type="submit" className="GrayButton mt-14  btn btn-primary prev-btn">
                                                <a href="/OfferDetails">
                                                    <img src={Prevarrow} className="img-fluid mr-5" alt="Rules" />Previous
                                                </a>
                                            </button>
                                            <button type="submit" className="BlueButton ml-10 mt-14 btn btn-primary">

                                                <a href="/PreviewPromotion">
                                                    Next<img src={Nextarrow} className="img-fluid ml-5" alt="Rules" />
                                                </a>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StoresPromotion;
