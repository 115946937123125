/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import ShopperGroup from "../Home/ShopperGroup/ShopperGroup";
import DepartmentBarChart from "../Home/DepartmentBarChart/DepartmentBarChart";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardDepartmentDataAnalysisData,
  getDashboardFocusItemssData,
} from "../../redux/API";
import { Spinner } from "react-bootstrap";
import IncomeServay from "../Home/ShopperGroup/IncomeServay/IncomeServay";
import FocusServay from "../Home/FocusGroup/FocusServay/FocusServay";
function Department() {
  const search = useLocation().search;
  const dispatch = useDispatch();
  const deptId = new URLSearchParams(search).get("department_id");
  const id = new URLSearchParams(search).get("store_id");
  const name = new URLSearchParams(search).get("name");
  const salesGraph = useSelector(
    (state) => state.dashboardDepartmentDataAnalysisData
  );
  const loading = useSelector(
    (state) => state.dashboardDepartmentDataAnalysisLoading
  );
  const focusItems = useSelector((state) => state.dashboardFocusItemsData);
  const focusItemsLoading = useSelector(
    (state) => state.dashboardFocusItemsLoading
  );
  const [graphData, setGraphData] = useState({});
  const [storeId, setStoreId] = useState(id);
  const storeChange = (value) => {
    setStoreId(value);
  };
  useEffect(() => {
    dispatch(getDashboardDepartmentDataAnalysisData(storeId, deptId));
  }, [dispatch, storeId, deptId]);

  useEffect(() => {
    dispatch(getDashboardFocusItemssData(storeId ?? 0, 0, "All", deptId ?? 0));
  }, [dispatch, storeId, deptId]);

  useEffect(() => {
    let datasets = [];
    const labels = salesGraph?.Message?.department_sales_graph?.map(
      (item) => item.month
    );
    const nonLoyaltyCount = salesGraph?.Message?.department_sales_graph?.filter(
      (a) => a.non_loyalty > 0
    );
    const loyaltyCount = salesGraph?.Message?.department_sales_graph?.filter(
      (a) => a.loyalty > 0
    );
    if (nonLoyaltyCount?.length > 0) {
      let nonloyalty = {
        label: "Non-Loyalty",
        data: salesGraph?.Message?.department_sales_graph.map(
          (item) => item.non_loyalty
        ),
        backgroundColor: "rgba(0, 104, 151, 0.5)",
      };
      datasets.push(nonloyalty);
    }
    if (loyaltyCount?.length > 0) {
      let loyalty = {
        label: "Loyalty",
        data: salesGraph?.Message?.department_sales_graph.map(
          (item) => item.loyalty
        ),
        backgroundColor: "rgba(100, 201, 254, 0.5)",
      };
      datasets.push(loyalty);
    }
    const data = {
      labels,
      datasets: datasets,
    };
    setGraphData(data);
  }, [salesGraph]);

  return (
    <React.Fragment>
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
          <div className="container">
            <div className="home-page-contain">
              <DepartmentBarChart
                storeId={storeId}
                salesGraph={salesGraph}
                graphData={graphData}
                storeChange={storeChange}
                deptId={deptId}
                PageHeaderTitle={name}
                IsDepartment="1"
              />
              <ShopperGroup storeId={storeId} deptId={deptId} />
              {salesGraph?.Message?.department_based_group?.length > 0 && (
                <div className="income-item">
                  <div className="main-title-wrapper">
                    <div className="main-title">
                      <h2>Department Based Shoppers Groups</h2>
                    </div>
                    <div className="store-link-warapper">
                      <div className="view-link">
                        <Link to="/Focusitem">View all</Link>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    {loading && (
                      <>
                        <div
                          style={{ height: "25vh" }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </>
                    )}
                    {!loading &&
                      salesGraph?.Message != null &&
                      salesGraph?.Message?.department_based_group.map(
                        (item, index) => (
                          <IncomeServay
                            storeId={storeId}
                            deptId={deptId}
                            key={index}
                            surveyData={item}
                          />
                        )
                      )}
                    {salesGraph?.Message?.department_based_group.length ==
                      0 && (
                      <>
                        <div
                          style={{ height: "25vh" }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          No records found
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {focusItems?.Message?.focus_items?.length > 0 && (
                <>
                  <div className="focus-item focus-item-title">
                    <div className="main-title-wrapper">
                      <div className="main-title">
                        <h2>Department based Focus items</h2>
                      </div>
                      <div className="store-link-warapper">
                        <div className="focus-link">
                          <Link to="/FocusGroupItem">
                            + new focus item Group
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="focus-item">
                    <div className="grid">
                      {focusItems?.Message?.focus_items.map((item, index) => (
                        <FocusServay
                          key={index}
                          storeId={storeId ?? 0}
                          focusData={item}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Department;
