/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import NextArrow from "../../assets/images/next-arrow.svg";
import PrevArrow from "../../assets/images/prev-arrow.svg";

export default function Notifications({
  handleStep,
  setValue,
  info,
  mode,
  notificationArray,
  setNotificationArray,
  modeDetails,
  SingleNotificationData,
  editMode,

}) {

  const handleNotificationInput = (value, name, index) => {
    var notificationInput = [...notificationArray];
    var notificationObject = { ...notificationArray[index], [name]: value };
    notificationInput.splice(index, 1, notificationObject);
    setNotificationArray(notificationInput);
  };

  const handleNotificationCheckBox = (e, index) => {
    var notificationInput = [...notificationArray];

    var notificationObject = {
      ...notificationArray[index],
      [e.target.name]: e.target.checked,
    };

    notificationInput.splice(index, 1, notificationObject);
    setNotificationArray(notificationInput);
  };
  useEffect(() => {
    if (modeDetails !== undefined) {
      if (info.NotificationArArray) {
        const updatedSingleNotificationData = info.NotificationArArray?.map((each, i) => {
          return {
            ApRuleNotificationId: each.ApRuleNotificationId ? each.ApRuleNotificationId : 0,
            sendpushnotification: each?.sendpushnotification,
            message: each?.message,
            time: each?.time?.slice(0, 10),
            postfb: each?.postfb,
            fbmessage: each?.fbmessage,
            fbtime: each?.fbtime?.slice(0, 10),
            sendsms: each?.sendsms,
            smsmessage: each?.smsmessage,
            smstime: each?.smstime?.slice(0, 10),
            sendemail: each?.Sendemail,
            emailmessage: each?.emailmessage,
            emailtime: each?.emailtime?.slice(0, 10),
          };
        });
        setNotificationArray(updatedSingleNotificationData);
      } else {
        if (SingleNotificationData.length) {
          const updatedSingleNotificationData = SingleNotificationData?.map((each, i) => {
            return {
              ApRuleNotificationId: each?.ApRuleNotificationId,
              sendpushnotification: each?.SendPushNotification,
              message: each?.PushNotificationMessageText,
              time: each?.PushNotificationTime?.slice(0, 10),
              postfb: each?.PostFB,
              fbmessage: each?.FBMsg,
              fbtime: each?.FBTime?.slice(0, 10),
              sendsms: each?.SendSMS,
              smsmessage: each?.SMSMsg,
              smstime: each?.SMSTime?.slice(0, 10),
              sendemail: each?.SendEmail,
              emailmessage: each?.EmailMsg,
              emailtime: each?.EmailTime?.slice(0, 10),
            };
          });
          setNotificationArray(updatedSingleNotificationData);
        }
      }
    }
  }, [modeDetails]);

  return (
    <div className="Wizard_Content_Card Rule_Type_Info">
      <React.Fragment>
        {
          notificationArray.map((each, i) => (

            <div className="Additional_Setting" key={i}>
              
              <div className="p-20">
              {
                editMode && (
                  <span style={{ color: "red",paddingBottom:"20px",display:"block" }} >This changes will take effect for all upcoming rule runs - already created groups and coupon will not change.</span>
                )
              }
                <Form.Check
                  inline
                  disabled={mode}
                  type="checkbox"
                  label="Send Push Notification"
                  id={`cbPushNotification+${i}`}
                  className="mr-29"
                  name="sendpushnotification"
                  checked={each?.sendpushnotification}
                  onChange={(e) => handleNotificationCheckBox(e, i)}
                />
                <Form.Check
                  inline
                  disabled={mode}
                  type="checkbox"
                  label="Post to Facebook"
                  id={`cbPostToFacebook+${i}`}
                  className="mr-29"
                  name="postfb"
                  checked={each?.postfb}
                  onChange={(e) => handleNotificationCheckBox(e, i)}
                />
                <Form.Check
                  inline
                  disabled={mode}
                  type="checkbox"
                  label="Send SMS"
                  id={`cbSendSMS+${i}`}
                  className="mr-29"
                  name="sendsms"
                  checked={each?.sendsms}
                  onChange={(e) => handleNotificationCheckBox(e, i)}
                />
                <Form.Check
                  inline
                  disabled={mode}
                  type="checkbox"
                  label="Send Email"
                  id={`cbSendEmail+${i}`}
                  className="mr-29"
                  name="sendemail"
                  checked={each?.sendemail}
                  onChange={(e) => handleNotificationCheckBox(e, i)}
                />
              </div>

              <hr className="m-0"></hr>
              {each?.sendpushnotification ? (
                <div className="p-20">
                  <Row className="row g-3">
                    <Col xs={12} lg={6}>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>
                          Enter Push Notification Message Below
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          disabled={mode}
                          placeholder="Enter Push Notification Message"
                          value={each?.message}
                          name="message"
                          onChange={(e) => {
                            handleNotificationInput(
                              e.target.value,
                              "message",
                              i
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Select Notification Time</Form.Label>
                        <Form.Control
                          type="time"
                          id="selectTime"
                          value={each?.time}
                          name="time"
                          disabled={mode}
                          onChange={(e) => {
                            handleNotificationInput(e.target.value, "time", i);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
              {each?.postfb ? (
                <div className="p-20">
                  <Row className="row g-3">
                    <Col lg={6} xs={12}>
                      <Form.Group>
                        <Form.Label>
                          Enter FaceBook Message Below
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          disabled={mode}
                          placeholder="Enter FaceBook Message"
                          value={each?.fbmessage}
                          name="fbmessage"
                          onChange={(e) => {
                            handleNotificationInput(
                              e.target.value,
                              "fbmessage",
                              i
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Select FaceBook Time</Form.Label>
                        <Form.Control
                          type="time"
                          value={each?.fbtime}
                          name="fbtime"
                          disabled={mode}
                          onChange={(e) => {
                            handleNotificationInput(
                              e.target.value,
                              "fbtime",
                              i
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
              {each.sendsms ? (
                <div className="p-20">
                  <Row className="row g-3">
                    <Col xs={12} lg={6}>
                      <Form.Group>
                        <Form.Label>Enter SMS Message Below</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          disabled={mode}
                          placeholder="Enter SMS Message"
                          value={each?.smsmessage}
                          name="smsmessage"
                          onChange={(e) => {
                            handleNotificationInput(
                              e.target.value,
                              "smsmessage",
                              i
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Select SMS Time</Form.Label>
                        <Form.Control
                          type="time"
                          value={each?.smstime}
                          name="smstime"
                          disabled={mode}
                          onChange={(e) => {
                            handleNotificationInput(
                              e.target.value,
                              "smstime",
                              i
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
              {each?.sendemail ? (
                <div className="p-20">
                  <Row className="row g-3">
                    <Col xs={12} lg={6}>
                      <Form.Group>
                        <Form.Label>Enter Email Message Below</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          disabled={mode}
                          placeholder="Enter Email Message"
                          value={each?.emailmessage}
                          name={"emailmessage"}
                          onChange={(e) =>
                            handleNotificationInput(
                              e.target.value,
                              "emailmessage",
                              i
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} xs={12}>
                      <Form.Group>
                        <Form.Label>Select Email Time</Form.Label>
                        <Form.Control
                          type="time"
                          value={each?.emailtime}
                          name="emailtime"
                          disabled={mode}
                          onChange={(e) => {
                            handleNotificationInput(
                              e.target.value,
                              "emailtime",
                              i
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
            </div>

          ))
        }
        <div className="flex align-items-center justify-content-end text-end p-17">
          <Button
            className="GrayButton mt-14"
            type="submit"
            onClick={() => {
              handleStep("previous");
              setValue((prev) => {
                return { ...prev, NotificationArray: notificationArray };
              });
            }}>
            <img src={PrevArrow} alt="Prev Arrow" className="img-fluid mr-5" />
            Previous
          </Button>
          <Button
            className="BlueButton ml-10 mt-14"
            type="submit"
            onClick={() => {
              handleStep("next");
              setValue((prev) => {
                return { ...prev, NotificationArray: notificationArray };
              });

            }}>
            Next
            <img src={NextArrow} alt="Next Arrow" className="img-fluid ml-5" />
          </Button>
        </div>
      </React.Fragment>

    </div>
  );
}
