/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Bakery from "../../../assets/images/Bakery.png";
import Focusicon from "../../../assets/images/focusicon.svg";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function FocusBarTitle(props) {
  const storeData = JSON.parse(localStorage.getItem("stores"));
  const salesGraph = useSelector(
    (state) => state.dashboardFocusItemAnalysisData
  );
  const loading = useSelector(
    (state) => state.dashboardFocusItemAnalysisLoading
  );

  const hanldeStore = (event) => {
    props.storeChange(event.target.value);
  };
  return (
    <React.Fragment>
      {loading && (
        <>
          <div
            style={{ height: "25vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner animation="border" variant="primary" />
          </div>
        </>
      )}
      {!loading && salesGraph?.Message != null && (
        <div className="produce-department-main-title">
          <div className="department-img-title">
            {props.IsDepartment === "1" ? (
              <div className="department-image">
                <img
                  src={Bakery}
                  alt="department image"
                  className="img-fluid"
                />
              </div>
            ) : (
              <div className="focus-icon">
                <img alt="icon" src={Focusicon} className="img-fluid" />
              </div>
            )}
            <div className="main-title">
              <h2>{salesGraph?.Message?.focus_item_details?.name} analysis</h2>
            </div>
          </div>
          <div className="title-unit-main-wrapper">
            <div className="focus-item-title-num">
              <div className="focus-unit-wrap"></div>
              <div className="focus-rev-data">
                <div className="focus-rev-main-wrap">
                  <div className="focus-unit-title">
                    <span>Revenue</span>
                  </div>
                  <div className="focus-title-wrap">
                    <div className="focus-item-title-year">
                      <div className="focus-item-year">
                        {salesGraph?.Message?.focus_item_details?.text_1?.replace(
                          "vs",
                          ""
                        )}
                      </div>
                      <div className="focus-item-servay-title">
                        {salesGraph?.Message?.focus_item_details?.prev_sales}
                      </div>
                    </div>
                    <div className="focus-item-title-year">
                      <div className="focus-item-year">
                        {
                          salesGraph?.Message?.focus_item_details
                            ?.currmonth_label
                        }
                      </div>
                      <div className="focus-item-servay-title">
                        {salesGraph?.Message?.focus_item_details?.total_sales}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="focus-item-servey-wrap">
                  <div className="focus-item-servay-num text-success">
                    {salesGraph?.Message?.focus_item_details?.trend === "Up" ? (
                      <div className="focus-item-up-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="square"
                            d="m10.5 8.5l-3-3l-3 3"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div className="focus-item-down-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="square"
                            d="m4.5 6.5l3 3l3-3"
                          />
                        </svg>
                      </div>
                    )}
                    <span>
                      {salesGraph?.Message?.focus_item_details?.trend_percent}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="focus-units-title-num">
              <div className="focus-unit-data">
                <div className="focus-unit-main-wrapper">
                  <div className="focus-unit-wrape">
                    <div className="focus-unit-main-wrapper">
                      <div className="focus-unit-main">
                        <div className="focus-unit-title">
                          <span>units sold</span>
                        </div>
                      </div>
                      <div className="focus-units-title-wrap">
                        <div className="focus-units-title-year">
                          <div className="focus-units-servay-title">
                            {salesGraph?.Message?.focus_item_details?.text_1?.replace(
                              "vs",
                              ""
                            )}
                          </div>
                          <div className="focus-units">
                            {
                              salesGraph?.Message?.focus_item_details
                                ?.prev_unitsold
                            }
                          </div>
                        </div>
                        <div className="focus-units-title-year">
                          <div className="focus-units-servay-title">
                            {
                              salesGraph?.Message?.focus_item_details
                                ?.currmonth_label
                            }
                          </div>
                          <div className="focus-units">
                            {
                              salesGraph?.Message?.focus_item_details
                                ?.total_units_sold
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-units-servey-wrap">
                      <div class="focus-units-servay-num text-success">
                        {salesGraph?.Message?.focus_item_details?.unit_trend ===
                        "Up" ? (
                          <div class="focus-units-up-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="square"
                                d="m10.5 8.5l-3-3l-3 3"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <div class="focus-units-down-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="square"
                                d="m4.5 6.5l3 3l3-3"
                              />
                            </svg>
                          </div>
                        )}

                        <span>
                          {
                            salesGraph?.Message?.focus_item_details
                              ?.unit_percent
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="focus-buyer-title-num">
              <div className="focus-unit-data">
                <div className="focus-unit-main-wrapper">
                  <div className="focus-buyer-wrape">
                    <div className="focus-unit-main-wrapper">
                      <div className="focus-unit-main">
                        <div className="focus-unit-title">
                          <span>Unique Buyers</span>
                        </div>
                      </div>
                      <div className="focus-units-title-wrap">
                        <div className="focus-units-title-year">
                          <div className="focus-units-servay-title">
                            {salesGraph?.Message?.focus_item_details?.text_1?.replace(
                              "vs",
                              ""
                            )}
                          </div>
                          <div className="focus-units">
                            {
                              salesGraph?.Message?.focus_item_details
                                ?.prev_members
                            }
                          </div>
                        </div>
                        <div className="focus-units-title-year">
                          <div className="focus-units-servay-title">
                            {
                              salesGraph?.Message?.focus_item_details
                                ?.currmonth_label
                            }
                          </div>
                          <div className="focus-units">
                            {
                              salesGraph?.Message?.focus_item_details
                                ?.curr_members
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-units-servey-wrap">
                      <div class="focus-units-servay-num text-success">
                        {salesGraph?.Message?.focus_item_details
                          ?.member_status === "Up" ? (
                          <div class="focus-units-up-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="square"
                                d="m10.5 8.5l-3-3l-3 3"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <div class="focus-units-down-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="square"
                                d="m4.5 6.5l3 3l3-3"
                              />
                            </svg>
                          </div>
                        )}

                        <span>
                          {
                            salesGraph?.Message?.focus_item_details
                              ?.member_percentage
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="produce-department-category">
            <select value={props.storeId} onChange={hanldeStore}>
              <option key="0" value="0" selected>
                All Stores
              </option>
              {storeData &&
                storeData?.map((store) => (
                  <option key={store.id} value={store.id}>
                    {store.Name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default FocusBarTitle;
