/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import Sorting from "../../../assets/images/sorting.svg";
import GroupPromotionsServey from "../GroupPromotionsList/GroupPromotionsServey/GroupPromotionsServey";
import { useDispatch, useSelector } from "react-redux";
import {
  expirePromotion,
  getDashboardShopperGroupsData,
  getPredefinedPromoData,
} from "../../../redux/API";
import expire from "../../../assets/images/expire.png";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import usePrevious from "../../../public/PrevValue";
import DeleteConfirmation from "../../Common/delete-confirmation/DeleteConfirmation";

function PromotionList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const groupId = new URLSearchParams(search).get("group_id") ?? "";
  const [itemOffset, setItemOffset] = useState(0);
  const [sortdesc, setSortDesc] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortItem, setSortItem] = useState("");
  const [keyId, setKeyId] = useState(0);
  const [promotionId, setPromotionId] = useState(0);
  const limitPerPage = 10;
  const [count, SetCount] = useState(false);
  const promotionList = useSelector((state) => state.getPromotionsData);
  const expirePromotionMessage = useSelector(
    (state) => state.expirePromotionMessage
  );
  const totalRecordsLength = promotionList?.Message?.totalcount;
  const pageCount = Math.ceil(totalRecordsLength / limitPerPage);
  const getPromotionsLoading = useSelector(
    (state) => state.getPromotionsLoading
  );
  const expirePromotionsLoading = useSelector(
    (state) => state.expirePromotionLoading
  );
  const prevSortItem = usePrevious(sortItem);
  const createSuccess = useSelector((state) => state.createPromotionMessage);
  const dashboardShopperGroupsData = useSelector(
    (state) => state.dashboardShopperGroupsData?.Details?.shopper_groups
  );
  useEffect(() => {
    dispatch(getPredefinedPromoData(groupId, limitPerPage, itemOffset));
  }, []);

  useEffect(() => {
    if (expirePromotionMessage != "") {
      if (expirePromotionMessage === "Successful") {
        toast.success("Promotion expired successfully.");
        if (sortdesc == 1) {
          dispatch(
            getPredefinedPromoData(
              groupId,
              limitPerPage,
              itemOffset,
              sortItem,
              0
            )
          );
        } else {
          dispatch(
            getPredefinedPromoData(
              groupId,
              limitPerPage,
              itemOffset,
              sortItem,
              1
            )
          );
        }
      } else {
        toast.error(expirePromotionMessage);
      }
    }
  }, [expirePromotionMessage]);

  useEffect(() => {
    if (createSuccess != "") {
      if (createSuccess === "Successful") {
        toast.success("Promotion created successfully.");
        getPredefinedPromoData(groupId, limitPerPage, itemOffset);
      } else {
        toast.error(createSuccess);
      }
    }
  }, [createSuccess]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * limitPerPage) % totalRecordsLength;
    setItemOffset(newOffset);
    dispatch(
      getPredefinedPromoData(
        groupId,
        limitPerPage,
        newOffset,
        sortItem,
        sortdesc
      )
    );
  };
  const handleSort = (e, value) => {
    e.preventDefault();
    setSortItem(value);
    if (prevSortItem === value) {
      if (sortdesc === 1) {
        setSortDesc(0);
        dispatch(
          getPredefinedPromoData(groupId, limitPerPage, itemOffset, value, 0)
        );
      } else {
        setSortDesc(1);
        dispatch(
          getPredefinedPromoData(groupId, limitPerPage, itemOffset, value, 1)
        );
      }
    } else {
      setSortDesc(0);
      dispatch(
        getPredefinedPromoData(groupId, limitPerPage, itemOffset, value, 0)
      );
    }
  };

  const showDeleteModal = (index, id) => {
    setDeleteMessage(`Are you sure you want to expire this Promotion ?`);
    setPromotionId(id);
    setKeyId(index);
    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const submitDelete = (index, id) => {
    dispatch(expirePromotion(id));
    setDisplayConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
      <div className="container">
        <ToastContainer />
      </div>
      </div>
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
            <div className="home-page-contain">
              <div className="promotion-item">
                <div className="main-title">
                  <h2>promotions list</h2>
                </div>
                {!getPromotionsLoading ? (
                  <>
                    <div className="grid">
                      <div className="col-12">
                        <div className="table-responsive">
                          <div className="promotion-card-wrapper">
                            <div className="promotion-card-detail">
                              <div className="col-12">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr className="promo-head">
                                        <th
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            handleSort(e, "OfferName");
                                          }}
                                        >
                                          Offer Title
                                          <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          />
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            handleSort(e, "CouponStart");
                                          }}
                                        >
                                          Start
                                          <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          />
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            handleSort(e, "CouponEnd");
                                          }}
                                        >
                                          Expiry
                                          <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          />
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            handleSort(e, "Clips");
                                          }}
                                        >
                                          Clips
                                          <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          />
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            handleSort(e, "Redemptions");
                                          }}
                                          className="head-width"
                                        >
                                          Redemptions
                                          <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          />
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          //   onClick={(e) => {
                                          //     handleSort(e, "Clips");
                                          //   }}
                                        >
                                          Targeted Group
                                          {/* <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          /> */}
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          //   onClick={(e) => {
                                          //     handleSort(e, "Clips");
                                          //   }}
                                        >
                                          Targeted Shopper Count
                                          {/* <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          /> */}
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          //   onClick={(e) => {
                                          //     handleSort(e, "Clips");
                                          //   }}
                                        >
                                          Total Discount
                                          {/* <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          /> */}
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          //   onClick={(e) => {
                                          //     handleSort(e, "Clips");
                                          //   }}
                                        >
                                          Total Revenue
                                          {/* <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          /> */}
                                        </th>
                                        <th
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            handleSort(e, "PromoStatus");
                                          }}
                                        >
                                          Status
                                          <img
                                            src={Sorting}
                                            className="img-fluid img-fluid ml-5"
                                            alt="Sorting"
                                          />
                                        </th>
                                        <th>actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {promotionList?.Message?.items?.length >
                                      0 ? (
                                        promotionList?.Message?.items?.map(
                                          (eachItem, i) => (
                                            <tr key={i}>
                                              <td>{eachItem.OfferName}</td>
                                              <td>{eachItem.CouponStart}</td>
                                              <td>{eachItem.CouponEnd}</td>
                                              <td>{eachItem.Clips}</td>
                                              <td>{eachItem.Redemptions}</td>
                                              <td>
                                                {
                                                  dashboardShopperGroupsData?.find(
                                                    (each) =>
                                                      each.group_id ===
                                                      eachItem.BQShopperGroup
                                                  )?.name
                                                }
                                              </td>
                                              <td>
                                                {eachItem.TargettedShopperCount}
                                              </td>
                                              <td>{eachItem.TotalDiscount}</td>
                                              <td>{eachItem.TotalRevenue}</td>

                                              <td>{eachItem.PromoStatus}</td>
                                              <td className="action-promo">
                                                {/* <Link
                                                                                                to={eachItem.PromoStatus === "Expired" ? '' : `/NewPromotion?mode=edit`}
                                                                                                className="Table_Icon ml-24"
                                                                                                title="Edit"
                                                                                                style={eachItem.PromoStatus === "Expired" ? { cursor: " not-allowed " } : {}}
                                                                                                
                                                                                            >
                                                                                                <svg width="19" height="18" viewBox="0 0 19 18" fill="#03053d" xmlns="http://www.w3.org/2000/svg"><path d="M13.1099 6.68596L11.6878 5.27196L2.32012 14.586V16H3.74227L13.1099 6.68596ZM14.5321 5.27196L15.9542 3.85796L14.5321 2.44396L13.1099 3.85796L14.5321 5.27196ZM4.57504 18H0.308594V13.757L13.821 0.321961C14.0096 0.13449 14.2654 0.0291748 14.5321 0.0291748C14.7988 0.0291748 15.0546 0.13449 15.2432 0.321961L18.0885 3.15096C18.277 3.33849 18.3829 3.5928 18.3829 3.85796C18.3829 4.12313 18.277 4.37743 18.0885 4.56496L4.57604 18H4.57504Z" fill="current"></path></svg>
                                                                                                <div className="action-title">
                                                                                                    <span>Edit</span>
                                                                                                </div>
                                                                                            </Link> */}
                                                <button
                                                  title="Expire"
                                                  className="Table_Icon ml-24 expiry-btn"
                                                  disabled={
                                                    eachItem.PromoStatus ===
                                                    "Expired"
                                                  }
                                                  onClick={() => {
                                                    showDeleteModal(
                                                      i,
                                                      eachItem.ApPreDefinePromosGuid
                                                    );
                                                  }}
                                                >
                                                  {expirePromotionsLoading &&
                                                  keyId === i ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                      <Spinner
                                                        animation="border"
                                                        variant="primary"
                                                      />
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <img
                                                        src={expire}
                                                        className="img-fluid img-fluid"
                                                        alt="expiry"
                                                      />
                                                      <div className="action-title">
                                                        <span>Expire</span>
                                                      </div>
                                                    </>
                                                  )}
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr style={{ fontWeight: "700" }}>
                                          No records
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Table_Pagination">
                      <div className="mb-0 justify-content-end">
                        <ReactPaginate
                          nextLabel={<i className="fas fa-chevron-right"></i>}
                          onPageChange={handlePageClick}
                          forcePage={currentPage}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={5}
                          pageCount={pageCount}
                          previousLabel={
                            <i className="fas fa-chevron-left"></i>
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination  mb-0 d-flex flex-row justify-content-end"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{ height: "25vh" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Spinner animation="border" variant="primary" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        index={keyId}
        id={promotionId}
        message={deleteMessage}
      />
    </React.Fragment>
  );
}

export default PromotionList;
