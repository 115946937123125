import { Link } from "react-router-dom";
import { deleteUserCall } from "../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import { headers } from "../../public/auth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const Users = ({
  companyusers,
  props_user_guid,
  brandRetailer,
  AlertModal,
  getUserListLoading,
}) => {
  const dispatch = useDispatch();

  let token = window.localStorage.getItem("access_token");
  let myHeaders = headers;
  let decrypted = myHeaders.Authorization;
  const decoded = token ? JSON.parse(window.atob(decrypted.split(".")[1])) : "";
  const Scopes_user = decoded.retailer.scopes;

  const [deleteUserButtonID, setDeleteUserButtonID] = useState("");
  const { deleteUserLoading, deleteUserMessage } = useSelector(
    (state) => state
  );

  const Buyer_user_scope = Scopes_user.includes("buyer:user");
  const Category_user_scope = Scopes_user.includes("category:user");
  const Write_user_scope = Scopes_user.includes("write:user");
  const Write_focusItem_scope = Scopes_user.includes("write:focusitem");
  const all_scope = Scopes_user.includes("all");

  const deleteFunctioncall = (userGUID) => {
    let reqBody = {};
    dispatch(deleteUserCall({ reqBody: reqBody, userGUID: userGUID }));
  };

  useEffect(() => {
    if (deleteUserMessage !== "") toast.success(deleteUserMessage);
  }, [deleteUserMessage]);

  return (
    <>
      <div className="profile-box">
        <div className="d-flex align-items-center justify-content-between User_Tab_Head mb-20">
          <h1 className="account-head mb-0">Users</h1>
          {(Write_user_scope || all_scope) && (
            <Link
              to={{
                pathname: "/AddUser",
                state: {
                  params: {
                    id4: brandRetailer,
                    id5: props_user_guid,
                  },
                },
              }}
              className="Add_User_Button  BlueButton"
            >
              <i className="fas fa-plus mr-3"></i> Add User
            </Link>
          )}
        </div>

        <div className="user-list-wrapper col-12 p-1">
          {getUserListLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              style={{
                marginLeft: "50%",
              }}
            />
          ) : (
            companyusers?.map((user, id) => (
              <div className="User_List_Main user-box">
                {(Write_user_scope || all_scope) && (
                  <div className="buttonContainer">
                    <button>
                      <Link
                        to={{
                          pathname: "/EditUser",
                        }}
                        state={{ user: user }}
                      >
                        Edit
                      </Link>
                    </button>
                    {decoded.username !== user?.email && (
                      <button
                        className="del-btn"
                        onClick={() => {
                          setDeleteUserButtonID(user.user_guid);
                          deleteFunctioncall(user.user_guid);
                        }}
                      >
                        {deleteUserLoading &&
                        deleteUserButtonID === user?.user_guid ? (
                          <div className="loader"></div>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <g fill="none">
                              <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                              <path
                                fill="#3d8ecc"
                                d="M20 5a1 1 0 1 1 0 2h-1l-.003.071l-.933 13.071A2 2 0 0 1 16.069 22H7.93a2 2 0 0 1-1.995-1.858l-.933-13.07A1.017 1.017 0 0 1 5 7H4a1 1 0 0 1 0-2zm-3.003 2H7.003l.928 13h8.138zM14 2a1 1 0 1 1 0 2h-4a1 1 0 0 1 0-2z"
                              />
                            </g>
                          </svg>
                        )}
                      </button>
                    )}
                  </div>
                )}

                <div>
                  <p>
                    <div className="user-details">Name:</div>
                    <span>{user?.first_name + " " + user?.last_name}</span>
                  </p>
                  <p>
                    <div className="user-details">Role:</div>
                    <span>{user?.role_name}</span>
                  </p>
                  <p>
                    <div className="user-details">Email:</div>
                    <span>{user?.email}</span>
                  </p>
                  <p>
                    <div className="user-details">Phone:</div>
                    <span>{user?.phone}</span>
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
