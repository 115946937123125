/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import ShopperGroup from "../Home/ShopperGroup/ShopperGroup";
function ShoppersGroupList() {
    const search = useLocation().search;
    const storeId = new URLSearchParams(search).get("store_id");
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="Content_Wrapper mt-50">
                <div className="container-fluid">
                    <div className="container">
                        <div className="home-page-contain">
                            <ShopperGroup storeId={storeId}/>  
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShoppersGroupList;
