import React from "react";
import { Routes, Route } from "react-router-dom";
import CheckYourMail from "./CheckYourMail/CheckYourMail";
import Coupon from "./Coupon/Coupon";
import Dashboard from "./Dashboard/Dashboard";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Login from "./Login/Login";
import CreatePassword from "./Password/CreatePassword/CreatePassword";
import ForgotPassword from "./Password/ForgotPassword/ForgotPassword";
import Preview from "./Preview/Preview";
import PrivateRoutes from "./PrivateRoutes";
import RuleAnalysis from "./RuleAnalysis/RuleAnalysis";
import FocusGroupItem from "./FocusGroupItem/FocusGroupItem";
import FocusGroupList from "./FocusGroupList/FocusGroupList";
import NewPromotion from "./NewPromotion/NewPromotion";
import OfferDetails from "./NewPromotion/OfferDetails";
import StoresPromotion from "./NewPromotion/StoresPromotion";
import PreviewPromotion from "./NewPromotion/PreviewPromotion";
import Wizard from "./Wizard/Wizard";
import ApproveScreen from "./Approve/ApproveScreen";
import Home from "./Home/Home";
import Department from "./Department/Department";
import Focusitem from "./Focusitem/Focusitem";
import GroupAnalysis from "./GroupAnalysis/GroupAnalysis";
import Performanceitem from "./Performanceitem/Performanceitem";
import Performancebar from "./Performancebar/Performancebar";
import PromotionList from "./Home/PromotionList";
import FocusItemsList from "./FocusItemsList/FocusItemsList";
import ShoppersGroupList from "./ShopperGroupList";
import Setting from "./Settings/Setting";
import Insights from "./Insights/Insights";
import Userlist from "./UserList";
import AddUser from "./UserList/AddUser";
import DepartmentGroupList from "./DepartmentGroupList/DepartmentGroupList";

function Layout() {
  const path = window.location.pathname;
  const locations = [
    "/",
    "/login",
    "/ForgotPassword",
    "/CreatePassword",
    "/CheckYourMail",
  ];
  return (
    <React.Fragment>
      {!locations.includes(path) && <Header />}
      <div style={{ minHeight: "800px" }}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/CheckYourMail" element={<CheckYourMail />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Department" element={<Department />} />
          <Route path="/Focusitem" element={<Focusitem />} />
          <Route path="/GroupAnalysis" element={<GroupAnalysis />} />
          <Route path="/Performanceitem" element={<Performanceitem />} />
          <Route path="/Performancebar" element={<Performancebar />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Rules" element={<Coupon />} />
            <Route path="/ApproveScreen" element={<ApproveScreen />} />
            <Route path="/Wizard" element={<Wizard />} />
            <Route path="/Wizard/:mode/:guid" element={<Wizard />} />
            <Route path="/Wizard/:mode" element={<Wizard />} />
            <Route path="/Preview" element={<Preview />} />
            <Route path="/RuleAnalysis/:ruleguid" element={<RuleAnalysis />} />
            <Route path="/RuleAnalysis" element={<RuleAnalysis />} />
            <Route path="/FocusGroupItem" element={<FocusGroupItem />} />
            <Route path="/NewPromotion" element={<NewPromotion />} />
            <Route path="/OfferDetails" element={<OfferDetails />} />
            <Route path="/FocusGroupList" element={<FocusGroupList />} />
            <Route path="/StoresPromotion" element={<StoresPromotion />} />
            <Route path="/PreviewPromotion" element={<PreviewPromotion />} />
            <Route path="/CreatePassword" element={<CreatePassword />} />
            <Route path="/PromotionList" element={<PromotionList />} />
            <Route path="/FocusItemsList" element={<FocusItemsList />} />
            <Route
              path="/DepartmentGroupList"
              element={<DepartmentGroupList />}
            />

            <Route path="/ShoppersGroupList" element={<ShoppersGroupList />} />
            <Route path="/Insights" element={<Insights />} />
            <Route path="/Setting" element={<Setting />} />
            <Route path="/Userlist" element={<Userlist />} />
            <Route path="/AddUser" element={<AddUser />} />
            <Route path="/EditUser" element={<AddUser />} />
          </Route>
        </Routes>
      </div>
      {!locations.includes(path) && <Footer />}
    </React.Fragment>
  );
}

export default Layout;
