/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import BarChart from "../Home/BarChart/BarChart";

function Performancebar() {


    return (
        <React.Fragment>
            <div className="Content_Wrapper mt-50">
                <div className="container-fluid">
                    <div className="container">
                        <div className="home-page-contain">
                            <BarChart />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Performancebar;
