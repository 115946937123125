import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";

function PurchaseUpcsMultiple({
  eachCouponPurchaseChips,
  mode,
  index,
  setCouponErrorArray,
  couponErrorArray,
  couponArray,
  setCouponArray,

  SingleCouponData,
  handleCouponUPCDelete
}) {
  const [purchaseUpcData, setPurchaseUpcData] = useState([]);
  const [purchaseUpcInput, setPurchaseUpcInput] = useState("");
  let PurchaseFilteredUniq = [];
  const purchaseInputElement = useRef();

  const validateData = (e) => {
    if (["Enter", "Tab", ","].includes(e.key) ||e.code === "Space" || e.keyCode === 32  ) {
      e.preventDefault();
      let upc = e.target.value;
      if (upc.includes(" ")) {
        const arrUpc = upc.split(" ");
        const filtered = arrUpc.filter((item) => item.trim().length > 0);
        if (filtered.every((each) => !isNaN(+each))) {
          const isPerfectLength = filtered.findIndex((each) => each.length < 4);
          if (isPerfectLength >= 0) {
            let updatedError = {
              ...couponErrorArray[index],
              purchaseupcs:
                "Please check one of the UPCS is less than 5 characters"
            };
            let errorArray = [...couponErrorArray];
            let errorObject = updatedError;
            errorArray.splice(index, 1, errorObject);
            setCouponErrorArray(errorArray);
          } else {
            if (true) {
              if (purchaseUpcData.length < 100 && filtered.length < 100) {
                PurchaseFilteredUniq = [...new Set(filtered)];
                PurchaseFilteredUniq.map((each) => {
                  let newArray = [...couponArray];
                  newArray[index].purchaseupcs = [
                    ...newArray[index].purchaseupcs,
                    each?.trim()
                  ];
                  setCouponArray(newArray);
                });

                let updatedError = {
                  ...couponErrorArray[index],
                  purchaseupcs: ""
                };
                let errorArray = [...couponErrorArray];
                let errorObject = updatedError;
                errorArray.splice(index, 1, errorObject);
                setCouponErrorArray(errorArray);
              } else {
                let updatedError = {
                  ...couponErrorArray[index],
                  purchaseupcs:
                    "UPC limit exceded, please mention 100 upcs only"
                };
                let errorArray = [...couponErrorArray];
                let errorObject = updatedError;
                errorArray.splice(index, 1, errorObject);
                setCouponErrorArray(errorArray);
              }
            }

            setPurchaseUpcInput("");
          }
        } else {
          let updatedError = {
            ...couponErrorArray[index],
            purchaseupcs:
              "Entered Data is having Characters , please check and add "
          };
          let errorArray = [...couponErrorArray];
          let errorObject = updatedError;
          errorArray.splice(index, 1, errorObject);
          setCouponErrorArray(errorArray);
        }
      } else {
        if (!isNaN(+upc)) {
          if (upc.length < 4) {
            let updatedError = {
              ...couponErrorArray[index],
              purchaseupcs: "for each UPC : Minimum 4 characters required"
            };
            let errorArray = [...couponErrorArray];
            let errorObject = updatedError;
            errorArray.splice(index, 1, errorObject);
            setCouponErrorArray(errorArray);
          } else {
            if (purchaseUpcData.indexOf(upc) === -1) {
              if (purchaseUpcData.length < 100) {
                if (
                  SingleCouponData?.length === undefined ||
                  (SingleCouponData?.length !== undefined &&
                    SingleCouponData?.length <= index)
                ) {
                  let newArray = [...couponArray];
                  newArray[index].purchaseupcs = [
                    ...newArray[index].purchaseupcs,
                    upc.trim()
                  ];
                  setCouponArray(newArray);
                } else {
                  let newArray = [...couponArray];
                  newArray[index].purchaseupcs = [
                    ...newArray[index].purchaseupcs,
                    upc.trim()
                  ];
                  setCouponArray(newArray);
                }

                let updatedError = {
                  ...couponErrorArray[index],
                  purchaseupcs: ""
                };
                let errorArray = [...couponErrorArray];
                let errorObject = updatedError;
                errorArray.splice(index, 1, errorObject);
                setCouponErrorArray(errorArray);
              } else {
                let updatedError = {
                  ...couponErrorArray[index],
                  purchaseupcs: "UPC limit exceded, limit is 100 UPCS only"
                };
                let errorArray = [...couponErrorArray];
                let errorObject = updatedError;
                errorArray.splice(index, 1, errorObject);
                setCouponErrorArray(errorArray);
              }
            }

            setPurchaseUpcInput("");
          }
        } else {
          let updatedError = {
            ...couponErrorArray[index],
            purchaseupcs: "Please Enter a valid UPC ,Number format required"
          };
          let errorArray = [...couponErrorArray];
          let errorObject = updatedError;
          errorArray.splice(index, 1, errorObject);
          setCouponErrorArray(errorArray);
        }
      }
    }
  };

  const handleDeleteTempUPC = (chip) => {
    let updated = purchaseUpcData.filter((each) => each !== chip);
    setPurchaseUpcData(updated);
  };

  eachCouponPurchaseChips = [...new Set(eachCouponPurchaseChips)];

  return (
    <div>
      <div className="chips-header">
        <span className="chips-title"></span>
        <span className="chips-validation-message">
          <span className=""></span>
        </span>
      </div>
      <div className="chips">
        {eachCouponPurchaseChips &&
          eachCouponPurchaseChips.map((chip, chipIndex) => (
            <span className="ml-5 mt-5" key={chipIndex}>
              <span className="chip-value">{chip}</span>
              <button
                type="button"
                className="chip-delete-button"
                onClick={() => {
                  if (!mode) {
                    handleDeleteTempUPC(chip);
                    handleCouponUPCDelete(index, chip);
                    purchaseInputElement.current.focus();
                  }
                }}>
                <span>x</span>
              </button>
            </span>
          ))}
        <Form.Control
          type="text"
          value={purchaseUpcInput}
          className="chips-input"
          placeholder="Enter UPC..."
          ref={purchaseInputElement}
          disabled={mode}
          onChange={(e) => {
            setPurchaseUpcInput(e.target.value);
          }}
          onKeyDown={(e) => validateData(e)}
          onKeyUp={(e) => {
            if (["Enter", "Tab", ","].includes(e.key) ||e.code === "Space" || e.keyCode === 32  ) {
              setPurchaseUpcInput("");
            }
          }}></Form.Control>
      </div>
    </div>
  );
}

export default PurchaseUpcsMultiple;
