import React from "react";
import { Form } from "react-bootstrap";
function PastedChips({
  infoChipsData,
  mode,
  upcsdata,
  handleDelete,
  upc,
  setUpc,
  setUpcs,
  upcs,
  handleUpcs,
  name,
  setFormError,
  zipCode,
  existing_groups,
  info,
  blockInvalidChar,
  onWheel,
  PlaceHolderText
}) {
  let filteredUniq = [];

  const validateData = (e) => {
    if (["Enter", "Tab", ","].includes(e.key) ||e.code === "Space" || e.keyCode === 32  ) {
      e.preventDefault();

      if (upcsdata) {
        if (upc.includes(" ")) {
          const arrUpc = upc.split(" ");
          const filtered = arrUpc.filter((item) => item.trim().length > 0);

          if (filtered.every((each) => !isNaN(+each))) {
            const isPerfectLength = filtered.findIndex(
              (each) => each.length < 4
            );
            if (isPerfectLength >= 0) {
              setFormError((prev) => {
                return {
                  ...prev,
                  upcs: "Some of Data is having UPC size less than 4 char,please check and Add"
                };
              });
            } else {
              const clubedArray = filtered.concat(upcs);
              if (true) {
                if (upcs.length < 200 && filtered.length < 200) {
                  filteredUniq = [...new Set(clubedArray)];
                  filteredUniq.map((each) =>
                    setUpcs((prev) => [...prev, each.trim()])
                  );
                  setFormError((prev) => {
                    return {
                      ...prev,
                      upcs: ""
                    };
                  });
                } else {
                  setFormError((prev) => {
                    return {
                      ...prev,
                      upcs: "UPC limit exceded, please mention 200 upcs only"
                    };
                  });
                }
              }

              setUpc("");
            }
          } else {
            setFormError((prev) => {
              return {
                ...prev,
                upcs: "Entered Data is having Characters , please check and add "
              };
            });
          }
        } else {
          if (!isNaN(+upc)) {
            if (upc.length < 4) {
              setFormError((prev) => {
                return {
                  ...prev,
                  upcs: "for each UPC : Minimum 4 characters required"
                };
              });
            } else {
              if (upcs.indexOf(upc) === -1) {
                if (upcs.length < 100) {
                  setUpcs((prev) => [...prev, upc.trim()]);

                  setFormError((prev) => {
                    return {
                      ...prev,
                      upcs: ""
                    };
                  });
                } else {
                  setFormError((prev) => {
                    return {
                      ...prev,
                      upcs: "UPC limit exceded, mention 100 upcs only"
                    };
                  });
                }
              }

              setUpc("");
            }
          } else {
            setFormError((prev) => {
              return {
                ...prev,
                upcs: "Please Enter a valid UPC ,Number format required"
              };
            });
          }
        }
      } else if (zipCode) {
        if (upc.includes(" ")) {
          const arrZip = upc.split(" ");
          const filtered = arrZip.filter((item) => item.trim().length > 0);

          if (filtered.every((each) => !isNaN(+each))) {
            const isPerfectLength = filtered.findIndex(
              (each) => each.length !== 5
            );
            if (isPerfectLength >= 0) {
              setFormError((prev) => {
                return {
                  ...prev,
                  zipCode:
                    "Some of Data is having zipcodes size less than 5 char,please check and Add"
                };
              });
            } else {
              const clubedArray = filtered.concat(upcs);
              if (true) {
                if (upcs.length < 1000) {
                  filteredUniq = [...new Set(clubedArray)];
                  filtered.map((each) =>
                    setUpcs((prev) => [...prev, ...infoChipsData, each.trim()])
                  );
                  setFormError((prev) => {
                    return { ...prev, zipCode: "" };
                  });
                } else {
                  setFormError((prev) => {
                    return {
                      ...prev,
                      zipCode:
                        "Zipcode limit exceded, please mention 100 zips only"
                    };
                  });
                }
              }
              setUpc("");
            }
          } else {
            setFormError((prev) => {
              return {
                ...prev,
                zipCode:
                  "Entered Data is having Characters , please check and add "
              };
            });
          }
        } else {
          if (!isNaN(+upc)) {
            if (upc.length < 5 || upc.length > 5) {
              setFormError((prev) => {
                return { ...prev, zipCode: "invalid zipcode - 5 char" };
              });
            } else {
              if (upcs.indexOf(upc) === -1) {
                if (upcs.length < 100) {
                  setUpcs((prev) => [...prev, ...infoChipsData, upc.trim()]);
                  setFormError((prev) => {
                    return { ...prev, zipCode: "" };
                  });
                } else {
                  setFormError((prev) => {
                    return {
                      ...prev,
                      zipCode:
                        "Zipcodes limit exceeded, limit is 100 Zipcodes only"
                    };
                  });
                }
              }
              setUpc("");
            }
            if (upcs.length >= 0) {
              setFormError((prev) => {
                return {
                  ...prev,
                  zipCode: ""
                };
              });
            }
          } else {
            setFormError((prev) => {
              return {
                ...prev,
                zipCode: "Please enter valid zip code : Number format required"
              };
            });
          }
        }
      } else if (existing_groups) {
        if (upc.includes(" ")) {
          const arrUpc = upc.split(" ");
          const filtered = arrUpc.filter((item) => item.trim().length > 0);

          if (filtered.every((each) => !isNaN(+each))) {
            const isPerfectLength = filtered.findIndex(
              (each) => each.length < 0
            );
            if (isPerfectLength >= 0) {
              setFormError((prev) => {
                return {
                  ...prev,
                  existingGroupsIds:
                    "Some of Data is having Group ID size less than 1 char,please check and Add"
                };
              });
            } else {
              const clubedArray = filtered.concat(upcs);
              if (true) {
                if (upcs.length < 100 && filtered.length < 100) {
                  filteredUniq = [...new Set(clubedArray)];
                  filteredUniq.map((each) =>
                    setUpcs((prev) => [...prev, each.trim()])
                  );
                  setFormError((prev) => {
                    return {
                      ...prev,
                      existingGroupsIds: ""
                    };
                  });
                } else {
                  setFormError((prev) => {
                    return {
                      ...prev,
                      existingGroupsIds:
                        "Group ID limit exceded, please mention 100 ids only"
                    };
                  });
                }
              }

              setUpc("");
            }
          } else {
            setFormError((prev) => {
              return {
                ...prev,
                existingGroupsIds:
                  "Entered Data is having Characters , please check and add "
              };
            });
          }
        } else {
          if (!isNaN(+upc)) {
            if (upc.length < 1) {
              setFormError((prev) => {
                return {
                  ...prev,
                  existingGroupsIds:
                    "for each Group ID : Minimum 1 characters required"
                };
              });
            } else {
              if (upcs.indexOf(upc) === -1) {
                if (upcs.length < 100) {
                  setUpcs((prev) => [...prev, upc.trim()]);

                  setFormError((prev) => {
                    return {
                      ...prev,
                      existingGroupsIds: ""
                    };
                  });
                } else {
                  setFormError((prev) => {
                    return {
                      ...prev,
                      existingGroupsIds:
                        "Group ID limit exceded, mention 100 ids only"
                    };
                  });
                }
              }

              setUpc("");
            }
          } else {
            setFormError((prev) => {
              return {
                ...prev,
                existingGroupsIds:
                  "Please Enter a valid Group ID ,Number format required"
              };
            });
          }
        }
      }
    }
  };

  infoChipsData = [...new Set(infoChipsData)];

  return (
    <div>
      <div className="chips-header">
        <span className="chips-title"></span>
        <span className="chips-validation-message">
          <span className=""></span>
        </span>
      </div>
      <div className="chips chips-overflow" style={{ padding: "7px" }}>
        {infoChipsData &&
          infoChipsData.map((chip, i) => (
            <span className="ml-5 mt-5" key={i}>
              <span className="chip-value">{chip}</span>
              <button
                type="button"
                className="chip-delete-button"
                onClick={() => {
                  if (!mode) {
                    handleDelete(chip);
                  }
                }}>
                <span>x</span>
              </button>
            </span>
          ))}

        <Form.Control
          type={info?.existing_groupsList ? "number" : "text"}
          value={upc}
          className="chips-input"
          placeholder={
            info?.existing_groupsList ? "Enter Group ID ..." : PlaceHolderText
          }
          disabled={mode}
          min={1}
          onChange={(e) => {
            setUpc(e.target.value);
          }}
          onWheel={onWheel}
          onKeyDown={(e) => {
            validateData(e);
            // blockInvalidChar(e);
          }}
          onKeyUp={(e) => {
            if (["Enter", "Tab", ","].includes(e.key) ||e.code === "Space" || e.keyCode === 32  ) {
              handleUpcs(upcs, name);
            }
          }}></Form.Control>
      </div>
    </div>
  );
}

export default PastedChips;
