/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreateFocusItemData, getDashboardDepartmentsData, getDashboardFocusItemAnalysisData, editFocusItemData } from "../../redux/API";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Spinner } from "react-bootstrap";

function FocusGroupItem() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const [focusItemData, setFocusItemData] = useState({
    storeid: "All Stores",
    focus_grp_name: "",
    departmentId: 0,
    upcs: [],
    focusid: 0,
  });
  const focusId = new URLSearchParams(search).get("focus_id") ?? "";
  const storeId = new URLSearchParams(search).get("store_id") ?? 0;
  const isEdit = focusId ? true : false;
  const focusItemsData = useSelector((state) => state.dashboardFocusItemAnalysisData);
  const focusItemsDataLoading = useSelector((state) => state.dashboardFocusItemAnalysisLoading);
  const dashboardFocusItemAnalysisMessage = useSelector((state) => state.dashboardFocusItemAnalysisMessage);
  const dashboardFocusItemAnalysisLoading = useSelector((state) => state.dashboardFocusItemAnalysisLoading);

  useEffect(() => {
    if (focusId !== "") {
      debugger;
      dispatch(getDashboardFocusItemAnalysisData(storeId ?? 0, focusId ?? ''));
    }
  }, [dispatch, focusId]);

  useEffect(() => {
    if (focusId !== "") {
      debugger;
      setFocusItemData({
        ...focusItemData,
        storeid: focusItemsData?.Message?.focus_item_details?.storeid,
        focus_grp_name: focusItemsData?.Message?.focus_item_details?.name,
        departmentId: focusItemsData?.Message?.focus_item_details?.departmentid,
        upcs: focusItemsData?.Message?.focus_upcs,
        focusid: focusItemsData?.Message?.focus_item_details?.focus_id
      });
    }
  }, [focusItemsData, focusId]);

  const storeData = JSON.parse(localStorage.getItem("stores"));
  const createSuccess = useSelector((state) => state.createFocusItemMessage);
  const editFocusItemMessage = useSelector((state) => state.editFocusItemMessage);
  const editFocusItemLoading = useSelector((state) => state.editFocusItemLoading);
  const savingFocusItem = useSelector((state) => state.createFocusItemLoading);
  const departmentData = useSelector((state) => state.dashboardDepartmentsData);
  const loading = useSelector((state) => state.dashboardDepartmentsLoading);
  useEffect(() => {
    dispatch(getDashboardDepartmentsData(0, 0, 0));
  }, []);


  const [formError, setFormError] = useState({
    focus_grp_name: "",
    upcs: ""
  });

  useEffect(() => {
    if (createSuccess !== "") {
      if (createSuccess === "Successful") {
        toast.success(`Focus Item created successfully. The newly added focus groups will require up to 24 hours for setup. 
        After this period, the data and analysis will begin to appear. 
        If its more than 24 hours, please contact the support. `);
        navigate('/FocusGroupList');
      }
      else {
        toast.error(createSuccess);
      }
    }
  }, [createSuccess]);

  useEffect(() => {
    if (editFocusItemMessage !== "") {
      if (editFocusItemMessage === "Successful") {
        toast.success(`Focus Item saved successfully.`);
        navigate('/FocusGroupList');
      }
      else {
        toast.error(editFocusItemMessage);
      }
    }
  }, [editFocusItemMessage]);

  useEffect(() => {
    if (dashboardFocusItemAnalysisMessage !== "") {
      if (dashboardFocusItemAnalysisMessage !== "Successful") {
        toast.error(dashboardFocusItemAnalysisMessage);
      }
    }
  }, [dashboardFocusItemAnalysisMessage]);

  const onFocusItemChange = (e) => {
    setFocusItemData({
      ...focusItemData,
      [e.target.name]: e.target.name == 'upcs' ? (e.target.value.includes("\n") ? e.target.value.split('\n') : e.target.value.split(',')) : e.target.value
    });
  };



  const saveBtn = () => {
    if (isEdit) {
      dispatch(editFocusItemData(focusItemData));
    }
    else {
      dispatch(getCreateFocusItemData(focusItemData));
    }

  };

  const validateForm = (e) => {
    e.preventDefault();
    if (focusItemData.focus_grp_name === "" || focusItemData.focus_grp_name === undefined) {
      setFormError((prev) => {
        return { ...prev, focus_grp_name: "Please Enter Valid Focus Group Name" };
      });
    }
    if (focusItemData.upcs.length === 0) {
      setFormError((prev) => {
        return { ...prev, upcs: "Please Enter UPCs" };
      });
    }
    if ((focusItemData.focus_grp_name !== "" || focusItemData.focus_grp_name === undefined) && focusItemData.upcs.length > 0) {
      saveBtn();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="Content_Wrapper mt-50">
        <div className="container-fluid">
          <div className="container">
            <div className="home-page-contain">
              <div className="focus-group-item">
                <div className="main-title">
                  <h2>{focusId ? 'Edit' : 'Add'} focus group</h2>
                </div>
                <form>
                  <div className="col-md-7 col-12 focus-form">
                  {
                   ( (isEdit && focusItemsDataLoading) || 
                      (isEdit && editFocusItemLoading) || 
                      (savingFocusItem) || 
                      (loading) ||
                      (isEdit && dashboardFocusItemAnalysisLoading)) &&
                    <>
                      <div
                        className="d-flex justify-content-center align-items-center spinner-card"
                      >
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </>
                  }
                    <div className="form-controls">
                      <div className="col-sm-4 col-6">
                        <label>Focus Group Name:</label>
                      </div>
                      <div className="col-sm-6 col-6">
                        <input
                          value={focusItemData?.focus_grp_name}
                          disabled={isEdit}
                          className="form-input"
                          type="text"
                          name="focus_grp_name"
                          id="focus_grp_name"
                          onChange={(e) => {
                            setFocusItemData({
                              ...focusItemData,
                              ["focus_grp_name"]: e.target.value
                            });
                          }}
                        />
                        {formError.focus_grp_name && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.focus_grp_name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div class="form-controls">
                      <div class="col-sm-4 col-6"><label>Department</label></div>
                      <div class="col-xl-6">
                        <div class="store-link-warapper">
                          <select
                            name="departmentId"
                            disabled={isEdit}
                            onChange={(e) => {
                              setFocusItemData({
                                ...focusItemData,
                                ["departmentId"]: e.target.value
                              });
                            }}
                            value={focusItemData?.departmentId}>
                            <option key="0" value="0" selected>Select Department</option>
                            {
                              departmentData?.Message?.departments?.map((department) => (<option key={department.department_id} value={department.department_id}>{department.name}</option>))
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-controls">
                      <div className="col-sm-4 col-6">
                        <label>Select Store:</label>
                      </div>
                      <div className="col-xl-6">
                        <div className="store-link-warapper">
                          <select
                            name="storeid"
                            value={focusItemData?.storeid}
                            disabled={isEdit}
                            onChange={(e) => {
                              setFocusItemData({
                                ...focusItemData,
                                ["storeid"]: e.target.value
                              });
                            }}>
                            <option key="0" value="0" selected>All Stores</option>
                            {storeData &&
                              storeData?.map((store) => (<option key={store.id} value={store.id}>{store.Name}</option>))
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-controls">
                      <div className="col-sm-4 col-6">
                        <label>UPC (s)</label>
                      </div>
                      <div className="col-sm-8 col-6">
                        <div className="focus-file">
                          <span>Upload File</span>
                        </div>
                        <textarea
                          name="upcs"
                          rows="5"
                          cols="30"
                          onChange={(e) => {
                            onFocusItemChange(e);
                          }}
                          value={focusItemData?.upcs}></textarea>
                        {formError.upcs && (
                          <p style={{ color: "red" }} className="error-message">
                            {formError.upcs}
                          </p>
                        )}
                        <span className="message-text">
                          Paste UPCs directly from excel or upload.
                        </span>
                      </div>
                    </div>
                    <div className="focus-item-btn">
                      <div className="focus-delete-btn">

                      </div>
                      <div className="focus-submit-btn">
                        <button onClick={() => navigate('/FocusGroupList')} className="cancel-btn">
                          <span>Cancel</span>
                        </button>
                        <button className="save-btn" 
                        onClick={validateForm}
                        disabled={savingFocusItem || 
                                  editFocusItemLoading || 
                                  loading ||
                                  savingFocusItem ||
                                  focusItemsDataLoading ||
                                  dashboardFocusItemAnalysisLoading}>Save
                        </button>
                      </div>
                    </div>
                    <div className="aelert-text">
                      <span className="text-danger">* </span>At the least a day
                      is required, for the analysis to show up for the newly
                      added focus group.
                    </div>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FocusGroupItem;
