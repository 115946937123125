/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ShoppersChart(props) {
    const dispatch = useDispatch();
    const salesGraph = useSelector((state) => state.dashboardFocusItemAnalysisData);
    const loading = useSelector((state) => state.dashboardFocusItemAnalysisLoading);
    const storeData = useSelector((state) => state.storesData);
    const focusItemsdata = useSelector((state) => state.dashboardFocusItemsData);
    const focusData = focusItemsdata?.Message?.focus_items?.filter(a => a.focus_id == props.focusId)[0];
    const options = {
        responsive: true,
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                font: { size: '29' },
                color: '333',
                position: 'top',
                text: 'Sales Overview',
            },
        },
    };

    const labels = salesGraph?.Message?.focus_sales_graph.map(item => item.month);

    const data = {
        labels,
        datasets: [
            {
                label: 'Non-Loyalty',
                data: salesGraph?.Message?.focus_sales_graph.map(item => item.non_loyalty),
                backgroundColor: 'rgba(60, 151, 218, 0.5)',
            },
            {
                label: 'Loyalty',
                backgroundColor: 'rgba(255, 206, 169, 0.5)',
                data: salesGraph?.Message?.focus_sales_graph.map(item => item.loyalty),
            },
            {
                label: 'sale',
                data: salesGraph?.Message?.focus_sales_graph.map(item => item.loyalty),
                backgroundColor: 'rgba(158, 203, 237, 0.5)',
            },
            {
                label: 'shoppers',
                data: salesGraph?.Message?.focus_sales_graph.map(item => item.loyalty),
                backgroundColor: 'rgba(255, 150, 169, 0.5)',
            },
        ],
    };
    const hanldeStore = (event) => {
        props.storeChange(event.target.value);
    }
    return (
        <React.Fragment>
            <div className="produce-department shoppers-chart col-md-6">
            <div className="chart-title">
                <h2>by shoppers group</h2>
            </div>
                {
                    loading &&
                    <>
                        <div
                            style={{ height: "25vh" }}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </>
                }
                {(!loading && salesGraph?.Message != null) &&
                    <>
                        <Bar options={options} data={data} />
                    </>
                }

            </div>
        </React.Fragment>
    );
}

export default ShoppersChart;