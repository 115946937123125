import React from "react";
import { Button, Form } from "react-bootstrap";

class Preview extends React.Component {
  render() {
    return (
      <>
        <div
          className="modal fade Lost_Super_Role_Modal"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {" "}
                  Lost Shopper Rule
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to disable this rule?</p>
              </div>
              <div className="modal-footer border-top-0 pt-0 pb-35 justify-content-center">
                <button type="button" className="btn btn-primary">
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* END:MODAL */}
        <div className="Content_Wrapper_Preview  ">
          <div className="Light_Blue_Bg pt-137 pb-92">
            <div className="container-fluid">
              <div className="row g-3">
                <div className="col-lg-4 col-12 ">
                  <h3 className="Preview_Main_Ttl">Lost Shopper Rule</h3>
                </div>
                <div className="col-lg-8 col-12 ">
                  <div className="Preview_Main_Head justify-content-end">
                    <div className="Status d-flex align-items-center text-nowrap">
                      <span>Status: &nbsp;</span>
                      <span>Draft</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Preview_Block ">
            <div className="container-fluid">
              <ul className="Preview_Info">
                <li>
                  <div className="Preview_Card">
                    <h5>Select Rule Type</h5>
                    <ul className="mt-20">
                      <li>
                        <span>Your Rule:</span>
                        <span>UI / UX Designer</span>
                      </li>
                      <li>
                        <span>Rule:</span>
                        <span>UI / UX Designer</span>
                      </li>
                      <li>
                        <span>Sub-Type:</span>
                        <span> Designer</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="Preview_Card">
                    <h5>Criteria</h5>
                    <ul className="mt-20">
                      <li>
                        <span>Give offer:</span>
                        <span>Basket deal</span>
                      </li>
                      <li>
                        <span>Rule:</span>
                        <span>UI / UX Designer</span>
                      </li>
                      <li>
                        <span>Sub-Type:</span>
                        <span> Designer</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="Preview_Card">
                    <h5>Limits & stores</h5>
                    <ul className="mt-20">
                      <li>
                        <span>Your Rule:</span>
                        <span>Basket deal</span>
                      </li>
                      <li>
                        <span>Rule:</span>
                        <span>UI / UX Designer</span>
                      </li>
                      <li>
                        <span>Sub-Type:</span>
                        <span> Designer</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="Preview_Card">
                    <h5>Reward Offer</h5>
                    <ul className="mt-20">
                      <li>
                        <span>Your Rule:</span>
                        <span>UI / UX Designer</span>
                      </li>
                      <li>
                        <span>Rule:</span>
                        <span>UI / UX Designer</span>
                      </li>
                      <li>
                        <span>Sub-Type:</span>
                        <span> Designer</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="Preview_Card">
                    <h5>Shpper Tiers</h5>
                    <ul className="mt-20">
                      <li>
                        <Button className="Preview_Card_Tier_Buuton">
                          Tiler 1
                        </Button>
                        <Button className="Preview_Card_Tier_Buuton">
                          $1 - $100
                        </Button>
                      </li>
                      <li>
                        <Button className="Preview_Card_Tier_Buuton">
                          Tiler 21
                        </Button>
                        <Button className="Preview_Card_Tier_Buuton">
                          $101 - $200
                        </Button>
                      </li>
                      <li>
                        <Button className="Preview_Card_Tier_Buuton">
                          Tiler 1
                        </Button>
                        <Button className="Preview_Card_Tier_Buuton">
                          $201 - $300
                        </Button>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="Preview_Card">
                    <h5>Rule Schedule</h5>
                    <ul className="mt-20">
                      <li>
                        <span>Your Rule:</span>
                        <span>UI / UX Designer</span>
                      </li>
                      <li>
                        <span>Rule:</span>
                        <span>UI / UX Designer</span>
                      </li>
                      <li>
                        <span>Sub-Type:</span>
                        <span> Designer</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="w-50 Last_block">
                  <div className="Preview_Card">
                    <h5>
                      Status: <span>Draft</span>
                    </h5>
                    <ul className="mt-20 d-flex flex-wrap">
                      <li className="w-50">
                        <span>Last Run:</span>
                        <span>02/01/2022</span>
                      </li>
                      <li className="w-50">
                        <span>Revenue Generated $:</span>
                        <span>100,090</span>
                      </li>
                      <li className="w-50">
                        <span>Offer Created:</span>
                        <span>40</span>
                      </li>
                      <li className="w-50">
                        <span>Shoppers Returned:</span>
                        <span>548</span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="Confirmation_Outer_Block mt-20 mb-30">
            <div className="container-fluid">
              <div className="Confirmation_Inner_Block">
                <div className="row g-3">
                  <div className="col-xl-6 col-12">
                    <Form.Check
                      type="checkbox"
                      label="Click here to confirm"
                      id="custom-switch"
                    />
                    <font color="#FD0404">
                      * please set the rule for targeted first and then release
                      to all
                    </font>
                  </div>
                  <div className="col-xl-6 col-12 ">
                    <ul className="nav justify-content-end">
                      <li>
                        <Button
                          role="button"
                          className="Preview_Btn Blue_Button ">
                          Save
                        </Button>
                      </li>
                      <li>
                        <Button
                          role="button"
                          className="Preview_Btn Gray_Button "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal">
                          Stop rule now
                        </Button>
                      </li>
                      <li>
                        <Button
                          role="button"
                          className="Preview_Btn Blue_Button ">
                          Close
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Preview;
