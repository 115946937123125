/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from "react";
import IncomeServay from "./IncomeServay/IncomeServay";
import {
    getDashboardShopperGroupsData
} from "../../../redux/API";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
function ShopperGroup({ storeId, focusId, deptId, groupId }) {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.dashboardShopperGroupsData);
    localStorage.setItem('shopperGroups', JSON.stringify(data));
    const loading = useSelector((state) => state.dashboardShopperGroupsLoading);
    useEffect(() => {
        dispatch(getDashboardShopperGroupsData(storeId ?? 0, deptId ?? -1, focusId ?? "", groupId ?? 0));
    }, [dispatch, storeId, deptId, focusId, groupId]);
    return (
        <React.Fragment>
            <div className="income-item">
                <div className="main-title-wrapper">
                    <div className="main-title">
                        <h2>shoppers group</h2>
                    </div>
                    {/* <div className="store-link-warapper">
                        <div className="view-link">
                            <a href="/Focusitem">View all</a>
                        </div>
                    </div> */}
                </div>
                <div className="grid">
                    {
                        loading &&
                        <>
                            <div
                                style={{ height: "25vh" }}
                                className="d-flex justify-content-center align-items-center"
                            >
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </>
                    }
                    {(!loading && data?.Message != null) &&
                        data?.Message?.shopper_groups.map((item, index) =>
                            <IncomeServay storeId={storeId} deptId={deptId} key={index} focusId={focusId} surveyData={item} />
                        )
                    }
                    {data?.Message?.shopper_groups.length === 0 &&
                        <>
                            <div
                                style={{ height: "25vh" }}
                                className="d-flex justify-content-center align-items-center"
                            >
                                No records found
                            </div>
                        </>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShopperGroup;
