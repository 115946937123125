/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../../assets/images/logo.png";
import SliderImage1 from "../../../assets/images/slider-image-1.png";
import ButtonArrow from "../../../assets/images/button-arrow.svg";
import Eye from "../../../assets/images/eye.png";
import Hidden from "../../../assets/images/hidden.png";
import Password from "../../../assets/images/password.png";
import { FormText, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { headers, Regex, setSession } from "../../../public/auth";
import usePrevious from "../../../public/PrevValue";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getChangePassword } from "../../../redux/API";

export default function CreatePassword() {
  const [passwordType, setPasswordType] = useState("password");

  let MyHeaders = headers;
  let decrypted = MyHeaders.Authorization;
  let token = window.localStorage.getItem("access_token");
  const decoded = token ? JSON.parse(window.atob(decrypted.split(".")[1])) : "";
  let user_guid = decoded.user_guid;
 
  const dispatch = useDispatch();
  const changePasswordData = useSelector((state) => state.changePasswordData);
  const changePasswordLoading = useSelector(
    (state) => state.changePasswordLoading
  );
  const changePasswordMessage = useSelector(
    (state) => state.changePasswordMessage
  );
  const PrevchangePasswordMessage = usePrevious(changePasswordMessage);

 
 

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000
  };

  const [currentpasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const toggleOldPasswordVisiblity = () => {
    setOldPasswordShown(oldPasswordShown ? false : true);
  };

  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const [value, setValue] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleInput = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    if (e.target.name === "old_password") {
      if (e.target.value === "")
        setCurrentPasswordError("please Enter Current Password");
      else setCurrentPasswordError("");
    } else if (e.target.name === "new_password") {
      if (e.target.value === "")
        setNewPasswordError("Please Enter New Password");
      else setNewPasswordError("");
    } else {
      if (e.target.value === "")
        setConfirmPasswordError("Please Enter Confirm Password");
      else if (e.target.value !== value.new_password)
        setConfirmPasswordError("Passwords does not match.");
      else setConfirmPasswordError("");
    }
  };

  const handleForm = () => {
    if (
      currentpasswordError === "" ||
      newPasswordError === "" ||
      confirmPasswordError === ""
    ) {
      dispatch(getChangePassword(value, user_guid));
    }
  };

  useEffect(() => {
    if (
      changePasswordMessage !== PrevchangePasswordMessage &&
      changePasswordMessage
    ) {
      if (changePasswordMessage === "Password Updated Successfully !!!") {
        toast.success(changePasswordMessage);
        window.location.href = "/login";
      } else toast.error("Please Enter the Password Input Fields...");
    }
  }, [changePasswordData, changePasswordMessage, PrevchangePasswordMessage]);

  return (
    <div className="CommonFormBg SignIn">
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pt-10 pb-10">
            <Link to="/">
              <img src={Logo} className="img-fluid" alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="Wrapper mt-85">
          <div className="row g-4 justify-content-center">
            <div className="col-xl-4 col-lg-6 col-12">
              <div className="Form_Card">
                <div className="Icon">
                  <img src={Password} className="img-fluid" alt="Logo" />
                </div>
                <h2 className="Form_Ttl">Change password?</h2>
                <p className="mt-13">
                  Your new password must be different from previous used
                  passwords.
                </p>
                <form className="mt-34">
                  <Form.Group
                    controlId="old_password"
                    className="mt-15 position-relative"
                  >
                    <Form.Label>
                      Current Password <font className="Text-Red">*</font>
                    </Form.Label>

                    <Form.Control
                      type={oldPasswordShown ? "text" : "password"}
                      placeholder="Current Password"
                      onChange={(e) => handleInput(e)}
                      name="old_password"
                    />
                    <img
                      onClick={toggleOldPasswordVisiblity}
                      src={oldPasswordShown === false ? Eye : Hidden}
                      className="img-fluid InputIcon cursor-pointer password-img"
                      alt="Password"
                    />
                    <FormText className="Text-Red">
                      {" "}
                      {currentpasswordError}
                    </FormText>
                  </Form.Group>
                  <Form.Group
                    controlId="new_password"
                    className="mt-15 position-relative"
                  >
                    <Form.Label>
                      New Password <font className="Text-Red">*</font>
                    </Form.Label>
                    <Form.Control
                      type={newPasswordShown ? "text" : "password"}
                      placeholder="New Password"
                      onChange={(e) => handleInput(e)}
                      name="new_password"
                    />
                    <img
                      onClick={toggleNewPasswordVisiblity}
                      src={newPasswordShown === false ? Eye : Hidden}
                      className="img-fluid InputIcon cursor-pointer password-img"
                      alt="Password"
                    />
                    <FormText className="Text-Red">
                      {" "}
                      {newPasswordError}
                    </FormText>
                  </Form.Group>
                  <Form.Group
                    controlId="confirm_password"
                    className="mt-15 position-relative"
                  >
                    <Form.Label>
                      Confirm Password <font className="Text-Red">*</font>
                    </Form.Label>
                    <Form.Control
                      type={confirmPasswordShown ? "text" : "password"}
                      placeholder="Confirm Password"
                      onChange={(e) => handleInput(e)}
                      name="confirm_password"
                    />
                    <img
                      onClick={toggleConfirmPasswordVisiblity}
                      src={confirmPasswordShown === false ? Eye : Hidden}
                      className="img-fluid InputIcon cursor-pointer password-img"
                      alt="Password"
                    />
                    <FormText className="Text-Red">
                      {" "}
                      {confirmPasswordError}
                    </FormText>
                  </Form.Group>

                  <Button
                    className="FormButton d-block mt-37"
                    onClick={() => handleForm()}
                    disabled={
                      changePasswordLoading ||
                      currentpasswordError ||
                      newPasswordError ||
                      confirmPasswordError
                    }
                  >
                    {changePasswordLoading ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <React.Fragment>
                        Reset Password
                        <img
                          src={ButtonArrow}
                          className="img-fluid ml-11 "
                          alt="Arrow"
                        />
                      </React.Fragment>
                    )}
                  </Button>
                </form>
              </div>
              <ul className="nav CustomLink mb-40 mt-50">
                <li>
                  <a href="https://rsaamerica.com/terms/">Terms</a>
                </li>
                <li>
                  <a href="https://rsaamerica.com/privacy-policy/">Privacy</a>
                </li>
                {/* <li>
                  <a href="#">Home</a>
                </li> */}
              </ul>
            </div>
            <div className="col-xl-4 col-lg-6 col-12 offset-xl-2">
              <Slider {...settings}>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Unlock Rapid Insights, Boost Engagement: Analyze Shopper Group Dynamics by Total Spend, Department, and Item Performance —  Intelligent Automation for Targeted Actions.
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Drive Customer Loyalty Automatically with AI and ML: Personalized Offers, Scheduled Rewards, Seamless Analytics.
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src={SliderImage1}
                    className="img-fluid mx-auto"
                    alt="Logo"
                  />
                  <h3>Autopilot</h3>
                  <p>
                  Effortless Loyalty, Endless Rewards: Tailor-Made Rules for everyday Grocer Needs.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
