/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import Sorting from "../../../assets/images/sorting.svg";
import GroupPromotionsServey from "./GroupPromotionsServey/GroupPromotionsServey";

function GroupPromotionsList() {


    return (
        <React.Fragment>
            <div className="promotion-item">
                <div className="main-title">
                    <h2>promotions</h2>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="table-responsive">
                    <GroupPromotionsServey/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default GroupPromotionsList;
