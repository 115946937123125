/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import PerformanceGroup from "../Home/PerformanceGroup/PerformanceGroup";

function Performanceitem() {


    return (
        <React.Fragment>
            <div className="Content_Wrapper mt-50">
                <div className="container-fluid">
                    <div className="container">
                        <div className="home-page-contain">
                            <PerformanceGroup />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Performanceitem;
